import React, { memo, useEffect, useCallback, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { subscribeNewsletter } from '../../../../services/space';
import './../../SpaceView.scss';
import default_profile_card_bg from '../../../../assets/images/profile_header.png';
import routes from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import SocialLinks from "../../../../components/commons/SocialLinks";

const SpacePasswordProtectedPro = (props) => {
	const navigate = useNavigate();
	const { spaceData, authUserInfo, onPasswordSubmit, displayPasswordError, globalActiveTab } = props;
	const pro_styles = spaceData.pro_style;
	const [subscriptionForm, continueForm] = Form.useForm();
	const [submittedPassword, setSubmittedPassword] = useState(false);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);
	const [alert, setAlert] = useState(null);
	const [alertPassword, setAlertPassword] = useState(null);
	let show_default_video = false;

	let header_background_image =
		spaceData && spaceData.background_video
			? spaceData.background_video
			: default_profile_card_bg;
	header_background_image =
		pro_styles && pro_styles.header_background_image
			? pro_styles.header_background_image
			: header_background_image;

	//google fonts
	const fontTitle = spaceData && pro_styles ? pro_styles.title_font : 'Josefin Sans'; //change value from database
	const fontBody = spaceData && pro_styles ? pro_styles.body_font : 'Bungee Inline'; //change value from database
	const googleFontUrl = 'https://fonts.googleapis.com/css?family=';

	if (fontTitle) {
		const proFontTitle = document.createElement('link');
		proFontTitle.rel = 'stylesheet';
		proFontTitle.href = googleFontUrl + fontTitle.replace(' ', '+');
		document.head.appendChild(proFontTitle);
	}

	if (fontBody) {
		const proFontBody = document.createElement('link');
		proFontBody.rel = 'stylesheet';
		proFontBody.href = googleFontUrl + fontBody.replace(' ', '+');
		document.head.appendChild(proFontBody);
	}

	//Logo & Key Color
	const root = document.documentElement;

	//Key Color and Fonts
	root.style.setProperty('--key-color', pro_styles.key_color);
	root.style.setProperty('--key-color-gradient', pro_styles.gradient_color);
	root.style.setProperty('--title-font', fontTitle);
	root.style.setProperty('--body-font', fontBody);

	//Section Colors & Backgrounds
	//Light Section Text & Background Colors
	root.style.setProperty('--light-section-text-color', pro_styles.light_text_color);
	root.style.setProperty('--light-section-bg-color', pro_styles.light_background_color);
	if (!pro_styles.light_background_video) {
		root.style.setProperty(
			'--light-section-bg-image',
			'url("' + pro_styles.light_background_image + '")'
		);
	}
	root.style.setProperty('--light-section-image-pos', pro_styles.light_image_position);
	root.style.setProperty('--light-section-video-pos', pro_styles.light_video_position);

	//Dark Section Text & Background Colors
	root.style.setProperty('--dark-section-text-color', pro_styles.dark_text_color);
	root.style.setProperty('--dark-section-bg-color', pro_styles.dark_background_color);
	if (!pro_styles.dark_background_video) {
		root.style.setProperty(
			'--dark-section-bg-image',
			'url("' + pro_styles.dark_background_image + '")'
		);
	}
	root.style.setProperty('--dark-section-image-pos', pro_styles.dark_image_position);
	root.style.setProperty('--dark-section-video-pos', pro_styles.dark_video_position);

	let foreground_position = 'center';

	if (pro_styles.foreground_position === 'top' && !pro_styles.foreground_text) {
		foreground_position = 'start';
	} else if (pro_styles.foreground_position === 'bottom' && !pro_styles.foreground_text) {
		foreground_position = 'end';
	}

	let lightVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.light_video_position === 'top') {
		lightVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.light_video_position === 'center') {
		lightVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.light_video_position === 'bottom') {
		lightVideoStyle = {
			bottom: '0'
		};
	}

	let darkVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.dark_video_position === 'top') {
		darkVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.dark_video_position === 'center') {
		darkVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.dark_video_position === 'bottom') {
		darkVideoStyle = {
			bottom: '0'
		};
	}

	const handlePasswordFormSubmit = useCallback(
		async (values) => {
			setSubmittedPassword(true);
			setAlert(null);
			setAlertPassword(null);

			if (!values.password) {
				return;
			}
			const data = {
				username: spaceData.username,
				password: values.password
			};

			onPasswordSubmit(data);
			setSubmittedPassword(false);
		},
		[spaceData, authUserInfo, continueForm]
	);

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		setSubmittedSubscription(true);
		setAlert(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await subscribeNewsletter(data);
		if (response && response.result) {
			setAlert({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlert({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedSubscription(false);
	}, []);

	const redirectToLogin = useCallback(async () => {
		navigate(routes.login());
	}, []);

	const getHeaderBackgroundVideo = () => {
		if(spaceData){
			if (globalActiveTab === 'home') {
				if(pro_styles){
					if(pro_styles?.home_banner_visibility === 'show'){
						if(pro_styles && pro_styles?.bannerBackgroundVideo){
							show_default_video = true;
							return pro_styles?.bannerBackgroundVideo
						}else if(pro_styles && pro_styles?.bannerBackgroundImage){
							show_default_video = false;
							return pro_styles?.bannerBackgroundImage
						}else{

							return default_profile_card_bg;
						}
					}else{
						if(pro_styles?.home_banner_visibility === 'hide'){
							return default_profile_card_bg;
						}

					}
				}else{
					show_default_video = !!spaceData.background_video;
					return spaceData.background_video || spaceData.background_photo
				}

			}else if (globalActiveTab === 'updates') {
				if(pro_styles?.updates_banner_visibility === 'show'){
					if(pro_styles?.updatesBannerImage){
						return pro_styles?.updatesBannerImage
					}else{
						return default_profile_card_bg
					}
				}else{
					return default_profile_card_bg
				}


			}else if (globalActiveTab === 'members') {
				if(pro_styles?.members_banner_visibility === 'show'){
					if(pro_styles?.membersBannerImage){
						return pro_styles?.membersBannerImage
					}else{
						return default_profile_card_bg
					}
				}else{
					return default_profile_card_bg
				}

			}else if (globalActiveTab === 'benefits') {
				if(pro_styles?.benefits_banner_visibility === 'show'){
					if(pro_styles?.benefitsBannerImage){
						return pro_styles?.benefitsBannerImage
					}else{
						return default_profile_card_bg
					}
				}else{
					return default_profile_card_bg
				}

			}else if (globalActiveTab === 'channel') {
				if(pro_styles?.channels_banner_visibility === 'show'){
					if(pro_styles?.channelBannerImage){
						return pro_styles?.channelBannerImage
					}else{
						return default_profile_card_bg
					}
				}else{
					return default_profile_card_bg
				}

			}
			else{
				return default_profile_card_bg
			}
		}
	}

	useEffect(() => {
		if (displayPasswordError) {
			setAlertPassword({
				variant: 'danger',
				message: 'Incorrect space password.'
			});
		}
	}, [displayPasswordError]);

	return (
		<>
			<main id="cs-space-main" className={`space-view-pro cs-main ${pro_styles ? pro_styles?.home_theme === 'alt' ? 'main-theme-alt' : 'main-theme' : 'main-theme'} ${pro_styles && pro_styles?.has_gradient ? 'has-gradient' : 'no-gradient'}`}>
				<section id={`cs-space-banner-hero`} className='p-section-safeview h-5row' //Needs to remove the padding in hero video because padding will show in background hero video
						 style={pro_styles?.bannerBackgroundImage ? {backgroundImage: `url(${getHeaderBackgroundVideo()})`} : {backgroundImage: `url(${getHeaderBackgroundVideo()})`}}
				>

					{show_default_video && (
						<video
							key={getHeaderBackgroundVideo()}
							autoPlay
							muted
							loop
							playsInline
							id="cs-hero-bg-video"
						>
							<source src={getHeaderBackgroundVideo()} type="video/mp4"/>
						</video>
					)}
					{(globalActiveTab !== 'channel') &&
						// CSJS Apply bg-cover-* based on {theme_background_position} value, where * sets background position: center (default), top, bottom, left, right, left-top, left-bottom, right-top, or right-bottom as inline class. Background-image URL is {space_hero_background_image}.
						// ANCHOR OPTION: Hero Image/Text
						<div className={`cs-hero-frame centerframe-grid`}>
							{/* CSJS The default "place-items-center" class changes based on {space_hero_foreground_position}.*/}
							{/* <div className="flex col-span-full">


							{pro_styles && (pro_styles?.header_foreground_image) && (
								<img
									className={`max-h-[416px]  ${pro_styles?.selected_banner_content_position === 'cover' ? 'object-cover' : 'object-' + pro_styles?.selected_banner_content_position}`}
									alt="SPACENAME"
									src={pro_styles?.header_foreground_image}
								/>
							)}
							{(pro_styles && !pro_styles?.header_foreground_image &&  pro_styles?.foreground_text) && (
								<h1 className="text-d2">{pro_styles?.foreground_text}</h1>
							)}

						</div> */}
							{/*<div className="cs-hero-content">*/}
							{/*	{pro_styles && (pro_styles?.header_foreground_image) && (*/}
							{/*		<img src={pro_styles?.header_foreground_image} alt="SPACENAME" className={` ${pro_styles?.selected_banner_content_position === 'cover' ? 'object-cover' : 'object-' + pro_styles?.selected_banner_content_position}`}/>*/}
							{/*	)}*/}
							{/*	{(pro_styles && !pro_styles?.header_foreground_image &&  pro_styles?.foreground_text) && (*/}
							{/*		<h1 className="text-d2">{pro_styles?.foreground_text}</h1>*/}
							{/*	)}*/}
							{/*</div>*/}
						</div>
					}
				</section>
				{/*<div className="w-full profile-banner" style={{backgroundImage: `url(${header_background_image})`}}>*/}
				{/*	<video autoPlay muted loop id="">*/}
				{/*		<source src={pro_styles.header_background_video} type="video/mp4" />*/}
				{/*	</video>*/}
				{/*	<div*/}
				{/*		className={`container sv-h flex justify-center align-items-${foreground_position}`}*/}
				{/*	>*/}
				{/*		{pro_styles.header_foreground_image && !pro_styles.foreground_text && (*/}
				{/*			<img*/}
				{/*				className="fore-ground-img"*/}
				{/*				src={pro_styles.header_foreground_image}*/}
				{/*			/>*/}
				{/*		)}*/}
				{/*		{pro_styles.foreground_text && (*/}
				{/*			<h3 className="header-medium">{pro_styles.foreground_text}</h3>*/}
				{/*		)}*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div className="w-full light-section">
					<div className="split-header pt-3 pb-3 mb-5 text-center">
						<h3 className="w-full">
							Welcome to the {spaceData.name}
						</h3>
					</div>

					<div className="tab-content space-profile-url" id="pills-tabContent">
						<div className="tab-pane show active">
							<div className="container">
								<div className="grid grid-cols-12 justify-center mt-5 mb-5">
									<div className="col-span-12 md:col-span-6 md:col-start-4 text-center">
										<h1 className="header-medium">Password Required</h1>
										{!authUserInfo && (
											<>
												<p className="body-text-black">
													Log in or enter site password to continue.
												</p>
												<button
													className="primary-gradient w-full mt-4"
													onClick={redirectToLogin}
												>
													<span><p>Login</p></span>
												</button>
											</>
										)}

										<Form
											form={continueForm}
											name="continueForm"
											id="continueForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handlePasswordFormSubmit}
											className={'space-settings-error pt-3'}
										>
											<>
												{alertPassword && (
													<div className={`alert callout ${alertPassword.variant === 'danger' ? 'error': 'success'} !m-0`}>
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{alertPassword.message}</h6>
															</div>
														</div>
													</div>
													// <div className="grid grid-cols-12 m-0 p-0">
													// 	<div className="col-span-8 m-0 p-0">
													// 		<Alert
													// 			key={alertPassword.variant}
													// 			variant={alertPassword.variant}
													// 			className={
													// 				'custom-alert ' +
													// 				(alertPassword.variant ===
													// 				'danger'
													// 					? 'custom-alert-danger'
													// 					: 'custom-alert-success')
													// 			}
													// 		>
													// 			{alertPassword.message}
													// 		</Alert>
													// 	</div>
													// </div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Password"
													name="password"
													rules={[{ required: true }]}
												>
													<div className="form-input-combo w-full">
														{/*<label htmlFor="password">Password</label>*/}
														<input id="password"
															   name="password"
															   type="password"
															   className="w-full"
															   onFocus={hideTooltip}
															   onBlur={showTooltip}
															   autoComplete="current-password"
															   required
															   placeholder="Enter site password for access."
														/>
														<button disabled={submittedPassword} type="submit" className="primary-gradient w-3/4">
															<span><p>
																{submittedPassword && (
																	<i className="fa-solid fa-spinner fa-spin"></i>
																)}
																Continue
															</p></span>
														</button>
													</div>
												</Form.Item>
											</div>
										</Form>

										<Form
											form={subscriptionForm}
											name="subscriptionForm"
											id="subscriptionForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleSubscriptionFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												setAlert(null);
											}}
										>
											<>
												{alert && (
													<div className={`alert callout ${alert.variant === 'danger' ? 'error': 'success'} !m-0`}>
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{alert.message}</h6>
															</div>
														</div>
													</div>
													// <div className="grid grid-cols-12 m-0 p-0">
													// 	<div className="col-span-8 m-0 p-0">
													// 		<Alert
													// 			key={alert.variant}
													// 			variant={alert.variant}
													// 			className={
													// 				'custom-alert ' +
													// 				(alert.variant === 'danger'
													// 					? 'custom-alert-danger'
													// 					: 'custom-alert-success')
													// 			}
													// 		>
													// 			{alert.message}
													// 		</Alert>
													// 	</div>
													// </div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													rules={[{ required: true }]}
												>
													<div className="form-input-combo w-full">
														{/*<label htmlFor="password">Password</label>*/}
														<input id="email"
															   name="email"
															   type="email"
															   className="w-full"
															   onFocus={hideTooltip}
															   onBlur={showTooltip}
															   autoComplete="email"
															   required
															   disabled={submittedSubscription}
															   placeholder="Enter email to subscribe to newsletter."
														/>
														<button disabled={submittedSubscription} type="submit" className="secondary-gradient w-3/4">
															<span><p>
																{submittedSubscription && (
																	<i className="fa-solid fa-spinner fa-spin"></i>
																)}
																Subscribe
															</p></span>
														</button>
													</div>
												</Form.Item>
											</div>
											{(spaceData && spaceData?.social_links) && (
												<SocialLinks
													socialLinks={spaceData.social_links}
													parentClassName="cs-stack-link-icons stack-row-wrap"
													childClassName="button-icon-round-gradient-alt"
												/>
											)}
											{/*<ul className="list-socials pt-2 flex list-none">*/}
											{/*	<li className="body-text--small color-lightest text-gray-400">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-twitter isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-instagram isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-facebook isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-tiktok isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-youtube isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-linkedin isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-discord isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-twitch isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li className="body-text--small color-lightest text-white">*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-regular fa-globe isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*</ul>*/}
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpacePasswordProtectedPro);
