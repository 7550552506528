import axios from 'axios';
import { API_DOMAIN } from '../constants/common';
import { getAccessToken } from '../utils/common';
import { assessTokenRequest, getGoogleReCaptchaToken } from './recaptcha';
import routes from '../routes';
import { hidePageContentLoader } from '../states/pageContentLoader';
import {useLocation} from "react-router-dom";
import {CUSTOM_REGEX} from "../constants/custom_regex";

// This function records the reCAPTCHA token and assesses it
const recordRecaptchaAndAssessToken = async (recaptcha_action) => {
	// Get the reCAPTCHA token
	// const token = await getGoogleReCaptchaToken(recaptcha_action);

	// if (token) {
		// Assess the reCAPTCHA token
		return await assessTokenRequest({ token, action: recaptcha_action });
	// }
};

// This is the base function for making HTTP requests
const baseFetch = async (url, options, recaptcha_action = null) => {
	// Construct the request URL using the API domain
	const requestURL = `${API_DOMAIN}${url}`;

	if (recaptcha_action) {
		// Record and assess the reCAPTCHA token if provided
		await recordRecaptchaAndAssessToken(recaptcha_action);
	}

	// Make the HTTP request using axios library
	return axios({
		url: requestURL,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || (status >= 400 && status < 500);
		},
		...options
	})
		.then((response) => {
			if (response.status >= 400 && response.status < 500) {
				if (response.status === 401) {
					// Handle error
				} else if (response.status === 404) {
					// console.log('not found');
				} else if (response.status === 403) {
					// if (
					// 	window.location.hostname === '54.193.122.254' ||
					// 	window.location.hostname === 'common.space'
					// ) {
					// 	// Redirect to a specific URL
					// 	window.location.href = 'https://create.common.space';
					// } else {
						const accessToken = getAccessToken();
						if (accessToken) {
							// Redirect to the dashboard
							window.location.replace(routes.dashboard());
						} else {
							// Redirect to the login page
							// TODO: This comment is added to the code to avoid an endless loop.
							//  If all redirections and queries result in a 403 error, it would otherwise keep looping back here.
							// console.log(window.location.pathname)
							// allowing the Updates Content Page to load
							if(!(CUSTOM_REGEX.NO_ACCESS_UPDATE_PATH.test(window.location.pathname) &&
								!(CUSTOM_REGEX.SPACE_VERIFY_EMAIL_PATH.test(window.location.pathname)))) {
								// window.location.replace(routes.login());
							}
						}
					// }
				} else {
					throw new Error('Something went wrong!');
				}
			}

			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};

// This function makes a GET request
export const getRequest = (url, params, recaptcha_action = null) => {
	const accessToken = getAccessToken();
	// console.log('params: '+params)
	// console.log('accessToken: '+accessToken)
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		},
		params
	};

	let captcha = null;
	if (recaptcha_action) {
		captcha = recaptcha_action;
	}

	return baseFetch(url, requestOptions, captcha);
};

// This function makes a POST request
export const postRequest = (url, body, recaptcha_action = null) => {
	const accessToken = getAccessToken();
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${accessToken}`
		},
		data: body
	};

	let captcha = null;
	if (recaptcha_action) {
		captcha = recaptcha_action;
	}

	return baseFetch(url, requestOptions, captcha);
};

// This function makes a PUT request
export const putRequest = (url, body, recaptcha_action = null) => {
	const accessToken = getAccessToken();
	const requestOptions = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		},
		data: body
	};

	let captcha = null;
	if (recaptcha_action) {
		captcha = recaptcha_action;
	}

	return baseFetch(url, requestOptions, captcha);
};

// This function makes a DELETE request
export const deleteRequest = (url, body, recaptcha_action = null) => {
	const accessToken = getAccessToken();
	const requestOptions = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		},
		data: body
	};

	let captcha = null;
	if (recaptcha_action) {
		captcha = recaptcha_action;
	}

	return baseFetch(url, requestOptions, captcha);
};