import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import ReactQuill from 'react-quill';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import TextareaAutosize from 'react-textarea-autosize';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import { useSelector } from 'react-redux';
import {checkItemShortCodeAvailability, checkMembershipShortCodeAvailability, createSpaceItem} from '../../../../services/space';
import Alert from 'react-bootstrap/Alert';
import routes from '../../../../routes';
import { CurrencyType } from '../../../../utils/MembershipItemUtils';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import { CustomValidators } from '../../../../utils/CustomValidators';
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";

const CreateItemCommunity = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createItemCommunityForm] = Form.useForm();
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [selectedVisibility, setSelectedVisibility] = useState('members');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [enableGiftingChecked, setGiftingChecked] = useState(false);
	const [enableAddNumericChecked, setAddNumericChecked] = useState(false);
	const [itemImage, setItemImage] = useState(null);
	const [itemImageBlobPreview, setItemImageBlobPreview] = useState(null);
	const [itemVideo, setItemVideo] = useState(null);
	const [itemVideoBlobPreview, setItemVideoBlobPreview] = useState(null);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);

	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);

	const [itemImageError, setItemImageError] = useState(null);
	const [itemVideoError, setItemVideoError] = useState(null);

	const setImageUploadErrors = (error) => {
		setItemImageError(error?.message)
	}
	const setVideoUploadErrors = (error) => {
		setItemVideoError(error?.message)
	}

	useEffect(() => {
		createItemCommunityForm.setFieldValue('currency', 'USD');
	});

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const onChangeEnableGifting = (e) => {
		setGiftingChecked(e.target.checked);
	};

	const onChangeEnableAddNumeric = (e) => {
		setAddNumericChecked(e.target.checked);
	};

	const handleItemImageUploadFileChange = (file, blobPreview) => {
		setItemImage(file);
		setItemImageBlobPreview(blobPreview);
	};

	const handleItemVideoUploadFileChange = (file, blobPreview) => {
		setItemVideo(file);
		setItemVideoBlobPreview(blobPreview);
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const handleSubmit = useCallback(
		async (values) => {
			setItemImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!itemImageBlobPreview) {
				setItemImageError('Item Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			setSubmitted(true);
			const data = values;

			data.currency = 'USD';
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.enable_add_numeric_count = enableAddNumericChecked;
			data.enable_gifting = enableGiftingChecked;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.visibility = selectedVisibility;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (itemImage) {
				data.item_image = itemImage;
			}

			if (itemVideo) {
				data.item_video = itemVideo;
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					const response = await createSpaceItem(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
						setDisplayDropDownError(false);
						setSubmitted(false);

						setTimeout(() => {
							if (enableExpirationChecked) {
								const route = routes.addItemMonthBenefit();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(':item_id', response.item.id);
								window.location.replace(fin_url);
							} else {
								const route = routes.manageItem();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(':item_id', response.item.id);
								window.location.replace(fin_url);
							}
						}, 1800);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						setSubmitted(false);
					}

					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitted(false);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			enableExpirationChecked,
			enableAddNumericChecked,
			enableGiftingChecked,
			selectedContentType,
			selectedAccessType,
			selectedVisibility,
			itemImage,
			itemVideo,
			selectedExplicitAccess,
			selectedExplicitContent,
			itemImageBlobPreview
		]
	);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createItemCommunityForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const handleFormMembershipChanges = useCallback(async () => {
		await createItemCommunityForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	useEffect(() => {}, [authUserInfo, spaceInfo, spaceInfoLoading, itemImageError]);

	return (
		<>
			<div className="block-full card">
				<div className="memberships">
					<div className="dashboard-wrapper">
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
							<div className="col-span-12">
								<h2 className="header-medium">Create New Community Item</h2>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
							<div className="col-span-12">
								<p className="body-text--small">
									The Community Item type is perfect for free or low-cost Items. They
									also have flexibility in changing details such as Max Supply and
									other options.{' '}
									<a className="body-txtitalic--small gradient-color-txt">
										{getSitewideLink('learn_more_on_community_item_type')}
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-full card">
				<div className="memberships">
					<div className="dashboard-wrapper">
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
							<div className="col-span-12 md:col-span-6">
								<h3 className="header-small">Item Details</h3>
							</div>
							<div className="col-span-12 md:col-span-6 hidden">
								<p className="body-text--small gradient-color-txt text-end">
									View Guide
								</p>
							</div>
						</div>
						<Form
							form={createItemCommunityForm}
							name="createItemCommunityForm"
							id="createItemCommunityForm"
							validateMessages={VALIDATE_MESSAGES}
							onFocus={() => {
								setFormIsOnFocusMembership(true);
							}}
							onBlur={() => {
								// setTimeout(() => {
								setFormIsOnFocusMembership(false);
								handleMembershipFormOnBlur().then(r => {});
								// }, 200);
							}}
							onChange={handleFormMembershipChanges}
							onFinish={handleSubmit}
							className={formIsOnFocusMembership ? 'hide-antd-error-messages' : ''}
						>
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
								<div className="col-span-12  md:col-span-4">
									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											onError={setImageUploadErrors}
											cropperAspectRatio={4 / 3}
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={itemImageBlobPreview}
											onUploadFileChange={handleItemImageUploadFileChange}
											minHeight={IMAGE_STANDARD_MIN_HEIGHT}
											minWidth={IMAGE_STANDARD_MIN_WIDTH}
											maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
											maxWidth={IMAGE_STANDARD_MAX_WIDTH}
											uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
											disableCropper={true}
											shape="aspect-cs-preview"
										/>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Item Image*
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 3840px square or wide. <br />
										JPEG, PNG, or GIF
									</p>
									{itemImageError && (
										<div className="alert callout warning items-center">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{itemImageError}</h6>
												</div>
											</div>

										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											hideDragIcon="true"
											hideText="true"
											onError={setVideoUploadErrors}
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={itemVideoBlobPreview}
											onUploadFileChange={handleItemVideoUploadFileChange}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
											shape="aspect-cs-preview"
										/>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Item Video
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px/1080px, square or wide. <br />
										MOV or MP4
									</p>
									{itemVideoError && (
										<div className="alert callout warning items-center">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{itemVideoError}</h6>
												</div>
											</div>

										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										Your Item Image (up to 8MB) should be as high resolution
										as possible, up to 3840px height and width. This will also be
										used for any thumbnails and previews.
									</p>
									<p className="body-txtitalic--smallest">
										Your Item Image (up to 8MB) should be as high resolution
										as possible, up to 3840px height and width. This will also be
										used for any thumbnails and previews.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12 md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Name</label>
										<Form.Item
											label="Name"
											name="name"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
											<Input
												placeholder="Up to 32 characters, including spaces."
												className="input-md body-text--small color-reg"
												maxLength={32}
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[0]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[0]?.errors}</h6>
												</div>
											</div>

										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										This is the displayed Name of the Item and is what people
										will see when browsing your Space and viewing their purchased
										Item, as well as the Collection Name when viewing the
										artwork.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12 md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Sku Short Code</label>
										<Form.Item
											label="Sku Short Code"
											name="sku_shortcode"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													min: 2,
													message: 'Must be minimum 2 characters.'
												},
												{
													max: 8,
													message: 'Must not exceed 8 characters.'
												},
												({ getFieldValue }) => ({
													async validator(_, value) {
														if (value) {
															const data = {
																shortcode: value
															};

															if (spaceInfo) {
																data['space_id'] = spaceInfo.id;
															}

															let available = false;
															const response =
																await checkItemShortCodeAvailability(
																	data
																);
															if (response && response.result) {
																available = response.availability;
															}

															if (!available && value.length <= 8) {
																return Promise.reject(
																	new Error(
																		'Shortcode not available.'
																	)
																);
															}
														}

														if (
															(value && value.match(/\ /g)) ||
															/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
																value
															)
														) {
															return Promise.reject(
																new Error('Invalid Input')
															);
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<Input
												placeholder="2 to 8 characters. Letters and numbers only, no spaces."
												className="input-sm body-text--small color-reg"
												maxLength={8}
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[1]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[1]?.errors}</h6>
												</div>
											</div>

										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										Choose a unique SKU Shortcode based the Item Name. This is
										must be unique within your Space and is used in transactions,
										support, and other areas.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Max Supply</label>
										<Form.Item
											label="Max Supply"
											name="max_supply"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (typeof value !== 'string' && value) {
															value = value.toString();
														}
														if (
															isNaN(value) ||
															value < 0 ||
															value.match(/\./g)
														) {
															return Promise.reject(
																new Error('Invalid value.')
															);
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<Input
												placeholder="Enter “0” for unlimited."
												className="input-md body-text--small color-reg"
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[2]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[2]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										Your Item can have a limited or unlimited Supply. Enter
										“0” to set the Supply to unlimited, “1” to make it a unique
										1-of-1, or any larger number to set a higher limit.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Max Per Account</label>
										<Form.Item
											label="Max Per Account"
											name="max_per_account"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{ validator: CustomValidators.maxPerCount }
											]}
										>
											<Input
												placeholder="Enter “0” for unlimited."
												className="input-md body-text--small color-reg"
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[3]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[3]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										You can put a limit how many Items can be purchased by a
										single account. Enter “0” to set this to unlimited.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12 md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Currency</label>
										<Form.Item
											label="Currency"
											name="currency"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[{ required: true }]}
										>
											<Input
												placeholder="Start typing your currency and select from the list."
												className="input-md body-text--small color-reg"
												disabled
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[4]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[4]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										You can offer your Item for free or sell it at any price
										you choose. Denominated in your selected Currency. Enter “0” to
										set the Price as free.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Price</label>
										<Form.Item
											label="Price"
											name="price"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													validator: CustomValidators.priceMinmax(
														CurrencyType.USD
													)
												}
											]}
										>
											<Input
												placeholder="Enter “0” if free. "
												className="input-md body-text--small color-reg"
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[5]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[5]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12  md:col-span-4">
									<p className="body-txtitalic--smallest">
										Set the Currency for your Item Price. Any purchases in a
										different currency will be converted based on market pricing at
										the time of purchase.{' '}
										<a className="gradient-color-txt">
											{' '}{getSitewideLink('see_supported_currencies_and_cryptocurrencies')}
											{/*See officially supported major currencies and cryptocurrencies.*/}
										</a>
									</p>
								</div>
							</div>

							{/*<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">*/}
							{/*	<div className="col-span-8">*/}
							{/*		<div className="form-check ms-5">*/}
							{/*			<label className="form-check-label body-text--small gradient-color-txt">*/}
							{/*				<Checkbox*/}
							{/*					className="form-check-input"*/}
							{/*					checked={enableExpirationChecked}*/}
							{/*					onChange={onChangeEnableExpiration}*/}
							{/*				/>*/}
							{/*				Enable Expiration Date and add Monthly Subscription for*/}
							{/*				continued access*/}
							{/*			</label>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*	<div className="col-span-12 md:col-span-4">*/}
							{/*		<p className="body-txtitalic--smallest">*/}
							{/*			Memberships have a one-time upfront Price. This option adds an*/}
							{/*			Expiration after a set number of months. After that, Members*/}
							{/*			must pay a Monthly Subscription to continue access. Manage*/}
							{/*			details in the Benefits Section.*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2 h-10 flex items-center">
										<label htmlFor="text">Summary</label>
										<Form.Item
											label="Summary"
											name="summary"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											{/* <TextareaAutosize
											minRows={1}
											placeholder="Up to 128 characters, including spaces."
											className="input-md body-text--small color-reg h-10 pt-0.5 placeholder:text-lg text-lg"
											maxLength={128}
										/> */}
											<TextareaAutosize
												minRows={1}
												placeholder="Up to 128 characters, including spaces."
												className={"w-full bg-transparent border-none no-scrollbar"}
												maxLength={128}
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[6]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[6]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										This Summary shows when Members view their Item. It should
										be 1 to 2 sentences summarizing the Item and its details.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2 h-10 flex items-center">
										<label htmlFor="text">Listing Text</label>
										<Form.Item
											label="Listing Text"
											name="listing_text"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											<TextareaAutosize
												minRows={1}
												placeholder="Up to 128 characters, including spaces."
												className={"w-full bg-transparent border-none no-scrollbar"}
												maxLength={128}
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[7]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[7]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										The Listing Text is used when listing your Item for
										purchase on your Space. It should be 1 to 2 sentences
										summarizing the Item and why people should join.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Individual Title</label>
										<Form.Item
											label="Individual Title"
											name="individual_title"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
											<Input
												maxLength={32}
												placeholder="Up to 32 characters, including spaces. Usually this is the same or similar to the Name."
												className="input-md body-text--small color-reg"
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[8]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[8]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										This is the Title given to an individual Item when
										purchased by someone. For example, if your Item Name is
										“Club All Access Item” then you could make this “Club
										Item”.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-check ms-5 flex items-center">
										<Checkbox
											className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											checked={enableAddNumericChecked}
											onChange={onChangeEnableAddNumeric}
										/>
										<label className="form-check-label body-text--small gradient-color-txt">

											Add Numeric Count to the Individual Title of each purchased
											Item
										</label>
									</div>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										This adds a numeric count to the Individual Title of each
										Item sold. For example, the first Item sold would
										have the Title of “Club Item #1”, and the second would be
										“Club Item #2”, and so on.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4">
								<div className="col-span-12 md:col-span-8 items-center">
									<div className="form-input w-full mt-2">
										<label htmlFor="text">Description</label>
										<Form.Item
											label="Description"
											name="description"
											className="mb-0 hidden-label w-full"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 512,
													message: 'Must not exceed 512 characters.'
												}
											]}
										>
											<TextareaAutosize
												minRows={6}
												placeholder="Up to 512 characters, including spaces."
												className={"w-full bg-transparent border-none no-scrollbar"}
												maxLength={512}
											/>
										</Form.Item>
									</div>
									{createItemCommunityForm.getFieldsError()[9]?.errors.length > 0 && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{createItemCommunityForm.getFieldsError()[9]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col-span-12 md:col-span-4 flex align-top">
									<p className="body-txtitalic--smallest">
										This is a full Description of your Item. You can use{' '}
										{getSitewideLink('markdown_syntax')} to add basic formatting.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">
								<div className="col-span-12  md:col-span-8">
									<div className="form-check ms-5">
										<label className="form-check-label body-text--small gradient-color-txt">
											<Checkbox
												className="form-check-input"
												checked={enableGiftingChecked}
												onChange={onChangeEnableGifting}
											/>
											Enable gifting to accounts and external transfers on
											marketplaces
										</label>
									</div>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										Enabling gifting and transfers allows Members to gift a
										purchased Item to another account. Free gifting is
										supported within your Space. External transfers and sales can be
										done on marketplaces like OpenSea.{' '}
										<a className="gradient-color-txt">{getSitewideLink('learn_more_enable_gifting')}</a>
									</p>
								</div>
							</div>

							{enableGiftingChecked && (
								<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
									<div className="col-span-12  md:col-span-8">
										<div className="form-input w-full mt-2">
											<label htmlFor="text">Royalty Percentage</label>
											<Form.Item
												label="Royalty Percentage"
												name="royalty_percentage"
												className="mb-0 hidden-label w-full"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{ validator: CustomValidators.royaltyPercentage }
												]}
											>
												<Input
													placeholder="Whole numbers only, 0% to 20%."
													className="input-md body-text--small color-reg"
												/>
											</Form.Item>
										</div>
										{createItemCommunityForm.getFieldsError()[10]?.errors.length > 0 && (
											<div className="alert callout warning items-center !w-full">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{createItemCommunityForm.getFieldsError()[10]?.errors}</h6>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="col-span-12 md:col-span-4">
										<p className="body-txtitalic--smallest">
											With Gifting and Transfers enabled, when a Item is sold on
											an external marketplace, you can specify that a percentage of
											the sale price be paid to you as a royalty.
											<a className="gradient-color-txt"> Learn more.</a>
										</p>
									</div>
								</div>
							)}

							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<DropdownWithItems
										title="Select Content Type"
										dropDownClass="dropdown-bg"
										customButtonClass="button gradient secondary distribute w-full"
										displayError={
											displayDropDownError && selectedContentType === 'default'
										}
										icon={getListIcon(
											CONTENT_TYPES_LIST_WITH_DEFAULT,
											selectedContentType
										)}
										selectedValue={selectedContentType}
										listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openContentTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitContent}
										setModalOpen={setModalOpenExplicitContent}
										selectedItems={selectedExplicitContent}
										isExplicitContent={true}
										onSelectedItems={(value) => setSelectedExplicitContent(value)}
									/>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										Change this setting if your Item Details, Image, or Video
										contains explicit or sensitive content. If it does, viewers may
										need to confirm their age before accessing content. View our
										Content Guidelines.
									</p>
								</div>
							</div>
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<DropdownWithItems
										title="Select Access Type"
										dropDownClass="dropdown-bg"
										customButtonClass="button gradient secondary distribute w-full"
										displayError={
											displayDropDownError && selectedAccessType === 'default'
										}
										icon={getListIcon(
											ACCESS_TYPES_LIST_WITH_DEFAULT,
											selectedAccessType
										)}
										selectedValue={selectedAccessType}
										listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openAccessTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitAccess}
										setModalOpen={setModalOpenExplicitAccess}
										selectedItems={selectedExplicitAccess}
										isExplicitContent={false}
										onSelectedItems={(value) => setSelectedExplicitAccess(value)}
									/>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										Change this setting if this Item will provide Members with
										access to explicit or sensitive content, either directly on your
										Space or via an external site or other source. View our Content
										Guidelines.
									</p>
								</div>
							</div>
							<ExplicitContent modalOpen={modalOpen} setModalOpen={setModalOpen} />
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12  md:col-span-8">
									<DropdownWithItems
										title="Update Visibility"
										dropDownClass="dropdown-bg"
										customButtonClass="button gradient secondary distribute w-full"
										selectedValue={selectedVisibility}
										listItems={UPDATES_VISIBILITY}
										onDropdownChange={(value) => setSelectedVisibility(value)}
									/>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										You can set the Visibility of this Item to viewable to the
										Public, Members Only (any membership), or Custom Access (based
										on owning a specific Membership or Item).
									</p>
								</div>
							</div>
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<>
									{alert && (
										<div className="col-span-12">
											<Alert
												key={alert.variant}
												variant={alert.variant}
												className={
													'custom-alert ' +
													(alert.variant === 'danger'
														? 'custom-alert-danger'
														: 'custom-alert-success')
												}
											>
												{alert.message}
											</Alert>
										</div>
									)}
								</>
							</div>
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12">
									<Form.Item className="mb-0">
										<button
											className="primary-gradient w-full"
											type="submit"
											disabled={submitted || formHasErrorMembership}
										>
										<span><p>{submitted ? (
											<>
												<i className="fa-solid fa-spinner fa-spin me-2"></i>
												Saving
											</>
										) : (
											<>Save</>
										)}</p></span>
										</button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(CreateItemCommunity);
