import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import {MODAL_CUSTOM_STYLE, VALIDATE_MESSAGES} from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import { updateSpaceOfflineMessage, updateSpaceViewPassword } from '../../../../services/space';

ReactModal.setAppElement('#root');

const SpaceProfilePassword = (props) => {
	const { spaceInfo, authUserInfo, modalOpen, setModalOpen } = props;
	const [initialLoad, setInitialLoad] = useState(true);
	const [spacePasswordForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: {
			background: 'rgba(0, 0, 0, 0.6)',
			zIndex: 888,
		}
	};

	const handleFormChanges = useCallback(async () => {
		await spacePasswordForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await spacePasswordForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setSubmitted(false);

			try {
				const data = {
					user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					password: values.password
				};

				const response = await updateSpaceViewPassword(data);
				if (response && response.result) {
					// success
				}

				setModalOpen(false);
				setSubmitted(false);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[spaceInfo, authUserInfo]
	);

	useEffect(() => {}, [spaceInfo, authUserInfo]);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={MODAL_CUSTOM_STYLE}
			portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
			overlayClassName="centerframe-flex frame-modal"
			className="block-lg modal-block card"
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">Password Protected</h3>

			<Form
				form={spacePasswordForm}
				name="spacePasswordForm"
				id="spacePasswordForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setFormIsOnFocus(false);
					handleFormOnBlur().then(r => {});
				}}
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
			>
				<>
					{alert && (
						<div className="grid grid-cols-12 mt-5">
							<div className="col-span-8 ">
								<Alert
									key={alert.variant}
									variant={alert.variant}
									className={
										'custom-alert ' +
										(alert.variant === 'danger'
											? 'custom-alert-danger'
											: 'custom-alert-success')
									}
								>
									{alert.message}
								</Alert>
							</div>
						</div>
					)}
				</>

				<div className="grid grid-cols-12 mt-5 flex items-center">
					<div className="col-span-12">
						<div className="form-input flex items-center">
							<label>Choose Password*</label>
							<Form.Item
								label="Password"
								name="password"
								className="mb-0 w-full"
								validateTrigger="onBlur"
								disabled={submitted}
								rules={[{ required: true }]}
							>
								<Input
									type="password"
									className="txt-long input-md body-text--small"
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12 mt-5 flex items-center">
					<div className="col-span-12">
						<div className="form-input flex items-center">
							<label>Confirm Password*</label>
							<Form.Item
								label="Confirm Password"
								name="confirm_password"
								className="mb-0 w-full"
								validateTrigger="onBlur"
								disabled={submitted}
								dependencies={['password']}
								rules={[
									{
										required: true,
										message: 'Please confirm your password.'
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error('Password does not match.')
											);
										}
									})
								]}
							>
								<Input
									type="password"
									className="txt-long input-md body-text--small"
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<button type="submit" className="primary-gradient my-2 w-full">
					<span><p>Save Password</p></span>
				</button>
			</Form>
		</ReactModal>
	);
};

export default SpaceProfilePassword;
