import { createSlice} from "@reduxjs/toolkit";

export const displayPageContentLoaderSlice = createSlice({
    name: "displayPageContentLoader",
    initialState: {
        displayPageContentLoaderStatus: false
    },
    reducers: {
        hidePageContentLoader: (state) => {
            state.displayPageContentLoaderStatus = false;
        },
        displayPageContentLoader:(state) => {
            state.displayPageContentLoaderStatus = true;
        }
    }
});

export const { hidePageContentLoader, displayPageContentLoader} = displayPageContentLoaderSlice.actions;

export default displayPageContentLoaderSlice.reducer;