export const getListIcon = (list, value) => {
	const res = list.find((x) => x.value === value);
	if (res && res.icon) {
		return res.icon;
	}
	return '';
};

export const getListDisplay = (list, value) => {
	const res = list.find((x) => x.value === value);
	if (res) {
		return res.label;
	}
	return '';
};
