import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import {MODAL_CUSTOM_STYLE, VALIDATE_MESSAGES} from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import TextareaAutosize from 'react-textarea-autosize';
import {updateSpaceOfflineMessage} from "../../../../services/space";

ReactModal.setAppElement('#root');

const SpaceProfileOfflineMessage = (props) => {
	const { spaceInfo, authUserInfo, modalOpen, setModalOpen } = props;
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceOfflineMessage] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: {
			background: 'rgba(0, 0, 0, 0.6)',
			zIndex: 888,
		}
	};

	const handleFormChanges = useCallback(async () => {
		await spaceOfflineMessage
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await spaceOfflineMessage
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		setAlert(null);
		setSubmitted(false);

		const data = {
			user_id: authUserInfo.id,
			space_id: spaceInfo.id,
			message: values.offline_message
		}

		const response = await updateSpaceOfflineMessage(data);
		if (response && response.result) {
			// success
		}

		setModalOpen(false);
	}, [spaceInfo, authUserInfo]);

	useEffect(() => {
		if (spaceInfo && spaceInfo.offline_message) {
			spaceOfflineMessage.setFieldsValue({
				offline_message: spaceInfo.offline_message
			});
		}
	}, [spaceInfo, authUserInfo]);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={MODAL_CUSTOM_STYLE}
			portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
			overlayClassName="centerframe-flex frame-modal"
			className="block-lg modal-block card"
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">Manage Offline Message</h3>

			<Form
				form={spaceOfflineMessage}
				name="spaceOfflineMessage"
				id="spaceOfflineMessage"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setFormIsOnFocus(false);
					handleFormOnBlur().then(r => {});
				}}
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
			>
				<>
					{alert && (
						<div className="grid grid-cols-12 mt-5">
							<div className="col-span-8">
								<Alert
									key={alert.variant}
									variant={alert.variant}
									className={
										'custom-alert ' +
										(alert.variant === 'danger'
											? 'custom-alert-danger'
											: 'custom-alert-success')
									}
								>
									{alert.message}
								</Alert>
							</div>
						</div>
					)}
				</>

				<div className="grid grid-cols-12 mt-5 flex items-center">
					<div className="col-span-12">
						<div className="form-input air flex item-center">
							<Form.Item
								label="Offline Message"
								name="offline_message"
								className="mb-0 w-full"
								validateTrigger="onBlur"
								disabled={submitted}
								rules={[{ required: true }]}
							>
								<TextareaAutosize
									minRows={5}
									placeholder="Up to 255 characters, including spaces."
									className={"w-full bg-transparent border-none no-scrollbar"}
									maxLength={255}
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<button type="submit" className="primary-gradient my-2 w-full">
					<span><p>Save Offline Message</p></span>
				</button>
			</Form>
		</ReactModal>
	);
};

export default SpaceProfileOfflineMessage;
