import { memo } from 'react';
import { useEffect } from 'react';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
const CustomPagination = ({ items, pageSize, currentPage, onPageChange }) => {
    const pagesCount = Math.ceil(items / pageSize); // 100/10
    
    if (pagesCount === 1) return null;
    const pages = Array.from({ length: pagesCount }, (_, i) => i + 1);
    // console.log(pages)
    // console.log(currentPage)

    useEffect(() => {
        // Focus on the current page button when the component mounts
        const pageButton = document.getElementById(`page${currentPage + 1}`);
        if (pageButton) {
          pageButton.focus();
        }
      }, [currentPage]);

	return (
		
			
            <nav class="pagination-gradient" aria-label="Pagination">
                    <a href="#" className={classNames("pagination-previous", (parseInt(currentPage)) == 0 ? "!hidden" : "")}  onClick={() => onPageChange(currentPage > 0 ? parseInt(currentPage)-1 : 0)} >
                        <span class="sr-only">Previous</span>
                        <i class="fa-regular fa-angle-left"> </i>
                    </a>
       
                    {pages.map((page, index) => (
                        <a href="#p1" key={index} id={'page' + page} onClick={() => onPageChange(page - 1)}>{page}</a>
                    ))}
                    <a href="#" class="pagination-next" className={classNames("pagination-next", (parseInt(currentPage)+1) == pages.length ? "!hidden" : "")}  onClick={() => onPageChange(currentPage < pagesCount ? parseInt(currentPage)+1 : currentPage) }>
                        <span class="sr-only">Next</span>
                        <i class="fa-regular fa-angle-right"></i>
                    </a>
                </nav>
		
	);
};
export default memo(CustomPagination);