import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {MODAL_CUSTOM_STYLE, VALIDATE_MESSAGES} from '../../../../constants/common';
import {createSpaceAccessGroup} from "../../../../services/space";
const CreateAccessGroupModal = (props) => {
    const {
        modalOpen,
        setModalOpen,
        spaceMembership,
        spaceItems,
        updateAccessGroupList,
        setAccessGroupModalOpen,
        spaceID,
        userID
    } = props;
    const [accessGroupName, setAccessGroupName] = useState('');
    const [selectedMemberships, setSelectedMemberships] = useState([]);
    const [selectedMembership, setSelectedMembership] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = useCallback(
        () => {
            setAccessGroupModalOpen(true);
            setModalOpen(false);
        }, []
    );

    const handleCreateAccessGroup = useCallback(
        async () => {
            if (accessGroupName !== '') {
                setIsLoading(true);
                let membershipIDs = [];
                let itemIDs = [];

                selectedMemberships.map((membership) => {
                    membershipIDs.push(membership.id);
                });

                selectedItems.map((item) => {
                    itemIDs.push(item.id);
                });

                const data = {};
                try {
                    data.space_id = spaceID;
                    data.name = accessGroupName;
                    data.included_memberships = JSON.stringify(membershipIDs);
                    data.included_items = JSON.stringify(itemIDs);
                    const response = await createSpaceAccessGroup(data);
                    if (response.result) {
                        updateAccessGroupList(response.access_group);
                        setIsLoading(false);
                        handleCloseModal();
                    } else {
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
        },
        [accessGroupName, selectedMemberships, selectedItems]
    );

    const handleMembershipClicked = useCallback(
        (data) => {
            let memberships = selectedMemberships;
            let isAddMembership = true;
            if (selectedMemberships?.length > 0) {
                selectedMemberships.map((selected) => {
                    if (selected.id === data.id) {
                        isAddMembership = false;
                    }
                })
            }

            if (isAddMembership) {
                memberships.push(data);
                setSelectedMembership(data);
            }
            else {
                memberships = memberships.filter(x => x.id !== data.id);
                setSelectedMembership(null);
            }

            setSelectedMemberships(memberships)
        }, [selectedMemberships]
    );

    const checkSelectedMemberships = useCallback(
        (id) => {
            let isSelected = false;
            if (selectedMemberships?.length > 0) {
                selectedMemberships.map((selected) => {
                    if (selected.id === id) {
                        isSelected = true;
                    }
                })
            }
            return isSelected;
        }, [selectedMemberships]
    );

    const handleItemClicked = useCallback(
        (data) => {
            let items = selectedItems;
            let isAddItem = true;
            if (selectedItems.length > 0) {
                selectedItems.map((selected) => {
                    if (selected.id === data.id) {
                        isAddItem = false;
                    }
                })
            }

            if (isAddItem) {
                items.push(data);
                setSelectedItem(data);
            }
            else {
                items = items.filter(x => x.id !== data.id);
                setSelectedItem(null);
            }

            setSelectedItems(items)
        }, [selectedItems]
    );

    const checkSelectedItems = useCallback(
        (id) => {
            let isSelected = false;
            if (selectedItems?.length > 0) {
                selectedItems.map((selected) => {
                    if (selected.id === id) {
                        isSelected = true;
                    }
                })
            }
            return isSelected;
        }, [selectedItems]
    );

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={MODAL_CUSTOM_STYLE}
            portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
            overlayClassName="centerframe-flex frame-modal"
            className="block-lg modal-block card"
        >
            <div className="stack-row split">
                <h2>New Access Group</h2>
                <a onClick={() => handleCloseModal()} id="modal-close" className="toggle-menu-gradient"><i
                    className="fa-solid fa-xmark-large"></i></a>
            </div>
            <p>Specify which Memberships and Items are included in this Access Group. Any Member with any of the
                selected Memberships or Items active will be able to access content and channels which are set for
                the <a href="link-via-admin" target="_blank">Access Group</a>. You can edit Access Groups at any
                time.</p>

            <div className="form-input gradient">
                <span>
                    <label htmlFor="name">Name</label>
                    <input
                        id="name"
                        name="text"
                        type="text"
                        value={accessGroupName}
                        onChange={(e) => {
                            setAccessGroupName(e.target.value);
                        }}
                        placeholder="Up to 24 characters with spaces."/>
                </span>
            </div>

            <div className="formrow title w-full">
                <h3>Included Memberships</h3>
                <p className="text-end">{selectedMemberships?.length + ' Selected'}</p>
            </div>

            {(spaceMembership && spaceMembership.length > 0) && (
                <div className="centerframe-flex cs-stack-cards-controls-select">
                    {spaceMembership.map((membership, index) => (
                        <a
                            key={index}
                            onClick={() => {
                                handleMembershipClicked(membership)
                            }}
                            className={"cs-dash-card-product cs-product-membership card block-smx" + (checkSelectedMemberships(membership.id) ? " active" : "")}
                            style={ {backgroundImage: 'url(' + membership.photo_small + ')',
                            } }
                        >
                            <div className="shader space-y-card rounded-card">
                                <hgroup>
                                    <h4>{membership.name} <i className="fa-regular fa-square-user"></i>
                                    </h4>
                                    {/*<p>145 active</p>*/}
                                </hgroup>
                            </div>
                        </a>
                    ))}
                </div>
            )}

            <div className="formrow title w-full">
                <h3>Included Products</h3>
                <p className="text-end">{selectedItems?.length + ' Selected'}</p>
            </div>

            {(spaceItems && spaceItems.length > 0) && (
                <div className="centerframe-flex cs-stack-cards-controls-select">
                    {spaceItems.map((item, index) => (
                        <a
                            key={index}
                            onClick={() => {
                                handleItemClicked(item)
                            }}
                            className={"cs-dash-card-product cs-product-item card block-smx" + (checkSelectedItems(item.id) ? " active" : "")}
                            style={ {backgroundImage: 'url(' +item.photo_small + ')'} }
                        >
                            <div className="shader space-y-card rounded-card">
                                <hgroup>
                                    <h4>{item.name}</h4>
                                    {/*<p>32 active</p>*/}
                                </hgroup>
                            </div>
                        </a>
                    ))}
                </div>
            )}

            <a
                onClick={()=> {
                    handleCreateAccessGroup().then(r => {});
                }}
                className="button gradient">
                <span>
                    <p>{isLoading && <i className="fa-solid fa-spinner fa-spin"/>}Save &amp; Close</p>
                </span>
            </a>
        </ReactModal>
    );
};

export default CreateAccessGroupModal;
