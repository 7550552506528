import { getRequest } from './base';

export const getSitewideLinks = async () => {
	return getRequest('/sitewide/links');
};


export const getUserData = async () => {
	return getRequest('/api/user/me');
};

export const checkUsernameAvailability = async (params) => {
	return getRequest('/api/availability/username/check', params);
};

export const getDataByUsername = async (params) => {
	return getRequest('/api/data/username/get', params);
};

export const getSpaceData = async (params) => {
	return await getRequest('/api/space/get', params);
};
