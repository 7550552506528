import { memo } from 'react';

const Activity = (props) => {
	const { authUserInfo, viewUserId } = props;

	return (
		<>
			<div className="w-full col-span-12 grid grid-cols-12 gap-8 mt-4">
				<div className="col-span-12 mt-4 mb-5">
					{authUserInfo && authUserInfo.id === viewUserId ? (
						<>
							<h3 className="header-medium text-center mt-5">No Activity Yet.</h3>
							<p className="header-small color-reg mt-5 text-center">
								Your recent activity will show here.
							</p>
						</>
					) : (
						<>
							<h3 className="header-medium text-center mt-5">
								This profile has no recent activity.
							</h3>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default memo(Activity);
