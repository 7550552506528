import $ from 'jquery';

export const hideTooltip = (event) => {
	$(event.target).closest('.input-group-com').addClass('hide-tooltip');
};

export const showTooltip = (event) => {
	$(event.target).closest('.input-group-com').removeClass('hide-tooltip');
};

export const hideLabelAndTooltip = (event) => {
	$(event.target).closest('.input-group-com').addClass('hide-tooltip').addClass('hide-label');
};

export const showLabelAndTooltip = (event) => {
	$(event.target)
		.closest('.input-group-com')
		.removeClass('hide-tooltip')
		.removeClass('hide-label');
};
