import React, {memo, useEffect} from 'react';
const SocialLinks = (parentProps) => {
    const { socialLinks, parentClassName, childClassName, removeATag = false } = parentProps;

    const setSocialLinkIcon = (social_link_type) => {
        let iconClass = 'hide'

        if (social_link_type === 'website')
            iconClass = "fa-regular fa-globe";
        if (social_link_type === 'instagram')
            iconClass = "fa-brands fa-instagram";
        if (social_link_type === 'youtube')
            iconClass = "fa-brands fa-youtube";
        if (social_link_type === 'tiktok')
            iconClass = "fa-brands fa-tiktok";
        if (social_link_type === 'twitch')
            iconClass = "fa-brands fa-twitch";
        if (social_link_type === 'facebook')
            iconClass = "fa-brands fa-facebook-f";
        if (social_link_type === 'linkedin')
            iconClass = "fa-brands fa-linkedin-in";
        if (social_link_type === 'x')
            iconClass = "fa-brands fa-x-twitter";

        return iconClass
    };

    useEffect(()=>{
    },[socialLinks])

    const isHideIcon = (social_link_type) => {
        let isHide = ' !hidden'

        if (socialLinks && socialLinks.length > 0) {
            socialLinks.map((socialLink)=>{
                if (socialLink.social_type === social_link_type && socialLink.social_link && socialLink.social_link?.trim() !== null)
                    isHide = ''
            })
        }

        return isHide
    };

    const setSocialURL = (social_link_type) => {
        let url = ''

        if (socialLinks && socialLinks.length > 0) {
            socialLinks.map((socialLink)=>{
                if (socialLink.social_type === social_link_type && socialLink.social_link && socialLink.social_link?.trim() !== null)
                    url = socialLink.social_link;
            })
        }

        return url
    };

    const checkSocialLink = (social_link_type) => {
        let active = ''
        if (socialLinks && socialLinks.length > 0) {
            socialLinks.map((socialLink)=>{
                if (socialLink.social_type === social_link_type && socialLink.social_link && socialLink.social_link?.trim() !== null)
                    active = ' active'
            })
        }
        return active
    };

    return (
        <div className={parentClassName}>
            {removeATag ? (
                <>
                    <i className={"fa-brands fa-x-twitter" + checkSocialLink('x')}/>
                    <i className={"fa-brands fa-instagram" + checkSocialLink('instagram')}/>
                    <i className={"fa-brands fa-facebook-f" + checkSocialLink('facebook')}/>
                    <i className={"fa-brands fa-tiktok" + checkSocialLink('tiktok')}/>
                    <i className={"fa-brands fa-youtube" + checkSocialLink('youtube')}/>
                    <i className={"fa-brands fa-linkedin-in" + checkSocialLink('linkedin')}/>
                    <i className={"fa-brands fa-twitch" + checkSocialLink('twitch')}/>
                    <i className={"fa-regular fa-globe" + checkSocialLink('website')}/>
                </>
            ) : (
                <>
                    <a className={childClassName + isHideIcon('x')} href={setSocialURL('x')}>
                        <span><p><i className="fa-brands fa-x-twitter"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('instagram')} href={setSocialURL('instagram')}>
                        <span><p><i className="fa-brands fa-instagram"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('facebook')} href={setSocialURL('facebook')}>
                        <span><p><i className="fa-brands fa-facebook-f"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('tiktok')} href={setSocialURL('tiktok')}>
                        <span><p><i className="fa-brands fa-tiktok"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('youtube')} href={setSocialURL('youtube')}>
                        <span><p><i className="fa-brands fa-youtube"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('linkedin')} href={setSocialURL('linkedin')}>
                        <span><p><i className="fa-brands fa-linkedin-in"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('twitch')} href={setSocialURL('twitch')}>
                        <span><p><i className="fa-brands fa-twitch"/></p></span>
                    </a>
                    <a className={childClassName + isHideIcon('website')} href={setSocialURL('website')}>
                        <span><p><i className="fa-regular fa-globe"/></p></span>
                    </a>
                </>
                // socialLinks.map((socialLink, index)=>
                //     (
                //         (socialLink.social_link && socialLink.social_link?.trim() !== null) && (
                //             <a
                //                 key={index}
                //                 className={childClassName}
                //                 href={socialLink.social_link}
                //             >
                //                 <span>
                //                     <p>
                //                         <i className={setSocialLinkIcon(socialLink.social_type)}/>
                //                     </p>
                //                 </span>
                //             </a>
                //         )
                //     )
                // )
            )}
        </div>
    )
}

export default memo(SocialLinks);