import { getRequest } from './base';
import { RECAPTCHA_ENTERPRISE_SITE_KEY } from '../constants/common';

export const getGoogleReCaptchaToken = async (action) => {
	return await grecaptcha.enterprise.execute(RECAPTCHA_ENTERPRISE_SITE_KEY, { action });
};

export const assessTokenRequest = async (params) => {
	return getRequest('/api/recaptcha/assess', params);
};
