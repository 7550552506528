import {postRequest, getRequest} from './base';

export const createPaymentMethod = async (data) => {
    return postRequest('/api/user/settings/payment_method/create', data);
};
export const createPaymentMethodAndPay = async (data) => {
    return postRequest('/api/user/settings/payment_method/create_pay', data);
};

export const getPaymentTransaction = async (data) => {
    return getRequest('/api/user/settings/payment/transaction/get', data);
};
export const getMembershipPaymentTransaction = async (data) => {
    return getRequest('/api/user/membership/payment/transaction/get', data);
};

export const updatePaymentMethod = async (data) => {
    return postRequest('/api/user/settings/payment_method/update', data);
};

export const removePaymentMethod = async (data) => {
    return postRequest('/api/user/settings/payment_method/remove', data);
};

export const retrieveStripePaymentMethod = async (data) => {
    return postRequest('/api/user/stripe/retrieve/payment_method', data);
};

export const createStripePaymentIntent = async (data) => {
    return postRequest('/api/user/stripe/create/payment_intent', data);
};

export const createStripeItemOnetimePayment = async (data) => {
    return postRequest('/api/user/stripe/item/onetime_payment', data);
};

export const createStripeMembershipOnetimePayment = async (data) => {
    return postRequest('/api/user/stripe/membership/onetime_payment', data);
};

export const createJoinMembershipItem = async (data) => {
    return postRequest('/api/user/stripe/item/join_free', data);
};

export const createMembershipItemSubscription = async (data) => {
    return postRequest('/api/user/stripe/membership_item/subscription', data);
};

export const checkMembershipItemStatus = async (data) => {
    return getRequest('/api/user/stripe/check/membership_item/status', data);
};

export const updateMembershipItemStatus = async (data) => {
    return postRequest('/api/user/stripe/update/membership_item/status', data);
};

export const checkActiveMembershipStatus = async (data) => {
    return getRequest('/api/user/stripe/check/active/membership/status', data);
};

export const getRedemptionSubscription = async (data) => {
    return getRequest('/api/user/redemption/subscription/get', data);
};


// export const createStripePaymentDraft = async (data) => {
//     return postRequest('/api/user/stripe/create/draft/payment_intent', data);
// };
//
// export const checkStripePaymentDraft = async (data) => {
//     return getRequest('/api/user/stripe/check/draft/payment_intent', data);
// };
//
// export const getStripePaymentDraft = async (data) => {
//     return getRequest('/api/user/stripe/draft/payment_intent/get', data);
// };