import { memo, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { GOOGLE_FONT_KEY } from '../../../constants/common';
import FontPicker from 'font-picker-react';
import DropdownWithItems from '../DropdownWithItems';

const FontsDropdownWithSearch = (parentProps) => {
	const { componentId, selectedValue, onDropdownChange } = parentProps;
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [isClearable, setIsClearable] = useState(true);
	const [isSearchable, setIsSearchable] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRtl, setIsRtl] = useState(false);
	const [allOptions, setAllOptions] = useState([]);
	const [first50Options, setFirst50Options] = useState([]);
	const [optionsForDisplay, setOptionsForDisplay] = useState([]);

	const loadGoogleApiClient = async () => {
		setTimeout(() => {
			/* global gapi */
			gapi.load('client');
			setTimeout(() => {
				gapi.client.setApiKey(GOOGLE_FONT_KEY);
				gapi.client
					.load('https://www.googleapis.com/discovery/v1/apis/webfonts/v1/rest')
					.then(
						function () {
							// console.log("GAPI client loaded for API");
						},
						function (err) {
							// console.error("Error loading GAPI client for API", err);
						}
					);

				setTimeout(() => {
					executeGoogleApiClient();
				}, 2000);
			}, 2000);
		}, 2000);
	};

	const executeGoogleApiClient = async() => {
		/* global gapi */
        const validFontFamilies = allOptions.map(option => option.value); // Get valid font families
        try {
    
            const response = await gapi.client.webfonts.webfonts.list();
            const fonts = response.result.items;
            const allFonts = [];
            if (!allFonts.some(font => font.value === 'DIN 2014')) {
                allFonts.push({ value: 'DIN 2014', label: 'DIN 2014' });
            }
            fonts.forEach((item) => {
                allFonts.push({ value: item.family, label: item.family });
            });
            // Add DIN 2014 to the array if it doesn't already exist
            
            setAllOptions(allFonts);
            // Check if no fonts are available
            if (allFonts.length === 0) {
                setOptionsForDisplay([{ value: selectedValue, label: selectedValue }]); // Change here to display selected font
            }
        } catch (err) {
            // Suppress specific error messages
            if (err && err.status !== 0) {
                console.error('Execute error', err);
            }
            // Set default font if there's a CORS issue or any other error
            setOptionsForDisplay([{ value: selectedValue, label: selectedValue }]); // Display selected font on error
        }

        
    
        try {
            const response = await gapi.client.webfonts.webfonts.list({
                sort: 'popularity'
            });
            const fonts = response.result.items;
            const fiftyPopularFonts = [];
            if (!fiftyPopularFonts.some(font => font.value === 'DIN 2014')) {
                fiftyPopularFonts.push({ value: 'DIN 2014', label: 'DIN 2014' });
            }
            fonts.forEach((item) => {
                if (fiftyPopularFonts.length < 50) {
                    fiftyPopularFonts.push({ value: item.family, label: item.family });
                }
            });
            // Add DIN 2014 to the array if it doesn't already exist
            
            setFirst50Options(fiftyPopularFonts);
            // Check if no popular fonts are available
            if (fiftyPopularFonts.length === 0) {
                setOptionsForDisplay([{ value: selectedValue, label: selectedValue }]); // Change here to display selected font
            }
        } catch (err) {
            // Suppress specific error messages
            if (err && err.status !== 0) {
                console.error('Execute error', err);
            }
            // Set default font if there's a CORS issue or any other error
            setOptionsForDisplay([{ value: selectedValue, label: selectedValue }]); // Display selected font on error
        }
    
        // Validate selected font family
        if (!validFontFamilies.includes(selectedValue)) {
            console.warn(`Invalid font family: ${selectedValue}. Falling back to default font.`);
            onDropdownChange(selectedValue); // Fallback to a default font
        }
    };

	const checkIfSelectedFontExistsInList = useCallback(async () => {
		const index = first50Options.findIndex(x => x.value === selectedValue);
		if (index >= 0) {
			// found
		} else {
			// set value to default 'Noto Sans'
            
			onDropdownChange('Noto Sans');
		}

		setIsLoading(false);
	}, [selectedValue, first50Options]);

	const handleInputChange = useCallback(
		(inputValue) => {
			if (inputValue && inputValue !== '') {
				setOptionsForDisplay(allOptions);
			} else {
				setOptionsForDisplay(first50Options);
			}
		},
		[allOptions, first50Options]
	);

	const handleDropdownChange = (value) => {
		onDropdownChange(value);
	};

	useEffect(() => {
		if (isInitialLoad) {
			setIsLoading(true);
			loadGoogleApiClient().then((r) => {});
			setIsInitialLoad(false);
		}
	}, [isInitialLoad]);

	useEffect(() => {
		if (selectedValue && first50Options.length > 0) {
			checkIfSelectedFontExistsInList(first50Options).then((r) => {});
		}
	}, [selectedValue, first50Options]);

	return (
		<>
			{isLoading ? (
				<i className="fa-solid fa-spinner fa-spin"></i>
			) : (
                <DropdownWithItems
                    title="Font"
                    customButtonClass="icon-dropdown-gradient-alt secondary"
                    icon="fa-light fa-book-font"
                    selectedValue={selectedValue}
                    listItems={first50Options}
                    onDropdownChange={handleDropdownChange}
                />
                
			)}
		</>
	);

	// TODO: look for ways to implement and display font family style on each dropdown item
	// return (
	// 	<>
	// 		<Select
	// 			className="fonts-dropdown-with-search"
	// 			classNamePrefix="select"
	// 			defaultValue={selectedValue ? selectedValue : 'Noto Sans'}
	// 			isDisabled={isDisabled}
	// 			isLoading={isLoading}
	// 			isClearable={isClearable}
	// 			isRtl={isRtl}
	// 			isSearchable={isSearchable}
	// 			name="color"
	// 			options={optionsForDisplay}
	// 			onInputChange={handleInputChange}
	// 		/>
	// 	</>
	// );
};

export default memo(FontsDropdownWithSearch);
