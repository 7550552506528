import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import {useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import {
	SPACE_CHANNEL_ACCESS, SPACE_CHANNEL_COMMENTING, SPACE_CHANNEL_POSTING, SPACE_CHANNEL_VISIBILITY,
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';

import './../../../SpaceCreate/AddDetails/Links/Links.scss';
import {
	createSpaceChannel,
	enableDisableChannels, getSpaceAccessGroup,
	getSpaceChannels,
	updateSpaceChannel
} from "../../../../services/space";
import {getSpaceInfo} from "../../../../states/spaceGlobal/actions";
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import Alert from "react-bootstrap/Alert";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";
import AccessGroupModal from "../../../../components/commons/Modals/AccessGroupModal/AccessGroupModal";
import CreateAccessGroupModal
	from "../../../../components/commons/Modals/CreateAccessGroupModal/CreateAccessGroupModal";

const ManageChannelsPage = (props) => {
	const { authUserInfo, spaceInfo } = props;

	const dispatch = useDispatch();
	const [createChannelForm] = Form.useForm();
	const [editChannelForm] = Form.useForm();
	const [disableChannelForm] = Form.useForm();

	const [accessError, setAccessError] = useState();
	const [selectedChannel, setSelectedChannel] = useState();

	const [formHasError, setFormHasError] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [defaultChecked, setDefaultChecked] = useState(false);
	const [openChannelForm, setOpenChannelForm] = useState(false);
	const [isChannelsEnable, setIsChannelsEnable] = useState(false);
	const [isDisableDropdown, setIsDisableDropdown] = useState(false);
	const [accessGroupModalOpen, setAccessGroupModalOpen] = useState(false);
	const [isEnableChannelLoading, setIsEnableChannelLoading] = useState(false);
	const [isCreateChannelLoading, setIsCreateChannelLoading] = useState(false);
	const [isDisableChannelLoading, setIsDisableChannelLoading] = useState(false);
	const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
	const [disableCheckbox, setDisableCheckbox] = useState(false);

	const [password, setPassword] = useState('');
	const [spaceUsername, setSpaceUsername] = useState('');
	const [selectedAccess, setSelectedAccess] = useState('default');
	const [selectedPosting, setSelectedPosting] = useState('default');
	const [selectedCommenting, setSelectedCommenting] = useState('default');
	const [selectedVisibility, setSelectedVisibility] = useState('default');

	const [alert, setAlert] = useState(null);
	// const [alertCheckbox, setAlertCheckbox] = useState(null);
	const channelFormRef = useRef(null);

	const [channels, setChannels] = useState([]);
	const [accessGroupList, setAccessGroupList] = useState([]);
	const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);


	const getSpaceAccessGroups = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					const space_param = {}
					space_param.space_id = spaceInfo.id;

					const responseGetSpaceAccessGroups = await getSpaceAccessGroup(space_param);
					if (responseGetSpaceAccessGroups && responseGetSpaceAccessGroups.result) {
						let groupArray = [];
						const groupList = responseGetSpaceAccessGroups.access_groups;
						if (groupList && groupList.length > 0) {
							groupList.map((group) => {
								groupArray.push({id: group.id, label: group.name, value: group.id})
							})
						}
						setAccessGroupList(groupArray);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo]
	);

	const updateAccessGroup = (group) => {
		getSpaceAccessGroups().then(r => {});
		selectedAccessGroups.push({id: group.id, label: group.name, value: group.id});
	}

	const getChannels = useCallback(
		async () => {
			try {
				if (spaceInfo && authUserInfo) {
					const channel_param = {}
					channel_param.space_id = spaceInfo.id;
					channel_param.user_id = authUserInfo.id;
					const responseGetChannels = await getSpaceChannels(channel_param);
					if (responseGetChannels && responseGetChannels.result) {
						setChannels(responseGetChannels.space_channels);
					}
					setOpenChannelForm(false);
					setSelectedChannel(undefined);
					setIsCreateChannelLoading(false);
				}
			} catch (error) {
			}
		},
		[spaceInfo, authUserInfo]
	);

	const handleEnableChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					setIsEnableChannelLoading(true);
					const data = {
						space_id: spaceInfo.id,
						channels_enable: true
					};
					const responseEnableDisableChannels = await enableDisableChannels(data);
					if (responseEnableDisableChannels && responseEnableDisableChannels.result) {
						setIsChannelsEnable(true);
						if (channels && channels.length < 1 ) {
							setOpenChannelForm(true);
							setSelectedChannel(undefined);
						}
						setIsEnableChannelLoading(false);
					} else {
						setIsEnableChannelLoading(false);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo, channels, openChannelForm, selectedChannel, isChannelsEnable, isEnableChannelLoading]
	);

	const handleCreateNewChannel = useCallback(
		() => {
			setOpenChannelForm(true);
			setSelectedChannel(undefined);
		},
		[openChannelForm, selectedChannel]
	);

	const handleSaveNewChannel = useCallback(
		async (values) => {
			try {
				if (spaceInfo) {
					setIsCreateChannelLoading(true);
					setAccessError(null);

					const data = {
						space_id: spaceInfo.id,
						user_id: authUserInfo.id,
						name: values.channel_name,
						visibility: selectedVisibility,
						access: selectedAccess,
						posting: selectedPosting,
						commenting: selectedCommenting,
						default: defaultChecked
					};

					if (selectedAccess === 'custom') {
						if (selectedAccessGroups.length > 0) {
							const ids = selectedAccessGroups.map(x => x.id);
							data.access_group = JSON.stringify(ids);
						} else {
							setAccessError('Custom is selected but no Access Group selected!');
							setIsCreateChannelLoading(false);
							setTimeout(() => {
								setAccessError(null);
							},2000);
							return;
						}
					}

					// if (selectedChannel.id.toString() === spaceInfo.default_channel_id && !defaultChecked) {
					// 	setIsCreateChannelLoading(false);
					// 	setAlertCheckbox('Keep at least one default channel.');
					// 	return;
					// }
					//
					// setAlertCheckbox(null);

					if (selectedChannel) {
						data.channel_id = selectedChannel.id;
						const responseUpdateSpaceChannel = await updateSpaceChannel(data);
						if (responseUpdateSpaceChannel && responseUpdateSpaceChannel.result) {
							// setOpenChannelForm(false);
							// setSelectedChannel(undefined);
							// setIsCreateChannelLoading(false);
							getChannels().then(r => {});
						} else {
							setIsCreateChannelLoading(false);
						}
					} else {
						const responseCreateSpaceChannel = await createSpaceChannel(data);
						if (responseCreateSpaceChannel && responseCreateSpaceChannel.result) {
							// setOpenChannelForm(false);
							// setSelectedChannel(undefined);
							// setIsCreateChannelLoading(false);
							getChannels().then(r => {});
						} else {
							setIsCreateChannelLoading(false);
						}
					}
					if (spaceUsername && spaceUsername !== '')
						dispatch(getSpaceInfo(spaceUsername, null, true));
				}
			} catch (error) {
			}
		},
		[authUserInfo, spaceInfo, openChannelForm, selectedChannel, selectedVisibility, selectedAccess,
			selectedPosting, selectedCommenting, defaultChecked, isCreateChannelLoading, selectedAccessGroups,
			// alertCheckbox
		]
	);

	const handleEditChannel = useCallback(
		(channel) => {
			// setAlertCheckbox(null);
			setSelectedChannel(channel);

			setDisableCheckbox(channel && channel?.id?.toString() === spaceInfo?.default_channel_id && !defaultChecked);

			editChannelForm.setFieldsValue({
				channel_name: channel.name
			})
			// setOpenChannelForm(true);
			// setTimeout(() => {
			// 	if (channelFormRef.current) {
			// 		channelFormRef.current.scrollIntoView({ behavior: 'smooth' });
			// 	}
			// }, 300);
		},
		[editChannelForm, spaceInfo, selectedChannel, disableCheckbox, defaultChecked
			// alertCheckbox
		]
	);

	const handleDisableChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					setIsDisableChannelLoading(true);
					const data = {
						space_id: spaceInfo.id,
						channels_enable: false,
						user_id: authUserInfo.id,
						password: password
					};
					const responseEnableDisableChannels = await enableDisableChannels(data);
					if (responseEnableDisableChannels && responseEnableDisableChannels.result) {
						setIsChannelsEnable(false);
						setOpenChannelForm(false);
						setIsDisableChannelLoading(false);
						setSelectedChannel(undefined);
						disableChannelForm.resetFields();
					} else {
						setIsDisableChannelLoading(false);
						setAlert({ variant: 'danger', message: responseEnableDisableChannels.message });
					}
				}
			} catch (error) {
			}
		},
		[authUserInfo, spaceInfo, password, openChannelForm, selectedChannel, isChannelsEnable, isEnableChannelLoading]
	);

	const checkValidChannelData = useCallback(
		() => {
			if (selectedVisibility === 'default' || selectedPosting === 'default' || selectedCommenting === 'default') {
				return false;
			} else {
				if (selectedVisibility === 'hidden' && selectedAccess === 'default')
					return false;
				else
					return true
			}
		},
		[createChannelForm, selectedVisibility, selectedAccess, selectedPosting, selectedCommenting]
	);

	const handleRedirectToChannel = (guid) => {
		window.location.replace('/@' + spaceUsername + '/channel/' +guid);
	}

	const handleCreateFormOnBlur = useCallback(async () => {
		await createChannelForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleEditFormOnBlur = useCallback(async () => {
		await editChannelForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const filterDropdownChange = useCallback(
		async (value) => {
			setSelectedAccess(value);
			if (value === 'custom') {
				setAccessGroupModalOpen(true);
			} else {
				setAccessGroupModalOpen(false);
			}
		}, [accessGroupModalOpen, selectedAccess]
	);

	useEffect(() => {
		createChannelForm.setFieldsValue({
			channel_name: ''
		})

		setSelectedVisibility('default');
		setSelectedAccess('default');
		setSelectedPosting('default');
		setSelectedCommenting('default');
		setDefaultChecked(false);

		if (selectedChannel) {
			editChannelForm.setFieldsValue({
				channel_name: selectedChannel.name
			})
			setSelectedVisibility(selectedChannel.visibility);
			setSelectedAccess(selectedChannel.access);
			setSelectedPosting(selectedChannel.posting);
			setSelectedCommenting(selectedChannel.commenting);
			setDefaultChecked(spaceInfo.default_channel_id.toString() === selectedChannel.id.toString());
		}
	}, [selectedChannel]);

	useEffect(() => {
		if (isDisableDropdown) {
			setSelectedVisibility(SPACE_CHANNEL_VISIBILITY[1].value); // visible
			setSelectedAccess(SPACE_CHANNEL_ACCESS[1].value); // all_members
			setSelectedPosting(SPACE_CHANNEL_POSTING[1].value); // allow
			setSelectedCommenting(SPACE_CHANNEL_COMMENTING[1].value); // allow
		} else {
			if (selectedChannel) {
				setSelectedVisibility(selectedChannel.visibility);
				setSelectedAccess(selectedChannel.access);
				setSelectedPosting(selectedChannel.posting);
				setSelectedCommenting(selectedChannel.commenting);
			}
		}
	},[isDisableDropdown])


	useEffect(() => {
		if (selectedChannel && selectedChannel?.id?.toString() === spaceInfo?.default_channel_id && !defaultChecked) {
			setDisableCheckbox(true);
		}
	},[selectedChannel, spaceInfo, defaultChecked])


	useEffect(() => {
		setIsDisableDropdown(defaultChecked)
	},[selectedVisibility, selectedAccess, selectedPosting, selectedCommenting, defaultChecked, disableCheckbox])

	useEffect(() => {
		if (spaceInfo) {
			setIsChannelsEnable(spaceInfo.channels_enable);
			setSpaceUsername(spaceInfo.username);
			getChannels().then(r => {});
			getSpaceAccessGroups().then(r => {});
		}
	}, [authUserInfo, spaceInfo, getChannels, getSpaceAccessGroups]);

	useEffect(() => {
		if (selectedChannel) {
			const accessGroup = JSON.parse(selectedChannel.access_group);
			let selectedGroups = [];
			if (accessGroup) {
				if (accessGroup.length > 0) {
					accessGroup.map((selected) => {
						accessGroupList.map((group) => {
							if (Number(selected) === group.id) {
								selectedGroups.push(group);
							}
						});
					});
				}
			}
			setSelectedAccessGroups(selectedGroups);
		} else {
			setSelectedAccessGroups([]);
		}
	}, [accessGroupList, selectedChannel]);

	return (
		<div id="cs-platform-dash" className="cs-dash">
			<div className="cs-dashframe section-flex">
				<div className="cs-dash-card block-full card">
					<hgroup className="heading">
						<h2>Manage Channels</h2>
						<p>Create and manage access to your Channels here. Each Channel is a group feed where anyone
							with access can post and comment. All Members can view the Channels section, and each
							individual Channel has its own access settings. Channels will display in the order displayed
							below, which you can drag and drop to re-order.</p>
					</hgroup>
					<a
						onClick={() => {
							if (!isEnableChannelLoading)
								if (isChannelsEnable)
									handleCreateNewChannel();
								else
									handleEnableChannels().then(r => {});
						}}
						className="button gradient secondary">
						<span>
							{isEnableChannelLoading && (
								<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
							)}
							<p>{isChannelsEnable ? "Create New Channel" : "Enable Channels"}</p>
						</span>
					</a>
				</div>

				{(openChannelForm && !selectedChannel && isChannelsEnable) && (
					<div className="cs-dash-card block-full card">
						<Form
							form={createChannelForm}
							name="createChannelForm"
							validateMessages={VALIDATE_MESSAGES}
							onFocus={() => {
								setFormIsOnFocus(true);
							}}
							onBlur={() => {
								// setTimeout(() => {
								setFormIsOnFocus(false);
								handleCreateFormOnBlur().then(r => {});
								// }, 100);
							}}
							onFinish={handleSaveNewChannel}
							className="stack-col"
						>
							<div className="formrow title">
								<h3>Create New Channel</h3>
								<p>Channels allow your Members to interact with each other</p>
							</div>
							<div className="cs-stack-controlrows stack-form-col">
								<div className="formrow split">
									<div className="form-input gradient">
										<span>
											<label htmlFor="name">Name</label>
											<Form.Item
												label="Name"
												name="channel_name"
												validateTrigger="onBlur"
												className="w-full"
												rules={[
													{ required: true },
													{
														max: 24,
														message: 'Must not exceed 24 characters.'
													},
													{
														pattern: CUSTOM_REGEX.CHANNEL_NAME_VALIDATION,
														message: 'Invalid channel name.'
													}
												]}
											>
												<FormFieldStacked
													formType='input'
													type="text"
													maxLength={24}
													disabled={false}
													placeholder="Up to 24 characters, no spaces."
												/>
											</Form.Item>
										</span>
									</div>
									<p>Your Channel name can contain letters, numbers, hyphens, and underscores, and must be
										24 characters or less with no spaces.</p>
								</div>
								{createChannelForm.getFieldError('channel_name').length > 0 && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content self-center">
											<div>
												<h6>{createChannelForm.getFieldError('channel_name')[0]}</h6>
												<p></p>
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="formrow title">
								<h4>Channel Permissions</h4>
								<p>Permissions control access and actions to the Channel</p>
							</div>
							<div className="cs-stack-controlrows stack-form-col">
								<div className="formrow split">
									<DropdownWithItems
										title="Channel Visibility"
										customButtonClass="button gradient secondary distribute w-full"
										icon="fa-light fa-eye"
										selectedValue={selectedVisibility}
										listItems={SPACE_CHANNEL_VISIBILITY}
										isDisableDropdown={isDisableDropdown}
										onDropdownChange={(value) => {
											setSelectedVisibility(value)
										}}
									/>
									<p>Visible Channels can be seen by all Members in the Channel List. Hidden only allows
										Members with Access to see the Channel Name.</p>
								</div>
								<div className="formrow split">
									<DropdownWithItems
										title="Channel Access"
										customButtonClass="button gradient secondary distribute w-full"
										icon="fa-light fa-unlock"
										selectedValue={selectedAccess}
										listItems={SPACE_CHANNEL_ACCESS}
										isDisableDropdown={isDisableDropdown}
										onDropdownChange={(value) => { filterDropdownChange(value).then(r => {}) }}
									/>
									{(spaceInfo != null && authUserInfo != null && selectedAccess === 'custom') && (
										<>
											{accessGroupModalOpen && (
												<AccessGroupModal
													modalOpen={accessGroupModalOpen}
													setModalOpen={setAccessGroupModalOpen}
													accessGroupList={accessGroupList}
													selectedAccessGroups={selectedAccessGroups}
													setSelectedAccessGroups={setSelectedAccessGroups}
													setCreateAccessGroupModalOpen={setCreateAccessGroupModalOpen}
													header_title={''}
													spaceID={spaceInfo.id}
													userID={authUserInfo.id}
												/>
											)}
											{createAccessGroupModalOpen && (
												<CreateAccessGroupModal
													modalOpen={createAccessGroupModalOpen}
													setModalOpen={setCreateAccessGroupModalOpen}
													spaceMembership={spaceInfo.memberships}
													spaceItems={spaceInfo.items}
													updateAccessGroupList={updateAccessGroup}
													setAccessGroupModalOpen={setAccessGroupModalOpen}
													spaceID={spaceInfo.id}
													userID={authUserInfo.id}
												/>
											)}
										</>
									)}
									<p>All Members Access allows anyone with any Membership to have access. Custom Access
										locks the Channel to specific Memberships only.</p>
								</div>
								<div className="formrow split">
									<DropdownWithItems
										title="Channel Posting"
										customButtonClass="button gradient secondary distribute w-full"
										icon="fa-light fa-subtitles"
										selectedValue={selectedPosting}
										listItems={SPACE_CHANNEL_POSTING}
										isDisableDropdown={isDisableDropdown}
										onDropdownChange={(value) => {
											setSelectedPosting(value)
										}}
									/>
									<p>Allow Posting lets any Member with Access post to the Channel. Disallow restricts
										Members from posting and only allows your Team to post.</p>
								</div>
								<div className="formrow split">
									<DropdownWithItems
										title="Channel Commenting"
										customButtonClass="button gradient secondary distribute w-full"
										icon="fa-light fa-comment"
										selectedValue={selectedCommenting}
										listItems={SPACE_CHANNEL_COMMENTING}
										isDisableDropdown={isDisableDropdown}
										onDropdownChange={(value) => {
											setSelectedCommenting(value)
										}}
									/>
									<p>Allow Commenting lets any Member with Access comment on a Post. Disallow restricts
										Members from commenting on Channel Posts.</p>
								</div>
								<div className="formrow split">
									<div className="form-input-checkbox gradient md rounded row w-full">
										<div className="checkbox">
											<input
												id="checkbox-cs-agreement"
												aria-describedby="checkbox-cs-agreement-description"
												name="checkbox-cs-agreement"
												type="checkbox"
												required=""
												onChange={(e)=>{
													const isChecked = e.target.checked;
													setDefaultChecked(isChecked);
												}}
											/>
										</div>
										<div>
											<label htmlFor="checkbox-cs-agreement" className="sr-only">Space Terms</label>
											<p id="checkbox-cs-agreement-description">
												Set as <a href="#link-via-admin" target="_blank">Default Channel</a>.
											</p>
										</div>
									</div>
									<p>Your Default Channel is where all Members automatically start when visiting the
										Channels section, and must have Access set to All Members.</p>
								</div>
								{accessError && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content self-center">
											<div>
												<h6>{accessError}</h6>
												<p></p>
											</div>
										</div>
									</div>
								)}
								<div className="stack-buttons col">
									<button
										className="button gradient"
										type="submit"
										disabled={
											isCreateChannelLoading || !checkValidChannelData()
										}
									>
										<span>
											<p>
												{isCreateChannelLoading && (
													<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
												)}
												Save Channel
											</p>
										</span>
									</button>
									<a
										className="button gradient secondary"
										onClick={() => {
											setSelectedChannel(undefined);
											setOpenChannelForm(false);
										}}
										aria-disabled={
											isCreateChannelLoading
										}
									>
										<span>
											<p>Cancel</p>
										</span>
									</a>
								</div>
							</div>
						</Form>
					</div>
				)}

				{(channels && channels.length > 0) && (
					<div id="draggable-cards" className={"cs-stack-form-drag-cards gap-gutter" + (isChannelsEnable ? "" : " disabled")}>
						{channels.map((channel, index) => (
							<div draggable="true" className="cs-dash-card block-full card cs-form-drag-card" key={index}>
								<div className="cs-stack-controlrows stack-form-col">
									<div className="cs-form-drag-card-title">
										<div className="cs-controls-draggable">
											<p className={(isChannelsEnable ? "" : "disabled")}><i className="fa-regular fa-grip-lines"></i></p>
											<div className={"cs-controls-draggable-buttons"}>
												<button><i className="fa-regular fa-angle-up"></i></button>
												<button><i className="fa-regular fa-angle-down"></i></button>
											</div>
										</div>
										<div className="cs-channel-card-title stack-row">
											<h4>{"#" + channel.name}</h4>
											<p>{channel.posts_count + " Posts"}</p>
										</div>
									</div>
									<div className="cs-stack-icons-attributes">
										<p>{(channel.visibility === 'visible' || channel.visibility === 'default') ? (<i className="fa-regular fa-eye"></i>) : (<i className="fa-regular fa-eye-low-vision"></i>)}</p>
										<p>{(channel.access === 'all_members' || channel.access === 'default') ? (<i className="fa-regular fa-unlock"></i>) : (<i className="fa-regular fa-lock"></i>)}</p>
										<p>{(channel.posting === 'allow' || channel.posting === 'default') ? (<i className="fa-regular fa-subtitles"></i>) : (<i className="fa-regular fa-subtitles-slash"></i>)}</p>
										<p>{(channel.commenting === 'allow' || channel.commenting === 'default') ? (<i className="fa-regular fa-comment"></i>) : (<i className="fa-regular fa-comment-slash"></i>)}</p>
									</div>
									<div className="cs-channel-card-controls stack-row">
										<a
											className={"button gradient secondary" + (isChannelsEnable ? "" : " disabled")}
											onClick={() => {
												if (isChannelsEnable)
													handleRedirectToChannel(channel.guid);
											}}
										>
											<span>
												<i className="fa-light fa-arrow-up-right-from-square"></i>
											</span>
										</a>
										<a
											className={"button gradient secondary" + (channel.id === selectedChannel?.id ? " active" : "")  + (isChannelsEnable ? "" : " disabled")}
											onClick={() => {
												if (isChannelsEnable)
													handleEditChannel(channel);
											}}
										>
											<span>
												<i className="fa-light fa-money-check-pen"></i>
											</span>
										</a>
									</div>
								</div>
								<Form
									form={editChannelForm}
									name="editChannelForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocus(true);
									}}
									onBlur={() => {
										// setTimeout(() => {
										setFormIsOnFocus(false);
										handleEditFormOnBlur().then(r => {});
										// }, 100);
									}}
									onFinish={handleSaveNewChannel}
									className={"cs-stack-form-channel-edit stack-col" + (channel.id === selectedChannel?.id ? "" : " hide")}
								>
									<div className="formrow title">
										<h4>Edit Channel</h4>
										<p>Channels allow your Members to interact with each other</p>
									</div>
									<div className="cs-stack-controlrows stack-form-col">
										<div className="formrow split">
											<div className="form-input gradient">
												<span>
													<label htmlFor="name">Name</label>
													<Form.Item
														label="Name"
														name="channel_name"
														validateTrigger="onBlur"
														className="w-full"
														onChange={(e) => {
														}}
														rules={[
															{ required: true },
															{
																max: 24,
																message: 'Must not exceed 24 characters.'
															},
															{
																pattern: CUSTOM_REGEX.CHANNEL_NAME_VALIDATION,
																message: 'Invalid channel name.'
															}
														]}
													>
														<FormFieldStacked
															formType='input'
															type="text"
															maxLength={24}
															disabled={false}
															placeholder="Up to 24 characters, no spaces."
														/>
													</Form.Item>
												</span>
											</div>
											<p>Your Channel name can contain letters, numbers, hyphens, and underscores, and
												must be 24 characters or less with no spaces.</p>
										</div>
										{editChannelForm.getFieldError('channel_name').length > 0 && (
											<div className="alert callout warning">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content self-center">
													<div>
														<h6>{editChannelForm.getFieldError('channel_name')[0]}</h6>
														<p></p>
													</div>
												</div>
											</div>
										)}
									</div>

									<div className="formrow title">
										<h4>Channel Permissions</h4>
										<p>Permissions control access and actions to the Channel</p>
									</div>
									<div className="cs-stack-controlrows stack-form-col">
										<div className="formrow split">
											<DropdownWithItems
												title="Channel Visibility"
												customButtonClass="button gradient secondary distribute w-full"
												icon="fa-light fa-eye"
												selectedValue={selectedVisibility}
												listItems={SPACE_CHANNEL_VISIBILITY}
												isDisableDropdown={isDisableDropdown}
												onDropdownChange={(value) => {
													setSelectedVisibility(value)
												}}
											/>
											<p>Visible Channels can be seen by all Members in the Channel List. Hidden only
												allows Members with Access to see the Channel Name.</p>
										</div>
										<div className="formrow split">
											<DropdownWithItems
												title="Channel Access"
												customButtonClass="button gradient secondary distribute w-full"
												icon="fa-light fa-unlock"
												selectedValue={selectedAccess}
												listItems={SPACE_CHANNEL_ACCESS}
												isDisableDropdown={isDisableDropdown}
												onDropdownChange={(value) => { filterDropdownChange(value).then(r => {}) }}
											/>
											{(spaceInfo != null && authUserInfo != null && selectedAccess === 'custom') && (
												<>
													{accessGroupModalOpen && (
														<AccessGroupModal
															modalOpen={accessGroupModalOpen}
															setModalOpen={setAccessGroupModalOpen}
															accessGroupList={accessGroupList}
															selectedAccessGroups={selectedAccessGroups}
															setSelectedAccessGroups={setSelectedAccessGroups}
															setCreateAccessGroupModalOpen={setCreateAccessGroupModalOpen}
															header_title={selectedChannel ? ('#'+selectedChannel.name) : ''}
															spaceID={spaceInfo.id}
															userID={authUserInfo.id}
														/>
													)}
													{createAccessGroupModalOpen && (
														<CreateAccessGroupModal
															modalOpen={createAccessGroupModalOpen}
															setModalOpen={setCreateAccessGroupModalOpen}
															spaceMembership={spaceInfo.memberships}
															spaceItems={spaceInfo.items}
															updateAccessGroupList={updateAccessGroup}
															setAccessGroupModalOpen={setAccessGroupModalOpen}
															spaceID={spaceInfo.id}
															userID={authUserInfo.id}
														/>
													)}
												</>
											)}
											<p>All Members Access allows anyone with any Membership to have access. Custom
												Access locks the Channel to specific Memberships only.</p>
										</div>
										<div className="formrow split">
											<DropdownWithItems
												title="Channel Posting"
												customButtonClass="button gradient secondary distribute w-full"
												icon="fa-light fa-subtitles"
												selectedValue={selectedPosting}
												listItems={SPACE_CHANNEL_POSTING}
												isDisableDropdown={isDisableDropdown}
												onDropdownChange={(value) => {
													setSelectedPosting(value)
												}}
											/>
											<p>Allow Posting lets any Member with Access post to the Channel. Disallow restricts
												Members from posting and only allows your Team to post.</p>
										</div>
										<div className="formrow split">
											<DropdownWithItems
												title="Channel Commenting"
												customButtonClass="button gradient secondary distribute w-full"
												icon="fa-light fa-comment"
												selectedValue={selectedCommenting}
												listItems={SPACE_CHANNEL_COMMENTING}
												isDisableDropdown={isDisableDropdown}
												onDropdownChange={(value) => {
													setSelectedCommenting(value)
												}}
											/>
											<p>Allow Commenting lets any Member with Access comment on a Post. Disallow
												restricts Members from commenting on Channel Posts.</p>
										</div>
										<div className="formrow split">
											<div className="form-input-checkbox gradient md rounded row w-full">
												<div className="checkbox">
													<input
														id="checkbox-cs-agreement"
														aria-describedby="checkbox-cs-agreement-description"
														name="checkbox-cs-agreement"
														type="checkbox"
														required=""
														disabled={disableCheckbox}
														checked={defaultChecked}
														onChange={(e)=>{
															const isChecked = e.target.checked;
															setIsDisableDropdown(isChecked);
															setDefaultChecked(isChecked);
														}}
													/>
												</div>
												<div>
													<label htmlFor="checkbox-cs-agreement" className="sr-only">Space Terms</label>
													<p id="checkbox-cs-agreement-description">
														Set as <a href="#link-via-admin" target="_blank">Default Channel</a>.
													</p>
												</div>
												{/*{alertCheckbox && (*/}
												{/*	<div className="alert callout warning">*/}
												{/*		<div className="action">*/}
												{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
												{/*		</div>*/}
												{/*		<div className="content self-center">*/}
												{/*			<div>*/}
												{/*				<h6>{alertCheckbox}</h6>*/}
												{/*				<p></p>*/}
												{/*			</div>*/}
												{/*		</div>*/}
												{/*	</div>*/}
												{/*)}*/}
											</div>
											<p>Your Default Channel is where all Members automatically start when visiting the
												Channels section, and must have Access set to All Members.</p>
										</div>
										{accessError && (
											<div className="alert callout warning">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content self-center">
													<div>
														<h6>{accessError}</h6>
														<p></p>
													</div>
												</div>
											</div>
										)}
										<button
											className="button gradient"
											type="submit"
											disabled={isCreateChannelLoading}
										>
											<span>
												<p>
													{isCreateChannelLoading && (
														<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
													)}
													Save Channel
												</p>
											</span>
										</button>
									</div>
								</Form>
							</div>
						))}
					</div>
				)}
				{(isChannelsEnable && channels && channels.length > 0) && (
					<div className="cs-dash-card block-full card">
						<div className="formrow title">
							<h3>Disable Channels</h3>
							<p>Disabling the Channels section will not delete any content</p>
						</div>
						<Form
							form={disableChannelForm}
							name="disableChannelForm"
							validateMessages={VALIDATE_MESSAGES}
							onFinish={handleDisableChannels}
							className="cs-stack-controlrows stack-form-col"
						>
							<div className="formrow split">
								<div className="form-input gradient">
									<span>
										<label htmlFor="password">Password</label>
										<Form.Item
											label="Password"
											name="channel_password"
											validateTrigger="onBlur"
											onChange={(e) => {
												setPassword(e.target.value);
												setAlert(null);
											}}
											rules={[
												{ required: true }
											]}
										>
											<FormFieldStacked
												formType="input"
												type="password"
												placeholder="Enter password to disable"
												className="input-md body-text--small color-reg"
												disabled={false}
											/>
										</Form.Item>
									</span>
								</div>
								<button
									className={"button gradient secondary" + (password.trim() === "" ? " disabled" : "")}
									type="submit"
									disabled={password.trim() === ""}
								>
									<span>
										<p>
											{isDisableChannelLoading && (
												<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
											)}
											Disable Channels
										</p>
									</span>
								</button>
							</div>
						</Form>
						{alert && (
							<div className="alert callout warning">
								<div className="action">
									<i className="fa-solid fa-triangle-exclamation"></i>
								</div>
								<div className="content self-center">
									<div>
										<h6>{alert.message}</h6>
										<p></p>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(ManageChannelsPage);
