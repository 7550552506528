import { memo } from 'react';
import moment from "moment/moment";

const SpaceItem = (parentProps) => {
	const { space, collection_index, data } = parentProps;

	return (
		<>
			{/*<div className="col-3">*/}
				<div className="no-blur">
					<img alt="collection-image" className="img-spaces" src={data.photo} />
					{/*{collection_index === 0 && (*/}
						<div className="collection-content">
							<h6 className="header-small-title text-center">
								{data.name}
							</h6>
							<p className="body-txtitalic--smallest text-center">
								{data.collection_type === 'membership' ? (
									<>
										{space.name} Membership {moment(data.created_on).format('MM/DD/YY')}
									</>
								) : (
									<>
										{space.name} Item {moment(data.created_on).format('MM/DD/YY')}
									</>
								)}

							</p>
						</div>
					{/*)}*/}
					{/*<div className="bg-overlay"/>*/}
				</div>
			{/*</div>*/}
		</>
	);
};

export default memo(SpaceItem);
