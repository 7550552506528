import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES, GOOGLE_MAP_KEY } from '../../../constants/common';
import {
	updateUserSettingsBasics,
	updateUserSettingsSecurity,
	updateUserSettingsContacts,
	updateUserSettings2FAStatus
} from '../../../services/user';
import { getAuthUserInfo } from '../../../states/general/actions';
import { isAlphaNumeric } from '../../../utils/form';
import routes from '../../../routes';
import Autocomplete from 'react-google-autocomplete';
import './SettingsContent.scss';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const SettingsContent = () => {
	const navigate = useNavigate();
	const [basicsEditForm] = Form.useForm();
	const [securityEditForm] = Form.useForm();
	const [contactNotificationsEditForm] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [formIsOnFocusBasic, setFormIsOnFocusBasic] = useState(false);
	const [formHasErrorBasic, setFormHasErrorBasic] = useState(true);
	const [formIsOnFocusSecurity, setFormIsOnFocusSecurity] = useState(false);
	const [formHasErrorSecurity, setFormHasErrorSecurity] = useState(true);
	const [formIsOnFocusContactNotification, setFormIsOnFocusContactNotification] = useState(false);
	const [formHasErrorContactNotification, setFormHasErrorContactNotification] = useState(true);
	const [submitted2FA, setSubmitted2FA] = useState(false);
	const [submittedBasics, setSubmittedBasics] = useState(false);
	const [submittedSecurity, setSubmittedSecurity] = useState(false);
	const [submittedContacts, setSubmittedContacts] = useState(false);
	const [birthdateAlert, setBirthdateAlert] = useState(null);
	const [alertBasic, setAlertBasic] = useState(null);
	const [alertSecurity, setAlertSecurity] = useState(null);
	const [alertContactNotification, setAlertContactNotification] = useState(null);
	const [inputElement, setInputElement] = useState();

	const onClick2FAuthentication = useCallback(async () => {
		if (!authUserInfo.auth_2fa_active) {
			const data = {
				status: true
			};

			try {
				if (authUserInfo) {
					setSubmitted2FA(true);
					data.user_id = authUserInfo.id;
					const result = await updateUserSettings2FAStatus(data);
					if (result) {
						if (result.result) {
							dispatch(getAuthUserInfo());
							navigate(routes.settings2FA());
						}
					}
					setSubmitted2FA(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitted2FA(false);
			}
		} else {
			navigate(routes.settings2FA());
		}
	}, [authUserInfo]);

	const handleBasicFormOnBlur = useCallback(async () => {
		setAlertBasic(null);
		await basicsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorBasic(false);
			})
			.catch((errors) => {
				setFormHasErrorBasic(true);
			});
	}, []);

	const handleSecurityFormOnBlur = useCallback(async () => {
		setAlertSecurity(null);
		await securityEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorSecurity(false);
			})
			.catch((errors) => {
				setFormHasErrorSecurity(true);
			});
	}, []);

	const handleContactNotificationFormOnBlur = useCallback(async () => {
		setAlertContactNotification(null);
		await contactNotificationsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorContactNotification(false);
			})
			.catch((errors) => {
				setFormHasErrorContactNotification(true);
			});
	}, []);

	const handleBasicFormChanges = useCallback(async () => {
		setAlertBasic(null);
		await basicsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorBasic(false);
			})
			.catch((errors) => {
				setFormHasErrorBasic(true);
			});
	}, []);

	const handleSecurityFormChanges = useCallback(async () => {
		setAlertSecurity(null);
		await securityEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorSecurity(false);
			})
			.catch((errors) => {
				setFormHasErrorSecurity(true);
			});
	}, []);

	const handleContactNotificationFormChanges = useCallback(async () => {
		setAlertContactNotification(null);
		await contactNotificationsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorContactNotification(false);
			})
			.catch((errors) => {
				setFormHasErrorContactNotification(true);
			});
	}, []);

	const submitBasicsEditForm = useCallback(
		async (values) => {
			setAlertBasic(null);
			setBirthdateAlert(null);

			if (!values.first_name) {
				setAlertBasic({
					variant: 'danger',
					message: 'Please enter at least 1 character.'
				});
				return;
			}

			if (!values.last_name) {
				setAlertBasic({
					variant: 'danger',
					message: 'Please enter at least 1 character.'
				});
				return;
			}

			if (!values.city) {
				setAlertBasic({
					variant: 'danger',
					message: 'Please enter a valid city.'
				});
				return;
			}

			if (!values.birthdate) {
				setAlertBasic({
					variant: 'danger',
					message: 'Please enter your birthdate.'
				});
				return;
			}

			const data = values;
            if (values.birthdate) {
                data.birthdate = values.birthdate;
            }
            console.log("data ", data)
			try {
				if (authUserInfo) {
					setSubmittedBasics(true);
					data.user_id = authUserInfo.id;
					const result = await updateUserSettingsBasics(data);
					if (result) {
						if (result.result) {
							setAlertBasic({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						} else {
							if (result.error_field === 'birthdate') {
								setBirthdateAlert({ variant: 'danger', message: result.message });
							}
						}
					}
					setSubmittedBasics(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedBasics(false);
			}
		},
		[authUserInfo]
	);

	const submitSecurityEditForm = useCallback(
		async (values) => {
			setAlertSecurity(null);

			if (!values.password) {
				setAlertSecurity({
					variant: 'danger',
					message: 'Password is required.'
				});
				return;
			}

			if (!values.confirmpassword) {
				setAlertSecurity({
					variant: 'danger',
					message: 'Please confirm your password.'
				});
				return;
			}

			try {
				if (authUserInfo) {
					setSubmittedSecurity(true);
					values.user_id = authUserInfo.id;
					const result = await updateUserSettingsSecurity(values);
					if (result) {
						if (result.result) {
							setAlertSecurity({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						}
					}
					setSubmittedSecurity(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedSecurity(false);
			}
		},
		[authUserInfo]
	);

	const submitContactNotificationsEditForm = useCallback(
		async (values) => {
			setAlertContactNotification(null);

			if (!values.email) {
				setAlertContactNotification({
					variant: 'danger',
					message: 'Email is required.'
				});
				return;
			}

			if (!values.phone_sms) {
				setAlertContactNotification({
					variant: 'danger',
					message: 'Phone (SMS) is required.'
				});
				return;
			}

			try {
				if (authUserInfo) {
					setSubmittedContacts(true);
					values.user_id = authUserInfo.id;
					const result = await updateUserSettingsContacts(values);
					if (result) {
						if (result.result) {
							setAlertContactNotification({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						} else {
							setAlertContactNotification({
								variant: 'danger',
								message: result.message
							});
						}
					}
					setSubmittedContacts(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedContacts(false);
			}
		},
		[authUserInfo]
	);

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			basicsEditForm.setFieldsValue({
				first_name: authUserInfo?.profile?.first_name ?? '',
				last_name: authUserInfo?.profile?.last_name ?? '',
				city: authUserInfo?.city ?? '',
				birthdate: authUserInfo?.birthdate
			});
			contactNotificationsEditForm.setFieldsValue({
				email: authUserInfo?.email ?? '',
				phone_sms: authUserInfo?.phone_sms ?? ''
			});

            console.log("basicsEditForm ", basicsEditForm.getFieldValue('birthdate'))
		}
		const checkValue = (str, max) => {
			if (str.charAt(0) !== '0' || str === '00') {
				var num = parseInt(str);
				if (isNaN(num) || num <= 0 || num > max) num = 1;
				str =
					num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
						? '0' + num
						: num.toString();
			}
			return str;
		};

		const handleInput = (e) => {
			e.target.type = 'text';
			let input = e.target.value;
			if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
			const values = input.split('/').map((v) => v.replace(/\D/g, ''));
			if (values[0]) values[0] = checkValue(values[0], 12);
			if (values[1]) values[1] = checkValue(values[1], 31);
			const output = values
				.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
				.join('')
				.substr(0, 14);
			inputElement.value = output;

			const inputDate = output;
			const dateParts = inputDate.split('/');
			const year = parseInt(dateParts[2], 10);
			const month = parseInt(dateParts[0], 10) - 1;
			const day = parseInt(dateParts[1], 10);
			// const newDate = new Date(year, month, day);

			const isFourDigits = (num) => {
				return /^\d{4}$/.test(num.toString());
			};

			// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
			if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
				basicsEditForm.setFieldValue('birthdate', dayjs(new Date(year, month, day)));
			}
		};

		setInputElement(document.getElementById('basicsEditForm_birthdate'));
		if (inputElement) {
			inputElement.addEventListener('input', handleInput);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleInput);
			}
		};
	}, [loading, authUserInfo, inputElement]);

	return (
		<div className="block-full">
			<div className="col-span-12">
				<div className="block-full card">
					<div className="dashboard-wrapper pt-4">
						<h3>Account Settings</h3>
						<p className="pt-3">
							Your account information and settings are separate from your{' '}
							<a className="gradient-color-txt body-txtitalic--small" href="">
								Profile
							</a>
							. It is kept private and used to manage your account, payments, ownership,
							identity, and benefits across spaces.{' '}
							{getSitewideLink('learn_more_account', 'gradient-color-txt body-txtitalic--small')}.
						</p>
					</div>
				</div>

				<div className="block-full card mt-8">
					<Form
						form={basicsEditForm}
						name="basicsEditForm"
						// initialValues={{ city: authUserInfo.city != null || undefined ? authUserInfo.city : "" }}
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusBasic(true);
						}}
						onBlur={() => {
							setFormIsOnFocusBasic(false);
							handleBasicFormOnBlur().then(r => {});
						}}
						onChange={handleBasicFormChanges}
						onFinish={submitBasicsEditForm}
						className={formIsOnFocusBasic ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Basics</h5>
							</div>
							<div className="col-span-6">
								<p className="text-end">
									Your basic account information
								</p>
							</div>
						</div>

						{alertBasic && (
							<section className={`alert banner ${alertBasic?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
								<div className="action">
									<i className="fa-solid fa-circle-check"/>
								</div>
								<div className="content">
									<hgroup>
										<h6>{alertBasic?.variant === 'danger' ? 'Error' : 'Success'}</h6>
										<p>{alertBasic.message}</p>
									</hgroup>
								</div>
							</section>
						)}

						<div className="centerframe-flex items-center">
							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										First Name
									</label>
									<Form.Item
										name="first_name"
										className="mb-0 hidden-label z-0 block-lg"
										validateTrigger="onBlur"
										// rules={[
										// 	{
										// 		required: true,
										// 		message: 'Please enter at least 1 character.'
										// 	},
										// ]}
									>
										<Input disabled={submittedBasics} />
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your First Name is private and used for communications, billing,
									and account management only. You may also opt-in to share it
									with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_firstname')}.
								</p>
							</div>

							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										Last Name
									</label>
									<Form.Item
										name="last_name"
										className="mb-0 hidden-label z-0 block-lg"
										validateTrigger="onBlur"
										// rules={[
										// 	{
										// 		required: true,
										// 		message: 'Please enter at least 1 character.'
										// 	}
										// ]}
									>
										<Input disabled={submittedBasics} />
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your Last Name is private and used for communications, billing,
									and account management only. You may also opt-in to share it
									with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_lastname')}.
								</p>
							</div>

							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										City
									</label>
									<Form.Item
										name="city"
										className="mb-0 hidden-label z-0 block-lg"
										validateTrigger="onBlur"
										// rules={[
										// 	{
										// 		required: true,
										// 		message: 'Please enter a valid city.'
										// 	}
										// ]}
									>
										<Autocomplete
											apiKey={GOOGLE_MAP_KEY}
											placeholder=''
											className={"input-md"}
											onPlaceSelected={(place) => {
												basicsEditForm.setFieldValue('city', place.formatted_address);
											}}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your City is private and used for communications, billing,
									account management, content delivery, and for compliance with
									local regulations. You may also opt-in to share it with the
									management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_city')}.
								</p>
							</div>

							{birthdateAlert && (
								<section className={`alert banner ${birthdateAlert?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
									<div className="action">
										<i className="fa-solid fa-circle-check"/>
									</div>
									<div className="content">
										<hgroup>
											<h6>{birthdateAlert?.variant === 'danger' ? 'Error' : 'Success'}</h6>
											<p>{birthdateAlert.message}</p>
										</hgroup>
									</div>
								</section>
							)}
							<div className="block-lg mt-3">
                            <Form.Item
										name="birthdate"
										rules={[
											{
												required: true,
                                                message: "Birthdate is required"
											}
										]}
									>
										<div  className="form-input">
											<label id="settings-birthdate"  htmlFor="birthdate">Birthdate</label>
											{/* <DatePicker
												placeholder="dd/mm/yy"
												className={
													'w-full rounded-full bg-transparent border-0'
												}
												format="DD/MM/YYYY"
                                                onChange={(date) => {
                                                    handleInput(date)
                                                    form.setFieldsValue({ birthdate: date });
                                                }}
											/> */}
                                            <input 
                                                id="birthdate" 
                                                onChange={(date) => {
                                                console.log(date)
                                                basicsEditForm.setFieldsValue({ birthdate: date.target.value });
                                                }}
                                                value={basicsEditForm.getFieldValue('birthdate') || ''}
                                                name="birthdate" 
                                                type="date" 
                                                autoComplete="birthdate" 
                                                required 
                                                placeholder="MM/DD/YYYY"
                                                />
										</div>
										

									</Form.Item>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your birthdate is private and used for communications, billing,
									account management, age-appropriate content gating, and for
									compliance with local regulations. You may also opt-in to share
									it with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_birthdate')}.
								</p>
							</div>
						</div>

						<div className="block-md mt-8">
							<Form.Item>
								<Button
									className="primary-gradient w-full"
									htmlType="submit"
									disabled={submittedBasics || formHasErrorBasic}
								>
									{submittedBasics && (
										<i className="fa-solid fa-spinner fa-spin"></i>
									)}
									<span><p>Save</p></span>
								</Button>
							</Form.Item>
						</div>
					</Form>
				</div>


				<div className="block-full card mt-8">
					<Form
						form={securityEditForm}
						name="securityEditForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusSecurity(true);
						}}
						onBlur={() => {
							setFormIsOnFocusSecurity(false);
							handleSecurityFormOnBlur().then(r => {});
						}}
						onChange={handleSecurityFormChanges}
						onFinish={submitSecurityEditForm}
						className={formIsOnFocusSecurity ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Security</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Two-Factor authentication is strongly recommended.
								</p>
							</div>
						</div>

						{alertSecurity && (
							<section className={`alert banner ${alertSecurity?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
								<div className="action">
									<i className="fa-solid fa-circle-check"/>
								</div>
								<div className="content">
									<hgroup>
										<h6>{alertSecurity?.variant === 'danger' ? 'Error' : 'Success'}</h6>
										<p>{alertSecurity?.message}</p>
									</hgroup>
								</div>
							</section>
						)}

						<div className="centerframe-flex items-center">
							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										Change Password
									</label>
									<Form.Item
										name="password"
										className="mb-0 hidden-label z-0 block-lg"
										validateTrigger="onBlur"
										rules={[
											({ }) => ({
												validator(_, value) {
													if (value && value.length < 8) {
														return Promise.reject(
															new Error('Password is case sensitive and must meet all the requirements.')
														).catch(() => {
															setAlertSecurity({
																variant: 'danger',
																message: 'Password is case sensitive and must meet all the requirements.'
															});
														});
													}
													if (isAlphaNumeric(value)) {
														return Promise.resolve();
													}
													if (value && value.length > 0) {
														return Promise.reject(
															new Error('Password is case sensitive and must meet all the requirements.')
														).catch(() => {
															setAlertSecurity({
																variant: 'danger',
																message: 'Password is case sensitive and must meet all the requirements.'
															});
														});
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											type="password"
											className="input-md"
											disabled={submittedSecurity}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Must be a minimum of 8 case-sensitive characters including a
									symbol and a number.
								</p>
							</div>

							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										Confirm New Password
									</label>
									<Form.Item
										name="confirmpassword"
										className="mb-0 hidden-label z-0 block-lg"
										dependencies={['password']}
										rules={[
											// {
											// 	required: true,
											// 	message: 'Please confirm your password.'
											// },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue('password') === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															'Your password entries do not match.'
														)
													).catch(()=>{
														setAlertSecurity({
															variant: 'danger',
															message: 'Your password entries do not match.'
														});
													});
												}
											})
										]}
									>
										<Input
											type="password"
											className="input-md"
											disabled={submittedSecurity}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									When changing your password, you must re-enter your new password
									to confirm that it matches. Be sure to click save.
								</p>
							</div>

							<div className="block-lg mt-3">
								<button
									className="secondary-gradient w-full"
									onClick={onClick2FAuthentication}
									disabled={submitted2FA}
								>
									{submitted2FA ? (
										<>
											<i className="fa-solid fa-spinner fa-spin me-2"/>
											<span><p>Enabling 2 Factor Authentication</p></span>
										</>
									) : (
										<span>
											<p>{authUserInfo && authUserInfo.auth_2fa_active
												? 'Manage'
												: 'Enable'}{' '}
												2 Factor Authentication
											</p>
										</span>
									)}
								</button>
							</div>
							<div className="block-lg mt-3">
								<div className="col-span-1 my-auto !order-1 md:!order-2">
									<p className="italic !leading-4">
										Two-Factor authentication is required if you own or manage a
										Space and is strongly recommended for all accounts.{' '}
										{getSitewideLink('learn_more_security_2fa')}.
									</p>
								</div>
							</div>
						</div>

						<div className="block-md mt-8">
							<Form.Item>
								<Button
									className="primary-gradient w-full"
									htmlType="submit"
									disabled={submittedSecurity || formHasErrorSecurity}
								>
									{submittedSecurity && (
										<i className="fa-solid fa-spinner fa-spin"></i>
									)}
									<span><p>Save</p></span>
								</Button>
							</Form.Item>
						</div>
					</Form>
				</div>

				<div className="block-full card mt-8">
					<Form
						form={contactNotificationsEditForm}
						name="contactNotificationsEditForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusContactNotification(true);
						}}
						onBlur={() => {
							setFormIsOnFocusContactNotification(false);
							handleContactNotificationFormOnBlur().then(r => {});
						}}
						onChange={handleContactNotificationFormChanges}
						onFinish={submitContactNotificationsEditForm}
						className={
							formIsOnFocusContactNotification ? 'hide-antd-error-messages z-0' : 'z-0'
						}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Contacts & Notifications</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Enable SMS to get the latest updates from your Spaces.
								</p>
							</div>
						</div>

						{alertContactNotification && (
							<section className={`alert banner ${alertContactNotification?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
								<div className="action">
									<i className="fa-solid fa-circle-check"/>
								</div>
								<div className="content">
									<hgroup>
										<h6>{alertContactNotification?.variant === 'danger' ? 'Error' : 'Success'}</h6>
										<p>{alertContactNotification.message}</p>
									</hgroup>
								</div>
							</section>
						)}

						<div className="centerframe-flex items-center">
							<div className="block-lg mt-3">
								<div className="form-input">
									<label>
										Email
									</label>
									<Form.Item
										name="email"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										// rules={[{ required: true, type: 'email' }]}
									>
										<Input className="input-md" disabled={submittedContacts} />
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your Email is private and used for communications, billing, and
									account management only. You may also opt-in to share it with
									the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_cn_email')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>

							<div className="block-lg mt-3">
								<div className="form-input">
									<label className="body-text--small ps-3 gradient-color-txt">
										Phone (SMS)
									</label>
									<Form.Item
										name="phone_sms"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										// rules={[{ required: true }]}
									>
										<Input className="input-md" disabled={submittedContacts} />
									</Form.Item>
								</div>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your phone number is private and will only be used for account
									security, billing, and for any notifications that you have
									enabled.
								</p>
							</div>

							<div className="block-lg">
								<button
									onClick={()=>{navigate(routes.settingsNotificationPreferences())}}
									className="secondary-gradient w-full"
								>
									<span>
										<p>Manage Notifications</p>
									</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									You can control the email and SMS notifications you receive
									here.
								</p>
							</div>
						</div>

						<div className="block-md mt-8">
							<Form.Item>
								<button
									className="primary-gradient w-full"
									type="submit"
									disabled={
										submittedContacts || formHasErrorContactNotification
									}
								>
									{submittedContacts && (
										<i className="fa-solid fa-spinner fa-spin"></i>
									)}
									<span><p>Save</p></span>
								</button>
							</Form.Item>
						</div>
					</Form>
				</div>


				<div className="block-full card mt-8">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Payments and Wallets</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Manage and view your payment methods and transactions.
								</p>
							</div>
						</div>

						<div className="centerframe-flex items-center">
							{/*<div className="block-lg mt-3">*/}
							{/*	<button*/}
							{/*		onClick={()=>{navigate(routes.settingsPaymentMethods())}}*/}
							{/*		className="secondary-gradient w-full"*/}
							{/*	>*/}
							{/*		<span>*/}
							{/*			<p>Manage Payment Methods</p>*/}
							{/*		</span>*/}
							{/*	</button>*/}
							{/*</div>*/}
							{/*<div className="block-lg">*/}
							{/*	<p className="italic !leading-4">*/}
							{/*		You can use credit cards and credit balances to pay for paid*/}
							{/*		features and Spaces.{' '}*/}
							{/*		{getSitewideLink('learn_more_payment_methods')}.*/}
							{/*	</p>*/}
							{/*</div>*/}

							<div className="block-lg mt-3">
								<button
									onClick={()=>{navigate(routes.settingsMagicWallet())}}
									className="secondary-gradient w-full"
								>
									<span>
										<p>Manage Wallets</p>
									</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Your connected ETH, MATIC, and USDC Wallets can be used for
									crypto payments and to hold digital collectibles, membership
									keys, and items.{' '}
									{getSitewideLink('learn_more_manage_wallets')}.
								</p>
							</div>

							<div className="block-lg mt-3">
								<button
									onClick={()=>{navigate(routes.settingsTransactions())}}
									className="secondary-gradient w-full"
								>
									<span>
										<p>View Transactions</p>
									</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									View your transactions and receipts from all the Spaces you are
									part of.{' '}
									{getSitewideLink('learn_more_view_transactions')}.
								</p>
							</div>
						</div>
					</form>
				</div>


				<div className="block-full card mt-8">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Data Management</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Control your data and what is shared with your Spaces.
								</p>
							</div>
						</div>

						<div className="centerframe-flex items-center">
							<div className="block-lg mt-3">
								<button
									onClick={()=>{}}
									disabled
									className="secondary-gradient w-full"
								>
									<span>
										<p>Manage Spaces Data Access</p>
									</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Managers of the Spaces you’ve joined have limited access to your
									data. You can further limit and manage their access here.{' '}
									{getSitewideLink('learn_more_spaces_data_access')}.
								</p>
							</div>

							<div className="block-lg mt-3">
								<button
									onClick={()=>{}}
									disabled
									className="secondary-gradient w-full"
								>
									<span>
										<p>Download Account Data</p>
									</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									Managers of the Spaces you’ve joined have limited access to your
									data. You can further limit and manage their access here.{' '}
									{getSitewideLink('learn_more_download_account_data')}.
								</p>
							</div>
						</div>
					</form>
				</div>

				<div className="block-full card mt-8">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Account Status</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Disable or delete your account here.
								</p>
							</div>
						</div>

						<div className="centerframe-flex items-center">
							<div className="block-lg mt-3">
								<button
									className="secondary-gradient w-full"
									disabled
								>
								<span>
									<p>Disable Account</p>
								</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									You can temporarily disable your Account, which will remove you
									from all activity and Spaces. You will only be able to login to
									manage your Account Settings.{' '}
									{getSitewideLink('learn_more_disable_account')}.
								</p>
							</div>

							<div className="block-lg mt-3">
								<button
									className="secondary-gradient w-full"
									disabled
								>
								<span>
									<p>Delete Account and Personal Data</p>
								</span>
								</button>
							</div>
							<div className="block-lg">
								<p className="italic !leading-4">
									You can PERMANENTLY delete your Account. Once confirmed, your
									account will be immediately disabled and your personal data will
									be scheduled for deletion. Your Wallets and items stored in them
									will not be affected.{' '}
									{getSitewideLink('learn_more_delete_account')}.
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default memo(SettingsContent);
