import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Form} from 'antd';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSpaceInfo} from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import {getSpaceItemData} from '../../../services/space';
import {
    ALCHEMY_API_KEY,
    MAGIC_CONNECT_KEY,
    NODE_ENV,
    MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import Web3 from 'web3';
import {Alchemy, Network} from 'alchemy-sdk';
import {setLocalStorage} from '../../../utils/storage';
import {Magic} from 'magic-sdk';
import {ConnectExtension} from '@magic-ext/connect';
import Alert from 'react-bootstrap/Alert';
import default_items_img from '../../../assets/images/dj_party.png';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";

const ItemErrorWalletConnect = () => {
    const currentLocation = useLocation();
    let space_username = null;
    const [searchParams, setSearchParams] = useSearchParams();
    const itemId = searchParams.get('id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [submitted, setSubmitted] = useState(false);
    const [alert, setAlert] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [itemDetails, setItemDetails] = useState(null);
    const [fetchingCollectionData, setFetchingCollectionData] = useState(false);
    const [collectionData, setCollectionData] = useState(null);
    const [collectionAssetType, setCollectionAssetType] = useState('image');

    // For testing purpose only //
    const test_public_address = '0x452d40db156034223e8865f93d6a532ae62c4a99';
    const test_contract_address = '0xb0bbbc0f43d611351b3115bd5e338f4c402ae8c2';
    // End testing data //

    const settings = {
        apiKey: ALCHEMY_API_KEY,
        network: Network.ETH_MAINNET
    };
    const alchemy = new Alchemy(settings);

    const magic = new Magic(MAGIC_CONNECT_KEY, {
        network: MAGIC_CONNECT_NETWORK,
        locale: 'en_US',
        extensions: [new ConnectExtension()]
    });
    const web3 = new Web3(magic.rpcProvider);

    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        space_username = path_exp[0];
    }

    const getNFTCollectionData = useCallback(async (itemData) => {
        if (itemData) {
            setFetchingCollectionData(true);
            alchemy.nft
                .getContractMetadata(itemData.collection_contract_address)
                .then((response) => {
                    if (response) {
                        setCollectionData(response);
                        setFetchingCollectionData(false);
                    }
                });
        }
    }, []);

    const getItem = useCallback(async (space_id, item_id) => {
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setItemDetails(itemData);

            if (itemData) {
                getNFTCollectionData(itemData).then();
                if (itemData.photo || itemData.video) {
                    if (itemData.photo) {
                        setCollectionAssetType('image');
                    }
                    if (itemData.video) {
                        setCollectionAssetType('video');
                    }
                } else {
                    setCollectionAssetType('image');
                }
            }
        }
    }, []);

    const checkAddressIfInCollection = useCallback(
        async (owner_contract_address, collection_address, nfts, owner_email = null) => {
            let found = false;
            nfts.forEach((nft) => {
                if (nft.contract && nft.contract.address) {
                    if (nft.contract.address === collection_address) {
                        found = true;
                    }
                }
            });

            setLocalStorage('temp_item_nft_is_owned', found);
            setLocalStorage('temp_item_space_username', space_username);

            if (found) {
                setLocalStorage('temp_item_wallet_owner_contract_address', owner_contract_address);
                setLocalStorage('temp_item_wallet_owner_email', owner_email);

                const url = routes.spaceItemWalletConnect();
                const fin_url =
                    url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
                navigate(fin_url);
            } else {
                setSubmitted(false);
                setAlert({
                    variant: 'danger',
                    message: 'NFT not found in your wallet.'
                });
            }
        },
        [space_username, itemId]
    );

    const checkOwnerIfInCollection = useCallback(
        async (owner_contract_address, owner_email = null) => {
            let collection_contract_address = itemDetails.collection_contract_address;

            //WE use test data on dev environment
            if (NODE_ENV === 'development') collection_contract_address = test_contract_address;

            alchemy.nft.getNftsForOwner(owner_contract_address).then((response) => {
                if (response) {
                    checkAddressIfInCollection(
                        owner_contract_address,
                        collection_contract_address,
                        response.ownedNfts,
                        owner_email
                    ).then();
                }
            });
        },
        [authUserInfo, spaceInfo, itemId, itemDetails]
    );

    const web3Login = async (loggedIn) => {
        await magic.connect.disconnect().catch((e) => {
            // console.log(e);
        });
        setSubmitted(true);
        web3.eth
            .getAccounts()
            .then((accounts) => {
                magic.connect
                    .requestUserInfo({isResponseRequired: false})
                    .then((user_account) => {
                        let values = {
                            email: user_account.email ?? 'no-email',
                            user_public_address: accounts?.[0]
                        };

                        //WE use test data on dev environment
                        if (NODE_ENV === 'development') {
                            checkOwnerIfInCollection(test_public_address, values.email).then();
                        } else {
                            checkOwnerIfInCollection(
                                values.user_public_address,
                                values.email
                            ).then();
                        }
                    });
            })
            .catch((error) => {
                setSubmitted(false);
            });
    };

    const connectWallet = useCallback(async () => {
        if (spaceInfo) {
            setAlert(null);
            setSubmitted(true);

            if (authUserInfo) {
                await web3Login(true);
            } else {
                await web3Login(false);
            }
        }
    }, [authUserInfo, spaceInfo, itemId, itemDetails]);

    useEffect(() => {
        if (space_username) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, itemId).then((r) => {
                    });
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(space_username));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }
    }, [loading, authUserInfo, space_username, spaceInfo, initialLoad, itemId]);

    return (
        <>
            <main id="membership-buy" className="flex">
                <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                    <div className="grid margin-right-0">
                        {fetchingCollectionData ? (
                            <div className="pt-5 pb-5">
                                Fetching collection...
                            </div>
                        ) : (
                            <div className="grid grid-cols-2 pr-0 gap-4">
                                {!collectionData ? (
                                    <div className="lg:col-span-1 xsm:col-span-2 lg:pe-5 row mb-5 align-left-center">
                                        {itemDetails && itemDetails.contract_address ? (
                                            <>
                                                <span className="collection-display-error">Collection not found.</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="collection-display-error">No collection contract address set.</span>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className="lg:col-span-1 xsm:col-span-2 lg:pe-5 row mb-5 align-left-center">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>

                                        <h1 className="mb-4 mt-5 header-large">
                                            Wallet Connected!
                                        </h1>
                                        <p className="body-text--reg">
                                            However the matching NFT needed to claim the{' '}
                                            <a href="" className="gradient-color-txt">
                                                {' '}
                                                {itemDetails ? itemDetails.name : ''}
                                            </a>{' '}
                                            for{' '}
                                            <a href="" className="gradient-color-txt">
                                                {' '}
                                                {spaceInfo ? spaceInfo.name : ''}
                                            </a>{' '}
                                            could not be found.
                                            <br/>
                                            <br/>
                                            Please check that you own an NFT from the{' '}
                                            <a href="" className="gradient-color-txt">
                                                {collectionData ? collectionData.name : ''}{' '}
                                                Collection
                                            </a>{' '}
                                            and that it is in the wallet you are connecting
                                            with.
                                        </p>
                                        <>
                                            {alert && (
                                                <div className="mt-5">
                                                    <Alert
                                                        key={alert.variant}
                                                        variant={alert.variant}
                                                        className={
                                                            'custom-alert ' +
                                                            (alert.variant === 'danger'
                                                                ? 'custom-alert-danger'
                                                                : 'custom-alert-success')
                                                        }
                                                    >
                                                        {alert.message}
                                                    </Alert>
                                                </div>
                                            )}
                                        </>
                                        <div className="my-5">
                                            <Form.Item className="mb-0">
                                                <Button
                                                    className="btn btn-primary btn-lg"
                                                    disabled={submitted}
                                                    onClick={connectWallet}
                                                >
                                                    {submitted && (
                                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                    )}
                                                    {submitted ? 'Checking' : 'Connect Wallet'}
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                )}
                                <div className="lg:inline xsm:hidden p-0">
                                    {membershipItemImage(collectionAssetType, itemDetails)}
                                </div>
                            </div>
                        )}
                        <div className="col-span-6">
                            <div className="left-image-holder"></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default memo(ItemErrorWalletConnect);
