import { memo, useCallback } from 'react';

import { shortenNumber } from '../../../../../utils/number';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes';

const Card = (parentProps) => {
	const { type, data, onEventClick } = parentProps;

	const handleFollowUnfollowUser = useCallback(async () => {
		let event = 'follow';
		if (data.user.is_following) {
			event = 'unfollow';
		}
		onEventClick(type, event, data.user.id);
	}, [type, data.user]);

	return (
		<>
			<div className="row-view-wrapper mt-4">
				<div className="grid grid-cols-12 md:gap-8">
					<div className="col-span-4 flex items-center">
						{data && data.user.profile.photo_small ? (
							<img
								className="rounded-circle me-4"
								alt="thumb"
								src={data.user.profile.photo_small}
							/>
						) : (
							<div className="rounded-circle no-user-profile-photo flex justify-center">
								<i className="fa-solid fa-user"></i>
							</div>
						)}
						<label className="header-small ms-2">
							{'@' + data.user.profile.username}
						</label>
					</div>
					<div className="col-span-12 md:col-span-4 flex items-center">
						<i className="fa-solid fa-user-group"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.followers_count)}
						</label>
						<i className="fa-light fa-user-group"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.mutuals_count)}
						</label>
						<i className="fa-light fa-solar-system"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.spaces_count)}
						</label>
						<i className="fa-light fa-grid"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.collections_count)}
						</label>
					</div>
					<div className="col-span-12 md:col-span-4 flex items-center justify-start md:justify-end">
						<Link to="#" onClick={handleFollowUnfollowUser} className="btn-card-view">
							<i
								className={
									'fa-light ' +
									(data.user.is_following ? 'fa-user-minus' : 'fa-user-plus')
								}
								title={data.user.is_following ? 'Unfollow' : 'Follow'}
							></i>
						</Link>
						<Link
							to={routes.viewUserSpace() + data.user.profile.username}
							className="btn-card-view ms-4"
						>
							<i className="fa-thin fa-arrow-up-right-from-square"></i>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(Card);
