import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {MODAL_CUSTOM_STYLE, VALIDATE_MESSAGES} from '../../../../constants/common';
import DropdownWithItems from "../../DropdownWithItems";
const AccessGroupModal = (props) => {
    const {
        modalOpen,
        setModalOpen,
        accessGroupList,
        selectedAccessGroups,
        setSelectedAccessGroups,
        setCreateAccessGroupModalOpen,
        header_title,
        spaceID,
        userID
    } = props;
    const [filteredAccessGroupList, setFilteredAccessGroupList] = useState([]);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState();

    const handleSaveAccessGroup = useCallback(
        () => { setModalOpen(false); }, []
    );

    // const filterDropdownChange = useCallback(
    //     async (value) => {
    //         if (value === 'create') {
    //
    //         } else {
    //             let selected = selectedAccessGroups;
    //             const selectedGroup = selectedAccessGroups.find((access) => access.value === value);
    //             const access = accessGroupList.find((access) => access.value === value);
    //             if (!selectedGroup) {
    //                 selected.push(access);
    //             }
    //             setSelectedAccessGroups(selected);
    //         }
    //     }, [selectedAccessGroups]
    // );

    const filterDropdownChange = (value) => {
        if (value === 'create') {
            setCreateAccessGroupModalOpen(true);
            setModalOpen(false);
        } else {
            let selected = selectedAccessGroups;
            const selectedGroup = selectedAccessGroups.find((access) => access.value === value);
            const access = accessGroupList.find((access) => access.value === value);
            if (!selectedGroup) {
                selected.push(access);
            }
            setSelectedAccessGroup(access);
            setSelectedAccessGroups(selected);
        }
    };

    const removeAccessGroup = useCallback(
        async (value) => {
            let selected = selectedAccessGroups;
            const access = accessGroupList.find((access) => access.value === value);
            if (access) {
                selected = selected.filter(x => x.value !== value)
                setSelectedAccessGroups(selected)
            }
        }, [selectedAccessGroups, accessGroupList]
    );

    useEffect(() => {
        if (!modalOpen) {
        }
    }, [modalOpen]);

    useEffect(() => {
        let groupList = [
            { label: 'Select Access Group', value: 'default', disabled: true },
            { label: 'Create New Access Group', value: 'create' }
        ];
        if (accessGroupList && accessGroupList.length > 0) {
            accessGroupList.map((accessGroup) => {
                groupList.push(accessGroup);
            })
        }
        // if (selectedAccessGroups && selectedAccessGroups.length > 0) {
        //     selectedAccessGroups.map((selectedAccessGroup) => {
        //         groupList.map((gList) => {
        //             if (selectedAccessGroup.value === gList.value)
        //                 groupList.shift(accessGroup);
        //         })
        //     })
        // }
        setFilteredAccessGroupList(groupList);
    }, [accessGroupList]);

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={MODAL_CUSTOM_STYLE}
            portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
            overlayClassName="centerframe-flex frame-modal"
            className="block-lg modal-block card"
        >
            <div className="stack-row split">
                <h2>{header_title + ' '}Custom Access</h2>
                <a onClick={() => setModalOpen(false)} id="modal-close" className="toggle-menu-gradient"><i className="fa-solid fa-xmark-large"></i></a>
            </div>
            <p>
                Specify which Access Group(s) can access this Channel. Members with any active Membership or Item in the selected Access Group will be granted access to the Channel. Access changes will apply immediately and you can manage
                <a href="link-via-admin" target="_blank">{' '}Access Groups</a> from
                <a href="cs-space-dash-space-access.html" target="_blank">{' '}Space Dashboard &gt; Access Groups</a>.
            </p>
            {/*<a href="#" className="button gradient secondary dropdown"><span><p>Select Access Group</p><i*/}
            {/*    className="fa-solid fa-caret-down"></i></span></a>*/}
            <DropdownWithItems
                title="Select Access Group"
                customButtonClass="button gradient secondary dropdown w-full"
                selectedValue='default'
                listItems={filteredAccessGroupList}
                onDropdownChange={(value) => { filterDropdownChange(value) }}
            />
            {(selectedAccessGroups && selectedAccessGroups.length > 0) && (
                <div className="stack-form-col">
                    {selectedAccessGroups.map((accessGroup, index) => (
                        <a key={index} className="button gradient secondary dropdown">
                            <span>
                                <p>{accessGroup.label}</p>
                                <i
                                    className="fa-solid fa-xmark"
                                    onClick={() => {
                                        removeAccessGroup(accessGroup.value).then(r => {});
                                    }}
                                />
                            </span>
                        </a>
                    ))}
                </div>
            )}
            <a onClick={handleSaveAccessGroup} className="button gradient"><span><p>Save &amp; Close</p></span></a>
        </ReactModal>
    );
};

export default AccessGroupModal;
