export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const RECAPTCHA_ENTERPRISE_SITE_KEY = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;
export const MAGIC_CONNECT_KEY = process.env.REACT_APP_MAGIC_CONNECT_KEY;
export const MAGIC_OAUTH_KEY = process.env.REACT_APP_MAGIC_OAUTH_KEY;
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
export const GOOGLE_FONT_KEY = process.env.REACT_APP_GOOGLE_FONT_KEY;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const MAGIC_CONNECT_NETWORK = process.env.REACT_APP_MAGIC_CONNECT_NETWORK;
export const CREATE_SPACE_UID = process.env.REACT_APP_CREATE_SPACE_UID;
export const TRANSLATOR_DEBUG = process.env.REACT_APP_TRANSLATOR_DEBUG ? process.env.REACT_APP_TRANSLATOR_DEBUG : false;

export const STORAGE_KEYS = {
	ACCESS_TOKEN: 'access_token',
	REFRESH_TOKEN: 'refresh_token'
};

export const VALIDATE_MESSAGES = {
	// eslint-disable-next-line no-template-curly-in-string
	required: '${label} is required.',
	types: {
		// eslint-disable-next-line no-template-curly-in-string
		email: '${label} is not valid.',
		// eslint-disable-next-line no-template-curly-in-string
		number: '${label} is not valid.'
	},
	number: {
		// eslint-disable-next-line no-template-curly-in-string
		range: '${label} must be between ${min} and ${max}.'
	}
};

export const FORM_LAYOUT = {
	labelCol: {
		span: 8
	},
	wrapperCol: {
		span: 16
	}
};

export const MODAL_CUSTOM_STYLE = {
	content: {
		position: null,
		inset: null,
		border: null,
		background: null,
		overflow: null,
		borderRadius: null,
		outline: null,
		padding: null
	},
	overlay: {
		backgroundColor: null,
		position: null,
		inset: null
	}
};
