import { combineReducers } from 'redux';
import generalReducer from './general/reducer';
import spaceReducer from './spaceGlobal/reducer';
import classSizeReducer from './classSize';
import channelReducer from './channelSlice';
import displayPageContentLoader from './pageContentLoader';
import footerInViewReducer from "./footerInViewSlice";

const appReducer = combineReducers({
	general: generalReducer,
	space: spaceReducer,
	classSize: classSizeReducer,
	channel: channelReducer,
	footerInView: footerInViewReducer,
	displayPageContentLoader: displayPageContentLoader
});

export default appReducer;
