import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

import routes from '../../../routes';

// import space_ex1 from '../../../../src/assets/images/space_ex1.png';
// import space_ex2 from '../../../../src/assets/images/space_ex2.png';
// import space_ex3 from '../../../../src/assets/images/space_ex3.png';
// import space_ex4 from '../../../../src/assets/images/space_ex4.png';
// import space_ex5 from '../../../../src/assets/images/space_ex5.png';
// import space_ex6 from '../../../../src/assets/images/space_ex6.png';
// import space_ex7 from '../../../../src/assets/images/space_ex7.png';
import space_ex1a from '../../../../src/assets/images/cs-space-sample-1a.png';
import space_ex1b from '../../../../src/assets/images/cs-space-sample-1b.png';
import space_ex1c from '../../../../src/assets/images/cs-space-sample-1c.png';
import space_ex2a from '../../../../src/assets/images/cs-space-sample-2a.png';
import space_ex2b from '../../../../src/assets/images/cs-space-sample-2b.png';
import space_ex3a from '../../../../src/assets/images/cs-space-sample-3a.png';
import space_ex3b from '../../../../src/assets/images/cs-space-sample-3b.png';
import space_ex3c from '../../../../src/assets/images/cs-space-sample-3c.png';
import space_ex4a from '../../../../src/assets/images/cs-space-sample-4a.png';
import space_ex4b from '../../../../src/assets/images/cs-space-sample-4b.png';
import space_ex4c from '../../../../src/assets/images/cs-space-sample-4c.png';


import {getSitewideLink} from "../../../utils/sitewideLinks";

const GettingStarted = () => {
	return (
		<>
			{/* <main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-8 gap-y-4">
						<div className="col-span-12">
							<h1 className="header-large !text-5xl">Getting Started</h1>
						</div>
						<div className="col-span-12 grid grid-cols-1 gap-5 md:grid-cols-12">
							<div className="md:col-span-7 grid max-[767px]:order-last">
								<p className="body-text--reg mt-4">
									With Commonspace, you can build a community site for yourself, your
									business, a group, or anything else you like. We call these{' '}
									{getSitewideLink('spaces_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}and are similar to an ecommerce website, except instead of adding
									different products to sell, you’re adding different{' '}

									{getSitewideLink('memberships_create_space', 'gradient-color-txt body-txtitalic--reg')}
									. You also have an{' '}
					
									{getSitewideLink('updates_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}blog area,{' '}
			
									{getSitewideLink('benefits_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}area, and{' '}
				
									{getSitewideLink('members_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}area. You can customize all of these and enable other features to
									integrate your Space into your business or group.
								</p>
								<h3 className="header-medium mt-8">How It Works</h3>
								<p className="body-text--reg mt-2">
									For your first time creating on Commonspace, you’ll be guided
									through each step to create and launch your Space. Here are the{' '}
			
									{getSitewideLink('steps_to_create_space', 'gradient-color-txt body-txtitalic--reg')}
									:
								</p>
								<ol className="mt-8 list-decimal list-outside pl-5" type="1">
									<li className="body-text--reg">
					
										<div className='flex'>
											<div >{getSitewideLink('create_your_space', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Get your Space set up with basic details, logo, and profile
												information.
											</div>
										</div>
						
									</li>
									<li className="body-text--reg">
						
										<div className='flex'>
											<div >{getSitewideLink('add_sections', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Customize your Welcome Section and other areas for your
												homepage.
											</div>
										</div>
						
									</li>
									<li className="body-text--reg">
						
										<div className='flex'>
											<div >{getSitewideLink('create_membership', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Offer a free or paid Membership so that people can signup and
												join your Space.
											</div>
										</div>
						
									</li>
									<li className="body-text--reg">
					
										<div className='flex'>
											<div >{getSitewideLink('add_membership_traits', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Customize your first Membership with optional information and
												additional details.
											</div>
										</div>
						
									</li>
									<li className="body-text--reg">
							
										<div className='flex'>
											<div >{getSitewideLink('create_benefits', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Create and add Benefits that will be provided to Members with
												your new Membership.
											</div>
										</div>
								
									</li>
								</ol>
								<p className="body-text--reg mt-5">
									Once you’ve completed these steps, you’ll be able to preview and
									launch your Space! You will also have the chance to further
									customize your Space by completing these optional guided steps:
								</p>
								<ul className="mt-5 list-disc list-outside pl-5">
									<li className="body-text--reg">
								
										<div className='flex'>
											
											<div >{getSitewideLink('create_item', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Offer a free or paid digital Item that gives Members additional
												Benefits or upgraded access.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
									
										<div className='flex'>
											
											<div >													
												{getSitewideLink('post_update', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Welcome visitors with a public post or make a Members Only post
												to guide your first Members.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
									
										<div className='flex'>
											
											<div >													
												{getSitewideLink('enable_community_features', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Set up and customize your Community Channels, Points System, and
												Leaderboard.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
								
										<div className='flex'>
											
											<div >													
												{getSitewideLink('upgrade_pro', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Connect your domain, get custom branding, and other Pro features
												by upgrading your Space.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
								
										<div className='flex'>
											
											<div >													
												{getSitewideLink('invite_team', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Give your team special access to your Space to manage, moderate,
												and post.
											</div>
										</div>										
									</li>
								</ul>
								<p className="body-text--reg mt-5 font-['notoSansItalic']">
									These additional steps are optional and you can always do them later
									from your Dashboard.{' '}
								</p>
								<h4 className="header-small mt-5">
									Ready to get started and create your Space?
								</h4>
								<Link
								
									to={routes.createSpace()}
									target="_self"
									className="btn btn-lg btn-primary mt-5"
								>
									Let's go! <i className="fa-solid fa-rocket ml-4"/>
								</Link>

								<p className="body-text--reg mt-5 italic">
									Need help?{' '}
					
									{getSitewideLink('view_guide_create_space', 'gradient-color-txt body-txtitalic--reg')}
								</p>
							</div>
							<div className="md:col-span-5 max-[767px]:order-first">
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-6">
										<img className="img-fluid mx-auto" src={space_ex1} />
									</div>
									<div className="col-span-6 text-end">
										<img className="img-fluid mx-auto" src={space_ex2} />
										<img className="img-fluid mx-auto mt-5" src={space_ex3} />
									</div>
								</div>
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-12">
										<img className="img-fluid mx-auto mt-5" src={space_ex4} />
									</div>
									<div className="col-span-6 mt-5">
										<img className="img-fluid mx-auto" src={space_ex5} />
										<img className="img-fluid mx-auto mt-5" src={space_ex6} />
									</div>
									<div className="col-span-6 mt-5 text-end">
										<img className="img-fluid mx-auto" src={space_ex7} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main> */}
            <main id="cs-platform-main" className="cs-main main-theme"> 
                <section id="cs-platform-create-start-c1" className="p-section-safeview theme-transparent">
                    <div className="centerframe-grid gap-2gutter">
                        <article className="block-xl">
                            <hgroup className="heading">
                                <h1>Getting Started</h1>
                                <p>
                                    With Commonspace, you can build a community site for yourself, your
									business, a group, or anything else you like. We call these{' '}
									{getSitewideLink('spaces_create_space', 'gradient-color-txt ')}
									{' '}and are similar to an ecommerce website, except instead of adding
									different products to sell, you’re adding different{' '}

									{getSitewideLink('memberships_create_space', 'gradient-color-txt ')}
									. You also have an{' '}
					
									{getSitewideLink('updates_create_space', 'gradient-color-txt')}
									{' '}blog area,{' '}
			
									{getSitewideLink('benefits_create_space', 'gradient-color-txt')}
									{' '}area, and{' '}
				
									{getSitewideLink('members_create_space', 'gradient-color-txt')}
									{' '}area. You can customize all of these and enable other features to
									integrate your Space into your business or group.
                                </p>
                            </hgroup>
                            <hgroup className="heading">
                                <h2>Create Your Space</h2>
                                <p>For your first time creating on Commonspace, you’ll be guided through each step to create and launch your Space. Here are the {getSitewideLink('steps_to_create_space', '')}:</p>
                            </hgroup>
                            <ol className='!list-decimal !pl-6'>
                                <li>
                                    <a target="_blank">{getSitewideLink('create_your_space', '')}
												:</a> Get your Space set up with basic details, logo, and profile information.
                                                
                                </li>
                                <li>
                                    <a  target="_blank">{getSitewideLink('add_sections', '')}
                                    :</a> Customize your Welcome Section and other areas for your homepage.
                                </li>
                                <li>
                                    <a target="_blank">{getSitewideLink('create_membership', ' ')}:</a> Offer a free or paid Membership so that people can signup and join your Space.
                                </li>
                                <li>
                                    <a target="_blank">{getSitewideLink('create_benefits', '')}:</a> Create and add Benefits that will be provided to Members with your new Membership.
                                </li>
                                <li>
                                    <a target="_blank">{getSitewideLink('enable_community_features', ' ')}:</a> Enable your Community Channels, Points System, and Leaderboard features.
                                </li>
                                
                            </ol>
                        <p>Once you’ve completed these steps, you’ll be able to preview and launch your Space! You will also have the chance to further customize your Space by completing these optional guided steps:</p>
                        <ul className='!list-disc'>
                           
                            <li>
                                <a target="_blank">{getSitewideLink('post_update', '')}:</a> Welcome visitors with a public post or make a Members Only post to guide your first Members.
                            </li>
                             <li>
                                <a target="_blank">{getSitewideLink('create_item', '')}:</a> Offer a free or paid digital Item that gives Members additional Benefits or upgraded access.
                            </li>
                            <li>
                                <a target="_blank">{getSitewideLink('upgrade_pro', '')}:</a> Connect your domain, get custom branding, and other Pro features by upgrading your Space.
                            </li>
                            {/* <li>
                                <a target="_blank">{getSitewideLink('enable_community_features', '')}:</a> Set up and customize your Community Channels, Points System, and Leaderboard.
                            </li>
                           
                            <li>
                                <a target="_blank">{getSitewideLink('invite_team', '')}:</a> Give your team special access to your Space to manage, moderate, and post.
                            </li> */}
                        </ul>
                        <p><i>These additional steps are optional and you can always do them later from your Dashboard.</i></p>

                        <h3>Ready to get started?</h3>

                        <a className="button-primary-gradient" href={routes.createSpace()}><span><p>Let's go!</p></span></a>
                        <p><i>Need help?</i> {getSitewideLink('view_the_guide_getting_started', '')}</p>
                        </article>
                        <aside className="block-md">
                        <div className="stack-row">
                            
                            <div className="stack-col">
                                <div className="cs-aside-image">
                                    <img src={space_ex1a}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex1b}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex1c}/>
                                </div>
                                <div className="cs-aside-image">
                                    <img src={space_ex2a}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex2b}/>
                                </div>    
                            </div>

                            <div className="stack-col">
                                <div className="cs-aside-image">
                                    <img src={space_ex3a}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex3b}/>
                                </div>
                                <div className="cs-aside-image">
                                    <img src={space_ex3c}/>
                                </div>
                                <div className="cs-aside-image">
                                    <img src={space_ex4a}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex4b}/>
                                </div>  
                                <div className="cs-aside-image">
                                    <img src={space_ex4c}/>
                                </div>   
                            </div>
                        </div>
                    </aside>
                    </div>
                   
                </section>
            </main>
		</>
	);
};

export default memo(GettingStarted);
