import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Form, Input} from 'antd';
import {VALIDATE_MESSAGES} from '../../../../constants/common';
import ReactQuill from 'react-quill';
import {getSpaceInformation, getSpaceInformations, setSpaceInformation} from '../../../../services/space';
import {useDispatch, useSelector} from 'react-redux';
import Alert from "react-bootstrap/Alert";
import {displayPageContentLoader, hidePageContentLoader} from "../../../../states/pageContentLoader";
import {getSpaceInfo} from "../../../../states/spaceGlobal/actions";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";

const EditInfoPages = () => {
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [aboutPageForm] = Form.useForm();
    const [faqPageForm] = Form.useForm();
    const [supportPageForm] = Form.useForm();
    const [communityGuidelinesPageForm] = Form.useForm();
    const [tosPageForm] = Form.useForm();
    const [privacyPageForm] = Form.useForm();

    const [email, setEmail] = useState('');
    const [aboutPageContent, setAboutPageContent] = useState('');
    const [faqPageContent, setFaqPageContent] = useState('');
    const [supportPageContent, setSupportPageContent] = useState('');
    const [communityGuidelinesPageContent, setCommunityGuidelinesPageContent] = useState('');
    const [tosPageContent, setTosPageContent] = useState('');
    const [privacyPageContent, setPrivacyPageContent] = useState('');

    const [spaceInformations, setSpaceInformations] = useState([]);
    const [isInfoLoaded, setIsInfoLoaded] = useState(false);
    const [updateOnce, setUpdateOnce] = useState(false)

    const [formIsOnFocusAbout, setFormIsOnFocusAbout] = useState(false);
    const [formIsOnFocusFaq, setFormIsOnFocusFaq] = useState(false);
    const [formIsOnFocusSupport, setFormIsOnFocusSupport] = useState(false);
    const [formIsOnFocusCommunityGuidelines, setFormIsOnFocusCommunityGuidelines] = useState(false);
    const [formIsOnFocusTos, setFormIsOnFocusTos] = useState(false);
    const [formIsOnFocusPrivacy, setFormIsOnFocusPrivacy] = useState(false);

    const [formHasErrorAbout, setFormHasErrorAbout] = useState(true);
    const [formHasErrorFaq, setFormHasErrorFaq] = useState(true);
    const [formHasErrorSupport, setFormHasErrorSupport] = useState(true);
    const [formHasErrorCommunityGuidelines, setFormHasErrorCommunityGuidelines] = useState(true);
    const [formHasErrorTos, setFormHasErrorTos] = useState(true);
    const [formHasErrorPrivacy, setFormHasErrorPrivacy] = useState(true);

    const [alert, setAlert] = useState(null);
    const [submitType, setSubmitType] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (spaceInfo) {
            dispatch(hidePageContentLoader());
        } else {
            dispatch(displayPageContentLoader());
        }
    }, [spaceInfo]);

    useEffect(() => {
        if (!isInfoLoaded && authUserInfo && spaceInfo) {
            fetchSpaceInformations();
        }

        if (!updateOnce && isInfoLoaded && spaceInformations != null && spaceInformations.length > 0) {
            spaceInformations.map((item) => {
                if (item.page_slug === 'about' + item.space_id) {
                    setEmail(item.email);
                    setAboutPageContent(item.page_content);
                } else if (item.page_slug === 'frequentlyaskedquestions' + item.space_id) {
                    setFaqPageContent(item.page_content);
                } else if (item.page_slug === 'getsupport' + item.space_id) {
                    setSupportPageContent(item.page_content);
                } else if (item.page_slug === 'termsofservice' + item.space_id) {
                    setTosPageContent(item.page_content);
                } else if (item.page_slug === 'privacypolicy' + item.space_id) {
                    setPrivacyPageContent(item.page_content);
                } else if (item.page_slug === 'communityguidelines' + item.space_id) {
                    setCommunityGuidelinesPageContent(item.page_content);
                }
            })

            setUpdateOnce(true);
        }
        aboutPageForm.setFieldValue('about', aboutPageContent);
        aboutPageForm.setFieldValue('email', email);
        faqPageForm.setFieldValue('faq', faqPageContent);
        supportPageForm.setFieldValue('supportContent', supportPageContent);
        communityGuidelinesPageForm.setFieldValue('communityGuidelinesContent', communityGuidelinesPageContent);
        tosPageForm.setFieldValue('tos', tosPageContent);
        privacyPageForm.setFieldValue('privacy', privacyPageContent);
        getFormContentError();
    }, [
        authUserInfo,
        spaceInfo,
        spaceInformations,
        isInfoLoaded,
        email,
        aboutPageContent,
        faqPageContent,
        supportPageContent,
        communityGuidelinesPageContent,
        tosPageContent,
        privacyPageContent
    ]);

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{size: ['small', false, 'large', 'huge']}], // custom dropdown
        [{font: []}],
        [{header: 1}, {header: 2}], // custom button values
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{script: 'sub'}, {script: 'super'}], // superscript/subscript
        [{indent: '-1'}, {indent: '+1'}], // outdent/indent
        [{direction: 'rtl'}], // text direction
        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{align: []}],
        ['clean'] // remove formatting button
    ];

    const handleFormChanges = useCallback(async (form) => {
        await form
            .validateFields()
            .then(() => {
                getFormError(form, false);
            })
            .catch((errors) => {
                getFormError(form, true);
            });
    }, []);

    const handleFormOnBlur = useCallback(async (form) => {
        await form
            .validateFields()
            .then(() => {
                getFormError(form, false);
            })
            .catch((errors) => {
                getFormError(form, true);
            });
    }, []);

    const getFormError = (form, value) => {
        if (form === aboutPageForm) {
            setFormHasErrorAbout(value);
        } else if (form === faqPageForm) {
            setFormHasErrorFaq(value);
        } else if (form === supportPageForm) {
            setFormHasErrorSupport(value);
        } else if (form === tosPageForm) {
            setFormHasErrorTos(value);
        } else if (form === privacyPageForm) {
            setFormHasErrorPrivacy(value);
        } else if (form === communityGuidelinesPageForm) {
            setFormHasErrorCommunityGuidelines(value);
        }
    }

    const getFormContentError = () => {
        const about = aboutPageForm.getFieldValue('about');
        const email = aboutPageForm.getFieldValue('email')
        setFormHasErrorAbout(about === '' || email === '');

        const faq = faqPageForm.getFieldValue('faq');
        setFormHasErrorFaq(faq === '');

        const supportContent = supportPageForm.getFieldValue('supportContent');
        setFormHasErrorSupport(supportContent === '');

        const communityGuidelinesContent = communityGuidelinesPageForm.getFieldValue('communityGuidelinesContent');
        setFormHasErrorCommunityGuidelines(communityGuidelinesContent === '');

        const tos = tosPageForm.getFieldValue('tos');
        setFormHasErrorTos(tos === '');

        const privacy = privacyPageForm.getFieldValue('privacy');
        setFormHasErrorPrivacy(privacy === '');
    }

    const fetchSpaceInformations = useCallback(async () => {
        try {
            if (authUserInfo && spaceInfo) {
                const data = {
                    // user_id: authUserInfo.id,
                    space_id: spaceInfo.id
                };

                const response = await getSpaceInformations(data);
                if (response && response.result) {
                    if (response.space_informations) {
                        setSpaceInformations(response.space_informations);
                        setIsInfoLoaded(true);
                    } else {
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [authUserInfo, spaceInfo, spaceInformations]);

    const handleQuery = async (values, page_title) => {
        setSubmitted(true);
        const data = values;
        setAlert(null);
        if (authUserInfo && spaceInfo) {
            try {
                data.user_id = authUserInfo.id;
                data.space_id = spaceInfo.id;
                data.page_title = page_title;
                data.page_slug = (page_title + data.space_id)
                    .toLowerCase()
                    .replace(/\s/g, '');
                if (data.page_slug === 'about' + data.space_id) {
                    data.email = aboutPageForm.getFieldValue('email');
                    data.page_content = aboutPageContent;
                } else if (data.page_slug === 'frequentlyaskedquestions' + data.space_id) {
                    data.page_content = faqPageContent;
                } else if (data.page_slug === 'getsupport' + data.space_id) {
                    data.page_content = supportPageContent;
                } else if (data.page_slug === 'termsofservice' + data.space_id) {
                    data.page_content = tosPageContent;
                } else if (data.page_slug === 'privacypolicy' + data.space_id) {
                    data.page_content = privacyPageContent;
                } else if (data.page_slug === 'communityguidelines' + data.space_id) {
                    data.page_content = communityGuidelinesPageContent;
                }

                const response = await setSpaceInformation(data);
                if (response && response.result) {
                    setAlert({
                        variant: 'success',
                        message: response.message
                    });
                    dispatch(getSpaceInfo(spaceInfo.username));

                    setSubmitted(false);
                    alertTimeOut();
                } else {
                    setSubmitted(false);
                    setAlert({
                        variant: 'danger',
                        message: response.message
                    });
                    alertTimeOut();
                }
            } catch (error) {
                setSubmitType('')
                setSubmitted(false);
                console.log(error);
            }
        }
    };

    const alertTimeOut = () => {
        setTimeout(() => {
            setSubmitType('')
            setAlert(null);
            setIsInfoLoaded(false);
        }, 4000);
    }

    const handleSubmitAbout = useCallback(
        async (values) => {
            handleQuery(values, 'About');
        },
        [authUserInfo, spaceInfo, aboutPageContent, email]
    );

    const handleSubmitFaq = useCallback(
        async (values) => {
            handleQuery(values, 'Frequently Asked Questions');
        },
        [authUserInfo, spaceInfo, faqPageContent]
    );

    const handleSubmitSupport = useCallback(
        async (values) => {
            handleQuery(values, 'Get Support');
        },
        [authUserInfo, spaceInfo, supportPageContent]
    );

    const handleSubmitCommunityGuideline = useCallback(
        async (values) => {
            handleQuery(values, 'Community Guidelines');
        },
        [authUserInfo, spaceInfo, communityGuidelinesPageContent]
    );

    const handleSubmitTos = useCallback(
        async (values) => {
            handleQuery(values, 'Terms of Service');
        },
        [authUserInfo, spaceInfo, tosPageContent]
    );

    const handleSubmitPrivacy = useCallback(
        async (values) => {
            handleQuery(values, 'Privacy Policy');
        },
        [authUserInfo, spaceInfo, privacyPageContent]
    );

    return (
        <>
            <div className="cs-dash-card block-full card">
                <div className="cs-stack-heading">
                    <div className="stack-row split">
                        <h1>Information Pages</h1>
                    </div>
                    <p>
                        Your Space requires an About, FAQ, Support, Terms of Service, and
                        Privacy Policy pages. This is important to help visitors and
                        members, to protect your business, and to comply with privacy
                        regulations.{' '}
                        <a>
                            Learn more about your Information Pages.
                        </a>
                    </p>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>About Section</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p>
                    Your About Page introduces yourself and/or your business or
                    organization. This can includes your mission, story, history, team
                    members, company information, and more. It can also be helpful to
                    explain the purpose of your Space for your community and how Members
                    can use the site.
                </p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>About Page Content</h3>
                    </div>
                    <p><a></a></p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={aboutPageForm}
                        name="aboutPageForm"
                        id="aboutPageForm"
                        onFinish={handleSubmitAbout}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusAbout(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusAbout(false);
                            handleFormOnBlur(aboutPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(aboutPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="about"
                            label="about"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'About Page Content is Required.'
                                }]}
                        >
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={aboutPageContent}
                                onChange={(e) => {
                                    setAboutPageContent(e);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Form Email"
                            name="email"
                            validateTrigger="onBlur"
                            rules={[{required: true}]}
                        >
                            <div className='formrow'>
                                <div className="form-input air">
                                    <label htmlFor="text">Form Email</label>
                                    <FormFieldStacked
                                        formType='input'
                                        type="text"
                                        maxLength={255}
                                        disabled={submitted && submitType === 'about'}
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => setEmail(e)}
                                    />
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>
                                        This email will receive all completed Contact and Support Form
                                        inquiries and can be changed as needed.
                                    </p>
                                </div>
                            </div>
                        </Form.Item>
                        {alert && submitType === ('about') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorAbout}
                            onClick={() => setSubmitType('about')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'about') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>FAQ Page</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p>
                    A Frequently Asked Questions Page helps visitors and Members find
                    answers to common questions they may have. You can include details
                    about how your Memberships will work, how to use Benefits, or how
                    Items tie into your business or products. It’s a good idea to update
                    this regularly to minimize any support requests you get and to help
                    create a great member experience.
                </p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>FAQ Page Content</h3>
                    </div>
                    <p>
                        <a>View Template</a>
                    </p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={faqPageForm}
                        name="faqPageForm"
                        id="faqPageForm"
                        onFinish={handleSubmitFaq}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusFaq(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusFaq(false);
                            handleFormOnBlur(faqPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(faqPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="faq"
                            label="faq"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'FAQ Page Content is Required.'
                                }
                            ]}>
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={faqPageContent}
                                onChange={(e) => {
                                    setFaqPageContent(e);
                                }}
                            />
                        </Form.Item>
                        {alert && submitType === ('faq') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorFaq}
                            onClick={() => setSubmitType('faq')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'faq') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>Support Page</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p>
                    Your Support Page should guide visitors and Members on how to get
                    help and can also include links to resources, updates, or other
                    pages for further information. There is also an automatic link
                    button to your Contact Form. If you have an existing support
                    site/system or want list different emails/links for specific uses,
                    you can include it here as an image button or link too.
                </p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>Support Page Content</h3>
                    </div>
                    <p>
                        <a>View Template</a>
                    </p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={supportPageForm}
                        name="supportPageForm"
                        id="supportPageForm"
                        onFinish={handleSubmitSupport}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusSupport(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusSupport(false);
                            handleFormOnBlur(supportPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(supportPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="supportContent"
                            label="supportContent"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'Support Page Content is Required.'
                                }
                            ]}>
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={supportPageContent}
                                onChange={(e) => {
                                    setSupportPageContent(e);
                                }}
                            />
                        </Form.Item>
                        {alert && submitType === ('support') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorSupport}
                            onClick={() => setSubmitType('support')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'support') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>Community Guidelines Page</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p></p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>Community Guidelines Page Content</h3>
                    </div>
                    <p>
                        <a>View Template</a>
                    </p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={communityGuidelinesPageForm}
                        name="communityGuidelinesPageForm"
                        id="communityGuidelinesPageForm"
                        onFinish={handleSubmitCommunityGuideline}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusCommunityGuidelines(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusCommunityGuidelines(false);
                            handleFormOnBlur(communityGuidelinesPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(communityGuidelinesPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="communityGuidelinesContent"
                            label="communityGuidelinesContent"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'Community Guidelines Page Content is Required.'
                                }
                            ]}>
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={communityGuidelinesPageContent}
                                onChange={(e) => {
                                    setCommunityGuidelinesPageContent(e);
                                }}
                            />
                        </Form.Item>
                        {alert && submitType === ('community_guide') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorCommunityGuidelines}
                            onClick={() => setSubmitType('community_guide')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'community_guide') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>Terms of Service Page</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p>
                    Your Terms of Service states the way in which your products,
                    services and/or content on your Space may be used by visitors and
                    Members. It is agreed to by Members on Signup and is legally
                    binding.{' '}
                    <a>
                        As your Space is hosted by and operates on Commonspace, we also
                        include this additional section on your page.
                    </a>
                </p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>Terms of Service Page Content</h3>
                    </div>
                    <p>
                        <a>View Template</a>
                    </p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={tosPageForm}
                        name="tosPageForm"
                        id="tosPageForm"
                        onFinish={handleSubmitTos}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusTos(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusTos(false);
                            handleFormOnBlur(tosPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(tosPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="tos"
                            label="tos"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'Terms of Service Page Content is Required.'
                                }
                            ]}>
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={tosPageContent}
                                onChange={(e) => {
                                    setTosPageContent(e);
                                }}
                            />
                        </Form.Item>
                        {alert && submitType === ('tos') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorTos}
                            onClick={() => setSubmitType('tos')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'tos') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>

            <div className="cs-dash-card block-full card">
                <div className="stack-row-wrap split">
                    <div>
                        <h2>Privacy Policy Page</h2>
                    </div>
                    <p>
                        <a>View Guide</a>
                    </p>
                </div>
                <p>
                    Your Privacy Policy page is an important page that tells users how
                    and why you're collecting their information. When users signup, they
                    can opt-in to sharing their personal information, contact details,
                    and activity data with you, but you must disclose how you will use
                    that data. Each country may also have local regulations that apply.
                </p>
                <div className="stack-row-wrap split">
                    <div>
                        <h3>Privacy Policy Page Content</h3>
                    </div>
                    <p>
                        <a>View Template</a>
                    </p>
                </div>
                <div className="cs-stack-form-guided">
                    <Form
                        form={privacyPageForm}
                        name="privacyPageForm"
                        id="privacyPageForm"
                        onFinish={handleSubmitPrivacy}
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocusPrivacy(true);
                        }}
                        onBlur={() => {
                            setFormIsOnFocusPrivacy(false);
                            handleFormOnBlur(privacyPageForm);
                        }}
                        onChange={() => {
                            handleFormChanges(privacyPageForm);
                        }}
                        className='w-full stack-form-col'
                    >
                        <Form.Item
                            name="privacy"
                            label="privacy"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: true,
                                    message: 'Privacy Policy Page Content is Required.'
                                }
                            ]}>
                            <ReactQuill
                                theme="snow"
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={privacyPageContent}
                                onChange={(e) => {
                                    setPrivacyPageContent(e);
                                }}
                            />
                        </Form.Item>
                        {alert && submitType === ('privacy') && (
                            <div className={"alert banner px-section-safeview" + (alert.variant === 'danger' ? " error" : " success")}>
                                <div className="action">
                                    <i className={"fa-solid" + (alert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{alert.message}</h6>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="action"
                                     onClick={()=>{
                                         setAlert(null)
                                     }}
                                >
                                    <a>
                                        <i className="fa-solid fa-xmark"/>
                                    </a>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="primary-gradient"
                            disabled={submitted || formHasErrorPrivacy}
                            onClick={() => setSubmitType('privacy')}
                        >
							<span>
								<p>
									{(submitted && submitType === 'privacy') && (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    )}
                                    Save
								</p>
							</span>
                        </button>
                    </Form>
                </div>
            </div>
        </>
        // <div className="block-full card">
        //     <div className="manage-space">
        //         <div className="dashboard-wrapper">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-12">
        //                     <h3 className="header-medium">Information Pages</h3>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         Your Space requires an About, FAQ, Support, Terms of Service, and
        //                         Privacy Policy pages. This is important to help visitors and
        //                         members, to protect your business, and to comply with privacy
        //                         regulations.{' '}
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             Learn more about your Information Pages.
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="dashboard-wrapper mt-5">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-6">
        //                     <h3 className="header-small">About Page</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Guide
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         Your About Page introduces yourself and/or your business or
        //                         organization. This can includes your mission, story, history, team
        //                         members, company information, and more. It can also be helpful to
        //                         explain the purpose of your Space for your community and how Members
        //                         can use the site.
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <h3 className="header-title">About Page Content</h3>
        //                 </div>
        //             </div>
        //             <Form
        //                 form={aboutPageForm}
        //                 name="aboutPageForm"
        //                 id="aboutPageForm"
        //                 onFinish={handleSubmitAbout}
        //                 validateMessages={VALIDATE_MESSAGES}
        //                 onFocus={() => {
        //                     setFormIsOnFocusAbout(true);
        //                 }}
        //                 onBlur={() => {
        //                     setFormIsOnFocusAbout(false);
        //                     handleFormOnBlur(aboutPageForm);
        //                 }}
        //                 onChange={() => {
        //                     handleFormChanges(aboutPageForm);
        //                 }}
        //                 className={formIsOnFocusAbout ? 'hide-antd-error-messages' : ''}
        //             >
        //                 <>
        //                     {alert && submitType === ('about') && (
        //                         <div className="grid grid-cols-12 gap-8 mt-5">
        //                             <Alert
        //                                 key={alert.variant}
        //                                 variant={alert.variant}
        //                                 className={
        //                                     'custom-alert ' +
        //                                     (alert.variant === 'danger'
        //                                         ? 'custom-alert-danger'
        //                                         : 'custom-alert-success')
        //                                 }
        //                             >
        //                                 {alert.message}
        //                             </Alert>
        //                         </div>
        //                     )}
        //                 </>
        //
        //                 <div className="mt-5">
        //                     <div className={"input-group-com w-full mt-2"}>
        //                         <Form.Item name="about"
        //                                    label="about"
        //                                    className="mb-0 hidden-label z-0"
        //                                    validateTrigger="onBlur"
        //                                    rules={[
        //                                        {
        //                                            required: true,
        //                                            message: 'About Page Content is Required.'
        //                                        }
        //                                    ]}>
        //
        //                             <ReactQuill
        //                                 className={'infopages-txteditor'}
        //                                 theme="snow"
        //                                 modules={{
        //                                     toolbar: toolbarOptions
        //                                 }}
        //                                 value={aboutPageContent}
        //                                 onChange={(e) => {
        //                                     if ('<p><br></p>' === e) {
        //                                         aboutPageForm.setFieldValue('about', '');
        //                                         setAboutPageContent(null)
        //                                     } else {
        //                                         setAboutPageContent(e);
        //                                     }
        //                                 }}
        //                             />
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //
        //
        //                 <div className="grid grid-cols-12 mt-4 md:gap-8 flex items-center">
        //                     <div className="col-span-12 md:col-span-8">
        //                         <div className="input-group-com w-full mt-2">
        //                             <label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">
        //                                 Form Email*
        //                             </label>
        //                             <Form.Item
        //                                 label="Form Email"
        //                                 name="email"
        //                                 className="mb-0 hidden-label z-0"
        //                                 validateTrigger="onBlur"
        //                                 rules={[{required: true}]}
        //                             >
        //                                 <Input
        //                                     placeholder="Email"
        //                                     className="input-md body-text--small color-reg"
        //                                     value={email}
        //                                     onChange={(e) => setEmail(e.target.value)}
        //                                 />
        //                             </Form.Item>
        //                         </div>
        //                     </div>
        //                     <div className="col-span-12 md:col-span-4">
        //                         <p className="body-txtitalic--smallest m-0">
        //                             This email will receive all completed Contact and Support Form
        //                             inquiries and can be changed as needed.
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 mt-4 md:gap-8 flex items-center">
        //                     <div className="col-span-12">
        //                         <Form.Item className="mb-0 z-0">
        //                             <Button className="btn btn-primary btn-md"
        //                                     onClick={() => setSubmitType('about')}
        //                                     type="submit"
        //                                     disabled={submitted || formHasErrorAbout}
        //                             >
        //                                 {submitted && submitType === ('about') && (
        //                                     <i className="fa-solid fa-spinner fa-spin"/>
        //                                 )}
        //                                 &nbsp;Save
        //                             </Button>
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //             </Form>
        //         </div>
        //         <div className="dashboard-wrapper mt-5">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-6">
        //                     <h3 className="header-small">FAQ Page</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Guide
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         A Frequently Asked Questions Page helps visitors and Members find
        //                         answers to common questions they may have. You can include details
        //                         about how your Memberships will work, how to use Benefits, or how
        //                         Items tie into your business or products. It’s a good idea to update
        //                         this regularly to minimize any support requests you get and to help
        //                         create a great member experience.
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-6">
        //                     <h3 className="header-title">FAQ Page Content</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Template
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <Form
        //                 form={faqPageForm}
        //                 name="faqPageForm"
        //                 id="faqPageForm"
        //                 onFinish={handleSubmitFaq}
        //                 validateMessages={VALIDATE_MESSAGES}
        //                 onFocus={() => {
        //                     setFormIsOnFocusFaq(true);
        //                 }}
        //                 onBlur={() => {
        //                     setFormIsOnFocusFaq(false);
        //                     handleFormOnBlur(faqPageForm);
        //                 }}
        //                 onChange={() => {
        //                     handleFormChanges(faqPageForm);
        //                 }}
        //                 className={formIsOnFocusFaq ? 'hide-antd-error-messages' : ''}
        //             >
        //                 <>
        //                     {alert && submitType === ('faq') && (
        //                         <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                             <Alert
        //                                 key={alert.variant}
        //                                 variant={alert.variant}
        //                                 className={
        //                                     'custom-alert ' +
        //                                     (alert.variant === 'danger'
        //                                         ? 'custom-alert-danger'
        //                                         : 'custom-alert-success')
        //                                 }
        //                             >
        //                                 {alert.message}
        //                             </Alert>
        //                         </div>
        //                     )}
        //                 </>
        //
        //                 <div className="mt-5">
        //                     <div className={"input-group-com w-full mt-2"}>
        //                         <Form.Item name="faq"
        //                                    label="faq"
        //                                    className="mb-0 hidden-label z-0"
        //                                    validateTrigger="onBlur"
        //                                    rules={[
        //                                        {
        //                                            required: true,
        //                                            message: 'FAQ Page Content is Required.'
        //                                        }
        //                                    ]}>
        //
        //                             <ReactQuill
        //                                 className={'infopages-txteditor'}
        //                                 theme="snow"
        //                                 modules={{
        //                                     toolbar: toolbarOptions
        //                                 }}
        //                                 value={faqPageContent}
        //                                 onChange={(e) => {
        //                                     if ('<p><br></p>' === e) {
        //                                         faqPageForm.setFieldValue('faq', '');
        //                                         setFaqPageContent(null)
        //                                     } else {
        //                                         setFaqPageContent(e);
        //                                     }
        //                                 }}
        //                             />
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 md:gap-8 mt-5 flex items-center">
        //                     <div className="col-span-12">
        //                         <Form.Item className="mb-0 z-0">
        //                             <Button className="btn btn-primary btn-md"
        //                                     onClick={() => setSubmitType('faq')}
        //                                     type="submit"
        //                                     disabled={submitted || formHasErrorFaq}
        //                             >
        //                                 {submitted && submitType === ('faq') && (
        //                                     <i className="fa-solid fa-spinner fa-spin"/>
        //                                 )}
        //                                 &nbsp;Save
        //                             </Button>
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //             </Form>
        //         </div>
        //         <div className="dashboard-wrapper mt-5">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-6">
        //                     <h3 className="header-small">Support Page</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Guide
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         Your Support Page should guide visitors and Members on how to get
        //                         help and can also include links to resources, updates, or other
        //                         pages for further information. There is also an automatic link
        //                         button to your Contact Form. If you have an existing support
        //                         site/system or want list different emails/links for specific uses,
        //                         you can include it here as an image button or link too.
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-6">
        //                     <h3 className="header-title">Support Page Content</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Template
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <Form
        //                 form={supportPageForm}
        //                 name="supportPageForm"
        //                 id="supportPageForm"
        //                 onFinish={handleSubmitSupport}
        //                 validateMessages={VALIDATE_MESSAGES}
        //                 onFocus={() => {
        //                     setFormIsOnFocusSupport(true);
        //                 }}
        //                 onBlur={() => {
        //                     setFormIsOnFocusSupport(false);
        //                     handleFormOnBlur(supportPageForm);
        //                 }}
        //                 onChange={() => {
        //                     handleFormChanges(supportPageForm);
        //                 }}
        //                 className={formIsOnFocusSupport ? 'hide-antd-error-messages z-0' : 'z-0'}
        //             >
        //                 <>
        //                     {alert && submitType === ('support') && (
        //                         <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                             <Alert
        //                                 key={alert.variant}
        //                                 variant={alert.variant}
        //                                 className={
        //                                     'custom-alert ' +
        //                                     (alert.variant === 'danger'
        //                                         ? 'custom-alert-danger'
        //                                         : 'custom-alert-success')
        //                                 }
        //                             >
        //                                 {alert.message}
        //                             </Alert>
        //                         </div>
        //                     )}
        //                 </>
        //                 <div className="mt-5">
        //                     <div className={"input-group-com w-full mt-2"}>
        //                         <Form.Item name="supportContent"
        //                                    label="supportContent"
        //                                    className="mb-0 hidden-label z-0"
        //                                    validateTrigger="onBlur"
        //                                    rules={[
        //                                        {
        //                                            required: true,
        //                                            message: 'Support Page Content is Required.'
        //                                        }
        //                                    ]}>
        //
        //                             <ReactQuill
        //                                 className={'infopages-txteditor'}
        //                                 theme="snow"
        //                                 modules={{
        //                                     toolbar: toolbarOptions
        //                                 }}
        //                                 value={supportPageContent}
        //                                 onChange={(e) => {
        //                                     if ('<p><br></p>' === e) {
        //                                         supportPageForm.setFieldValue('supportContent', '');
        //                                         setSupportPageContent(null)
        //                                     } else {
        //                                         setSupportPageContent(e);
        //                                     }
        //                                 }}
        //                             />
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 md:gap-8 mt-5 flex items-center">
        //                     <div className="col-span-12">
        //                         <Form.Item className="mb-0 z-0">
        //                             <Button className="btn btn-primary btn-md"
        //                                     onClick={() => setSubmitType('support')}
        //                                     type="submit"
        //                                     disabled={submitted || formHasErrorSupport}
        //                             >
        //                                 {submitted && submitType === ('support') && (
        //                                     <i className="fa-solid fa-spinner fa-spin"/>
        //                                 )}
        //                                 &nbsp;Save
        //                             </Button>
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //             </Form>
        //         </div>
        //         <div className="dashboard-wrapper mt-5">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-6">
        //                     <h3 className="header-small">Terms of Service Page</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Guide
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         Your Terms of Service states the way in which your products,
        //                         services and/or content on your Space may be used by visitors and
        //                         Members. It is agreed to by Members on Signup and is legally
        //                         binding.{' '}
        //                         <a className="gradient-color-txt">
        //                             As your Space is hosted by and operates on Commonspace, we also
        //                             include this additional section on your page.
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-6">
        //                     <h3 className="header-title">Terms of Service Page Content</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Template
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <Form
        //                 form={tosPageForm}
        //                 name="tosPageForm"
        //                 id="tosPageForm"
        //                 onFinish={handleSubmitTos}
        //                 validateMessages={VALIDATE_MESSAGES}
        //                 onFocus={() => {
        //                     setFormIsOnFocusTos(true);
        //                 }}
        //                 onBlur={() => {
        //                     setFormIsOnFocusTos(false);
        //                     handleFormOnBlur(tosPageForm);
        //                 }}
        //                 onChange={() => {
        //                     handleFormChanges(tosPageForm);
        //                 }}
        //                 className={formIsOnFocusTos ? 'hide-antd-error-messages' : ''}
        //             >
        //                 <>
        //                     {alert && submitType === ('tos') && (
        //                         <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                             <Alert
        //                                 key={alert.variant}
        //                                 variant={alert.variant}
        //                                 className={
        //                                     'custom-alert ' +
        //                                     (alert.variant === 'danger'
        //                                         ? 'custom-alert-danger'
        //                                         : 'custom-alert-success')
        //                                 }
        //                             >
        //                                 {alert.message}
        //                             </Alert>
        //                         </div>
        //                     )}
        //                 </>
        //                 <div className="mt-5">
        //                     <div className={"input-group-com w-full mt-2"}>
        //                         <Form.Item name="tos"
        //                                    label="tos"
        //                                    className="mb-0 hidden-label z-0"
        //                                    validateTrigger="onBlur"
        //                                    rules={[
        //                                        {
        //                                            required: true,
        //                                            message: 'Terms of Service Page Content is Required.'
        //                                        }
        //                                    ]}>
        //
        //                             <ReactQuill
        //                                 className={'infopages-txteditor'}
        //                                 theme="snow"
        //                                 modules={{
        //                                     toolbar: toolbarOptions
        //                                 }}
        //                                 value={tosPageContent}
        //                                 onChange={(e) => {
        //                                     if ('<p><br></p>' === e) {
        //                                         tosPageForm.setFieldValue('tos', '');
        //                                         setTosPageContent(null)
        //                                     } else {
        //                                         setTosPageContent(e);
        //                                     }
        //                                 }}
        //                             />
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 md:gap-8 mt-5 flex items-center">
        //                     <div className="col-span-12">
        //                         <Form.Item className="mb-0">
        //                             <Button className="btn btn-primary btn-md"
        //                                     onClick={() => setSubmitType('tos')}
        //                                     type="submit"
        //                                     disabled={submitted || formHasErrorTos}
        //                             >
        //                                 {submitted && submitType === ('tos') && (
        //                                     <i className="fa-solid fa-spinner fa-spin"/>
        //                                 )}
        //                                 &nbsp;Save
        //                             </Button>
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //             </Form>
        //         </div>
        //         <div className="dashboard-wrapper mt-5">
        //             <div className="grid grid-cols-12 md:gap-8">
        //                 <div className="col-span-6">
        //                     <h3 className="header-small">Privacy Policy Page</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Guide
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-12">
        //                     <p className="body-text--small">
        //                         Your Privacy Policy page is an important page that tells users how
        //                         and why you're collecting their information. When users signup, they
        //                         can opt-in to sharing their personal information, contact details,
        //                         and activity data with you, but you must disclose how you will use
        //                         that data. Each country may also have local regulations that apply.
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="grid grid-cols-12 md:gap-8 mt-5">
        //                 <div className="col-span-6">
        //                     <h3 className="header-title">Privacy Policy Page Content</h3>
        //                 </div>
        //                 <div className="col-span-6">
        //                     <p className="text-end">
        //                         <a className="body-txtitalic--small gradient-color-txt">
        //                             View Template
        //                         </a>
        //                     </p>
        //                 </div>
        //             </div>
        //             <Form
        //                 form={privacyPageForm}
        //                 name="privacyPageForm"
        //                 id="privacyPageForm"
        //                 onFinish={handleSubmitPrivacy}
        //                 validateMessages={VALIDATE_MESSAGES}
        //                 onFocus={() => {
        //                     setFormIsOnFocusPrivacy(true);
        //                 }}
        //                 onBlur={() => {
        //                     setFormIsOnFocusPrivacy(false);
        //                     handleFormOnBlur(privacyPageForm);
        //                 }}
        //                 onChange={() => {
        //                     handleFormChanges(privacyPageForm);
        //                 }}
        //                 className={formIsOnFocusPrivacy ? 'hide-antd-error-messages' : ''}
        //             >
        //                 <>
        //                     {alert && submitType === ('privacy') && (
        //                         <div className="grid grid-cols-12md:gap-8 mt-5">
        //                             <Alert
        //                                 key={alert.variant}
        //                                 variant={alert.variant}
        //                                 className={
        //                                     'custom-alert ' +
        //                                     (alert.variant === 'danger'
        //                                         ? 'custom-alert-danger'
        //                                         : 'custom-alert-success')
        //                                 }
        //                             >
        //                                 {alert.message}
        //                             </Alert>
        //                         </div>
        //                     )}
        //                 </>
        //                 <div className="mt-5">
        //                     <div className={"input-group-com w-full mt-2"}>
        //                         <Form.Item name="privacy"
        //                                    label="privacy"
        //                                    className="mb-0 hidden-label"
        //                                    validateTrigger="onBlur"
        //                                    rules={[
        //                                        {
        //                                            required: true,
        //                                            message: 'Privacy Policy Page Content is Required.'
        //                                        }
        //                                    ]}>
        //
        //                             <ReactQuill
        //                                 className={'infopages-txteditor'}
        //                                 theme="snow"
        //                                 modules={{
        //                                     toolbar: toolbarOptions
        //                                 }}
        //                                 value={privacyPageContent}
        //                                 onChange={(e) => {
        //                                     if ('<p><br></p>' === e) {
        //                                         privacyPageForm.setFieldValue('privacy', '');
        //                                         setPrivacyPageContent(null)
        //                                     } else {
        //                                         setPrivacyPageContent(e);
        //                                     }
        //                                 }}
        //                             />
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 md:gap-8 mt-5 flex items-center">
        //                     <div className="col-span-12">
        //                         <Form.Item className="mb-0">
        //                             <Button className="btn btn-primary btn-md"
        //                                     onClick={() => setSubmitType('privacy')}
        //                                     type="submit"
        //                                     disabled={submitted || formHasErrorPrivacy}
        //                             >
        //                                 {submitted && submitType === ('privacy') && (
        //                                     <i className="fa-solid fa-spinner fa-spin"/>
        //                                 )}
        //                                 &nbsp;Save
        //                             </Button>
        //                         </Form.Item>
        //                     </div>
        //                 </div>
        //             </Form>
        //         </div>
        //     </div>
        // </div>
    );
};

export default memo(EditInfoPages);
