import routes from '../routes';

export const notLoggedInAvailableRoutes = [
	routes.signUp(),
	routes.emailVerification(),
	routes.viewUserSpace(),
	routes.forgotPassword(),
	routes.termsOfUse(),
	routes.privacyPolicy(),
	routes.communityGuidelines(),
];
