import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { NODE_ENV } from '../constants/common';
import appReducer from './appReducer';
import appSaga from './appSaga';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
	reducer: appReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			immutableCheck: false,
			serializableCheck: false
		}).concat(sagaMiddleware),
	devTools: NODE_ENV !== 'production'
});

sagaMiddleware.run(appSaga);

export default store;
