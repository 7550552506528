import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';

import { hideTooltip, showTooltip } from '../../../../utils/input';
import UploadDragger from '../../../../components/commons/UploadDragger';
import FormFieldStacked from '../../../../components/commons/FormFieldStacked';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../../constants/file';

import { CUSTOM_REGEX } from '../../../../constants/custom_regex';
import './Links.scss';
import UploadDraggerV2 from '../../../../components/commons/UploadDraggerV2';

const Links = (parentProps) => {
	const { data, index, submitted, updateData, form, updateLinkToRemove, resetLinkPreviews, createSpaceAddDetailsForm, isFromManage = false } =
		parentProps;
	const [imagePreview, setImagePreview] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);
    const [imagePreviewError, setImagePreviewError] = useState(null);
	const [videoPreviewError, setVideoPreviewError] = useState(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [urlLink, setUrlLink] = useState('');
	const [displayError, setDisplayError] = useState();
	const titleRef = useRef(null);
	const descriptionRef = useRef(null);
	const urlRef = useRef(null);
    console.log(data)
	/**
	 * File change event handler for Image
	 *
	 * @param file
	 * @param blobPreview
	 */
	const handleImageUploadFileChange = (file, blobPreview, error = false) => {
		updateData(file, 'image', index);
		setImagePreview(blobPreview);

        if(error){
            setImagePreviewError('Image is Invalid.');
        }else{
            setImagePreviewError(null);
        }
	};

	/**
	 * File change event handler for Video
	 *
	 * @param file
	 * @param blobPreview
	 */
	const handleVideoUploadFileChange = (file, blobPreview, error = false) => {
		updateData(file, 'video', index);
		setVideoPreview(blobPreview);

        if(error){
            setVideoPreviewError('Video is Invalid.');
        }else{
            setVideoPreviewError(null);
        }
	};

	/**
	 * Field value change event handler
	 *
	 * @param e
	 * @param field
	 * @param index
	 */
	const onChangeHandler = (e, field, index) => {
		updateData(e.target.value, field, index);
	};

	/**
	 * Field 'url' checker on onBlur handler
	 *
	 * @param e
	 * @param index
	 */
	const websiteOnBlur = (e, index) => {
		showTooltip(e);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(e.target.value)) {
			e.target.value = 'https://' + e.target.value;
			form.setFieldValue('add_link_link_url_' + index, e.target.value);
		}
	};

	/**
	 * Update parent component on link to remove
	 */
	const removeLink = () => {
		if (data) {
			updateLinkToRemove({ index: index, id: data.id });
		}
	};

	useEffect(() => {
		// console.log('index: ' + index + ' === ' + imagePreview);
	}, [imagePreview]);

	useEffect(() => {
		// console.log('index: ' + index + ' === ' + videoPreview);
	}, [videoPreview]);

	useEffect(() => {
		if (resetLinkPreviews) {
			// reset previews
			setImagePreview(null);
			setVideoPreview(null);
		}

		if (data && data.image) {
			if (typeof data.image == 'string' || data.image instanceof String) {
				setImagePreview(data.image);
			} else {
				const imageFile = data.image;
				if (imageFile.name && imageFile.size && imageFile.type) {
					const imageObjectUrl = URL.createObjectURL(imageFile);
					setImagePreview(imageObjectUrl);
				}
			}
		}
		if (data && data.video) {
			if (typeof data.video == 'string' || data.video instanceof String) {
				setVideoPreview(data.video);
			} else {
				const videoFile = data.video;
				if (videoFile.name && videoFile.size && videoFile.type) {
					const videoObjectUrl = URL.createObjectURL(data.video);
					setVideoPreview(videoObjectUrl);
				}
			}
		}
		if (data) {
			setTitle(data.title);
			setDescription(data.description);
			setUrlLink(data.url);
			setDisplayError(false);
			setTimeout(() => {
				setDisplayError(true);
			}, 1000);
		}
	}, [data, resetLinkPreviews]);

	return (
        <>
			{isFromManage ? (
				<>
					<div></div>
					<div className="stack-row-wrap split">
						<div><h2></h2></div>
						<a onClick={removeLink}>Remove Link</a>
					</div>
				</>
			) : (
				<div className="formrow title">
					<div  onClick={removeLink}>
						<h2></h2>
						<h3 className='cursor-pointer'>Remove Link</h3>
					</div>
					<div className="cs-formrow-helper-none">
					</div>
				</div>
			)}
        <Form.Item
            label="Title"
            name={'add_link_link_title_' + index}
            onChange={(e) => onChangeHandler(e, 'title', index)}
			validateTrigger="onBlur"
            rules={[
                { required: true },
                {
                    max: 32,
                    message: 'Must not exceed 32 characters.'
                }
            ]}
        >
            <div className="formrow">
                <div className="form-input air">
                    <label htmlFor="text">Title*</label>
                    <input id="text" name="text" type="text" required placeholder="Up to 32 characters, including spaces." value={title}/>
                    {displayError && createSpaceAddDetailsForm && createSpaceAddDetailsForm.getFieldError('add_link_link_title_' + index).length > 0 && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{createSpaceAddDetailsForm.getFieldError('add_link_link_title_' + index)}</h6>
                                    
                                </div>  
                            </div>
                            
                        </div>
                    )}
                </div>
                <div className="cs-formrow-helper-end">
                    <p>Enter a Link Title. This will be displayed over the Link Background.</p>
                </div>
            </div>
        </Form.Item>
        <Form.Item
            label="Description"
                name={'add_link_link_description_' + index}
                onChange={(e) => onChangeHandler(e, 'description', index)}
				validateTrigger="onBlur"
                rules={[
                    { required: true },
					{
						max: 128,
						message: 'Must not exceed 128 characters.'
					}
                ]}
            >
                <div className="formrow">
                    <div className="form-textarea air">
                        <label htmlFor="text">Description*</label>
						<textarea
							id="text"
							name="text"
							rows={3}
							value={description}
							required
							placeholder='Up to 128 characters, including spaces.'
						/>
                        {/*<input id="text" name="text" type="text" placeholder='Up to 128 characters, including spaces.' value={description} required/>*/}
                        {displayError && createSpaceAddDetailsForm && createSpaceAddDetailsForm.getFieldError('add_link_link_description_' + index).length > 0 && (
                            <div className="alert callout warning !w-full items-center">
                                <div className="action">
                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{createSpaceAddDetailsForm.getFieldError('add_link_link_description_' + index)}</h6>
                                        
                                    </div>  
                                </div>
                                
                            </div>
                        )}
                    </div>
                    <div className="cs-formrow-helper-end">
                        <p>Enter a Link Description. This will be displayed over the Link Background.</p>
                    </div>
                </div>
            </Form.Item>
            <Form.Item
                label="URL"
                name={'add_link_link_url_' + index}
                onChange={(e) => onChangeHandler(e, 'url', index)}
                validateTrigger="onBlur"
                rules={[
                    { required: true },
                    {
                        pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
                        message: 'Please Enter a valid URL'
                    }
                ]}
            >
                <div className="formrow">
                    <div className="form-input air">
                        <label htmlFor="text">URL*</label>
                        <input id="text" value={urlLink} name="text" type="text" required placeholder="domain.com/page"
								onBlur={(e) => websiteOnBlur(e, index)}
								disabled={submitted}/>
                                {displayError && createSpaceAddDetailsForm && createSpaceAddDetailsForm.getFieldError('add_link_link_url_' + index).length > 0 && (
                                <div className="alert callout warning !w-full items-center">
                                    <div className="action">
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                    </div>
                                    <div className="content">
                                        <div>
                                            <h6>{createSpaceAddDetailsForm.getFieldError('add_link_link_url_' + index)}</h6>
                                            
                                        </div>  
                                    </div>
                                    
                                </div>
                            )}
                    </div>
                    <div className="cs-formrow-helper-end">
                        <p>You can link to any valid URL. If the URL is not a page in your Space, it will open in a new browser window when clicked. Include the full link URL starting with “https://”.</p>
                    </div>
                </div>
                
            </Form.Item>
            <div className="formrow media">
                <div className="cs-stack-form-media stack-row">
                
                    <div className="cs-stack-form-media-item">
                        <UploadDraggerV2
							onError={setImagePreviewError}
							cropperAspectRatio={4 / 3}
							hideDragIcon="true"
							hideText="true"
                            shape="square"
							uploadFileTypes={IMAGE_FILETYPES.toString()}
							uploadedFilePreview={imagePreview}
							onUploadFileChange={handleImageUploadFileChange}
							minHeight={IMAGE_LARGE_MIN_HEIGHT}
							minWidth={IMAGE_LARGE_MIN_WIDTH}
							maxHeight={IMAGE_LARGE_MAX_HEIGHT}
							maxWidth={IMAGE_LARGE_MAX_WIDTH}
							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
							disableCropper={true}
							blobUrlType={'image'}
						/>
                        <p className="cs-label">Link Background Image</p>
                        <p className="cs-helper">Up to 1920px JPEG, PNG, or GIF</p>
                        {imagePreviewError && (
                            <div className="alert callout warning !w-full items-center">
                                <div className="action">
                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{imagePreviewError.message}</h6>
                                    </div>  
                                </div>
                                
                            </div>
                        )}
                    </div>
                    
                    <div className="cs-stack-form-media-item">
                            <UploadDraggerV2
								onError={setVideoPreviewError}
                                hideDragIcon="true"
                                hideText="true"
                                shape="square"
                                uploadFileTypes={VIDEO_FILETYPES.toString()}
                                uploadedFilePreview={videoPreview}
                                onUploadFileChange={handleVideoUploadFileChange}
                                minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                blobUrlType={'video'}
                            />
                        <p className="cs-label">Link Background Video</p>
                        <p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
                        {videoPreviewError && (
                            <div className="alert callout warning !w-full items-center">
                                <div className="action">
                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{videoPreviewError.message}</h6>
                                    </div>  
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                <div className="cs-formrow-helper-split">
                    <p>Your Link Background Image (up to 4MB, 16:9 ratio preferred) is displayed as
		 				a card with your link information. If no Image is uploaded, your link card
						will display a color background.</p>
                    <p>
                        Your Link Background Video (up to 8MB, 16:9 ratio preferred) replaces the
                        Link Background Image in some areas on limited devices only. Optional.
                    </p>
                </div>
            </div>
        </>
		// <div className="add-link-row">
		// 	<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 flex items-center mt-8">
		// 		<div className="col-span-12 flex justify-center md:justify-end my-auto !order-last md:!order-first">
		// 		<a
		// 					className="remove-link body-txtitalic--small gradient-color-txt"
		// 					onClick={removeLink}
		// 				>
		// 					Remove Link
		// 				</a>
					
		// 		</div>
		// 	</div>
		// 	<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-8">
		// 		<div className="md:col-span-8 my-auto !order-last md:!order-first">
					
        //             <div className="cs-stack-form-guided">
		// 			<Form.Item
		// 				label="Links Title"
		// 				name={'add_link_link_title_' + index}
		// 				onChange={(e) => onChangeHandler(e, 'title', index)}
		// 				className="mb-0 hidden-label"
		// 				rules={[
		// 					{ required: true },
		// 					{
		// 						max: 32,
		// 						message: 'Must not exceed 32 characters.'
		// 					}
		// 				]}
		// 			>								
        //                 <div className="formrow">
        //                     <div className="form-input air">
        //                         <label htmlFor="text">Title *</label>
        //                         <input id="text" name="text" type="text" required placeholder="Up to 32 characters, including spaces."/>
        //                     </div>                           
        //                 </div>
		// 			</Form.Item>
		// 		</div>	
        //         </div>
			
		// 		<div className="md:col-span-4 flex items-center my-auto !order-first md:!order-last">
		// 			<p className="body-txtitalic--smallest color-reg">
		// 				Enter a Link Title. This will be displayed over the Link Background.
		// 			</p>
		// 		</div>
		// 	</div>
		// 	<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-8">
		// 		<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 			<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 				Description*
		// 			</label>
		// 			<div className="relative rounded-none ">
		// 			<Form.Item
		// 					label="Links Description"
		// 					name={'add_link_link_description_' + index}
		// 					onChange={(e) => onChangeHandler(e, 'description', index)}
		// 					className="mb-0 hidden-label"
		// 					rules={[
		// 						{ required: true },
		// 						{
		// 							max: 32,
		// 							message: 'Must not exceed 32 characters.'
		// 						}
		// 					]}
		// 				>
		// 					<input
		// 						type="text"
		// 						className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 						placeholder="Up to 32 characters, including spaces."
		// 						maxLength={32}
		// 						disabled={submitted}
		// 						ref={descriptionRef}
		// 						/>
		// 				</Form.Item>
		// 			</div>
					
		// 		</div>	
				
		// 		<div className="md:col-span-4 flex items-center my-auto !order-first md:!order-last">
		// 			<p className="body-txtitalic--smallest color-reg">
		// 				Enter a Link Description. This will be displayed over the Link Background.
		// 			</p>
		// 		</div>
		// 	</div>
		// 	<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-8">
		// 	<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 			<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 				URL*
		// 			</label>
		// 			<div className="relative rounded-none ">
		// 			<Form.Item
		// 					label="Link URL"
		// 					name={'add_link_link_url_' + index}
		// 					onChange={(e) => onChangeHandler(e, 'url', index)}
		// 					className="mb-0 hidden-label"
		// 					validateTrigger="onBlur"
		// 					rules={[
		// 						{ required: true },
		// 						{
		// 							pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
		// 							message: 'Please Enter a valid URL'
		// 						}
		// 					]}
		// 				>
		// 					<input
		// 						type="text"
		// 						className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 						placeholder="domain.com/page"
		// 						maxLength={32}
		// 						onBlur={(e) => websiteOnBlur(e, index)}
		// 						disabled={submitted}
		// 						ref={urlRef}
		// 						/>
							
		// 				</Form.Item>
		// 			</div>
					
		// 		</div>
				
		// 		<div className="md:col-span-4 flex items-end my-auto !order-first md:!order-last">
		// 			<p className="body-txtitalic--smallest color-reg">
		// 				You can link to any valid URL. If the URL is not a page in your Space, it
		// 				will open in a new browser window when clicked. Include the full link URL
		// 				starting with “https://”.
		// 			</p>
		// 		</div>
		// 	</div>
		// 	<div className="grid grid-cols-1 md:grid-cols-12 mt-5">
		// 		<div className="md:hidden md:col-span-4">
		// 			<p className="body-txtitalic--smallest color-reg">
		// 				Your Link Background Image (up to 4MB, 16:9 ratio preferred) is displayed as
		// 				a card with your link information. If no Image is uploaded, your link card
		// 				will display a color background.
		// 			</p>
		// 		</div>
		// 		<div className="md:col-span-4">
		// 			<div className="upload-rec">
		// 				<UploadDragger
		// 					cropperAspectRatio={4 / 3}
		// 					hideDragIcon="true"
		// 					hideText="true"
		// 					uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 					uploadedFilePreview={imagePreview}
		// 					onUploadFileChange={handleImageUploadFileChange}
		// 					minHeight={IMAGE_LARGE_MIN_HEIGHT}
		// 					minWidth={IMAGE_LARGE_MIN_WIDTH}
		// 					maxHeight={IMAGE_LARGE_MAX_HEIGHT}
		// 					maxWidth={IMAGE_LARGE_MAX_WIDTH}
		// 					uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
		// 					disableCropper={true}
		// 					blobUrlType={'image'}
		// 				/>
		// 				<i className="fa-regular fa-plus"></i>
		// 			</div>
		// 			<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 				Link Background Image
		// 			</p>
		// 			<p className="text-center body-txtitalic--smallest">
		// 				720px to 1080px JPEG, PNG, GIF
		// 			</p>
		// 		</div>
		// 		<div className="md:hidden md:col-span-4">
		// 			<p className="body-txtitalic--smallest color-reg mt-5">
		// 				Your Link Background Video (up to 8MB, 16:9 ratio preferred) replaces the
		// 				Link Background Image in some areas on limited devices only. Optional.
		// 			</p>
		// 		</div>
		// 		<div className="md:col-span-4">
		// 			<div className="upload-rec">
		// 				<UploadDragger
		// 					hideDragIcon="true"
		// 					hideText="true"
		// 					uploadFileTypes={VIDEO_FILETYPES.toString()}
		// 					uploadedFilePreview={videoPreview}
		// 					onUploadFileChange={handleVideoUploadFileChange}
		// 					minHeight={VIDEO_STANDARD_MIN_HEIGHT}
		// 					minWidth={VIDEO_STANDARD_MIN_WIDTH}
		// 					maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
		// 					maxWidth={VIDEO_STANDARD_MAX_WIDTH}
		// 					uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
		// 					blobUrlType={'video'}
		// 				/>
		// 				<i className="fa-regular fa-plus"></i>
		// 			</div>
		// 			<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 				Link Background Video
		// 			</p>
		// 			<p className="text-center body-txtitalic--smallest">
		// 				Up to 1280px/720p wide. MOV or MP4
		// 			</p>
		// 		</div>
		// 		<div className="hidden md:block md:col-span-4">
		// 			<p className="body-txtitalic--smallest color-reg">
		// 				Your Link Background Image (up to 4MB, 16:9 ratio preferred) is displayed as
		// 				a card with your link information. If no Image is uploaded, your link card
		// 				will display a color background.
		// 			</p>
		// 			<p className="body-txtitalic--smallest color-reg mt-5">
		// 				Your Link Background Video (up to 8MB, 16:9 ratio preferred) replaces the
		// 				Link Background Image in some areas on limited devices only. Optional.
		// 			</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default memo(Links);
