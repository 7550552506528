import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { useLocation } from 'react-router-dom';

import {
	CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS,
	CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS,
	CUSTOM_SPACE_FOREGROUND_POSITIONS,
	CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS,
	CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS
} from '../../../../constants/space';
import { setSpaceProStyle } from '../../../../services/space';
import UploadDragger from '../../../../components/commons/UploadDragger';
import { GOOGLE_FONT_KEY } from '../../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LOGO_MAX_HEIGHT,
	IMAGE_LOGO_MAX_WIDTH,
	IMAGE_LOGO_MIN_HEIGHT,
	IMAGE_LOGO_MIN_WIDTH
} from '../../../../constants/file';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import ColorPickerAndInput from '../../../../components/commons/ColorPickerAndInput';
import header_layout1 from '../../../../../src/assets/images/header_layout1.png';
import header_layout2 from '../../../../../src/assets/images/header_layout2.png';
import './../../../SpaceCreate/CustomizeProSpace/CustomizeProSpace.scss';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import Alert from 'react-bootstrap/Alert';
import FontsDropdownWithSearch from '../../../../components/commons/FontsDropdownWithSearch';
import CustomizeProSpace from '../../../SpaceCreate/CustomizeProSpace/CustomizeProSpace';
import { useDispatch } from 'react-redux';
import {
	displayPageContentLoader,
	hidePageContentLoader
} from '../../../../states/pageContentLoader';
import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';

const ProSpaceCustomization = (props) => {
	const { hash } = useLocation();
	const dispatch = useDispatch();
	const { authUserInfo, spaceInfo } = props;
	const [submitted, setSubmitted] = useState(false);
	const [alert, setAlert] = useState(null);
	const [logoImage, setLogoImage] = useState(null);
	const [logoImageBlobPreview, setLogoImageBlobPreview] = useState(null);
	const [logoImageError, setLogoImageError] = useState(null);
	const [lightBackgroundImage, setLightBackgroundImage] = useState(null);
	const [lightBackgroundImageBlobPreview, setLightBackgroundImageBlobPreview] = useState(null);
	const [lightBackgroundVideo, setLightBackgroundVideo] = useState(null);
	const [lightBackgroundVideoBlobPreview, setLightBackgroundVideoBlobPreview] = useState(null);
	const [darkBackgroundImage, setDarkBackgroundImage] = useState(null);
	const [darkBackgroundImageBlobPreview, setDarkBackgroundImageBlobPreview] = useState(null);
	const [darkBackgroundVideo, setDarkBackgroundVideo] = useState(null);
	const [darkBackgroundVideoBlobPreview, setDarkBackgroundVideoBlobPreview] = useState(null);
	const [headerBackgroundImage, setHeaderBackgroundImage] = useState(null);
	const [headerBackgroundImageBlobPreview, setHeaderBackgroundImageBlobPreview] = useState(null);
	const [headerBackgroundVideo, setHeaderBackgroundVideo] = useState(null);
	const [headerBackgroundVideoBlobPreview, setHeaderBackgroundVideoBlobPreview] = useState(null);
	const [headerForegroundImage, setHeaderForegroundImage] = useState(null);
	const [headerForegroundImageBlobPreview, setHeaderForegroundImageBlobPreview] = useState(null);
	const [selectedLightImagePosition, setSelectedLightImagePosition] = useState('cover');
	const [selectedLightVideoPosition, setSelectedLightVideoPosition] = useState('cover');
	const [selectedDarkImagePosition, setSelectedDarkImagePosition] = useState('cover');
	const [selectedDarkVideoPosition, setSelectedDarkVideoPosition] = useState('cover');
	const [selectedForegroundPosition, setSelectedForegroundPosition] = useState('cover');
	const [foregroundText, setForegroundText] = useState('');
	const [headerLayout, setHeaderLayout] = useState('standard');
	const [hasGradientChecked, setHasGradientChecked] = useState(false);
	const [logoImageIsRemoved, setLogoImageIsRemoved] = useState(false);
	const [lightBackgroundImageIsRemoved, setLightBackgroundImageIsRemoved] = useState(false);
	const [lightBackgroundVideoIsRemoved, setLightBackgroundVideoIsRemoved] = useState(false);
	const [darkBackgroundImageIsRemoved, setDarkBackgroundImageIsRemoved] = useState(false);
	const [darkBackgroundVideoIsRemoved, setDarkBackgroundVideoIsRemoved] = useState(false);
	const [headerBackgroundImageIsRemoved, setHeaderBackgroundImageIsRemoved] = useState(false);
	const [headerBackgroundVideoIsRemoved, setHeaderBackgroundVideoIsRemoved] = useState(false);
	const [headerForegroundImageIsRemoved, setheaderForegroundImageIsRemoved] = useState(false);

	//use state for color picker
	const [color1, setColor1] = useState('#FB03B9');
	const [color2, setColor2] = useState('#FFFFFF');
	const [color3, setColor3] = useState('#0C0C0C');
	const [color4, setColor4] = useState('#FFFFFF');
	const [color5, setColor5] = useState('#FFFFFF');
	const [color6, setColor6] = useState('#0C0C0C');

	//use state for google fonts
	const [font1, setFont1] = useState('Noto Sans');
	const [font2, setFont2] = useState('Noto Sans');

	const foregroundTextRef = useRef(null);
	const logoIdentityRef = useRef(null);
	const homepageRef = useRef(null);
	const sectionsRef = useRef(null);

	const handleLogoImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLogoImageIsRemoved(true);
		} else {
			setLogoImageIsRemoved(false);
		}

		setLogoImage(file);
		setLogoImageBlobPreview(blobPreview);
	};

	const handleLightBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLightBackgroundImageIsRemoved(true);
		} else {
			setLightBackgroundImageIsRemoved(false);
		}

		setLightBackgroundImage(file);
		setLightBackgroundImageBlobPreview(blobPreview);
	};

	const handleLightBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLightBackgroundVideoIsRemoved(true);
		} else {
			setLightBackgroundVideoIsRemoved(false);
		}

		setLightBackgroundVideo(file);
		setLightBackgroundVideoBlobPreview(blobPreview);
	};

	const handleDarkBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setDarkBackgroundImageIsRemoved(true);
		} else {
			setDarkBackgroundImageIsRemoved(false);
		}

		setDarkBackgroundImage(file);
		setDarkBackgroundImageBlobPreview(blobPreview);
	};

	const handleDarkBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setDarkBackgroundVideoIsRemoved(true);
		} else {
			setDarkBackgroundVideoIsRemoved(false);
		}

		setDarkBackgroundVideo(file);
		setDarkBackgroundVideoBlobPreview(blobPreview);
	};

	const handleHeaderBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setHeaderBackgroundImageIsRemoved(true);
		} else {
			setHeaderBackgroundImageIsRemoved(false);
		}

		setHeaderBackgroundImage(file);
		setHeaderBackgroundImageBlobPreview(blobPreview);
	};

	const handleHeaderBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setHeaderBackgroundVideoIsRemoved(true);
		} else {
			setHeaderBackgroundVideoIsRemoved(false);
		}

		setHeaderBackgroundVideo(file);
		setHeaderBackgroundVideoBlobPreview(blobPreview);
	};

	const handleHeaderForegroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setheaderForegroundImageIsRemoved(true);
		} else {
			setheaderForegroundImageIsRemoved(false);
		}

		setHeaderForegroundImage(file);
		setHeaderForegroundImageBlobPreview(blobPreview);
	};

	const submitStyles = useCallback(async () => {
		setLogoImageError(null);

		let hasError = false;

		if (!logoImageBlobPreview) {
			setLogoImageError('Pro Space Logo is required.');
			hasError = true;
		}

		if (hasError) {
			window.scrollTo(0, 0);
			return;
		}

		const data = {
			space_id: spaceInfo.id,
			user_id: authUserInfo.id,
			logo: logoImage,
			key_color: color1,
			has_gradient: hasGradientChecked,
			gradient_color: color2,
			title_font: font1,
			body_font: font2,
			light_text_color: color3,
			light_background_color: color4,
			light_background_image: lightBackgroundImage,
			light_background_video: lightBackgroundVideo,
			light_image_position: selectedLightImagePosition,
			light_video_position: selectedLightVideoPosition,
			dark_text_color: color5,
			dark_background_color: color6,
			dark_background_image: darkBackgroundImage,
			dark_background_video: darkBackgroundVideo,
			dark_image_position: selectedDarkImagePosition,
			dark_video_position: selectedDarkVideoPosition,
			header_layout: headerLayout,
			header_background_image: headerBackgroundImage,
			header_background_video: headerBackgroundVideo,
			header_foreground_image: headerForegroundImage,
			foreground_text: foregroundText,
			foreground_position: selectedForegroundPosition
		};

		if (logoImageIsRemoved) {
			data.logo_is_empty = true;
		}

		if (lightBackgroundImageIsRemoved) {
			data.light_background_image_is_empty = true;
		}

		if (lightBackgroundVideoIsRemoved) {
			data.light_background_video_is_empty = true;
		}

		if (darkBackgroundImageIsRemoved) {
			data.dark_background_image_is_empty = true;
		}

		if (darkBackgroundVideoIsRemoved) {
			data.dark_background_video_is_empty = true;
		}

		if (headerBackgroundImageIsRemoved) {
			data.header_background_image_is_empty = true;
		}

		if (headerBackgroundVideoIsRemoved) {
			data.header_background_video_is_empty = true;
		}

		if (headerForegroundImageIsRemoved) {
			data.header_foreground_image_is_empty = true;
		}

		try {
			setSubmitted(true);
			const response = await setSpaceProStyle(data);
			if (response && response.result) {
				setAlert({
					variant: 'success',
					message: response.message
				});
				dispatch(getSpaceInfo(spaceInfo.username));
				setLogoImageIsRemoved(false);
				setLightBackgroundImageIsRemoved(false);
				setLightBackgroundVideoIsRemoved(false);
				setDarkBackgroundImageIsRemoved(false);
				setDarkBackgroundVideoIsRemoved(false);
				setHeaderBackgroundImageIsRemoved(false);
				setHeaderBackgroundVideoIsRemoved(false);
				setheaderForegroundImageIsRemoved(false);
			} else {
				setAlert({
					variant: 'danger',
					message: response.message
				});
			}
			setSubmitted(false);
		} catch (error) {
			console.log(error);
		}

	}, [
		authUserInfo,
		spaceInfo,
		logoImage,
		logoImageBlobPreview,
		color1,
		color2,
		font1,
		font2,
		color3,
		color4,
		lightBackgroundImage,
		lightBackgroundVideo,
		selectedLightImagePosition,
		selectedLightVideoPosition,
		color5,
		color6,
		darkBackgroundImage,
		darkBackgroundVideo,
		selectedDarkImagePosition,
		selectedDarkVideoPosition,
		headerLayout,
		headerBackgroundImage,
		headerBackgroundVideo,
		headerForegroundImage,
		foregroundText,
		selectedForegroundPosition,
		hasGradientChecked,
		logoImageIsRemoved,
		lightBackgroundImageIsRemoved,
		lightBackgroundVideoIsRemoved,
		darkBackgroundImageIsRemoved,
		darkBackgroundVideoIsRemoved,
		headerBackgroundImageIsRemoved,
		headerBackgroundVideoIsRemoved,
		headerForegroundImageIsRemoved
	]);

	const setFormSpaceData = useCallback(
		async (spaceData) => {
			if (spaceData) {
				if (spaceData.plan === 'pro') {
					// set space data to forms here
					if (spaceData.pro_style) {
						const pro_style = spaceData.pro_style;
						setColor1(pro_style.key_color);
						setHasGradientChecked(pro_style.has_gradient);
						setColor2(pro_style.gradient_color);
						setColor3(pro_style.light_text_color);
						setColor4(pro_style.light_background_color);
						setSelectedLightImagePosition(pro_style.light_image_position);
						setSelectedLightVideoPosition(pro_style.light_video_position);
						setColor5(pro_style.dark_text_color);
						setColor6(pro_style.dark_background_color);
						setSelectedDarkImagePosition(pro_style.dark_image_position);
						setSelectedDarkVideoPosition(pro_style.dark_video_position);
						setHeaderLayout(pro_style.header_layout);
						setForegroundText(pro_style.foreground_text);
						setSelectedForegroundPosition(pro_style.foreground_position);

						if (pro_style.logo) {
							if (!logoImageIsRemoved) {
								setLogoImageBlobPreview(pro_style.logo);
							}
						}

						if (pro_style.light_background_image) {
							if (!lightBackgroundImageIsRemoved) {
								setLightBackgroundImageBlobPreview(
									pro_style.light_background_image
								);
							}
						}

						if (pro_style.light_background_video) {
							if (!lightBackgroundVideoIsRemoved) {
								setLightBackgroundVideoBlobPreview(
									pro_style.light_background_video
								);
							}
						}

						if (pro_style.dark_background_image) {
							if (!darkBackgroundImageIsRemoved) {
								setDarkBackgroundImageBlobPreview(pro_style.dark_background_image);
							}
						}

						if (pro_style.dark_background_video) {
							if (!darkBackgroundVideoIsRemoved) {
								setDarkBackgroundVideoBlobPreview(pro_style.dark_background_video);
							}
						}

						if (pro_style.header_background_image) {
							if (!headerBackgroundImageIsRemoved) {
								setHeaderBackgroundImageBlobPreview(
									pro_style.header_background_image
								);
							}
						}

						if (pro_style.header_background_video) {
							if (!headerBackgroundVideoIsRemoved) {
								setHeaderBackgroundVideoBlobPreview(
									pro_style.header_background_video
								);
							}
						}

						if (pro_style.header_foreground_image) {
							if (!headerForegroundImageIsRemoved) {
								setHeaderForegroundImageBlobPreview(
									pro_style.header_foreground_image
								);
							}
						}

						// added delay on setting font value to allow api fetch to finish
						setTimeout(() => {
							setFont1(pro_style.title_font);
							setFont2(pro_style.body_font);
						}, 2000);
					}
				}
			}
		},
		[
			logoImageIsRemoved,
			lightBackgroundImageIsRemoved,
			lightBackgroundVideoIsRemoved,
			darkBackgroundImageIsRemoved,
			darkBackgroundVideoIsRemoved,
			headerBackgroundImageIsRemoved,
			headerBackgroundVideoIsRemoved,
			headerForegroundImageIsRemoved
		]
	);

	const tooltipFocused = (input) => {
		if (input === 'foreground_text') {
			foregroundTextRef.current.focus();
		}
	};

	const onChangeHasGradient = (e) => {
		setHasGradientChecked(e.target.checked);
	};

	// useEffect(() => {
	// 	if (spaceInfo) {
	// 		dispatch(hidePageContentLoader());
	// 		setFormSpaceData(spaceInfo).then((r) => {});
	// 	} else {
	// 		dispatch(displayPageContentLoader());
	// 	}

	// 	if (hash === '#logo-identity') {
	// 		logoIdentityRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	}
	// 	if (hash === '#homepage') {
	// 		homepageRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	}
	// 	if (hash === '#sections') {
	// 		sectionsRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	}

	// 	document.title = 'Commonspace Spaces';
	// }, [authUserInfo, spaceInfo, hash, logoImageError]);

	return (
        <CustomizeProSpace dropSection={hash}></CustomizeProSpace>
		// <div className="block-full card">
		// 	<div id="member-profile" className="dashboard-profile p-5">
		// 		<div
		// 			id="logo-identity"
		// 			ref={logoIdentityRef}
		// 			className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 flex items-center "
		// 		>
		// 			<div className="col-span-12 md:col-span-8">
		// 				<h5 className="leading-tight header-medium">Logo &amp; Key Color</h5>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<button type="submit" className="btn btn-lg btn-secondary">
		// 					View Guide
		// 					<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
		// 				</button>
		// 			</div>
		// 			<div className="col-span-12">
		// 				<p className="body-text--small mt-5">
		// 					Your Space has upgraded to a Pro Space! You’ve unlocked full
		// 					customization for your branding, layout, and style, as well as more
		// 					advanced features. To get started, set up your customization settings
		// 					below. Also more custom features are launching in March and April with
		// 					our Beta rollout.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="mt-4 grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 flex item-center">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						passedError={logoImageError}
		// 						cropperAspectRatio={1 / 1}
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 						uploadedFilePreview={logoImageBlobPreview}
		// 						onUploadFileChange={handleLogoImageUploadFileChange}
		// 						minHeight={IMAGE_LOGO_MIN_HEIGHT}
		// 						minWidth={IMAGE_LOGO_MIN_WIDTH}
		// 						maxHeight={IMAGE_LOGO_MAX_HEIGHT}
		// 						maxWidth={IMAGE_LOGO_MAX_WIDTH}
		// 						uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
		// 						disableCropper={true}
		// 						imagePreviewCustomClassName={'centered-image-preview'}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Pro Space Logo File*
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					SVG preferred, or 64px height or better PNG, GIF, or JPG.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4 z-0">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Key Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						color={color1}
		// 						onColorChange={(value) => setColor1(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 				<div className="input-group-com h-10 flex items-center justify-between mt-5 has-gradient-group">
		// 					<Checkbox
		// 						checked={hasGradientChecked}
		// 						className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-pink-600 has-gradient-checkbox"
		// 						onChange={(value) => onChangeHasGradient(value)}
		// 					></Checkbox>
		// 					<label className="has-gradient-label">Has Gradient</label>
		// 				</div>
		// 				<div
		// 					className={
		// 						'input-group-com h-10 flex items-center justify-between ' +
		// 						(!hasGradientChecked ? 'disable-picker' : '')
		// 					}
		// 				>
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Gradient Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						className={!hasGradientChecked ? 'disable-picker' : ''}
		// 						color={color2}
		// 						onColorChange={(value) => setColor2(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					Select your Key Color by entering a color hex code or using the selector
		// 					by clicking the color square.
		// 				</p>
		// 				<p className="body-txtitalic--smallest color-reg mt-5">
		// 					OPTIONAL FOR GRADIENT ONLY. If you would like your Key Color to be a
		// 					gradient, your first color will be the Start Color and this color will
		// 					be the End Color of the gradient.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="mt-5 grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 flex item-center">
		// 			<div className="col-span-12">
		// 				<h5 className="leading-tight header-medium">Fonts</h5>
		// 			</div>
		// 			<div className="col-span-12">
		// 				<p className="body-text--small mt-5">
		// 					Your Space has upgraded to a Pro Space! You’ve unlocked full
		// 					customization for your branding, layout, and style, as well as more
		// 					advanced features. To get started, set up your customization settings
		// 					below. Also more custom features are launching in March and April with
		// 					our Beta rollout.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div id="homepage" ref={homepageRef} className="mt-4 grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 flex item-center">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-[100px] md:w-1/4 gradient-color-txt ms-4">
		// 						Title Font*
		// 					</label>
		// 					<FontsDropdownWithSearch
		// 						componentId="titleFont"
		// 						selectedValue={font1}
		// 						onDropdownChange={setFont1}
		// 					></FontsDropdownWithSearch>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-[100px] md:w-1/4 gradient-color-txt ms-4">
		// 						Body Font*
		// 					</label>
		// 					<FontsDropdownWithSearch
		// 						componentId="bodyFont"
		// 						selectedValue={font2}
		// 						onDropdownChange={setFont2}
		// 					></FontsDropdownWithSearch>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					Some fonts may have different sizing, so be sure to review how they look
		// 					on your Space before launching.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">
		// 			<div className="col-span-12">
		// 				<h5 className="leading-tight header-medium">Homepage Header Layout</h5>
		// 			</div>
		// 			<div className="col-span-12 mt-5">
		// 				<p className="body-text--small">
		// 					Pro Spaces have their own Homepage Header with custom branding and
		// 					navigation, versus Basic Spaces which use the Commonspace Header. You
		// 					can choose from the Standard or the Split Pro Header for your homepage.
		// 					The Standard Pro Header brings the focus to the homepage content, versus
		// 					the Split Pro Header which focuses on the branding and requires a
		// 					background image that won’t blend in with the logo and buttons. Images
		// 					below for example only.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 hidden md:grid">
		// 			<div className="col-span-6">
		// 				<img className="img-fluid" src={header_layout1} />
		// 			</div>
		// 			<div className="col-span-6">
						
		// 				<img className="img-fluid" src={header_layout2} />
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
		// 			<div className="col-span-12 md:col-span-6 block md:hidden">
		// 				<img className="img-fluid" src={header_layout1} />
		// 			</div>
		// 			<div className="col-span-12 md:col-span-6 mt-4 sm:mt-0">
		// 				<Button
		// 					className={
		// 						'btn btn-lg ' +
		// 						(headerLayout === 'standard' ? 'btn-primary text-[14px] md:text-[20px]' : 'btn-secondary text-[14px] md:text-[20px]')
		// 					}
		// 					onClick={() => setHeaderLayout('standard')}
		// 				>
		// 					Select Standard Header
		// 				</Button>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-6  mt-4 sm:mt-0 block md:hidden">
		// 				<img className="img-fluid" src={header_layout1} />
		// 			</div>
		// 			<div className="col-span-12 md:col-span-6  mt-4 sm:mt-0">
		// 				<Button
		// 					className={
		// 						'btn btn-lg ' +
		// 						(headerLayout === 'split' ? 'btn-primary text-[14px] md:text-[20px]' : 'btn-secondary text-[14px] md:text-[20px]')
		// 					}
		// 					onClick={() => setHeaderLayout('split')}
		// 				>
		// 					Select Split Header
		// 				</Button>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">
		// 			<div className="col-span-12">
		// 				<h5 className="leading-tight header-medium">Homepage Header Background & Foreground</h5>
		// 			</div>
		// 			<div className="col-span-12 mt-5">
		// 				<p className="body-text--small">
		// 					Your Homepage Header can have a custom Background and Foreground. The
		// 					default is to use your Space Profile Background but you can optionally
		// 					choose a different Image or Video below. In addition, you can choose an
		// 					optional Foreground Image or Text which will display over the Background
		// 					on your Homepage only. In the example in the section above, the
		// 					Background is the sky image with the head and the Foreground is the
		// 					DRMVRSE logo.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						cropperAspectRatio={16 / 9}
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 						uploadedFilePreview={headerBackgroundImageBlobPreview}
		// 						onUploadFileChange={handleHeaderBackgroundImageUploadFileChange}
		// 						minHeight={IMAGE_LARGE_MIN_HEIGHT}
		// 						minWidth={IMAGE_LARGE_MIN_WIDTH}
		// 						maxHeight={IMAGE_LARGE_MAX_HEIGHT}
		// 						maxWidth={IMAGE_LARGE_MAX_WIDTH}
		// 						uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
		// 						disableCropper={true}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Image
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 3840px square or wide. JPEG or PNG.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={VIDEO_FILETYPES.toString()}
		// 						uploadedFilePreview={headerBackgroundVideoBlobPreview}
		// 						onUploadFileChange={handleHeaderBackgroundVideoUploadFileChange}
		// 						minHeight={VIDEO_STANDARD_MIN_HEIGHT}
		// 						minWidth={VIDEO_STANDARD_MIN_WIDTH}
		// 						maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
		// 						maxWidth={VIDEO_STANDARD_MAX_WIDTH}
		// 						uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Video
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 1920px/1080px, square or wide. MOV or MP4.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					The optional Header Background Image (up to 8MB) will replace your
		// 					default Space Profile Background for the Homepage only.
		// 				</p>
		// 				<p className="body-txtitalic--smallest color-reg mt-5">
		// 					The optional Header Background Video (up to 16MB) will replace the
		// 					Header Background Image on limited supported devices.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						cropperAspectRatio={16 / 9}
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 						uploadedFilePreview={headerForegroundImageBlobPreview}
		// 						onUploadFileChange={handleHeaderForegroundImageUploadFileChange}
		// 						minHeight={IMAGE_LARGE_MIN_HEIGHT}
		// 						minWidth={IMAGE_LARGE_MIN_WIDTH}
		// 						maxHeight={IMAGE_LARGE_MAX_HEIGHT}
		// 						maxWidth={IMAGE_LARGE_MAX_WIDTH}
		// 						uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
		// 						disableCropper={true}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Foreground Image
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 3840px square or wide. JPEG or PNG.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4 hide-label">
		// 						Foreground
		// 						<br />
		// 						Text
		// 					</label>
		// 					<input
		// 						onFocus={hideTooltip}
		// 						onBlur={showTooltip}
		// 						className="input-lg body-text--small"
		// 						type="text"
		// 						placeholder="&nbsp;"
		// 						onChange={(e) => setForegroundText(e.target.value)}
		// 						value={foregroundText}
		// 						ref={foregroundTextRef}
		// 						maxLength={128}
		// 					/>
		// 					<span
		// 						style={{ left: '74%' }}
		// 						className="tool-tip prevent-select"
		// 						onClick={() => tooltipFocused('foreground_text')}
		// 					>
		// 						Up to 128 characters, including spaces.
		// 					</span>
		// 				</div>
		// 				<div className="input-group-com h-10 flex items-center justify-between mt-5 ">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Foreground
		// 						<br />
		// 						Position
		// 					</label>
		// 					<DropdownWithItems
		// 						dropDownClass="w-3/4 "
		// 						customButtonClass="btn-lg btn-secondary dropdown-secondary !h-[55px]"
		// 						title="Foreground Position"
		// 						selectedValue={selectedForegroundPosition}
		// 						listItems={CUSTOM_SPACE_FOREGROUND_POSITIONS}
		// 						onDropdownChange={(value) => setSelectedForegroundPosition(value)}
		// 					/>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					The optional Header Foreground Image (up to 8MB) will display over the
		// 					Header Background for the Homepage only.
		// 				</p>
		// 				<p className="body-txtitalic--smallest color-reg mt-5">
		// 					The Foreground Image can be positioned as a scaled Fill or as a Top,
		// 					Center (Default), or Bottom position with no scaling. The Optional
		// 					Foreground Text will replace the Foreground Image in the middle center
		// 					if added.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div id="sections" ref={sectionsRef} className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center ">
		// 			<div className="col-span-12">
		// 				<h5 className="leading-tight header-medium">Section Colors & Backgrounds</h5>
		// 			</div>
		// 			<div className="col-span-12 mt-5">
		// 				<p className="body-text--small">
		// 					Your Pro Space has different sections which use your Key Color with a
		// 					Text Color and a Background Color. These have light and dark styles and
		// 					you can select your Text Color and Background Color, plus an optional
		// 					Background Image or Video for each. When selecting your Backgrounds, be
		// 					sure that your Key Color will still stand out. Any Background Images or
		// 					Video which will show on top of Background Colors.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">
		// 			<div className="col-span-12 md:col-span-8">
		// 				<h5 className="header-small">Light Section Text & Background Colors</h5>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg">
		// 					Your Light Section should have a dark Text Color on a light Background
		// 					Color.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Text Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						color={color3}
		// 						onColorChange={(value) => setColor3(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Background
		// 						<br />
		// 						Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						color={color4}
		// 						onColorChange={(value) => setColor4(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					Default Light Section Text Color is #0C0C0C for dark grey text on a
		// 					#FFFFFF white Background Color.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						cropperAspectRatio={16 / 9}
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 						uploadedFilePreview={lightBackgroundImageBlobPreview}
		// 						onUploadFileChange={handleLightBackgroundImageUploadFileChange}
		// 						minHeight={IMAGE_LARGE_MIN_HEIGHT}
		// 						minWidth={IMAGE_LARGE_MIN_WIDTH}
		// 						maxHeight={IMAGE_LARGE_MAX_HEIGHT}
		// 						maxWidth={IMAGE_LARGE_MAX_WIDTH}
		// 						uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
		// 						disableCropper={true}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Image
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 3840px square or wide. JPEG or PNG.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4 gap-y-8 md:gap-y-0 md:gap-8">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={VIDEO_FILETYPES.toString()}
		// 						uploadedFilePreview={lightBackgroundVideoBlobPreview}
		// 						onUploadFileChange={handleLightBackgroundVideoUploadFileChange}
		// 						minHeight={VIDEO_STANDARD_MIN_HEIGHT}
		// 						minWidth={VIDEO_STANDARD_MIN_WIDTH}
		// 						maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
		// 						maxWidth={VIDEO_STANDARD_MAX_WIDTH}
		// 						uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Video
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 1920px/1080px, square or wide. MOV or MP4.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4 gap-y-8 md:gap-y-0 md:gap-8">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					The optional Background Image (up to 8MB) for your Section will display
		// 					over the Background Color and transparency is supported.
		// 				</p>
		// 				<p className="body-txtitalic--smallest color-reg mt-5">
		// 					The optional Background Video (up to 16MB) for your Section will display
		// 					over the Background Color instead of the Background Image on limited
		// 					supported devices.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 flex items-center gap-y-8 md:gap-y-0 md:gap-8">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-[100px]  md:w-1/4 gradient-color-txt ms-4">
		// 						Image
		// 						<br />
		// 						Position
		// 					</label>
		// 					<DropdownWithItems
		// 						customButtonClass="btn-lg btn-secondary dropdown-secondary !h-[55px]"
		// 						dropDownClass="w-3/4"
		// 						title="Image Position"
		// 						selectedValue={selectedLightImagePosition}
		// 						listItems={CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS}
		// 						onDropdownChange={(value) => setSelectedLightImagePosition(value)}
		// 					/>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-[100px]  md:w-1/4 gradient-color-txt ms-4">
		// 						Video
		// 						<br />
		// 						Position
		// 					</label>
		// 					<DropdownWithItems
		// 						dropDownClass="w-3/4"
		// 						customButtonClass="btn-lg btn-secondary dropdown-secondary !h-[55px]"
		// 						title="Video Position"
		// 						selectedValue={selectedLightVideoPosition}
		// 						listItems={CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS}
		// 						onDropdownChange={(value) => setSelectedLightVideoPosition(value)}
		// 					/>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					You can choose how your Background Image or Video will display. Fill is
		// 					the default and is scaled to the page size, or choose Top, Center, or
		// 					Bottom with no scaling.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 flex items-center gap-y-8 md:gap-y-0 md:gap-8">
		// 			<div className="col-span-12 md:col-span-8">
		// 				<h5 className="header-small">Dark Section Text & Background Colors</h5>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg">
		// 					Your Dark Section should have a light Text Color on a dark Background
		// 					Color.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 flex items-center gap-y-8 md:gap-y-0 md:gap-8">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Text Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						color={color5}
		// 						onColorChange={(value) => setColor5(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-full gradient-color-txt ms-4">
		// 						Background
		// 						<br />
		// 						Color
		// 					</label>
		// 					<ColorPickerAndInput
		// 						color={color6}
		// 						onColorChange={(value) => setColor6(value)}
		// 					></ColorPickerAndInput>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					Default Dark Section Text Color is #FFFFFF” white text on a #0C0C0C dark
		// 					grey Background Color.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 flex gap-y-8 md:gap-y-0 md:gap-8">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						cropperAspectRatio={16 / 9}
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={IMAGE_FILETYPES.toString()}
		// 						uploadedFilePreview={darkBackgroundImageBlobPreview}
		// 						onUploadFileChange={handleDarkBackgroundImageUploadFileChange}
		// 						minHeight={IMAGE_LARGE_MIN_HEIGHT}
		// 						minWidth={IMAGE_LARGE_MIN_WIDTH}
		// 						maxHeight={IMAGE_LARGE_MAX_HEIGHT}
		// 						maxWidth={IMAGE_LARGE_MAX_WIDTH}
		// 						uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
		// 						disableCropper={true}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Image
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 3840px square or wide. JPEG or PNG.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="upload-rec">
		// 					<UploadDragger
		// 						hideDragIcon="true"
		// 						hideText="true"
		// 						uploadFileTypes={VIDEO_FILETYPES.toString()}
		// 						uploadedFilePreview={darkBackgroundVideoBlobPreview}
		// 						onUploadFileChange={handleDarkBackgroundVideoUploadFileChange}
		// 						minHeight={VIDEO_STANDARD_MIN_HEIGHT}
		// 						minWidth={VIDEO_STANDARD_MIN_WIDTH}
		// 						maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
		// 						maxWidth={VIDEO_STANDARD_MAX_WIDTH}
		// 						uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
		// 					/>
		// 					<i className="fa-regular fa-plus"></i>
		// 				</div>
		// 				<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
		// 					Background Video
		// 				</p>
		// 				<p className="text-center body-txtitalic--smallest">
		// 					Up to 1920px/1080px, square or wide. MOV or MP4.
		// 				</p>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					The optional Background Image (up to 8MB) for your Section will display
		// 					over the Background Color and transparency is supported.
		// 				</p>
		// 				<p className="body-txtitalic--smallest color-reg mt-5">
		// 					The optional Background Video (up to 16MB) for your Section will display
		// 					over the Background Color instead of the Background Image on limited
		// 					supported devices.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 mt-5 flex items-center gap-y-8 md:gap-y-0 md:gap-8">
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-1/4 gradient-color-txt ms-4">
		// 						Image
		// 						<br />
		// 						Position
		// 					</label>
		// 					<DropdownWithItems
		// 						dropDownClass="w-3/4"
		// 						customButtonClass="btn-lg btn-secondary dropdown-secondary !h-[55px]"
		// 						title="Image Position"
		// 						selectedValue={selectedDarkImagePosition}
		// 						listItems={CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS}
		// 						onDropdownChange={(value) => setSelectedDarkImagePosition(value)}
		// 					/>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<div className="input-group-com h-10 flex items-center justify-between">
		// 					<label className="body-text--small w-1/4 gradient-color-txt ms-4">
		// 						Video
		// 						<br />
		// 						Position
		// 					</label>
		// 					<DropdownWithItems
		// 						dropDownClass="w-3/4"
		// 						customButtonClass="btn-lg btn-secondary dropdown-secondary !h-[55px]"
		// 						title="Video Position"
		// 						selectedValue={selectedDarkVideoPosition}
		// 						listItems={CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS}
		// 						onDropdownChange={(value) => setSelectedDarkVideoPosition(value)}
		// 					/>
		// 				</div>
		// 			</div>
		// 			<div className="col-span-12 md:col-span-4">
		// 				<p className="body-txtitalic--smallest color-reg m-0">
		// 					You can choose how your Background Image or Video will display. Fill is
		// 					the default and scales to the page size, or choose Top, Center, or
		// 					Bottom with no scaling.
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
		// 			<>
		// 				{alert && (
		// 					<div className="col-span-12 mb-3">
		// 						<Alert
		// 							key={alert.variant}
		// 							variant={alert.variant}
		// 							className={
		// 								'custom-alert ' +
		// 								(alert.variant === 'danger'
		// 									? 'custom-alert-danger'
		// 									: 'custom-alert-success')
		// 							}
		// 						>
		// 							{alert.message}
		// 						</Alert>
		// 					</div>
		// 				)}
		// 			</>
		// 			<div className="col-span-12">
		// 				<Button
		// 					onClick={() => submitStyles()}
		// 					className="btn btn-lg btn-primary"
		// 					type="submit"
		// 					disabled={submitted}
		// 				>
		// 					{submitted && <i className="fa-solid fa-spinner fa-spin mr-8"></i>}
		// 					Save
		// 				</Button>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default memo(ProSpaceCustomization);
