import { memo, useCallback, useEffect, useState } from 'react';
import {
	UPDATES_FILTER,
	UPDATES_SORT,
	PUBLISH_STATUSES,
	UPDATES_VISIBILITY,
	UPDATE_AUTHORS, MANAGE_MEMBERS_FILTER, MANAGE_MEMBERS_SORT
} from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { useSelector } from 'react-redux';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';

import { getSpaceCategories, getSpaceUpdates } from '../../../services/space';
import { getListDisplay } from '../../../utils/list';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";

const Updates = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedFilter, setSelectedFilter] = useState('default');
	const [selectedSort, setSelectedSort] = useState('default');
	const [filterArr, setFilterArr] = useState(null);
	const [sortArr, setSortArr] = useState(null);
	const [updatesFetching, setUpdatesFetching] = useState(false);
	const [updatesList, setUpdatesList] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [totalUpdatesCount, setTotalUpdatesCount] = useState(0);
	const [updatesPagination, setUpdatesPagination] = useState([]);
	const [updatesCurrentPage, setUpdatesCurrentPage] = useState(1);

	const [clickedTableColumnOrder, setClickedTableColumnOrder] = useState(null);
	const [titleSortOrder, setTitleSortOrder] = useState(null);
	const [creationDateSortOrder, setCreationDateSortOrder] = useState(null);
	const [statusSortOrder, setStatusSortOrder] = useState(null);
	const [categorySortOrder, setCategorySortOrder] = useState(null);

	const [searchInputTimer, setSearchInputTimer] = useState(null);
	const [searchText, setSearchText] = useState('');

	const [categories, setCategories] = useState(null);
	const [updatedUpdatesFilterList, setUpdatedUpdatesFilterList] = useState(
		JSON.parse(JSON.stringify(UPDATES_FILTER))
	);

	function changeDelay(change) {
		if (searchInputTimer) {
			clearTimeout(searchInputTimer);
			setSearchInputTimer(null);
		}
		setSearchInputTimer(
			setTimeout(() => {
				getUpdatesRequest().then((r) => {});
			}, 3000)
		);
	}

	const filterDropdownChange = useCallback(
		async (value) => {
			const data = {
				filter_value: value
			};

			if (value.includes('category_id_')) {
				data['filter_type'] = 'category';
			}
			if (UPDATES_VISIBILITY.findIndex((x) => x.value === value) >= 0) {
				data['filter_type'] = 'type';
			}
			if (PUBLISH_STATUSES.findIndex((x) => x.value === value) >= 0) {
				data['filter_type'] = 'status';
			}
			if (UPDATE_AUTHORS.findIndex((x) => x.value === value) >= 0) {
				data['filter_type'] = 'author';
			}

			setFilterArr(data);
			setSelectedFilter(value);
		},
		[authUserInfo, spaceInfo, updatesCurrentPage]
	);

	const sortDropdownChange = useCallback(
		async (value) => {
			setTitleSortOrder(null);
			setCreationDateSortOrder(null);
			setStatusSortOrder(null);
			setCategorySortOrder(null);

			const data = {
				sort_by: value
			};

			setSortArr(data);
			setSelectedSort(value);
		},
		[authUserInfo, spaceInfo, updatesCurrentPage]
	);

	const handleTableSorts = useCallback(
		async (column) => {
			setClickedTableColumnOrder(column);

			if (column === 'title') {
				setCreationDateSortOrder(null);
				setStatusSortOrder(null);
				setCategorySortOrder(null);

				if (titleSortOrder === 'asc') {
					setTitleSortOrder(null);
				} else if (titleSortOrder === 'desc') {
					setTitleSortOrder('asc');
				} else {
					setTitleSortOrder('desc');
				}
			}
			if (column === 'date') {
				setTitleSortOrder(null);
				setStatusSortOrder(null);
				setCategorySortOrder(null);

				if (creationDateSortOrder === 'asc') {
					setCreationDateSortOrder(null);
				} else if (creationDateSortOrder === 'desc') {
					setCreationDateSortOrder('asc');
				} else {
					setCreationDateSortOrder('desc');
				}
			}
			if (column === 'status') {
				setTitleSortOrder(null);
				setCreationDateSortOrder(null);
				setCategorySortOrder(null);

				if (statusSortOrder === 'asc') {
					setStatusSortOrder(null);
				} else if (statusSortOrder === 'desc') {
					setStatusSortOrder('asc');
				} else {
					setStatusSortOrder('desc');
				}
			}
			if (column === 'category') {
				setTitleSortOrder(null);
				setCreationDateSortOrder(null);
				setStatusSortOrder(null);

				if (categorySortOrder === 'asc') {
					setCategorySortOrder(null);
				} else if (categorySortOrder === 'desc') {
					setCategorySortOrder('asc');
				} else {
					setCategorySortOrder('desc');
				}
			}
		},
		[titleSortOrder, creationDateSortOrder, statusSortOrder, categorySortOrder]
	);

	const handleOnClickPagination = useCallback(async (event) => {
		const itemClicked = event.target.text;
		if (itemClicked) {
			const number = parseInt(itemClicked);
			setUpdatesCurrentPage(number);
			await getUpdatesRequest();
		}
	}, []);

	const buildPagination = (current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		setUpdatesPagination(items);
	};

	const updateFilterList = useCallback(
		async (group, data) => {
			let label = null;
			const list = updatedUpdatesFilterList;
			if (group === 'category') {
				label = 'By Category';
			}
			if (group === 'status') {
				label = 'By Status';
			}
			if (group === 'type') {
				label = 'By Visibility';
			}
			if (group === 'author') {
				label = 'By Author';
			}

			const index = list.findIndex((x) => x.label === label);
			if (index >= 0) {
				let arr = [];
				if (group === 'category') {
					data.forEach((item) => {
						arr.push({ label: item.name, value: 'category_id_' + item.id });
					});
				} else {
					data.forEach((item) => {
						if (item.value !== 'default') {
							arr.push(item);
						}
					});
				}
				list[index]['value'] = arr;
			}

			setUpdatedUpdatesFilterList(list);
		},
		[updatedUpdatesFilterList]
	);

	const getSpaceCategoriesRequest = useCallback(async () => {
		await getSpaceCategories({ space_id: spaceInfo.id }).then((response) => {
			if (response && response.result) {
				setCategories(response.categories);
			}
		});
	}, [spaceInfo]);

	const getUpdatesRequest = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					viewing_user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					list_count: 10,
					page: updatesCurrentPage
				};

				if (selectedFilter !== 'default') {
					data['filter_type'] = filterArr['filter_type'];
					data['filter_value'] = filterArr['filter_value'];

					if (selectedFilter.includes('category_id_')) {
						data['filter_value'] = selectedFilter.replace('category_id_', '');
					}
				}

				if (selectedSort !== 'default') {
					data['sort_by'] = sortArr['sort_by'];
				}

				if (searchText && searchText !== '') {
					data['search'] = searchText;
				}

				if (clickedTableColumnOrder === 'title') {
					if (titleSortOrder) {
						data['sort_by'] = 'title';
						data['sort_order'] = titleSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'date') {
					if (creationDateSortOrder) {
						data['sort_by'] = 'date';
						data['sort_order'] = creationDateSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'status') {
					if (statusSortOrder) {
						data['sort_by'] = 'status';
						data['sort_order'] = statusSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'category') {
					if (categorySortOrder) {
						data['sort_by'] = 'category';
						data['sort_order'] = categorySortOrder;
					}
				}

				setUpdatesFetching(true);

				const response = await getSpaceUpdates(data);
				if (response && response.result) {
					if (response.data) {
						setUpdatesList(response.data);
						setTotalUpdatesCount(response.total_updates_count);
						buildPagination(parseInt(response.page), parseInt(response.page_range));
					} else {
						setUpdatesList(null);
					}
				}
				setUpdatesFetching(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		authUserInfo,
		spaceInfo,
		updatesCurrentPage,
		selectedFilter,
		selectedSort,
		filterArr,
		sortArr,
		clickedTableColumnOrder,
		searchText,
		titleSortOrder,
		creationDateSortOrder,
		statusSortOrder,
		categorySortOrder
	]);

	const redirectLink = (update, spaceInfo, route) => {
		if (spaceInfo) {
			window.location.href = route
				.replace(':space_username', '@' + spaceInfo.username)
				.replace(':update_id', update.id);
		}
	};

	const redirectToEditUpdateLink = useCallback(
		(update) => {
			if (spaceInfo) {
				let route = routes.editUpdate();
				route = route.replace(':space_username', '@' + spaceInfo.username);
				return route.replace(':update_id', update.id);
			}
			return '';
		},
		[spaceInfo]
	);

	function getFirstCategory(categories) {
		if (categories.length > 0) {
			return categories[0]['name'];
		}
		return null;
	}

	const UpdateRow = (data) => {
		return (
			<div className='min-w-[768px]'>
				<div className="row-view-wrapper mt-4">
					<div className="grid grid-cols-12">
						<div className="col-span-3 flex items-start md:items-center ">
							{(data && data.cover_video) ?
								<div className="thumb-rectangle flex-none me-4 ">
									<ReactPlayer
										playing={true}
										loop={true}
										controls={false}
										muted={true}
										width="100%"
										height="100%"
										// className="object-cover"
										url={data.cover_video}
									/>
								</div>
								:
								<img className="thumb-rectangle flex-none me-4" src={data.cover_photo_thumb} />
							}

							<h4>{data.title}</h4>
						</div>
						<div className="col-span-2 flex items-center justify-start ">
							<label className="body-text--smallest ms-1">
								{moment(data.created_on).format('MM/DD/YY')}
							</label>
						</div>
						<div className="col-span-2 flex items-center justify-start">
							<label className="body-text--smallest ms-1">
								{getListDisplay(PUBLISH_STATUSES, data.publish_status)}
							</label>
						</div>
						<div className="col-span-2   flex  items-center justify-start">
							<label className="body-text--smallest ms-1">
								{getFirstCategory(data.categories)}
							</label>
						</div>
						<div className="col-span-3 flex items-center">
							<button
								className="secondary-gradient w-full"
								onClick={() =>
									redirectLink(data, spaceInfo, routes.spaceViewSpaceUpdate())
								}
							>
								<span><p><i className="fa-light fa-arrow-up-right-from-square"/></p></span>
							</button>
							<a
								className="button-secondary-gradient ms-4 w-full"
								href={redirectToEditUpdateLink(data)}
							>
								<span><p><i className="fa-light fa-pen"/></p></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const redirectToCreateUpdateLink = useCallback(() => {
		if (spaceInfo) {
			const route = routes.createUpdate();
			return route.replace(':space_username', '@' + spaceInfo.username);
		}
		return '';
	}, [spaceInfo]);

	useEffect(() => {
		if (!initialLoad) {
			getUpdatesRequest().then((r) => {});
		}

		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getUpdatesRequest().then((r) => {});
				getSpaceCategoriesRequest().then((r) => {});
			}

			setInitialLoad(false);
		}

		if (categories) {
			updateFilterList('category', categories).then((r) => {});
		}
		updateFilterList('status', PUBLISH_STATUSES).then((r) => {});
		updateFilterList('type', UPDATES_VISIBILITY).then((r) => {});
		updateFilterList('author', UPDATE_AUTHORS).then((r) => {});
	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
		updatesCurrentPage,
		selectedFilter,
		filterArr,
		sortArr,
		clickedTableColumnOrder,
		titleSortOrder,
		creationDateSortOrder,
		statusSortOrder,
		categorySortOrder,
		categories,
		updatedUpdatesFilterList
	]);

	return (
		<div className="block-full">
			<div className="manage-updates">
				<div className="grid grid-cols-12 md:gap-8">
					<div className="col-span-12">
						<div className="dashboard-wrapper block-lg card">
							<div className="section-flex">
								<div className="block-lg">
									<div className="section-flex items-center">
										<div className="block-md">
											<h2>Manage Updates</h2>
										</div>
										<div className="block-xs text-end">
											<p className="italic">{totalUpdatesCount} Total</p>
										</div>
									</div>
								</div>
								<div className="block-lg">
									<div className="section-flex">
										<div className="block-smx">
											<div className="form-input-combo">
												<input
													type="text"
													placeholder="Enter Text"
													onChange={(e) => {
														setSearchText(e.target.value);
														changeDelay();
													}}
													value={searchText}
													onBlur={() => getUpdatesRequest()}
												/>
												<button
													onClick={() => {
														clearTimeout(searchInputTimer);
														setSearchInputTimer(null);
														getUpdatesRequest().then((r) => {});
													}}
													className="secondary-gradient">
													<span><p><i className="fa-light fa-magnifying-glass"/></p></span>
												</button>
											</div>
										</div>
										<div className="block-smx">
											<Link
												className="button-secondary w-full"
												to={redirectToCreateUpdateLink()}
											>
												Create New
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 mt-5 gap-8">
								<DropdownWithItems
									title="Filter"
									customButtonClass="button gradient secondary distribute w-full"
									icon="fa-light fa-filter"
									selectedValue={selectedFilter}
									listItems={updatedUpdatesFilterList}
									onDropdownChange={(value) => filterDropdownChange(value)}
								/>
								<DropdownWithItems
									title="Sort"
									customButtonClass="button gradient secondary distribute w-full"
									icon="fa-light fa-bars-sort"
									selectedValue={selectedSort}
									listItems={UPDATES_SORT}
									onDropdownChange={(value) => sortDropdownChange(value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row-view z-0">
					<div className="row-view-wrapper mt-4 overflow-auto">
					<div className='min-w-[760px] w-full overflow-auto relative'>
						<div className="grid grid-cols-12 header-tiny table-header color-light align-center justify-center">
							<div className="col-span-3 pl-[30px]">
								<Link onClick={() => handleTableSorts('title')}>
									Title
									{titleSortOrder ? (
										<>
											{titleSortOrder === 'asc' ? (
												<i className="fa-solid fa-sort-up"></i>
											) : (
												<i className="fa-solid fa-sort-down"></i>
											)}
										</>
									) : (
										<i className="fa-regular fa-sort"></i>
									)}
								</Link>
							</div>
							<div className="col-span-2 pl-[20px]">
								<Link onClick={() => handleTableSorts('date')}>
									Date
									{creationDateSortOrder ? (
										<>
											{creationDateSortOrder === 'asc' ? (
												<i className="fa-solid fa-sort-up"></i>
											) : (
												<i className="fa-solid fa-sort-down"></i>
											)}
										</>
									) : (
										<i className="fa-regular fa-sort"></i>
									)}
								</Link>
							</div>
							<div className="col-span-2">
								<Link onClick={() => handleTableSorts('status')}>
									Status
									{statusSortOrder ? (
										<>
											{statusSortOrder === 'asc' ? (
												<i className="fa-solid fa-sort-up"></i>
											) : (
												<i className="fa-solid fa-sort-down"></i>
											)}
										</>
									) : (
										<i className="fa-regular fa-sort"></i>
									)}
								</Link>
							</div>
							<div className="col-span-2">
								<Link onClick={() => handleTableSorts('category')}>
									Category
									{categorySortOrder ? (
										<>
											{categorySortOrder === 'asc' ? (
												<i className="fa-solid fa-sort-up"></i>
											) : (
												<i className="fa-solid fa-sort-down"></i>
											)}
										</>
									) : (
										<i className="fa-regular fa-sort"></i>
									)}
								</Link>
							</div>
							<Link className="col-span-2">Actions</Link>
						</div>
					</div>
					{updatesList && (
						<div className='grid grid-cols-1'>
							{updatesList.map((data, index) => (
								<div key={index}>{UpdateRow(data)}</div>
							))}
						</div>
					)}
				</div>
				</div>
			</div>
			{updatesList && updatesList.length > 10 && (
			<div className="grid grid-cols-12 gap-8 mt-3 items-center">
				<Pagination size="sm" onClick={(event) => handleOnClickPagination(event)}>
					{updatesPagination}
				</Pagination>
			</div>
			)}
		</div>
	);
};

export default memo(Updates);
