import React, {memo} from "react";

const YourMembership = (parentsProps) => {
    const { containerRef, membership, status, inactiveMemberships } = parentsProps;

    return (
        <>
            <div
                className="cs-dash-card block-full card"
                id='your-memberships'
                ref={containerRef}
            >
                <div className="formrow title">
                    <h3>Your Memberships</h3>
                    <p>Manage and Activate your memberships</p>
                </div>
                <div className="cs-stack-controlrows stack-form-col">
                    <div className="formrow">
                        <div className={"data-item cs-stack-avatar-data" + (status !== 'active' ? ' inactive' : '')}>
                            <div className="avatar gradient md cs-avatar-membership">
                                <img src={membership.photo} alt="Image of space_product_name"/>
                            </div>
                            <hgroup>
                                <h4>{membership.name}</h4>
                                <p>{membership.listing_text}</p>
                                <p>{status.charAt(0).toUpperCase() + status.slice(1)}</p>
                            </hgroup>
                        </div>
                        <a className="button gradient secondary"><span><p>Manage Membership</p></span></a>
                    </div>
                    {inactiveMemberships && inactiveMemberships.length > 0 && inactiveMemberships.map((inactive_membership, index) => (
                        <div className="formrow">
                            <div className={"data-item cs-stack-avatar-data" + (status !== 'active' ? ' inactive' : '')}>
                                <div className="avatar gradient md cs-avatar-membership">
                                    <img src={inactive_membership.photo} alt="Image of space_product_name"/>
                                </div>
                                <hgroup>
                                    <h4 className="!text-gray-600">{inactive_membership.name}</h4>
                                    <p className="!text-gray-400">{inactive_membership.listing_text}</p>
                                    <p className="!text-gray-400">Inactive</p>
                                </hgroup>
                            </div>
                            <a className="button gradient secondary"><span><p>Manage Membership</p></span></a>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export default memo(YourMembership);