import { ACTION_TYPES } from './const';

export const getAuthUserInfo = () => ({
	type: ACTION_TYPES.GET_AUTH_USER_INFO
});

export const getAuthUserInfoSuccess = (authUserInfo) => ({
	type: ACTION_TYPES.GET_AUTH_USER_INFO_SUCCESS,
	payload: authUserInfo
});

export const getAuthUserInfoFailed = () => ({
	type: ACTION_TYPES.GET_AUTH_USER_INFO_FAILED
});
