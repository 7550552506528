// Github: https://github.com/alchemyplatform/alchemy-sdk-js
// Setup: npm install alchemy-sdk
import { Network, Alchemy } from 'alchemy-sdk';
import { ALCHEMY_API_KEY } from '../../constants/common';
import { memo, useEffect, useState } from 'react';

const Lab = () => {
	// Optional Config object, but defaults to demo api-key and eth-mainnet.
	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};

	const alchemy = new Alchemy(settings);

	const getCollections = async (collection_address) => {
		// Print NFT metadata returned in the response:
		alchemy.nft.getNftMetadata(collection_address, '1590').then((result) => {
			// The contract owner
			// Other vars: address, contractDeployer, name, description
			console.log(result.contract.contractDeployer ?? 'No Deployer');
		});
	};

	useEffect(() => {
		console.log('use effect');
		getCollections('0x5180db8F5c931aaE63c74266b211F580155ecac8');
	}, []);
};
export default memo(Lab);
