import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES } from './const';
import { getSpaceData } from '../../services/general';
import * as actions from './actions';

function* getSpaceInfo(load) {
	try {
		const res = yield call(getSpaceData, load);
		if (res.result) {
			const spaceData = res.space;
			const spaceInfo = {
				...spaceData
			};
			yield put(actions.getSpaceInfoSuccess({ spaceInfo }));
		} else {
			yield put(actions.getSpaceInfoFailed(res.message));
		}
	} catch (error) {
		yield put(actions.getSpaceInfoFailed(error));
	}
}

function* watchGetSpaceInfo() {
	yield takeLatest(ACTION_TYPES.GET_SPACE_INFO, getSpaceInfo);
}

export default function* spaceSagas() {
	yield all([watchGetSpaceInfo()]);
}
