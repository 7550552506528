import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';

import {API_DOMAIN, VALIDATE_MESSAGES} from '../../constants/common';
import dj_bg_img from '../../assets/images/cs-splash.jpg';
import routes from '../../routes';
import { emailForgotPassword, getForgotEmailVerification } from '../../services/user';
import ResetPassword from './ResetPassword';
import {getDomainUrl} from "../../utils/common";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(true);
	const [formHasError, setFormHasError] = useState(true);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();
	const [params] = useSearchParams();
	const [email, setEmail] = useState();
	const [resultMessage, setResultMessage] = useState('');
	const token = params.get('token');
	const [showAnswers, setShowAnswers] = useState(false);

	const [verifying, setVerifying] = useState(false);
	const [verified, setVerified] = useState(false);
	const [verifiedError, setVerifiedError] = useState(false);
	const navigate = useNavigate();
	const statusTypes = {
		forgot: 'forgot',
		reset: 'reset',
		link: 'link',
		expired: 'expired'
	};

	const [statusType, setStatusType] = useState(statusTypes.forgot); //forgot, reset, link

	const handleFormOnBlur = useCallback(async () => {
		setAlert(null);
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
                console.log(errors);
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		try {
			setSubmitted(true);
			values.email = values.email.toLowerCase();

			if (params.get('rd')) {
				const hostname = window.location.hostname;
				const from_domain_decoded = atob(params.get('rd'));
				const isLocal = hostname === 'localhost' || hostname === '127.0.0.1';
				const protocol = isLocal ? 'http://' : 'https://';
				const port = isLocal ? ':3004' : '';
				values.api_domain = `${protocol}${from_domain_decoded}${port}`;
			} else {
				// Pass the current api domain to make the api_domain dynamic base on the server domain
				values.api_domain = getDomainUrl();
			}

			const response = await emailForgotPassword(values);
			if (response && response.result) {
				setStatusType(statusTypes.link);
				setSubmitted(false);
			} else {
				setSubmitted(false);
				setAlert({ variant: 'danger', message: response.message });
			}
		} catch (error) {
			setSubmitted(false);
			console.log(error);
		}
	}, []);

	const verifyEmail = useCallback(
		async (token) => {
			try {
				setVerified(false);
				await getForgotEmailVerification({ token }).then((result) => {
					if (result && result.result) {
						setVerified(true);
						setEmail(result.email);
						setResultMessage(result.message);
					} else {
						if (result.is_expired) setStatusType(statusTypes.expired);
						else {
							setVerifiedError(true);
							setAlert({ variant: 'danger', message: result.message });
						}
					}
				});
			} catch (error) {
				console.log(error);
			}
		},
		[setEmail]
	);

	const goBackToRequestPassword = () => {
		navigate(routes.forgotPassword());
		setStatusType(statusTypes.forgot);
	};

	useEffect(() => {
		document.title = 'Commonspace Forgot Password';
		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});

		if (token && token !== '' && !verifying && !email) {
			setStatusType(statusTypes.reset);
			setVerifying(true);
			if (token) {
				setTimeout(() => {
					verifyEmail(token).then();
				}, 1000);
			} else {
				setResultMessage('Email verification token is required.');
			}
		}
	}, [token, verifyEmail, email, verifying, verified, statusType]);

	return (
		<main id="cs-platform-main" className="cs-main main-theme">
			<section id="cs-platform-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
				<div className="cs-centerframe-split">
					<div className="cs-centerframe-split-left order-2 sm:order-1">
						<div className="p-section-safeview sm:pr-gutter">
							<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
								<hgroup className="heading">
									<h2>Reset Password</h2>
									{(statusType === statusTypes.forgot && verified) && (
										<p className="text-p1">
											Enter your account email request a password reset.
										</p>
									)}
									{(statusType === statusTypes.reset && verified) && (
										<p className="text-p1">Enter a new password below to change your password.</p>
									)}
								</hgroup>

								{(statusType === statusTypes.reset && !verified) && (
									<div className="block-callout card stack-col">
										<hgroup className="heading">
											<h3>Verifying</h3>
											<p>Please wait...</p>
										</hgroup>
									</div>
								)}

								{(statusType === statusTypes.reset && !verified && verifiedError) && (
									<div className="block-callout card stack-col">
										<hgroup className="heading">
											<h3>Oops!</h3>
											<p>{resultMessage}</p>
										</hgroup>
									</div>
								)}

								{statusType === statusTypes.expired && (
									<div className="block-callout card stack-col">
										<hgroup className="heading">
											<h3>Error!</h3>
											<p>Sorry, the password reset link has expired.</p>
										</hgroup>
									</div>
								)}
								{statusType === statusTypes.link && (
									<div className="block-callout card stack-col">
										<hgroup className="heading">
											<h3>Request Sent!</h3>
											<p>
												If an account exists with the email address you
												submitted then an email with reset instuctions will
												be sent. If you do not see it within 5 minutes,
												please check your spam folder and/or that you
												provided the correct email address.
											</p>
										</hgroup>
									</div>
								)}
								{statusType === statusTypes.forgot && (
                                    <div className='w-full'>
										<Form
											form={form}
											name="forgotPasswordForm"
											validateMessages={VALIDATE_MESSAGES}
											onFocus={() => {
												setFormIsOnFocus(true);
											}}
											onBlur={() => {
												setFormIsOnFocus(false);
												handleFormOnBlur().then(() => {});
											}}
											autoComplete="off"
											onChange={handleFormChanges}
											onFinish={handleSubmit}
											className='w-full'
										>
											<Form.Item
												name="email"
												className=""
												validateTrigger="onBlur"
												rules={[{ required: true, type: 'email' }]}
											>
												<div className="form-input-combo">
													<input
														id="email"
														name="email"
														type="email"
														autoComplete="email"
														required
														placeholder="Email"
													/>
													<button
														disabled={submitted || formHasError}
														type="submit"
														className={submitted || formHasError ? 'secondary-gradient pointer-events-none' : 'secondary-gradient pointer-events-auto'}
													>
														<span>
															<p>
																{submitted && (
																	<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
																)}
																Send Request
															</p>
														</span>
													</button>
												</div>
											</Form.Item>
											{(form.getFieldsError()[0]?.errors.length > 0 ||
												(alert && alert.message)) && (
												<div className="alert callout warning">
													<div className="action">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>
																{form.getFieldsError()[0]?.errors
																	.length > 0
																	? form.getFieldsError()[0]?.errors
																	: alert && alert.message
																	? alert.message
																	: ''}
															</h6>
														</div>
													</div>
												</div>
											)}
										</Form>
                                    </div>
								)}
								{(statusType === statusTypes.reset && verified) && (
									<ResetPassword email={email} token={token} />
								)}
								<p>
									Need additional help?{' '}
									<a href="https://support.common.space">Get support here.</a>
								</p>
							</div>
						</div>
					</div>
					<div className="cs-centerframe-split-right order-1 sm:order-2">
						<div className="lg:pl-half-gutter">
							<img className="sm:max-h-element" src={dj_bg_img} />
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};
export default memo(ForgotPassword);
