import { postRequest, getRequest } from './base';

export const getSpacePosts = async (params) => {
    return getRequest('/api/space/posts/get', params);
};

export const getSpacePost = async (params) => {
    return getRequest('/api/space/post/get', params);
};

export const createSpacePost = async (params) => {
    return postRequest('/api/space/post/create', params);
};

export const updateSpacePost = async (params) => {
    return postRequest('/api/space/post/update', params);
};

export const deleteSpacePost = async (params) => {
    return postRequest('/api/space/post/delete', params);
};

export const getSpaceChannelPosts = async (params) => {
    return getRequest('/api/space/channel/posts/get', params);
};


export const userUpdateHeartPost = async (data) => {
    return postRequest('/api/user/update/heart', data);
};

export const userUpdateUnheartPost = async (data) => {
    return postRequest('/api/user/update/unheart', data);
};


export const userHeartPost = async (data) => {
    return postRequest('/api/user/post/heart', data);
};

export const userUnheartPost = async (data) => {
    return postRequest('/api/user/post/unheart', data);
};

export const userPinPost = async (data) => {
    return postRequest('/api/user/post/pin', data);
};

export const userUnpinPost = async (data) => {
    return postRequest('/api/user/post/unpin', data);
};