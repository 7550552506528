import { memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import moment from 'moment/moment';

const MemberBenefits = (parentProps) => {
	const { data, user, spaceData } = parentProps;

	let assetType = 'image';
	if (data.video) {
		assetType = 'video';
	}

	return (
		<>
			{data && data.id ? (
				// 	<div className="card-updates">
				// 		{assetType === 'image' ? (
				// 			<img src={data.photo} />
				// 		) : (
				// 			<ReactPlayer
				// 				playing={true}
				// 				loop={true}
				// 				controls={false}
				// 				muted={true}
				// 				className="videoPreview"
				// 				url={data.video}
				// 			/>
				// 		)}
				// 		<div className="flex items-center justify-between mt-4">
				// 			<p className="body-text--small">
				// 				{moment(data.created_on).format('LL')}
				// 			</p>
				// 			{/*<p className="update-tag color-lightest">Blog</p>*/}
				// 		</div>
				// 		<h5 className="header-title color-reg mt-4">{data.name}</h5>
				// 		<p className="body-text--smallest">{data.summary}</p>
				// 		{!user ? (
				// 			<>
				// 				<a className="btn btn-secondary btn-md mt-5">
				// 					Login to&nbsp;
				// 					<i className="fa-solid fa-unlock"></i>
				// 					&nbsp;Unlock
				// 				</a>
				// 			</>
				// 		) : (
				// 			<>
				// 				<a className="btn btn-secondary btn-md mt-5">Read More</a>
				// 			</>
				// 		)}
				// 	</div>
				<article className="cs-excerpt-benefit block-md stack-y" itemScope itemType="http://schema.org/BlogPosting">
					<a href="#" target="_self">
						{assetType === 'image' ? (
							<img className="card" alt="Description of image" itemProp="image" src={data.photo} />
						) : (
							<ReactPlayer
								playing={true}
								loop={true}
								controls={false}
								muted={true}
								className="videoPreview"
								url={data.video}
							/>
						)}
					</a>
					<div className="cs-stack-subtitle-published stack-row">
						<time itemProp="datePublished" dateTime="2024-03-15">{moment(data.created_on).format('LL')}</time>
						<a href="#" itemProp="articleSection" className="chip-gradient">Music</a>
					</div>
					<hgroup>
						<h4 itemProp="headline"><a href="#" target="_self">{data.name}</a></h4>
						<p itemProp="description">{data.summary}</p>
					</hgroup>
					<div className="cs-stack-fill">
						{!user ? (
							<>
								<a href="#" className="button-secondary-gradient" itemProp="url">
									<span>
										<p>
											<i className="fa-regular fa-lock"/>
											Login to unlock
										</p>
									</span>
								</a>
							</>
						) : (
							<>
								<a
									className="button-secondary-gradient"
								>
										<span>
											<p>
												Read More
											</p>
										</span>
								</a>
							</>
						)}
					</div>
				</article>
			) : (
				<>
					{spaceData && spaceData.viewing_user_is_owner && (
						<>
							{/*<div className="col-4">*/}
								<div className="card-updates space-placeholder flex items-center justify-center">
									<h6 className="header-title text-center">
										Create Custom Benefits for Members <br />
										from your Dashboard
									</h6>
								</div>
							{/*</div>*/}
						</>
					)}
				</>
			)}
		</>
	);
};

export default memo(MemberBenefits);
