import React, {memo, useCallback, useEffect, useState} from "react";
import DropdownWithItems from "../../../../../components/commons/DropdownWithItems";
import {RENEW_MANAGE_PAYMENT_OPTIONS, RENEW_PAYMENT_OPTIONS} from "../../../../../constants/space";
import {autoRenewParser, getExpirationDateData, getSelectedMonth} from "../../../../../components/commons/helpers/MembershipItemHelpers";
import DropdownWithCards from "../../../../../components/commons/DropdownWithCards";
import {getSpaceMembership} from "../../../../../services/space";
import {createStripePaymentIntent} from "../../../../../services/payment_method";
import {VALIDATE_MESSAGES} from "../../../../../constants/common";
import {Button, Form} from "antd";
import routes from "../../../../../routes";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";

const RenewPaymentMethod = (parentProps) => {
    const { user, space, membershipID, isMembershipBenefit, status } = parentProps;
    const [selectedCard, setSelectedCard] = useState('default');
    const [selectedPaymentOptions, setSelectedPaymentOptions] = useState('one-time');
    const [selectedMonthlyPrice, setSelectedMonthlyPrice] = useState();
    const [selectedMonthlyPriceLabel, setSelectedMonthlyPriceLabel] = useState();
    const [autoMonthlyPrice, setAutoMonthlyPrice] = useState([]);
    const [membershipDetails, setMembershipDetails] = useState(null);
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);
    const [purchaseBtnTitle, setPurchaseBtnTitle] = useState('test');
    const [isInitialize, setInitialize] = useState(true);
    const [isParseOnce, setParseOnce] = useState(true);
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();

    // Expired Recurring, Active Recurring No Auto-Renew, Inactive Recurring

    /**
     * Returns the membership details
     * @type {(function(string, string): Promise<void>)|*}
     */
    const getMembership = useCallback(async (space_id, membership_id) => {
        const data = {
            space_id: space_id,
            membership_id: membership_id,
            is_get_redemption: true
        }

        if (user) {
            data['viewing_user_id'] = user.id;
        }

        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            // setIsPaid(membershipData.is_paid);
            setMembershipDetails(membershipData);
            setMembershipLoaded(true);
            // if (membershipData.photo || membershipData.video) {
            //     if (membershipData.photo) {
            //         const bg = {
            //             backgroundImage: 'url(' + membershipData.photo + ')',
            //             backgroundPosition: 'center',
            //             backgroundRepeat: 'no-repeat',
            //             backgroundSize: 'cover',
            //         };
            //         setCollectionAssetBGImage(bg);
            //         setCollectionAssetType('image');
            //     }
            //     if (membershipData.video) {
            //         setCollectionAssetType('video');
            //     }
            // } else {
            //     const bg = {
            //         backgroundImage: 'url(' + default_items_img + ')',
            //         backgroundPosition: 'center',
            //         backgroundRepeat: 'no-repeat',
            //         backgroundSize: 'cover',
            //     };
            //     setCollectionAssetBGImage(bg);
            //     setCollectionAssetType('image');
            // }
        }
    }, []);


    const handleSubmit = useCallback(
        async (values) => {
            if (membershipDetails != null) {

                if (selectedCard.value === 'default')
                    return;

                try {
                    setSubmitted(true);
                    const data = values;
                    // setDisplayDropDownError(true);

                    data.is_subscription_enable = membershipDetails.enable_expiration_and_subscription;
                    data.space_id = space.id;
                    data.card_type = 'card';
                    data.card_cvc = data.cvc;
                    data.membership_id = membershipID;
                    data.is_onetime_renew = selectedPaymentOptions === 'one-time';
                    data.is_renew = true;

                    if (membershipDetails.enable_expiration_and_subscription && membershipDetails.first_benefit) {
                        data.amount = (selectedMonthlyPrice === 'Auto-Renew Off' ? 0 : selectedMonthlyPrice);
                        data.membership_fee = parseFloat((membershipDetails.price === null ? 0 : membershipDetails.price));
                        data.currency = membershipDetails.first_benefit.currency.toLowerCase();
                        data.included_months = membershipDetails.first_benefit.included_months;
                        data.recurring_months = getSelectedMonth(selectedMonthlyPrice, autoMonthlyPrice);
                        data.start_date = getExpirationDateData(data.included_months, true);
                        // console.log('data.start_date: '+data.start_date);

                        if (user) {
                            data.user_id = user.id;
                            data.stripe_id = selectedCard.cardObject.id;

                            const response = await createStripePaymentIntent(data);
                            if (response && response.result) {
                                setSubmitted(false);
                                navigate(routes.spaces());
                                // await proceedPaymentCompleted();
                            } else {
                                setSubmitted(false);
                            }
                        } else {
                            setSubmitted(false);
                        }
                    }

                } catch (error) {
                    setSubmitted(false);
                    console.log(error);
                }
            }
        },
        [selectedCard, membershipDetails, selectedMonthlyPrice, autoMonthlyPrice, selectedPaymentOptions]
    );

    useEffect(()=> {
        if (!isMembershipLoaded) {
            if (space && space.id && user && user.id) {
                getMembership(space.id, membershipID).then((r) => {
                });
            }
        }

        if (membershipDetails != null && isParseOnce){
            setParseOnce(false);
             setAutoMonthlyPrice(autoRenewParser(membershipDetails, false, true));
        }

        if (autoMonthlyPrice.length > 0 && isInitialize) {
            setInitialize(false);
            setSelectedMonthlyPrice(autoMonthlyPrice[0].value);
            setSelectedMonthlyPriceLabel(autoMonthlyPrice[0]);
        }

        if (selectedMonthlyPriceLabel != null) {
            const price = selectedMonthlyPriceLabel.currency_symbol + selectedMonthlyPriceLabel.value +' '+ selectedMonthlyPriceLabel.currency;
            const months = selectedMonthlyPriceLabel.months;
            const totalMonths = months+` ${months > 1 ?' Months':' Month'}`;
            if (status === 'expired') {
                if (selectedPaymentOptions==='one-time'){
                    setPurchaseBtnTitle('Purchase '+ totalMonths +' for '+ price +' Total');
                } else {
                    setPurchaseBtnTitle('Purchase '+ totalMonths +' for '+price+' Total with Recurring Auto-Renew every '+totalMonths);
                }
            } else {
                if (selectedPaymentOptions==='one-time'){
                    setPurchaseBtnTitle('Purchase '+ totalMonths +' for '+ price +' Total');
                } else {
                    const isExpiration = membershipDetails != null &&  membershipDetails.redemption != null && membershipDetails.redemption.expiration_on != null;
                    let expiredDate = '';
                    if (isExpiration) {
                        const expirationDate = membershipDetails.redemption.expiration_on
                        const date = new Date(expirationDate);
                        expiredDate =  format(date, 'MMMM d, yyyy');
                    }
                    setPurchaseBtnTitle('Authorize Auto-Renew every '+ totalMonths +' for '+price+` Total ${isExpiration ? ' starting '+ expiredDate :''} `);
                }
            }
        }

    },[membershipDetails, selectedMonthlyPrice, selectedMonthlyPriceLabel, purchaseBtnTitle, autoMonthlyPrice, selectedPaymentOptions, status])

    return(
        <>
            {isMembershipLoaded && (
                <>
                    <div className={`manage-renew-payment ${isMembershipBenefit ? 'benefit' : ''} `} >
                        <Form
                            form={form}
                            name="form"
                            validateMessages={VALIDATE_MESSAGES}
                            // onFocus={() => {
                            //     setFormIsOnFocus(true);
                            //     setAlert(null);
                            // }}
                            // onBlur={() => {
                            //     setTimeout(() => {
                            //         setFormIsOnFocus(false);
                            //         handleFormOnBlur();
                            //     }, 200);
                            // }}
                            autoComplete="off"
                            // onChange={handleFormChanges}
                            onFinish={handleSubmit}
                            // className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                        >
                            <div className="grid grid-cols-12 gap-8 items-center pt-2">

                                <div className="col-span-4 ">
                                    <div className="p-left-right">
                                        <p className="body-text--small color-lightest">
                                            Choose how many Months to Renew
                                        </p>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <p className="body-text--small color-lightest">
                                            Select Recurring or One-Time
                                        </p>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <p className="body-text--small color-lightest">
                                            Select Payment Method
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-8 items-center pt-2">
                                <div className="col-span-4 ">
                                    <div className="p-left-right">
                                        <div className="grid grid-cols-12 gap-8 my-3 flex items-center">
                                            <div className="input-group-com">
                                                <DropdownWithItems
                                                    dropDownClass="w-full"
                                                    customButtonClass="btn-md btn-secondary dropdown-primary"
                                                    title="Credit Card"
                                                    selectedValue={selectedMonthlyPrice}
                                                    isRenew={true}
                                                    isCharge={false}
                                                    listItems={autoMonthlyPrice}
                                                    onDropdownChange={(item) => {
                                                        setSelectedMonthlyPrice(item.value);
                                                        setSelectedMonthlyPriceLabel(item);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <div className="grid grid-cols-12 gap-8 my-3 flex items-center">
                                            <div className="input-group-com">
                                                <DropdownWithItems
                                                    dropDownClass="w-full"
                                                    isRenew={true}
                                                    isAddNew={false}
                                                    isCharge={true}
                                                    customButtonClass="btn-md btn-secondary dropdown-primary"
                                                    title="Credit Card"
                                                    selectedValue={selectedPaymentOptions}
                                                    listItems={status==='active' ? RENEW_MANAGE_PAYMENT_OPTIONS : RENEW_PAYMENT_OPTIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedPaymentOptions(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <div className="grid grid-cols-12 gap-8 my-3 flex items-center">
                                            <div className="input-group-com">
                                                <DropdownWithCards
                                                    dropDownClass="w-full"
                                                    isAddNew={false}
                                                    customButtonClass="btn-md btn-secondary dropdown-primary"
                                                    selectedValue={selectedCard}
                                                    listItems={user.payment_methods}
                                                    icon={'card-ico fa fa-brands fa-cc-visa'}
                                                    onDropdownChange={(value) =>
                                                        setSelectedCard(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="grid grid-cols-12 gap-8 items-center pt-2">
                                <div className="col-span-4 ">
                                    <div className="p-left-right">
                                        <p className="body-txtitalic--small color-lightest">
                                            Subject to Membership Terms & Details
                                        </p>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <p className="body-txtitalic--small color-lightest">
                                            Learn more about renewing access
                                        </p>
                                    </div>
                                </div>

                                <div className="col-span-4">
                                    <div className="p-left-right">
                                        <p className="body-txtitalic--small color-lightest">
                                            Add New Payment Method
                                        </p>
                                    </div>
                                </div>

                                <Button className="btn btn-tertiary btn-md mt-5"
                                        onClick={handleSubmit}
                                        disabled={submitted}
                                >
                                    {submitted && (
                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                    )}
                                    <span>{purchaseBtnTitle}</span>
                                </Button>
                            </div>

                        </Form>

                    </div>
                </>
            )}
        </>


    );
}

export default memo(RenewPaymentMethod);