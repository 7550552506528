import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT } from '../../../../constants/profile';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { updateProfileVisibility } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { getListIcon } from '../../../../utils/list';

const ManageContact = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [selectedVisibilitySettings, setSelectedVisibilitySettings] = useState({
		email: 'default'
	});
	const [classSize, setClassSize] = useState('md');
	const { screenSize } = useSelector((state) => state.classSize);

	const handleChanges = useCallback(
		(field, value) => {
			setSelectedVisibilitySettings((prev) => ({ ...prev, [field]: value }));

			if (authUserInfo && authUserInfo.profile.visibility) {
				const data = {
					user_id: authUserInfo.id,
					field: field,
					value: value
				};

				updateRequest(data).then((r) => {});
			}
		},
		[authUserInfo]
	);

	const updateRequest = async (data) => {
		try {
			const result = await updateProfileVisibility(data);
			if (result && result.result) {
				dispatch(getAuthUserInfo());
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (authUserInfo.profile.visibility) {
				setSelectedVisibilitySettings((prev) => ({
					...prev,
					email: authUserInfo.profile.visibility.email
				}));
			}
		}
		setClassSize(screenSize < 1300 ? 'sm' : 'md');
		document.title = 'Commonspace Profile Manage Contact';
	}, [loading, authUserInfo, classSize, screenSize]);

	return (
		<div className="cs-dash-card block-full card">
			<hgroup className="heading">
				<h2>Profile Contact Settings</h2>
				<p>
					You can enable Contact options on your profile that will click to your email
					address. This is completely optional but can be useful for businesses or
					creators.
				</p>
			</hgroup>
			<div className="cs-stack-controlrows stack-form-col">
				<div className="formrow">
					<p>Email Button</p>
					<p>
						If visible, your profile will show a Contact Button that will
						click through to your account email. This is hidden by default.
					</p>
					<DropdownWithItems
						title="Email Button"
						customButtonClass="button gradient secondary distribute w-full"
						icon={getListIcon(
							VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
							selectedVisibilitySettings.email
						)}
						selectedValue={selectedVisibilitySettings.email}
						listItems={VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
						onDropdownChange={(value) => handleChanges('email', value)}
					/>
				</div>
			</div>
		</div>
		// <>
		// 	<div className="col-span-12">
		// 		<div className="dashboard-wrapper mt-4">
		// 			<h3 className="p-1 header-medium">Profile Contact Settings</h3>
		// 			<p className="pt-3 body-txtitalic--small">
		// 				You can enable Contact options on your profile that will click to your email
		// 				address. This is completely optional but can be useful for businesses or
		// 				creators.
		// 			</p>
		// 			<div className="grid grid-cols-12 gap-2 md:gap-8 flex items-center pt-5">
		// 				<div className="col-span-12 md:col-span-2">
		// 					<label className="body-text--small gradient-color-txt">
		// 						Email Button
		// 					</label>
		// 				</div>
		// 				<div className="col-span-12 md:col-span-7">
		// 					<p className="body-txtitalic--smallest m-0">
		// 						If visible, your profile will show a Contact Button that will
		// 						clickthrough to your account email. This is hidden by default.
		// 					</p>
		// 				</div>
		// 				<div className="col-span-12 md:col-span-3">
		// 					<div className="dropdown ">
		// 						<DropdownWithItems
		// 							title="Email Button"
		// 							customButtonClass={"btn-secondary btn-size btn-"+classSize}
		// 							icon={getListIcon(
		// 								VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
		// 								selectedVisibilitySettings.email
		// 							)}
		// 							selectedValue={selectedVisibilitySettings.email}
		// 							listItems={VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
		// 							onDropdownChange={(value) => handleChanges('email', value)}
		// 						/>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </>
	);
};

export default memo(ManageContact);
