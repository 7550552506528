import { getLocalStorage, removeLocalStorage, setLocalStorage } from './storage';
import { STORAGE_KEYS } from '../constants/common';
import { checkTokenIsExpired } from './jwt';

export const checkIsLoggedIn = () => {
	const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
	const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));

	if (checkTokenIsExpired(accessToken)) {
		if (checkTokenIsExpired(refreshToken)) {
			removeAccessToken();
		} else {
			return 'request-refresh';
		}
		return false;
	} else {
		if (!accessToken) {
			return false;
		}
		return true;
	}
};

export const setAccessToken = (data) => {
	setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, data.access_token);
	setLocalStorage(STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token);
};

export const removeAccessToken = () => {
	removeLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
	removeLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
};

export const getAccessToken = () => {
	// console.log('getAccessToken: '+checkIsLoggedIn())
	if (checkIsLoggedIn()) {
		const accessToken = JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN));
		if (accessToken) {
			return JSON.parse(accessToken);
		}
	}
	return '';
};

export const useScript = (url) => {
	const script = document.createElement('script');

	script.src = url;
	script.async = true;

	document.body.appendChild(script);

	return () => {
		document.body.removeChild(script);
	};
};


// Check the domain if it's local or not and return the current domain
export const getDomainUrl = () => {
	const hostname = window.location.hostname;
	const isLocal = hostname === 'localhost' || hostname === '127.0.0.1';
	const protocol = isLocal ? 'http://' : 'https://';
	const port = isLocal ? ':3004' : '';
	return `${protocol}${hostname}${port}`;
}