import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { updateProfileAboutMe, getProfileAboutMe } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import './EditAboutSection.scss';
import routes from '../../../../routes';
import {getSitewideLink} from "../../../../utils/sitewideLinks";

const EditAboutSection = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [quillValue, setQuillValue] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [isFetching, setIsFetching] = useState(false);

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
		[{ font: [] }],
		[{ header: 1 }, { header: 2 }], // custom button values
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
		[{ direction: 'rtl' }], // text direction
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],
		['clean'] // remove formatting button
	];

	const saveChanges = useCallback(() => {
		if (authUserInfo && authUserInfo.profile) {
			const data = {
				user_id: authUserInfo.id,
				about_me: quillValue
			};

			setSubmitted(true);
			updateRequest(data).then((r) => {});
		}
	}, [authUserInfo, quillValue]);

	const updateRequest = async (data) => {
		try {
			const result = await updateProfileAboutMe(data);
			if (result && result.result) {
				dispatch(getAuthUserInfo());
			}
			setSubmitted(false);
		} catch (error) {
			console.log(error);
			setSubmitted(false);
		}
	};

	const getAboutRequest = async (data) => {
		try {
			if (!quillValue) {
				setIsFetching(true);
			}

			const result = await getProfileAboutMe(data);
			if (result && result.result) {
				setQuillValue(result.about_me);
			}
			setIsFetching(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			const data = {
				user_id: authUserInfo.id
			};
			getAboutRequest(data).then((r) => {});
		}

		document.title = 'Commonspace Profile Edit About Section';
	}, [loading, authUserInfo]);

	return (
		<>
			{authUserInfo && (
				<div className="col-span-12 edit-about-section">
					<div className="dashboard-wrapper mt-4">
						<h3 className="header-medium">Edit About Section</h3>
						<p className="pt-3 body-txtitalic--small">
							Your{' '}
							<Link to="" className="gradient-color-txt">
								About Section
							</Link>{' '}
							appears on your{' '}
							<Link to="" className="gradient-color-txt">
								Profile
							</Link>{' '}
							and you can add custom content and details here. Please make sure to
							follow our{' '}
							{/*<Link to="" className="gradient-color-txt">*/}
							{/*	Community Guidelines*/}
							{/*</Link>*/}
							{getSitewideLink('community_guidelines')}
							{' '}and{' '}
							{/*<Link to="" className="gradient-color-txt">*/}
							{/*	Terms of Service*/}
							{/*</Link>*/}
							{getSitewideLink('terms_of_service')}
							.
						</p>
						<div className="row pt-5">
							<div className="col text-editor-area">
								{isFetching && (
									<>
										<div className="loader">
											<div className="background"></div>
											<i className="fa-solid fa-spin fa-spinner spinner"></i>
										</div>
									</>
								)}
								<ReactQuill
									theme="snow"
									modules={{
										toolbar: toolbarOptions
									}}
									value={quillValue}
									onChange={setQuillValue}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 items-center pt-5">
							<div className="col-span-12 md:col-span-6">
								<Link
									target="_blank"
									className="button-secondary-gradient w-full"
									to={
										routes.viewUserSpace() +
										authUserInfo.profile.username +
										'/about'
									}
								>
									<span><p>View About Section</p></span>
								</Link>
							</div>
							<div className="col-span-12 md:col-span-6">
								<Button
									className="primary-gradient w-full"
									disabled={submitted}
									onClick={saveChanges}
								>
									<span><p>
										{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
										Save
									</p></span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default memo(EditAboutSection);
