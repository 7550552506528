import {ALLOWED_UPLOAD_SIZE_LETTERS, IMAGE_FILETYPES, IMAGE_STANDARD_FILE_UPLOAD_SIZE, IMAGE_STANDARD_MAX_HEIGHT, IMAGE_STANDARD_MAX_WIDTH, IMAGE_STANDARD_MIN_HEIGHT, IMAGE_STANDARD_MIN_WIDTH, VIDEO_FILETYPES, VIDEO_STANDARD_FILE_UPLOAD_SIZE, VIDEO_STANDARD_MAX_HEIGHT, VIDEO_STANDARD_MAX_WIDTH, VIDEO_STANDARD_MIN_HEIGHT, VIDEO_STANDARD_MIN_WIDTH} from '../constants/file';

export const fileToBase64 = (file, cb) => {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		cb(reader.result);
	};
	reader.onerror = function (error) {
		console.log('Error: ', error);
	};
};

export const getBackgroundFileTypeFromUrl = (url) => {
	const type = url.split(/[#?]/)[0].split('.').pop().trim();
	const type_with_dot = '.' + type;
	if (IMAGE_FILETYPES.includes(type_with_dot)) {
		return 'image';
	}
	if (VIDEO_FILETYPES.includes(type_with_dot)) {
		return 'video';
	}
};

export const formatFileSize = (bytes, decimalPoint) => {
	if (bytes === 0) return '0 Bytes';
	let k = 1000,
		dm = decimalPoint || 2,
		sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const convertUrlToFile = async (url) => {
	try {
		const filename = 'image.jpg'; // Replace with your desired filename
		const mimeType = 'image/jpeg'; // Replace with the correct MIME type of your image

		// Fetch the image data from the provided URL
		const res = await fetch(url, { mode: 'cors' }); // Add 'mode: cors' to handle CORS
		if (!res.ok) {
			throw new Error('Failed to fetch image data');
		}

		// Convert the response data to a Blob
		const blob = await res.blob();

		// Create a File object with the Blob data
		const file = new File([blob], filename, { type: mimeType });

		console.log('File created:', file);
		return file;
	} catch (error) {
		console.error('Error converting URL to file:', error.message);
		// Handle the error (e.g., show an error message to the user)
		return null; // Return null or handle the error appropriately
	}
};

export const getFileExtension = (file, is_url = false) => {
	let file_name = null;
	if (file) {
		if (!is_url) {
			file_name = file.name.split('.');
		} else {
			file_name = file.split('.');
		}
		let file_type = file_name[file_name.length - 1];
		if (file_type.includes('?')) {
			return file_type.split('?')[0];
		}
		return file_name[file_name.length - 1];
	}
	return file_name;
};

export const checkImageValidity = async (file, setAlert = null) => {
	let img = new Image();
	img.src = URL.createObjectURL(file);
	await img.decode();
	let width = img.width;
	let height = img.height;
	let passed = false;
	let error_message = null;
	let minHeightError = false;
	let maxHeightError = false;
	if (IMAGE_STANDARD_MIN_HEIGHT) {
		if (height < IMAGE_STANDARD_MIN_HEIGHT) {
			error_message = 'Minimum height ' + IMAGE_STANDARD_MIN_HEIGHT + 'px';
			minHeightError = true;
		} else {
			passed = true;
		}
	}
	if (IMAGE_STANDARD_MIN_WIDTH) {
		if (width < IMAGE_STANDARD_MIN_WIDTH) {
			if (!minHeightError) {
				error_message = 'Minimum width ' + IMAGE_STANDARD_MIN_WIDTH + 'px';
			}
		} else {
			passed = true;
		}
	}
	if (IMAGE_STANDARD_MAX_HEIGHT) {
		if (height > IMAGE_STANDARD_MAX_HEIGHT) {
			error_message = 'Maximum height ' + IMAGE_STANDARD_MAX_HEIGHT + 'px';
			maxHeightError = true;
			passed = false;
		}
	}
	if (IMAGE_STANDARD_MAX_WIDTH) {
		if (width > IMAGE_STANDARD_MAX_WIDTH) {
			if (!maxHeightError) {
				error_message = 'Maximum width ' + IMAGE_STANDARD_MAX_WIDTH + 'px';
			}
			passed = false;
		}
	}
	if (!passed && error_message && setAlert) {
		setAlert({
			variant: 'danger',
			message: error_message
		});
		return false;
	}
	return checkFileSizeValidity(file, IMAGE_STANDARD_FILE_UPLOAD_SIZE);
};

export const checkFileSizeValidity = (file, uploadSizeLimit, setAlert = null) => {
	const fileSize = formatFileSize(file.size);
	const byteSizeValue = fileSize.split(' ')[0];
	const byteSizeLetter = fileSize.split(' ')[1];
	if (uploadSizeLimit) {
		if (!ALLOWED_UPLOAD_SIZE_LETTERS.includes(byteSizeLetter)) {
			setAlert({
				variant: 'danger',
				message: 'File exceeds max upload size ' + uploadSizeLimit
			});
			return false;
		}
		if (byteSizeLetter === 'MB') {
			const byteSizeValueLimit = parseFloat(uploadSizeLimit.split(' ')[0]);
			if (parseFloat(byteSizeValue) > byteSizeValueLimit) {
				setAlert({
					variant: 'danger',
					message: 'File exceeds max upload size ' + uploadSizeLimit
				});
				return false;
			}
		}
	}
	return true;
};


export const checkVideoValidity = async (file, setAlert = null) => {
	const dimensions = await getVideoDimensions(file);
	if (dimensions) {
		const height = dimensions[1];
		const width = dimensions[0];
		if (height && VIDEO_STANDARD_MIN_HEIGHT && width && VIDEO_STANDARD_MIN_WIDTH) {
			if (height < VIDEO_STANDARD_MIN_HEIGHT || width < VIDEO_STANDARD_MIN_WIDTH) {
				const dimensionStr = VIDEO_STANDARD_MIN_HEIGHT + 'px x ' + VIDEO_STANDARD_MIN_WIDTH + 'px';
				setAlert({
					variant: 'danger',
					message: 'Minimum dimension ' + dimensionStr
				});
				return false;
			}
		}
		if (height && VIDEO_STANDARD_MAX_HEIGHT && width && VIDEO_STANDARD_MAX_WIDTH) {
			if (height > VIDEO_STANDARD_MAX_HEIGHT || width > VIDEO_STANDARD_MAX_WIDTH) {
				const dimensionStr = VIDEO_STANDARD_MAX_HEIGHT + 'px x ' + VIDEO_STANDARD_MAX_WIDTH + 'px';
				setAlert({
					variant: 'danger',
					message: 'Maximum dimension ' + dimensionStr
				});
				return false;
			}
		}
	}
	return checkFileSizeValidity(file, VIDEO_STANDARD_FILE_UPLOAD_SIZE, alert);
};

export const getVideoDimensions = async (file) => {
	return new Promise((res) => {
		const url = URL.createObjectURL(file);
		const video = document.createElement('video');
		video.onloadedmetadata = (evt) => {
			// Revoke when you don't need the url any more to release any reference
			URL.revokeObjectURL(url);
			res([video.videoWidth, video.videoHeight]);
		};
		video.src = url;
		video.load(); // fetches metadata
	});
};