import { memo, useEffect } from 'react';

import routes from '../../routes';

const Support = () => {
	useEffect(() => {
		document.title = 'Commonspace Support';
	}, []);

	return (
		<main id="cs-space-main" className="cs-main main-theme">
			<section id="cs-space-guidelines-c1" className="p-section-safeview theme-transparent">
				<div className="centerframe-grid gap-2gutter">
					<article className="cs-support-article block-full order-1">
						<div className="cs-stack-title-icons stack-row-wrap">
							<h2>
								<span>Commonspace </span> Support
							</h2>
							<div className="cs-stack-link-icons stack-row">
								<a
									href="#xtwitter"
									target="_blank"
									className="button-icon-round-gradient"
								>
									<span>
										<p>
											<i className="fa-brands fa-x-twitter"></i>
										</p>
									</span>
								</a>
								<a
									href="#instagram"
									target="_blank"
									className="button-icon-round-gradient"
								>
									<span>
										<p>
											<i className="fa-brands fa-instagram"></i>
										</p>
									</span>
								</a>
								<a
									href="#facebook"
									target="_blank"
									className="button-icon-round-gradient"
								>
									<span>
										<p>
											<i className="fa-brands fa-facebook-f"></i>
										</p>
									</span>
								</a>
								<a
									href="#linkedin"
									target="_blank"
									className="button-icon-round-gradient"
								>
									<span>
										<p>
											<i className="fa-brands fa-linkedin-in"></i>
										</p>
									</span>
								</a>
							</div>
						</div>
						<div className="cs-support-content-body card stack-col">
							{/* <div
								id="terms-of-use"
								className="container mx-auto min-h-screen body-text--small pt-10 pb-10"
							>
								<p className="body-text--big font-bold mt-5 mb-5">Terms of Use</p>

								<p className="mt-5 mb-5">
									<p className="body-text--small mt-5 mb-5">
										Last Updated Date: June 1, 2024
									</p>
								</p>

								<p>
									You accept and comply to these Terms of Use{' '}
									<span className="font-bold">(“Terms” or “Agreement”)</span> by
									using the website located at the domain common.space and all
									related subdomains (collectively,{' '}
									<span className="font-bold">“Site”</span>) and various related
									services (together known as the{' '}
									<span className="font-bold">“Services”</span>), all operated by
									Commonspace Platforms, Inc. (referred to as{' '}
									<span className="font-bold">
										“Commonspace,” “Company”, “us,” “our,” or “we”
									</span>
									); by agreeing to any other written agreement between
									Commonspace and you or your company; or by selecting{' '}
									<span className="font-bold">“Agree”</span> where you are given
									the option to do so. In addition, when using particular
									Services, you shall be subject to any posted guidelines or rules
									applicable to such Services that may contain terms and
									conditions in addition to those in these Terms. All such
									guidelines or rules are hereby incorporated by reference into
									this Agreement. All of these collective Terms, guidelines and
									rules, form an agreement between Commonspace and you.
								</p>
								<br />
								<p>
									By completing any Site registration process or using the Site,
									you agree to be immediately bound by these Terms. If you do not
									accept the terms of this Agreement, you may not use this Site
									and its Services. If you are accepting this Agreement on behalf
									of a company or other legal entity, regardless of whether you
									are a principal, employee, authorized agent or third-party
									service provider, you represent and warrant that you have the
									legal authority to bind that company or legal entity by the
									terms of this Agreement. If at any time you do not wish to be
									bound by these Terms, you must discontinue your use of the Site.
									Your agreement with us regarding compliance with these Terms
									becomes effective immediately upon commencement of your use of
									the Site.
								</p>
								<br />
								<p>
									Material Terms: As provided in greater detail in this Agreement
									(and without limiting the express language of this Agreement),
									you acknowledge the following:
								</p>
								<br />
								<ul className="list-disc ml-10">
									<li>
										You consent to the collection, use, and disclosure of your
										information in accordance with the Commonspace’s posted{' '}
										<a
											className="font-bold"
											href={routes.privacyPolicy()}
											target="_blank"
										>
											Privacy Policy
										</a>{' '}
										which is incorporated into these Terms by reference;
									</li>
									<li>
										The Site is provided “as is” without warranties of any kind
										and Commonspace’s liability to you is limited; and
									</li>
									<li>
										We will resolve disputes arising under these Terms through
										binding arbitration. By accepting this Term of Use, you and
										Commonspace are each waiving the right to a trial by jury or
										to participate in a class action.
									</li>
								</ul>
								<br />
								<p>
									Moreover, the Company is not a securities exchange, broker,
									financial institution, or creditor and does not in any way
									warrant or represent itself as such.
								</p>

								<div className="flex">
									<ol className="list-decimal ml-5 gap-y-5">
										<li className="mt-5">
											Definitions.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													“Content” means any text, image, photograph,
													audiovisual work, sound recording, musical work
													(including when embodies in a sound recording or
													audiovisual work) and/or other work subject to
													protection under the laws of the United States
													or any foreign jurisdiction, including, without
													limitation, patent, trademark, trade secret
													and/or copyright laws;
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Commonspace User Account.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													Consideration. Commonspace currently provides
													you with free access to the Site as a user. In
													return for enjoying this free access, you
													acknowledge and agree that Commonspace may
													generate revenues, increase goodwill or
													otherwise increase the value of Commonspace from
													your use of the Site, and you will have no right
													to share in any such revenues, goodwill or value
													whatsoever.
												</li>
												<li>
													Site Registration. While you always may view the
													public-facing portions of the Site without
													registering an account with us, you must
													register such an account to enjoy the full
													benefits and functionalities of the Site
													(“Account”). You agree to provide us with
													accurate contact information when prompted
													during your account registration, and to
													promptly provide us with any changes in such
													information.
												</li>
												<li>
													Eligibility. IF YOU ARE UNDER 13 YEARS OF AGE,
													THEN YOU MUST NOT USE OR ACCESS THE SITE AT ANY
													TIME IN ANY MANNER. If you have been previously
													suspended or removed from the Site by
													Commonspace for any reason, you must not use or
													access the Site in any other capacity.
													Furthermore, by using the Site, you affirm that
													either you are at least 18 years of age or have
													been authorized to use the Site by your parent
													or legal guardian who is at least 18 years of
													age.
												</li>
												<li>
													Account Security. You are responsible for the
													security of your Commonspace Account, and are
													fully responsible for all activities that occur
													through the use of your log-in credentials to
													Commonspace Site. You agree to notify
													Commonspace immediately at{' '}
													<a
														className="text-blue-500 hover:underline"
														href="mailto:support@common.space"
													>
														support@common.space
													</a>{' '}
													if you suspect or know of any unauthorized use
													of your log-in credentials or any other breach
													of security with respect to your Commonspace
													Account. Commonspace will not be liable for any
													loss or damage arising from unauthorized use of
													your log-in credentials prior to you notifying
													Commonspace of such unauthorized use or loss of
													your log-in credentials to your Commonspace
													Account.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Term and Termination.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													Term. As between you and Commonspace, these
													Terms commence as of your first use of the Site
													and continue until the termination of these
													Terms by either you or Commonspace.
												</li>
												<li>
													Termination. You may cancel your Account at any
													time by sending an email to us at{' '}
													<a
														className="text-blue-500 hover:underline"
														href="mailto:support@common.space"
													>
														support@common.space
													</a>{' '}
													requesting to cancel your account. We may also
													terminate your access to your Account or
													Services, at our sole discretion, at any time
													and without notice to you. Upon any termination,
													discontinuation or cancellation of Services or
													your Account, all provisions of these Terms,
													which by their nature should survive, will
													survive, including, without limitation,
													ownership provisions, warranty disclaimers,
													limitations of liability, and dispute resolution
													provisions.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											License Grants and Intellectual Property Rights.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													License. Subject to these Terms, Commonspace
													grants you a personal, revocable, non-exclusive
													and non-transferable license during the Term to
													permit you to access and use the Site and/or its
													Services in accordance with these Terms.
												</li>
												<li>
													Content. Subject to these Terms, you grant to
													Commonspace a perpetual, transferrable,
													irrevocable, royalty-free, fully paid-up,
													worldwide and fully sublicensable license to
													access, collect, store and use any data,
													information, records and files that (i) you
													load, transmit to or enter into the Site, or
													(ii) we collect from your local computer system
													or from third-parties with your permission, and
													(in each case) including all results from
													processing such data, including compilations,
													and derivative works thereof solely for the
													purpose of: (A) providing, marketing, or
													promoting Commonspace’s Site and Services, (B)
													complying with applicable law, and (C)
													Commonspace’s reasonable audit and data
													retention policies. Commonspace may use your
													public-facing information (including but not
													limited to your name, brand, or logo, where
													applicable) on our Site and marketing materials.
													You may opt out from our use of your Content at
													any time by sending us an email request to
													Commonspace customer support at{' '}
													<a
														className="text-blue-500 hover:underline"
														href="mailto:support@common.space"
													>
														support@common.space
													</a>
													.
												</li>
												<li>
													Marks. The Commonspace trademarks, service
													marks, and logos (collectively, the “Commonspace
													Trademarks”) used and displayed on the Site are
													Commonspace’s registered and/or unregistered
													trademarks or service marks. Any other product
													and service names located on the Site may be
													trademarks or service marks owned by third
													parties (collectively with the Commonspace
													Trademarks, the “Trademarks”). Except as
													otherwise permitted by law, you may not use the
													Trademarks to disparage Commonspace or any third
													party, Commonspace’s or a third party’s products
													or services, or in any manner (using
													commercially reasonable judgment) that may
													damage any goodwill in the Trademarks. You may
													not use any Trademarks without the prior written
													permission of Commonspace, except solely to
													perform activities and obligations contemplated
													under these Terms, or as otherwise set out in
													these Terms.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Facilitated Payments and Withholding Consent.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													When Commonspace facilitates the transmission of
													any payments from the buyer to the seller in
													connection with a Purchase Transaction (each, a
													“Facilitated Payment”), Commonspace may in its
													sole discretion withhold from any such
													Facilitated Payment any amounts that are payable
													to Commonspace by the applicable seller in
													connection with that Purchase Transaction
													(“Fees”).
												</li>
												<li>
													Commonspace is not a trustee or fiduciary to any
													party in respect to any Facilitated Payments or
													any Purchase Transaction. Commonspace reserves
													the right, at its sole discretion, to place a
													hold on a Facilitated Payment, if Commonspace
													determines that the funds may be subject to a
													breach of applicable law (e.g. fraud or money
													laundering), or if Commonspace is required to
													cooperate with law enforcement, or if
													Commonspace is in the midst of resolving
													disputes between parties of the related Purchase
													Transaction.
												</li>
												<li>
													Commonspace currently uses a third party payment
													processor to collect Facilitated Payment from
													buyers and to pay sellers. By receiving these
													Facilitated Payments, you agree to its terms and
													conditions of use. You acknowledge and agree
													that Commonspace has no liability with regard to
													any fees or problems you may have with our
													payment processor, in connection with receiving
													any Facilitated Payments.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Confidentiality.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													You agree not to disclose any Commonspace
													Confidential Information without our prior
													written consent. “Commonspace Confidential
													Information” includes:
													<ol className="list-lower-roman list-outside ml-5">
														<li>
															the existence of beta features of the
															Site and Services;
														</li>
														<li>
															all Commonspace technologies, software,
															and documentation related to the Site
															and Services;
														</li>
														<li>
															all statistics relating to performance
															as pertaining to the Site and Services;
														</li>
														<li>
															any other information provided by
															Commonspace which is reasonably
															understood to be confidential based on
															the circumstances or nature of
															disclosure. Commonspace Confidential
															Information does not include information
															that was independently developed by you
															without reference to Commonspace
															Confidential Information, any
															information that is in the public domain
															without breach, information known to you
															prior to your use of our Services, or
															information that was lawfully provided
															to you by a third party.
														</li>
													</ol>
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Advertisements.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													Some of the Services may be supported by
													advertising revenue and may display
													advertisements and promotions. These
													advertisements may be targeted to Content,
													user-generated content, queries, or other
													information. The manner, mode, and extent of
													advertising by or permitted by us on the Site
													are subject to change without specific notice to
													you. In consideration for our granting you
													access to and use of the Site and Services, you
													agree that we may place such advertising on the
													Site or Services.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Messages, Requests, or Other Comments or Content to or
											for Us.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													The Site may provide you with the ability to
													contact us through email addresses, contact
													forms, or other means. To the extent that you do
													so and send along any ideas, input, feedback, or
													anything of similar nature, you grant us a
													perpetual, irrevocable, nonexclusive,
													royalty-free license to use any such ideas,
													input, feedback, or anything of similar nature
													to change our existing products or services, to
													develop new products or services, or otherwise
													in our business.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Restrictions on Use of the Site.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													You may use the Site for purposes expressly
													permitted by the Site. You acknowledge and agree
													that Commonspace may suspend your use of the
													Site, without notice, in the event that
													Commonspace, in its sole discretion, suspects
													that you are in violation of any provision of
													these Terms or any applicable law. As a
													condition of your use of the Site and related
													Services, you represent and warrant to us that
													you will not use the Site or Services for any
													purpose that is prohibited by these terms,
													conditions, and notices and will do none of the
													following:
													<ol className="list-lower-roman list-outside ml-5">
														<li>
															Conduct or promote any illegal
															activities, including, but not limited
															to, intellectual property infringement,
															while using the Site or Services;
														</li>
														<li>
															Transmit any information that we believe
															to be, in our sole discretion, abusive,
															excessively violent, harassing,
															defamatory, vulgar, obscene, lewd,
															lascivious, or otherwise objectionable;
														</li>
														<li>
															Attempt to reverse engineer or
															jeopardize the correct functioning of
															the Site or Services, or otherwise
															attempt to derive the source code of the
															software (including the tools, methods,
															processes, and infrastructure) that
															enables or underlies the Site;
														</li>
														<li>
															Attempt to gain access to secured
															portions of the Site or Services to
															which you do not possess access rights
															or obtain or attempt to obtain any
															materials, content, or information
															through any means not intentionally made
															available or provided for through the
															Site or Services;
														</li>
														<li>
															Upload or transmit any form of virus,
															worm, Trojan horse, or other malicious
															code;
														</li>
														<li>
															Use any automatic or manual process to
															search or harvest information from the
															Site or Services, or to interfere in any
															way with the proper functioning of the
															Site and Services;
														</li>
														<li>
															Use the Site or Services in any manner
															which could disable, overburden, damage,
															or impair the Site or interfere with any
															other party’s use and enjoyment of the
															Site; or
														</li>
														<li>
															Co-brand the Site without the express
															prior written permission of an
															authorized representative of
															Commonspace. For purposes of these
															Terms, “co-branding” means to display a
															name, logo, trademark, or other means of
															attribution or identification of any
															party in such a manner as is reasonably
															likely to give a user the impression
															that such other party has the right to
															display, publish, or distribute the Site
															or content accessible within the Site.
														</li>
													</ol>
													<li>
														You agree to cooperate with Commonspace in
														causing any of the prohibited activities set
														forth in this section to cease immediately.
													</li>
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Third Party Sites.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													Commonspace may enable you to link your
													Commonspace Account with a valid account on a
													third party social networking, email or content
													service including but not limited to Facebook,
													Instagram, YouTube, TikTok, LinkedIn, or X
													(formerly known as Twitter), (such service, a
													“Third-Party Service” and each such account, a
													“Third-Party Account”) by allowing Commonspace
													to access your Third-Party Account, as is
													permitted under the applicable terms and
													conditions that govern your use of each
													Third-Party Account.
												</li>
												<li>
													This Site may contain links to sites of Third
													Party Services which are not maintained by, or
													related to, Commonspace. Links to such sites are
													provided as a convenience to users. Commonspace
													is not responsible for, and makes no
													representations or warranties about, the
													content, completeness, or accuracy of these
													links or any materials on the sites hyper-linked
													to this Site.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Geographic Restrictions.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													The Site is controlled and operated by
													Commonspace from its offices in the State of
													California in the United States. We make no
													claims that the Site or any of its content is
													accessible or appropriate outside of the United
													States. Access to the Site may not be legal by
													certain persons or in certain countries. If you
													access the Site from outside the United States,
													you do so on your own initiative and are
													responsible for compliance with local laws.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Warranties And Disclaimers.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													You represent and warrant that (i) you have full
													power and authority to enter into and be bound
													by these Terms; (ii) you are legally authorized
													to act on behalf of another person or a
													corporate entity; (iii) Commonspace has never
													previously terminated or otherwise disabled a
													Commonspace account created by you due to your
													breach of these Terms or another agreement with
													Commonspace or due to impermissible activity;
													(iv) entering into or performing under these
													Terms will not violate any agreement you have
													with a third party of any third-party rights;
													and (v) all the information provided by you to
													Commonspace is current and correct.
												</li>
												<li>
													YOUR USE OF THIS SITE AND ACCEPTANCE OF CONTENT
													IS SOLELY AT YOUR OWN RISK. THE SITE AND
													SERVICES ARE PROVIDED “AS IS” AND WITHOUT
													WARRANTIES OF ANY KIND, EITHER EXPRESSED OR
													IMPLIED. COMMONSPACE DISCLAIMS ALL WARRANTIES,
													INCLUDING ANY IMPLIED WARRANTIES OF
													MERCHANTABILITY, FITNESS FOR A PARTICULAR
													PURPOSE, TITLE, OR NON-INFRINGEMENT. COMMONSPACE
													DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT
													CONTAINED IN THIS SITE WILL BE UNINTERRUPTED OR
													ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR
													THAT THIS SITE OR THE SERVER THAT MAKES IT
													AVAILABLE ARE FREE OF VIRUSES OF OTHER HARMFUL
													COMPONENTS. COMMONSPACE DOES NOT WARRANT OR MAKE
													ANY REPRESENTATION REGARDING USE, OR THE RESULT
													OF USE, OF THE CONTENT IN TERMS OF ACCURACY,
													RELIABILITY, OF OTHERWISE AND IS NOT LIABLE FOR
													ANY CONTENT THAT ARE POSTED BY USERS. THE
													CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR
													TYPOGRAPHICAL ERRORS, AND COMMONSPACE MAY MAKE
													CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND
													NOT COMMONSPACE, ASSUME THE ENTIRE COST OF ALL
													NECESSARY SERVICING, REPAIR OR CORRECTION IN THE
													EVENT OF ANY LOSS OR DAMAGE TO YOU OR YOUR
													PROPERTY ARISING FROM THE USE OF THIS SITE OR
													ITS CONTENT.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Limitation of Liability.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													COMMONSPACE, ITS SUBSIDIARIES, AFFILIATES,
													LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS,
													EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL
													NOT BE LIABLE FOR ANY INCIDENTAL, INDIRECT,
													PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL,
													EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF
													REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL
													DISTRESS, OR SIMILAR DAMAGES, EVEN IF
													COMMONSPACE HAS BEEN ADVISED OF THE POSSIBILITY
													OF SUCH DAMAGES. IN NO EVENT, WILL THE
													COLLECTIVE LIABILITY OF COMMONSPACE AND ITS
													SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE
													PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS,
													OFFICERS, AND DIRECTORS TO ANY PARTY (REGARDLESS
													OF THE FORM OF ACTION, WHETHER IN CONTRACT,
													TORT, OR OTHERWISE) EXCEED THE AMOUNT OF FEES
													PAID OR PAYABLE TO COMMONSPACE BY THE SELLER (AS
													DEDUCTED FROM FACILITATED PAYMENTS) FROM THE
													PURCHASE TRANSACTION RESULTING IN THE EVENT
													GIVING RISE TO SUCH LIABILITY, AT COMMONSPACE’S
													DISCRETION. IN ALL CASES, COMMONSPACE AS WELL AS
													ANY PARTY WITH ACTIVE ACCOUNTS ON THE SITE, WILL
													NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT
													REASONABLY FORESEEABLE. EACH PARTY ACKNOWLEDGES
													THAT THE OTHER PARTY HAS AGREED TO THESE TERMS
													IN RELIANCE ON THE LIMITATION OF LIABILITY
													STATED HEREIN AND THAT THOSE LIMITATIONS ARE IN
													ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE
													PARTIES.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Indemnification.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													You will indemnify and hold Commonspace, its
													subsidiaries, affiliates, licensors, content
													providers, service providers, employees, agents,
													officers, directors, and contractors (the
													“Indemnified Parties”) harmless from any claims
													or liabilities due arising out of or related to
													your access to the Site, any Services provided
													to you, your alleged breach of these Terms, and
													alleged violations of applicable law through
													your access of the Site or your use of the
													Services. You agree that the Indemnified Parties
													will have no liability in connection with any
													such breach, Content on the Site, or
													unauthorized use, and you agree to indemnify any
													and all resulting loss, damages, judgments,
													awards, costs, expenses, and attorneys’ fees of
													the Indemnified Parties in connection therewith.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Security.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													You are prohibited from using any Services or
													facilities provided in connection with the Site
													to compromise security or tamper with system
													resources and/or accounts. The use or
													distribution of tools designed for compromising
													security (e.g., password guessing programs,
													cracking tools or network probing tools) is
													strictly prohibited. If you become involved in
													any violation of system security, Commonspace
													reserves the right to release your details to
													system administrators at other sites in order to
													assist them in resolving security incidents.
													Commonspace reserves the right to investigate
													suspected violations of these Terms.
												</li>
												<li>
													Commonspace reserves the right to fully
													cooperate with any law enforcement authorities
													or court order requesting or directing
													Commonspace to disclose the identity of anyone
													posting any messages or Content, or publishing
													or otherwise making available any materials that
													are believed to violate these Terms. BY
													ACCEPTING THIS AGREEMENT, YOU WAIVE AND HOLD
													COMMONSPACE HARMLESS FROM ANY CLAIMS RESULTING
													FROM ANY ACTION TAKEN BY COMMONSPACE DURING OR
													AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM
													ANY ACTIONS TAKEN AS A CONSEQUENCE OF
													INVESTIGATIONS BY EITHER COMMONSPACE OR LAW
													ENFORCEMENT AUTHORITIES.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											No Waiver.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													No delay or failure by Commonspace to enforce
													any of these Terms shall constitute a waiver of
													any of our rights under these Terms. Neither the
													receipt of any funds by Commonspace nor the
													reliance of any person on our actions shall be
													deemed to constitute a waiver of any part of
													these Terms. Only a specific, written waiver
													signed by an authorized representative of
													Commonspace shall have any legal effect.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Severability.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													If any clause or provision set forth in this
													Terms statement is determined to be illegal,
													invalid or unenforceable under present or future
													law, the clause or provision shall be deemed to
													be deleted without affecting the enforceability
													of all remaining clauses or provisions.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Governing Law, Jurisdiction, Jury Trial Waiver, Class
											Action Waiver and Venue.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													These Terms, and any disputes arising under or
													related to these Terms or to the Site, will be
													governed by the laws of the State of California,
													without reference to its conflict of law
													principles. In addition, you acknowledge and
													agree that you irrevocably waive your rights to
													a trial by jury in any action or proceeding
													arising out of or relating to this Agreement or
													the transactions relating to its subject matter.
													For any disputes that are not resolved through
													good faith negotiations or for any declaratory
													relief, including, but not limited to,
													injunctions or temporary restraining orders
													sought by either you or Commonspace, such
													disputes shall be resolved by binding
													arbitration in Los Angeles, California,
													administered by the American Arbitration
													Association under its Commercial Arbitration
													Rules then in effect, and you expressly waive
													all objections to jurisdiction and venue for
													such matters. YOU UNDERSTAND AND AGREE THAT, BY
													ENTERING INTO THESE TERMS, YOU AND COMMONSPACE
													ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR
													TO PARTICIPATE IN A CLASS ACTION AND THAT THIS
													AGREEMENT SHALL BE SUBJECT TO AND GOVERNED BY
													THE FEDERAL ARBITRATION ACT.
												</li>
												<li>
													Exceptions. Notwithstanding Section (a) above,
													nothing in these Terms will be deemed to waive,
													preclude, or otherwise limit the right of either
													party to: (i) bring an individual action in
													small claims court; (ii) pursue an enforcement
													action through the applicable federal, state, or
													local agency if that action is available; (iii)
													seek injunctive relief in aid of arbitration
													from a court of competent jurisdiction; or (iv)
													to file suit in a court of law to address an
													intellectual property infringement claim.
												</li>
												<li>
													These English-language Terms are Commonspace’s
													official agreement for using the Site and
													accessing its Services. In case of any
													inconsistency between these English-language
													Terms statement and its translation into another
													language, this English-language document shall
													control.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Miscellaneous.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													Independent Contractor. Commonspace and you are
													independent contractors and these Terms do not
													create or suggest any affiliation, association,
													partnership, agency, partnership, or joint
													venture between the parties. Neither party shall
													represent itself or act as the associate,
													partner, agent or joint venturer of the other
													party in any way whatsoever. This Agreement does
													not create any third-party beneficiary rights.
													In any action to enforce these Terms, the
													prevailing party will be entitled to costs and
													any fees associated with such enforcement. Any
													cause of action brought by you against
													Commonspace or Commonspace’s affiliates must be
													instituted within one after the cause of action
													arises or be deemed forever waived and barred.
													You may not assign your rights and obligations
													under these Terms to any third party, and any
													purported attempt to do so shall be null and
													void. Commonspace may freely assign its rights
													and obligations under these Terms.
												</li>
												<li>
													Force Majeure. In addition to any excuse
													provided by applicable law, Commonspace shall be
													excused from liability for non-delivery or delay
													in delivery or availability of Services arising
													from any event beyond Commonspace’s reasonable
													control, whether or not foreseeable by either
													party, including, but not limited to, labor
													disturbance, war, fire, accident, adverse
													weather, or changes in law, treaties, rulings,
													regulations, decisions or requirements of any
													governmental, administrative or regulatory
													agency, Internet disturbance, and other causes
													or events, whether or not similar to those which
													are enumerated above.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Consent to Electronic Communications.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													By using the Site, you consent to receiving
													certain electronic mail from us to the address
													you provide to us during Account registration,
													for operational purposes, including but not
													limited to account management, customer service,
													and system maintenance, and to provide you with
													information about Commonspace, the Site and/or
													Purchase Transaction. We may also communicate
													with you using this or other electronic means to
													help us collect more optional information about
													you in order to optimize your usage of the Site.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Changes to this Agreement.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													At any time, Commonspace reserves the right, in
													its sole discretion, to modify, alter, or
													otherwise change these Terms as well as other
													posted agreements that contain guidelines or
													rules applicable to related Services on
													Commonspace that may contain terms and
													conditions in addition to those in these Terms.
													We may provide notice of such change on the Site
													or by other means as determined in our sole
													discretion. Please review the Terms and/or
													additional terms periodically for changes. Your
													continued use of the Site and the Services
													constitute your acceptance of and agreement to
													be bound by these changes without limitation,
													qualification or change. If at any time you
													determine that you do not accept these changes,
													then your sole remedy is to stop using the Site.
												</li>
											</ol>
										</li>
										<li className="mt-5">
											Contact Us.
											<ol className="list-lower-alpha list-outside ml-5 mt-5">
												<li>
													If you would like to contact us in connection
													with these Terms or your use of the Site, then
													please contact us at 6121 Sunset Blvd, Los
													Angeles, CA 90028 or email us at{' '}
													<a
														className="text-blue-500 hover:underline"
														href="mailto:support@common.space"
													>
														support@common.space
													</a>
													.
												</li>
											</ol>
										</li>
									</ol>
								</div>
							</div> */}
							<p>SUPPORT CONTENT GOES HERE.</p>
						</div>
					</article>

					<div id="cs-space-support-links" className="grid-base col-span-full order-2">
						<article className="cs-featured-support block-md card">
							<a href="fam-space-faq.html" className="rounded-card p-card">
								<hgroup>
									<p>
										<i className="fa-solid fa-comments-question-check"></i>
									</p>
									<h4>FAQ</h4>
								</hgroup>
							</a>
						</article>

						<article className="cs-featured-support block-md card">
							<a href="fam-space-contact.html" className="rounded-card p-card">
								<hgroup>
									<p>
										<i className="fa-solid fa-envelope"></i>
									</p>
									<h4>Contact</h4>
								</hgroup>
							</a>
						</article>

						<article className="cs-featured-support block-md card">
							<a href="fam-space-support.html" className="rounded-card p-card">
								<hgroup>
									<p>
										<i className="fa-solid fa-life-ring"></i>
									</p>
									<h4>Support</h4>
								</hgroup>
							</a>
						</article>
					</div>
				</div>
			</section>
		</main>
	);
};

export default memo(Support);
