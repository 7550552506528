import {memo, useCallback, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from '../../../../../routes';
import { shortenNumber } from '../../../../../utils/number';
import default_profile_card_bg from '../../../../../assets/images/card_bg.png';

const Card = (parentProps) => {
	const { col, type, data, onEventClick, displayButtons } = parentProps;
	const { authUserInfo, loading } = useSelector((state) => state.general);

	const background =
		data && data.user.profile.background_photo
			? data.user.profile.background_photo
			: default_profile_card_bg;
	const sectionStyle = {
		backgroundImage: 'url(' + background + ')'
	};

	const handleFollowUnfollowUser = useCallback(async () => {
		let event = 'follow';
		if (data.user.is_following) {
			event = 'unfollow';
		}
		onEventClick(type, event, data.user.id);
	}, [type, data.user]);

	useEffect(() => {}, [loading, authUserInfo]);

	return (
		<>
			<div className={col}>
				<div className="card-wrapper" style={sectionStyle}>
					{data && data.user.profile.photo_thumb ? (
						<img
							className="rounded-circle flex justify-center object-cover"
							alt="thumb"
							src={data.user.profile.photo_thumb}
						/>
					) : (
						<div className="rounded-circle no-user-profile-photo flex justify-center">
							<i className="fa-solid fa-user"></i>
						</div>
					)}
					<h6 className="text-center mt-2">{'@' + data.user.profile.username}</h6>
					<div className="flex justify-evenly mt-3">
						<i className="fa-solid fa-user-group"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.followers_count)}
						</label>
						<i className="fa-light fa-user-group"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.mutuals_count)}
						</label>
					</div>
					<div className="flex justify-evenly mt-3">
						<i className="fa-light fa-solar-system"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.spaces_count)}
						</label>
						<i className="fa-light fa-grid"></i>{' '}
						<label className="body-text--smallest">
							{shortenNumber(data.user.collections_count)}
						</label>
					</div>
					{authUserInfo && displayButtons && (
						<>
							<div className="flex items-center justify-evenly mt-3">
								<Link to="#" onClick={handleFollowUnfollowUser} className="btn-card-view">
									<i
										className={
											'fa-light ' +
											(data.user.is_following ? 'fa-user-minus' : 'fa-user-plus')
										}
										title={data.user.is_following ? 'Unfollow' : 'Follow'}
									></i>
								</Link>
								<Link
									to={routes.viewUserSpace() + data.user.profile.username}
									className="btn-card-view ms-4"
								>
									<i className="fa-thin fa-arrow-up-right-from-square"></i>
								</Link>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default memo(Card);
