import { useEffect, useMemo, useState } from 'react';

export default function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    if (typeof window !== 'undefined') {
        const observer = useMemo(
            () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
            []
        );

        useEffect(() => {
            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);

    }

    return isIntersecting;
}
