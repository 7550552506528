import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import DropdownWithItems from '../../DropdownWithItems';
import {MANAGE_MEMBERS_FILTER, MANAGE_MEMBERS_SORT} from '../../../../constants/space';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { useSelector } from 'react-redux';
import { getSpaceMemberships } from '../../../../services/space';
import {CustomValidators} from "../../../../utils/CustomValidators";
import FormFieldStacked from "../../FormFieldStacked";
import {MODAL_CUSTOM_STYLE} from "../../../../constants/common";

ReactModal.setAppElement('#root');

const InviteGrantMembershipModal = (props) => {
	const { modalOpen, setModalOpen } = props;
	const [selectedFilter, setSelectedFilter] = useState('default');
	const [selectedMembership, setSelectedMembership] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [membershipsFetching, setMembershipsFetching] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const [membershipsList, setMembershipsList] = useState([]);
	// const [filteredMembership, setFilteredMembership] = useState([
		// { label: 'Select a Membership to Grant', value: 'default', disabled: true }
	// ]);
	const filteredMembership = [
		{ label: 'Select a Membership to Grant', value: 'default', disabled: true },
		...membershipsList.reduce((acc, item) => {
			const data = { label: item.name, value: item.id, disabled: false };
			if (!acc.some(({ label }) => label === data.label)) {
				acc.push(data);
			}
			return acc;
		}, [])
	];

	console.log('filteredMembership')
	console.log(filteredMembership)


	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: {
			background: 'rgba(0, 0, 0, 0.6)',
			zIndex: 888,
		}
	};

	// const filterMembership = () => {
	// 	const newFilteredMembership = [...filteredMembership];
	// 	if (membershipsList != null) {
	// 		membershipsList.forEach((item) => {
	// 			const data = { label: item.name, value: item.space_id };
	// 			if (!newFilteredMembership.some(({ label }) => label === data.label)) {
	// 				newFilteredMembership.push(data);
	// 			}
	// 		});
	// 	}
	// 	setFilteredMembership(newFilteredMembership);
	// };


	useEffect(() => {
		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getMembershipsRequest().then((r) => {});
			}
			setInitialLoad(false);
		}

	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
		filteredMembership,
		selectedMembership,
		membershipsList
	]);

	const getMembershipsRequest = useCallback(
		async (pageNumber = null, filter = null, sort = null) => {
			try {
				if (authUserInfo && spaceInfo) {
					const data = {
						viewing_user_id: authUserInfo.id,
						space_id: spaceInfo.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};
					if (filter) {
						data['filter_type'] = filter['filter_type'];
						data['filter_value'] = filter['filter_value'];
					}
					if (sort) {
						data['sort_value'] = filter['sort_value'];
					}
					setMembershipsFetching(true);
					const response = await getSpaceMemberships(data);
					if (response && response.result) {
						if (response.data) {
							setMembershipsList(response.data);
							// setTotalMembershipsCount(response.total_memberships_count);
							// buildPagination(parseInt(response.page), parseInt(response.page_range));
						} else {
							setMembershipsList(null);
						}
					}
					setMembershipsFetching(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo, spaceInfo]
	);

	const filterDropdownChange = useCallback(async (value) => {
			if (filteredMembership != null) {
				filteredMembership.map((item) => {
					if (item.value === value) {
						setSelectedMembership(item);
					}
				});
				// setFilteredMembership(filteredMembership);
			}

			setSelectedFilter(value);

			// await getMembersRequest(1, data);
		}
		// [authUserInfo, spaceInfo]
	);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={MODAL_CUSTOM_STYLE}
			portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
			overlayClassName="centerframe-flex frame-modal"
			className="block-lg modal-block card"
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="mt-2">Invite & Grant Membership</h3>
			<Form
				// form={form}
				name="loginForm"
				// validateMessages={VALIDATE_MESSAGES}
				// onFocus={() => {
				// 	setFormIsOnFocus(true);
				// }}
				// onBlur={() => {
				// 	setFormIsOnFocus(false);
				// 	handleFormOnBlur();
				// }}
				// autoComplete="off"
				// onChange={handleFormChanges}
				// onFinish={handleSubmit}
				className={'add-category'}
			>

				<div className="form-input">
					<label className="shrink-0">Email or @username</label>
					<Form.Item
						name="email"
						className="mb-0 w-full"
						validateTrigger="onBlur"
						rules={[
							{ required: true },
							{ validator: CustomValidators.emailUsername }
						]}
					>
						<FormFieldStacked
							formType='input'
							type="text"
							// maxLength={32}
							// disabled={submitted}
							// onChange={console.log(form.getFieldsError()[0])}
							placeholder="Enter email or @username to invite someone to your Space."
						/>
						{/*<Input*/}
						{/*	type="text"*/}
						{/*	className="txt-xlong input-md"*/}
						{/*	placeholder="Enter email or @username to invite someone to your Space."*/}
						{/*/>*/}
					</Form.Item>
				</div>
			</Form>

			<div className="row mt-5">
				<DropdownWithItems
					title="Select a Membership to Grant"
					dropDownClass="dropdown-bg"
					customButtonClass="button gradient secondary distribute w-full"
					selectedValue={selectedFilter}
					listItems={filteredMembership}
					onDropdownChange={(value) => filterDropdownChange(value)}
				/>
			</div>
			<p className="body-text--small mt-5">
				You can invite anyone directly to your Space and provide them a Membership for free.
				Once you invite them, the invite cannot be canceled and they will have 7 days to
				redeem the Membership Grant before it expires.
			</p>

			{selectedMembership != null && (
				<button
					className="primary-gradient my-2 w-full"
					// onClick={web3Login}
					type="submit"
					// disabled={submitted}
				>
					<span><p><i className={isLoading ? 'fa-solid fa-spinner fa-spin mr-3' : 'fa-solid mr-3'}/>Invite to {selectedMembership.label}</p></span>
				</button>
			)}
		</ReactModal>
	);
};

export default InviteGrantMembershipModal;
