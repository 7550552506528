import { memo, useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, DatePicker } from 'antd';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES, GOOGLE_MAP_KEY } from '../../../../constants/common';
import { walletSetAccount } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import routes from '../../../../routes';
import dayjs from 'dayjs';
// import './../../Register.scss';
import Autocomplete from 'react-google-autocomplete';
import {getDomainUrl} from "../../../../utils/common";

const WalletConnectedSetAccount = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, email } = props;
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [emptyEmail, setEmptyEmail] = useState(true);
	const [emptyCity, setEmptyCity] = useState(true);
	const [inputElement, setInputElement] = useState();

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		if (form) {
			let emptyEmail = true;
			if (form.getFieldValue('email')) {
				emptyEmail = false;
			}
			setEmptyEmail(emptyEmail);

			let emptyCity = true;
			if (form.getFieldValue('city')) {
				emptyCity = false;
			}
			setEmptyCity(emptyCity);

			await form
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		}
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);

			try {
				setSubmitted(true);
				await setWalletAccount(values);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
			}
		},
		[email, authUserInfo]
	);

	const setWalletAccount = useCallback(
		async (values) => {
			const data = values;
			data.user_id = authUserInfo.id;
			data.birthdate = values.birthdate.format('YYYY-MM-DD');
			// Pass the current api domain to make the api_domain dynamic base on the server domain
			data.api_domain = getDomainUrl();
			const result = await walletSetAccount(data);
			if (result) {
				if (result.result) {
					dispatch(getAuthUserInfo());

					form.resetFields();
					setAlert({
						variant: 'success',
						message: result.message
					});

					if (values.email) {
						navigate(routes.walletSetAccountEmailSent());
					} else {
						navigate(routes.registerMemberProfile());
					}
				} else {
					if (result.message) {
						setAlert({ variant: 'danger', message: result.message });
					} else {
						setAlert({
							variant: 'danger',
							message: 'Something went wrong'
						});
					}
				}
			}

			setSubmitted(false);
		},
		[authUserInfo]
	);

	useEffect(() => {
		if (authUserInfo) {
			if (location.pathname === routes.walletSetAccount() && authUserInfo.email) {
				form.setFieldsValue({
					email: authUserInfo.email
				});
				setEmptyEmail(false);
			}
		}

		const checkValue = (str, max) => {
			if (str.charAt(0) !== '0' || str === '00') {
				var num = parseInt(str);
				if (isNaN(num) || num <= 0 || num > max) num = 1;
				str =
					num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
						? '0' + num
						: num.toString();
			}
			return str;
		};

		const handleInput = (e) => {
			e.target.type = 'text';
			let input = e.target.value;
			if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
			const values = input.split('/').map((v) => v.replace(/\D/g, ''));
			if (values[0]) values[0] = checkValue(values[0], 12);
			if (values[1]) values[1] = checkValue(values[1], 31);
			const output = values
				.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
				.join('')
				.substr(0, 14);
			inputElement.value = output;

			const inputDate = output;
			const dateParts = inputDate.split('/');
			const year = parseInt(dateParts[2], 10);
			const month = parseInt(dateParts[0], 10) - 1;
			const day = parseInt(dateParts[1], 10);
			// const newDate = new Date(year, month, day);

			const isFourDigits = (num) => {
				return /^\d{4}$/.test(num.toString());
			};

			// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
			if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
				form.setFieldValue('birthdate', dayjs(new Date(year, month, day)));
			}
		};

		setInputElement(document.getElementById('registerForm_birthdate'));
		if (inputElement) {
			inputElement.addEventListener('input', handleInput);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleInput);
			}
		};
	}, [authUserInfo, inputElement]);

	return (
		<>
			<h1 className="mb-4 mt-5 header-large">Wallet Connected!</h1>
			<h5>
				Continue your account setup by adding your details below.
				<i className="ms-2">
					We strongly recommend you add an email for full access and benefits.
				</i>
			</h5>
			<Form
				form={form}
				name="registerForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setFormIsOnFocus(false);
					handleFormOnBlur().then(r => {});
				}}
				autoComplete="off"
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
			>
				<>
					{alert && (
						<div className="row my-2">
							<div className="col">
								<Alert
									key={alert.variant}
									variant={alert.variant}
									className={
										'custom-alert ' +
										(alert.variant === 'danger'
											? 'custom-alert-danger'
											: 'custom-alert-success')
									}
								>
									{alert.message}
								</Alert>
							</div>
						</div>
					)}
				</>

				<div className={'row ' + (alert ? 'my-3' : 'my-5')}>
					<div className="col">
						<div className="input-group-com input-border-login">
							<label className="body-text--small">First Name*</label>
							<Form.Item
								label="First Name"
								name="first_name"
								className="mb-0  hidden-label"
								validateTrigger="onBlur"
								rules={[{ required: true }]}
							>
								<Input
									className="input-lg"
									// placeholder="First Name*"
									disabled={submitted}
								/>
							</Form.Item>
						</div>
					</div>
					<div className="col">
						<div className="input-group-com input-border-login">
							<label className="body-text--small">Last Name*</label>
							<Form.Item
								label="Last Name"
								name="last_name"
								className="mb-0 hidden-label"
								validateTrigger="onBlur"
								rules={[{ required: true }]}
							>
								<Input
									className="input-lg"
									// placeholder="Last Name*"
									disabled={submitted}
								/>
							</Form.Item>
						</div>
					</div>
				</div>
				<div className="row my-5">
					<div className="col">
						<div className="input-group-com input-border-login">
							<label className="body-text--small">Birthdate*</label>
							<Form.Item
								label="Birthdate"
								name="birthdate"
								className="mb-0 hidden-label"
								validateTrigger="onBlur"
								rules={[
									{
										type: 'object',
										required: true
									}
								]}
							>
								<DatePicker
									className="input-lg input-birthdate"
									placeholder="MM/DD/YYYY"
									format="MM/DD/YYYY"
								/>
							</Form.Item>
							{/* <span className="tool-tip-sm">MM/DD/YYYY</span> */}
						</div>
					</div>
					<div className="col">
						<h5 className="gradient-color-txt fst-italic mt-3">
							Why do we ask for this?
						</h5>
					</div>
				</div>
				<div className="row my-5">
					<div className="col">
						<div className="input-group-com input-border-login">
							<label className="body-text--small">Enter a city</label>
							<Form.Item
								label="City"
								name="city"
								className="mb-0 hidden-label"
								validateTrigger="onBlur"
								rules={[{ required: true }]}
							>
								<Autocomplete
									apiKey={GOOGLE_MAP_KEY}
									placeholder=""
									style={{
										height: '64px',
										// padding: '15px 30px',
										fontSize: '18px'
									}}
									onPlaceSelected={(place) => {
										form.setFieldValue('city', place.formatted_address);
									}}
								/>
							</Form.Item>
							{emptyCity && (
								<>
									<span className="tool-tip">
										Start typing your City and select from the list. This is not
										publicly visible
									</span>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="input-group-com input-border-login my-5">
					<label className="body-text--small ">Email</label>
					<Form.Item
						label="Email"
						name="email"
						className="mb-0 hidden-label"
						validateTrigger="onBlur"
						rules={[{ type: 'email' }]}
					>
						<Input
							className="input-lg"
							// placeholder="Email"
							disabled={submitted}
						/>
					</Form.Item>
					{emptyEmail && (
						<>
							<span className="tool-tip">
								OPTIONAL - Add your email for account notifications and for account
								recovery.
							</span>
						</>
					)}
				</div>
				<div className="input-group-com my-5">
					<Form.Item className="mb-0">
						<Button
							className="btn border-filled text-white btn-lg"
							type="submit"
							disabled={submitted || formHasError}
						>
							{submitted && <i className="fa-solid fa-spinner fa-spin me-3"></i>}
							Save Account Details
						</Button>
					</Form.Item>
				</div>
			</Form>
		</>
	);
};

export default memo(WalletConnectedSetAccount);
