import { memo } from 'react';

import item_img from '../../../../assets/images/item_img.png'; //Change to Item Image
import space_bg from '../../../../assets/images/item_bg.png'; //Change to Space Background Image

const sample_bg = { backgroundImage: 'url(' + space_bg + ')' };
const ViewItem = () => {
	return (
		<div className="block-full card">
			<div className="dashboard-profile mt-4">
				<div className="grid grid-cols-12 gap-8">
					<div className="col-span-6">
						<div className="card-btn-wrapper">
							<h6 className="header-small mt-4">DRMVRSE Red Rocks 2022 #2</h6>
							<h1 className="header-title color-light">NGHTMRE Red Rocks 2022</h1>
							<p className="mt-4 body-text--small">
								Thank you for attending the first ever DRMVRSE show on April 15,
								2022 at the legendary Red Rocks!! All attendees have the chance to
								mint their own DRMVRSE collectible proof of attendance NFT!! Holding
								this in your digital wallet will provide you with merch discounts,
								meet n greets free tickets, automatic submission for NGHTMRE
								contests and giveaways, and much more!!
							</p>
							<p className="mt-5 body-text--reg color-light">
								Owned by <span className="gradient-color-txt">@mikeprasad</span>
							</p>
							<p className="body-text--reg color-light">
								Created by{' '}
								<span className="gradient-color-txt">NGHTMRE_DRMVRSE</span>
							</p>
							<div className="flex items-center justify-center py-4">
								<a className="btn btn-secondary btn-md m-2">
									<i className="fa-light fa-magnifying-glass-plus"></i>
								</a>
								<a className="btn btn-secondary btn-md m-2">
									<i className="fa-light fa-gift"></i>
								</a>
								<a className="btn btn-secondary btn-md m-2">
									<i className="fa-light fa-download"></i>
								</a>
								<a className="btn btn-secondary btn-md m-2">
									<i className="fa-light fa-list-timeline"></i>
								</a>
								<a className="btn btn-secondary btn-md m-2">
									<i className="fa-light fa-solar-system"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-span-6">
						<div className="profile-card-item" style={sample_bg}>
							<img src={item_img} />
							<div className="bg-overlay"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="dashboard-wrapper mt-5">
				<div className="grid grid-cols-12  gap-8">
					<div className="col-span-3">
						<h5 className="p-1 header-small">Benefits</h5>
					</div>
					<div className="col-span-9">
						<p className="body-text--small text-end">
							Once activated, this Item provides Benefits for{' '}
							<i className="fa-light fa-solar-system"></i>{' '}
							<span className="gradient-color-txt">DRMVRSE</span> Memberships.
						</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-3 ">
						<p className="body-text--small color-light">+5% Off All Merch</p>
					</div>
					<div className="col-span-5">
						<p className="body-txtitalic--smallest">
							Unlimited member bonus discount on all NGHTMRE Merch, exclusively at
							shop.nghtmre.com. Some exceptions may apply.
						</p>
					</div>
					<div className="col-span-4">
						<button className="btn btn-warning btn-md" type="button">
							<i className="fa-light fa-lock"></i>
							<span className="w-3/4">Activate to unlock</span>
							<i className="fa-light fa-arrow-up-right-from-square"></i>
						</button>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-3 ">
						<p className="body-text--small color-light">DRMVRSE Red Rocks Patch</p>
					</div>
					<div className="col-span-5">
						<p className="body-txtitalic--smallest">
							Get a limited edition patch from the NGHTMRE Red Rocks show at the Merch
							Booth at any future NGHTMRE show, while supplies last. (1 Redemption)
						</p>
					</div>
					<div className="col-span-4">
						<button className="btn btn-warning btn-md" type="button">
							<i className="fa-light fa-lock"></i>
							<span className="w-3/4">Activate to unlock</span>
							<i className="fa-light fa-arrow-up-right-from-square"></i>
						</button>
					</div>
				</div>
				<div className="grid-cols-12">
					<div className="col-span-12">
						<button
							className="btn btn-secondary btn-md mt-3"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							Activate Item
						</button>
					</div>
				</div>
			</div>
			<div className="dashboard-wrapper mt-5">
				<div className="grid grid-cols-12  gap-8">
					<div className="col-span-3">
						<h5 className="p-1 header-small">Traits</h5>
					</div>
					<div className="col-span-9">
						<p className="body-text--small text-end">SCRR22</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Collection</p>
					</div>
					<div className="col-span-10">
						<p className="body-text--smallest">NGHTMRE DRMVRSE TOUR</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Summary</p>
					</div>
					<div className="col-span-10">
						<p className="body-text--smallest">
							Registered attendees can claim the Red Rocks Show Card for a free
							DRMVRSE Membership + bonus benefits!
						</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Hidden Content</p>
					</div>
					<div className="col-span-10">
						<div className="item-box w-full">
							<i className="fa-regular fa-eye-slash"></i>&nbsp;
							<p className="body-text--smallest m-0">Click to Reveal</p>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Properties</p>
					</div>
					<div className="col-span-10 flex justify-start">
						<div className="item-box w-1/4 me-4">
							<div className="text-center">
								<span className="body-text--tiny color-reg">Event</span>
								<p className="body-text--smallest m-0">#1 Genesis</p>
								<span className="body-txtitalic--tiny color-light">
									100% have this trait
								</span>
							</div>
						</div>
						<div className="item-box w-1/4 me-4">
							<div className="text-center">
								<span className="body-text--tiny color-reg">Festival</span>
								<p className="body-text--smallest m-0">Red Rocks 2022</p>
								<span className="body-txtitalic--tiny color-light">
									100% have this trait
								</span>
							</div>
						</div>
						<div className="item-box w-1/4">
							<div className="text-center">
								<span className="body-text--tiny color-reg">Location</span>
								<p className="body-text--smallest m-0">Morrison, CO</p>
								<span className="body-txtitalic--tiny color-light">
									100% have this trait
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Dates</p>
					</div>
					<div className="col-span-10 flex justify-start">
						<div className="item-box w-1/4 me-4">
							<div className="text-center">
								<p className="body-text--smallest m-0 color-reg">
									Friday, April 15th, 2021
								</p>
								<span className="body-txtitalic--tiny color-reg">Show Date</span>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12  gap-8 flex tems-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Stats</p>
					</div>
					<div className="col-span-10 flex justify-start">
						<div className="item-box w-1/4 me-4">
							<div className="text-center">
								<span className="body-text--tiny color-reg">Tour Stop</span>
								<p className="body-text--smallest m-0">1</p>
								<span className="body-txtitalic--tiny color-light">Out of 12</span>
							</div>
						</div>
						<div className="item-box w-1/4 me-4">
							<div className="text-center">
								<span className="body-text--tiny color-reg">2022 Show</span>
								<p className="body-text--large m-0">85</p>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Boosts</p>
					</div>
					<div className="col-span-10 flex justify-start">
						<div className="progress-bar me-4">
							<div className="half-arc" style={{ '--percentage': '75%' }}>
								<div className="content">
									<p className="body-text--reg gradient-color-txt m-0">75%</p>
									<p className="body-text--tiny color-reg m-0">Luck Bonus</p>
								</div>
							</div>
						</div>
						<div className="progress-bar me-4">
							<div className="half-arc" style={{ '--percentage': '25%' }}>
								<div className="content">
									<p className="body-text--reg gradient-color-txt m-0">25%</p>
									<p className="body-text--tiny color-reg m-0">Unsound Bonus</p>
								</div>
							</div>
						</div>
						<div className="progress-bar me-4">
							<div className="half-arc" style={{ '--percentage': '100%' }}>
								<div className="content">
									<p className="body-text--reg gradient-color-txt m-0">+5</p>
									<p className="body-text--tiny color-reg m-0">Unsound Bonus</p>
								</div>
							</div>
						</div>
						<div className="progress-bar me-4">
							<div className="half-arc" style={{ '--percentage': '50%' }}>
								<div className="content">
									<p className="body-text--reg gradient-color-txt m-0">+5</p>
									<p className="body-text--tiny color-reg m-0">Rank Bonus</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Rankings</p>
					</div>
					<div className="col-span-10 flex justify-start"></div>
				</div>
			</div>

			<div className="dashboard-wrapper mt-5">
				<div className="grid grid-cols-12  gap-8">
					<div className="col-span-2">
						<h5 className="p-1 header-small">Activity</h5>
					</div>
					<div className="col-span-10">
						<p className="body-text--small text-end">
							<a
								href=""
								className="body-txtitalic--small gradient-color-txt text-end"
							>
								View All
							</a>
						</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="header-title color-light">Event</p>
					</div>
					<div className="col-span-2 ">
						<p className="header-title color-light">By</p>
					</div>
					<div className="col-span-2">
						<p className="header-title color-light">To</p>
					</div>
					<div className="col-span-3">
						<p className="header-title color-light">Basis</p>
					</div>
					<div className="col-span-3">
						<p className="header-title color-light">Date</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Deactivated</p>
					</div>
					<div className="col-span-2 ">
						<p className="body-text--smallest gradient-color-txt">@mikeprasad</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--smallest gradient-color-txt">
							<i className="fa-light fa-solar-system"></i>&nbsp;DRMVRSE
						</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest color-light">drmvrse.nghtmre.com</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest gradient-color-txt">1 day ago</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Activated</p>
					</div>
					<div className="col-span-2 ">
						<p className="body-text--smallest gradient-color-txt">@mikeprasad</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--smallest gradient-color-txt">
							<i className="fa-light fa-solar-system"></i>&nbsp;DRMVRSE
						</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest color-light">drmvrse.nghtmre.com</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest gradient-color-txt">2 weeks ago</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Connected</p>
					</div>
					<div className="col-span-2 ">
						<p className="body-text--smallest gradient-color-txt">mikeprasad.eth</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--smallest gradient-color-txt">@mikeprasad</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest color-light">
							<i className="fa-solid fa-wallet"></i>&nbsp;mikeprasad.eth
						</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest gradient-color-txt">1 month ago</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Transferred</p>
					</div>
					<div className="col-span-2 ">
						<p className="body-text--smallest gradient-color-txt">nghtmredrmvrse.eth</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--smallest gradient-color-txt">mikeprasad.eth</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest color-light">
							<i className="fa-brands fa-ethereum"></i>&nbsp;0.00 ETH
						</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest gradient-color-txt">1 day ago</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small gradient-color-txt">Minted</p>
					</div>
					<div className="col-span-2 ">
						<p className="body-text--smallest color-light">NullAddress</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--smallest gradient-color-txt">nghtmredrmvrse.eth</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest color-light">Unknown</p>
					</div>
					<div className="col-span-3">
						<p className="body-text--smallest gradient-color-txt">7 months ago</p>
					</div>
				</div>
			</div>

			<div className="dashboard-wrapper mt-5">
				<div className="grid grid-cols-12 gap-8">
					<div className="col-span-2">
						<h5 className="p-1 header-small">Details</h5>
					</div>
					<div className="col-span-10">
						<p className="body-text--small text-end">Space & Item Information</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-4">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Connected Space</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">
							<i className="fa-light fa-solar-system"></i>&nbsp;DRMVRSE
						</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Created by</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">nghtmredrmvrse.eth</p>
					</div>
				</div>
				<div className="grid grid-cols-12  gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Space URL</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">drmvrse.nghtmre.comE</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Contract Address</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">0xa063...24d4</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Connected Profile</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">@mikeprasad</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Created by</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">
							<i className="fa-brands fa-ethereum"></i>&nbsp;mikeprasad.eth
						</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Benefits</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">2</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Token ID</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small gradient-color-txt">2</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Connected Space</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">No</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Chain & Standard</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">Ethereum ERC-721</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Account Max</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">None</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Max Count</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">None</p>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-8 flex items-center mt-2">
					<div className="col-span-2 ">
						<p className="body-text--small color-reg">Transferable</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">Yes</p>
					</div>
					<div className="col-span-2">
						<p className="body-text--small color-reg">Creator Royalty</p>
					</div>
					<div className="col-span-4">
						<p className="body-text--small color-light">5%</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ViewItem);
