import {memo, useCallback, useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import {Button, Form, Input, message} from 'antd';
import {VALIDATE_MESSAGES} from '../../../../constants/common';
import {
    updateUserSettings2FASMS,
    updateUserSettings2FAGoogleCode,
    enableOtp,
    getQrString,
    verifyMfaSetup,
    getBackupCodes,
    setupSms,
    checkSmsEnabled,
    disableSms
} from '../../../../services/user';

import badge_iampass from '../../../../assets/images/badge_IAMPASS.png';
import {getAuthUserInfo} from '../../../../states/general/actions';
import routes from '../../../../routes';
import React from 'react';
import QRCode from 'react-qr-code';
import {getSitewideLink} from "../../../../utils/sitewideLinks";

const Manage2FAContent = () => {
    const [phoneSMSEditForm] = Form.useForm();
    const [googleAuthEditForm] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const [submittedPhoneSMS, setSubmittedPhoneSMS] = useState(false);
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [submittedGoogleAuth, setSubmittedGoogleAuth] = useState(false);

    const [show2fa, setShow2fa] = useState(false);
    const [qrString, setQrString] = useState('https://www.common.space/');
    const [backupcode, setBackupcode] = useState(null);
    const [alert, setAlert] = useState(null);

    const authNumber1 = useRef(null);
    const authNumber2 = useRef(null);
    const authNumber3 = useRef(null);
    const authNumber4 = useRef(null);
    const authNumber5 = useRef(null);
    const authNumber6 = useRef(null);

    const enable2fa = async () => {
        if (!authUserInfo?.id) {
            console.log('UserId not found!');
            return;
        }
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await enableOtp(values);
            if (result.result) {
                setShow2fa(true);
                setQrString(result.qr_string);
                await setQR();
                // console.log(message);
            } else {
                // console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const cancel2fa = async () => {
        setShow2fa(false);
    };

    const checkSms2Fa = async () => {
        if (!authUserInfo?.id) {
            console.log('UserId not found!');
            return;
        }
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await checkSmsEnabled(values);
            if (result.result) {
                setSmsEnabled(true);
                // console.log(message);
            } else {
                setSmsEnabled(false);
                // console.log(message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBackupCode = async () => {
        if (!authUserInfo?.id) {
            console.log('UserId not found!');
            return;
        }
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getBackupCodes(values);
            if (result.result) {
                setBackupcode(result.secret_key);
                setAlert({
                    variant: 'success',
                    message: result.secret_key
                });
                // console.log(message);
            } else {
                // console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const downloadBackupCode = async () => {
        if (!authUserInfo?.id) {
            console.log('UserId not found!');
            return;
        }
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getBackupCodes(values);
            if (result.result) {
                setBackupcode(result.secret_key);
                const element = document.createElement('a');
                const file = new Blob([result.secret_key], {
                    type: 'text/plain'
                });
                element.href = URL.createObjectURL(file);
                element.download = 'backupcode.txt';
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
                // console.log(message);
            } else {
                // console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setQR = async () => {
        if (!authUserInfo?.id) {
            console.log('UserId not found!');
            return;
        }
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getQrString(values);
            if (result.result) {
                setQrString(result.qr_string);
                // console.log(message);
            } else {
                // console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submitPhoneSMSEditForm = useCallback(
        async (values) => {
            try {
                if (authUserInfo) {
                    if (!smsEnabled) {
                        setSubmittedPhoneSMS(true);
                        values.user_id = authUserInfo.id;
                        const fasms = updateUserSettings2FASMS(values);
                        const sms = setupSms(values);

                        const result = await fasms;
                        const sms_res = await sms;

                        if (result && sms_res.result) {
                            dispatch(getAuthUserInfo());
                            await checkSms2Fa();
                        }
                        setSubmittedPhoneSMS(false);
                    } else {
                        setSubmittedPhoneSMS(true);
                        values.user_id = authUserInfo.id;
                        const fasms = updateUserSettings2FASMS(values);
                        const sms = disableSms(values);

                        const result = await fasms;
                        const sms_res = await sms;

                        if (result && sms_res.result) {
                            dispatch(getAuthUserInfo());
                            checkSms2Fa();
                        }
                        setSubmittedPhoneSMS(false);
                    }
                }
            } catch (error) {
                console.log(error);
                setSubmittedPhoneSMS(false);
            }
        },
        [authUserInfo, smsEnabled]
    );

    const submitGoogleAuthEditForm = useCallback(
        async (values) => {
            const data = values;
            data.code =
                values.code_num1 +
                '' +
                values.code_num2 +
                '' +
                values.code_num3 +
                '' +
                values.code_num4 +
                '' +
                values.code_num5 +
                '' +
                values.code_num6;

            data.otp = data.code;

            try {
                if (authUserInfo) {
                    setSubmittedGoogleAuth(true);
                    data.user_id = authUserInfo.id;

                    // Allow 2 async to work simultaneously
                    let check_otp = verifyMfaSetup(data);
                    let update2fa = updateUserSettings2FAGoogleCode(data);

                    // Await the return value, not the async function to prevent blocking
                    const result = await update2fa;
                    const result2 = await check_otp;

                    if (result && result2.result) {
                        dispatch(getAuthUserInfo());
                    } else {
                        // OTP not verified
                        // console.log(result2.message);
                    }
                    setSubmittedGoogleAuth(false);
                }
            } catch (error) {
                console.log(error);
                setSubmittedGoogleAuth(false);
            }
        },
        [authUserInfo]
    );

    const onChangeAuthNumber = (e, field) => {
        if (/^-?\d+$/.test(e.target.value)) {
            if (field === 'code_num1') {
                authNumber2.current.focus();
            }
            if (field === 'code_num2') {
                authNumber3.current.focus();
            }
            if (field === 'code_num3') {
                authNumber4.current.focus();
            }
            if (field === 'code_num4') {
                authNumber5.current.focus();
            }
            if (field === 'code_num5') {
                authNumber6.current.focus();
            }
        } else {
            googleAuthEditForm.setFieldsValue({
                [field]: null
            });
        }
    };

    useEffect(() => {
        if (loading && loading === 'done' && authUserInfo) {
            if (!authUserInfo?.auth_2fa_active) {
                navigate(routes.settings());
            } else {
                phoneSMSEditForm.setFieldsValue({
                    phone_sms: authUserInfo.phone_sms
                });
                // Don't pre-fill code
                // if (authUserInfo.auth_2fa_token && authUserInfo.auth_2fa_token.length === 6) {
                //     googleAuthEditForm.setFieldsValue({
                //         code_num1: authUserInfo.auth_2fa_token[0],
                //         code_num2: authUserInfo.auth_2fa_token[1],
                //         code_num3: authUserInfo.auth_2fa_token[2],
                //         code_num4: authUserInfo.auth_2fa_token[3],
                //         code_num5: authUserInfo.auth_2fa_token[4],
                //         code_num6: authUserInfo.auth_2fa_token[5]
                //     });
                // }
            }
            setQR().then(r => {});
            checkSms2Fa().then(r => {});
            setShow2fa(false);
        }
    }, [loading, authUserInfo]);

    return (
        <div className="block-full">
            <div className="col-span-12">
                <div className="block-full card">
                    <div className="dashboard-wrapper mt-4 pt-4">
                        <h3>2 Factor Authentication</h3>
                        <p className="pt-3">
                            Adding 2 Factor Authentication (2FA) or better is strongly recommended for
                            your security. It is also required if you own or manage a Space.{' '}
                            <a className="gradient-color-txt body-txtitalic--small" href="">
                                Learn more about 2FA and how it protects your account.
                            </a>
                        </p>
                    </div>
                </div>

                <div className="block-full card mt-8">
                    <Form
                        form={phoneSMSEditForm}
                        name="phoneSMSEditForm"
                        validateMessages={VALIDATE_MESSAGES}
                        onFinish={submitPhoneSMSEditForm}
                    >
                        <div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
                            <div className="col-span-12  md:col-span-6">
                                <h5>SMS 2 Factor Authentication </h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="text-end">
                                    Use your mobile phone for basic 2FA.
                                </p>
                            </div>
                        </div>


                        <div className="centerframe-flex items-center">
                            <div className="block-lg mt-3">
                                <div className="form-input">
                                    <label>
                                        Phone (SMS)
                                    </label>
                                    <Form.Item
                                        name="phone_sms"
                                        className="mb-0 hidden-label"
                                        rules={[{required: true}]}
                                    >
                                       
                                        <Input className="input-md" disabled={submittedPhoneSMS}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="block-lg">
                                <p className="italic">
                                    This is your phone number that you set up in your{' '}
                                    <a className="gradient-color-txt" href="">
                                        Account Settings.
                                    </a>{' '}
                                    Changing it here will also change it there.
                                </p>
                            </div>

                            <div className="block-md mt-8">
                                <Form.Item>
                                    <Button
                                        className="primary-gradient w-full"
                                        htmlType="submit"
                                        disabled={submittedPhoneSMS}
                                    >

                                        {smsEnabled && 	<span><p>Disable SMS 2FA</p></span>}
                                        {!smsEnabled && <span><p>Set up SMS 2FA</p></span>}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>

                {!show2fa && (
                    <div className="block-full card mt-8">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 md:col-span-6">
                                <h5 className="header-small">Google Authenticator</h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="italic text-end">
                                    Use the Google Authenticator App for 2FA.
                                </p>
                            </div>
                        </div>

                        <div className="centerframe-flex items-center">
                            <div className="block-lg mt-3">
                                <div className="flex gap-x-16">
                                    <button
                                        className="secondary-gradient w-full"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span className="flex items-center">
                                            <i className="fa-brands fa-app-store-ios"/>
                                            <p className="w-full">iOS</p>
                                            <i className="fa-light fa-arrow-up-right-from-square"/>
                                        </span>
                                    </button>
                                    <button
                                        className="secondary-gradient w-full"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                         <span className="flex items-center">
                                            <i className="fa-brands fa-google-play"/>
                                            <p className="w-full">Android</p>
                                            <i className="fa-light fa-arrow-up-right-from-square"/>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="block-lg">
                                <p className="italic">
                                    To get started, first download the app on your phone from your
                                    app store. You can also use your preferred 2FA App if it’s
                                    compatible.
                                </p>
                            </div>
                        </div>
                        <button
                            className="primary-gradient w-full"
                            type="button"
                            onClick={enable2fa}
                        >
                            <span><p>Set up Google 2FA</p></span>
                        </button>
                    </div>
                )}


                {show2fa && (
                    <div className="block-full card !p-0 mt-8">

                        <div className="grid grid-cols-12 google-auth-header px-6 pt-6">
                            <div className="col-span-12 md:col-span-6">
                                <h5 className="header-small">Google Authenticator</h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="italic text-end">Setting up Google 2FA</p>
                            </div>
                        </div>

                        <div className="google-auth-wrapper gradient-color-bg overflow-hidden rounded-b-3xl p-6">
                            <Form
                                form={googleAuthEditForm}
                                name="googleAuthEditForm"
                                validateMessages={VALIDATE_MESSAGES}
                                onFinish={submitGoogleAuthEditForm}
                            >
                                <div className="centerframe-flex items-center">
                                    {/*<div className="md:col-span-5 mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-2">*/}
                                        {/* <img id="qr_code" alt="qr" /> */}
                                        {qrString && (
                                            <QRCode
                                                id="qr_code"
                                                alt="qr"
                                                title="GeeksForGeeks"
                                                value={qrString}
                                                style={{
                                                    height: '136',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                }}
                                                className="block-sm"
                                            />
                                        )}
                                        <div className="block-sm text-white">
                                            <h6 className="text-white">Step 1: Scan QR Code</h6>
                                            <p>
                                                Scan the QR Code with the Google Authenticator App.
                                            </p>
                                            <small>View text code instead</small>
                                        </div>
                                    {/*</div>*/}
                                    <div className="block-md text-white">
                                        <h6 className="text-white">Step 2: Enter 2FA Code</h6>
                                        <div className="flex gap-x-2">
                                            <Form.Item
                                                name="code_num1"
                                                className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num1')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber1}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="code_num2"
                                              className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num2')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber2}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="code_num3"
                                              className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num3')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber3}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="code_num4"
                                              className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num4')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber4}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="code_num5"
                                              className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num5')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber5}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="code_num6"
                                              className="mb-0 hidden-label google-auth-numbers border-b-4"
                                                onChange={(e) => onChangeAuthNumber(e, 'code_num6')}
                                                rules={[{required: true, message: ''}]}
                                            >
                                                <Input
                                                    disabled={submittedGoogleAuth} className="bg-transparent  border-hidden  text-white text-center" 
                                                    maxLength={1}
                                                    ref={authNumber6}
                                                />
                                            </Form.Item>
                                        </div>
                                        <small>Get help setting up 2FA</small>
                                    </div>
                                    <div className="block-sm text-white">
                                        <h6 className="text-white">Step 3: Save</h6>
                                        <Form.Item className="mb-0">
                                            <Button
                                                className="secondary-gradient-alt w-full"
                                                type="submit"
                                                disabled={submittedGoogleAuth}
                                            >
                                                {submittedGoogleAuth && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                <span><p>Save & Confirm</p></span>
                                            </Button>
                                        </Form.Item>
                                        <small onClick={cancel2fa}>Cancel Google 2FA Setup</small>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}


                <div className="block-full card mt-8">
                    <div className="centerframe-flex items-center">
                        <div className="block-lg flex gap-x-4 items-center">
                            <h5>
                                Commonspace Multifactor
                            </h5>
                            <img alt="badge" className="!m-0" src={badge_iampass}/>
                        </div>
                        <div className="block-lg ">
                            <p className="italic text-end">
                                Use Commonspace MultiFactor App by IAMPASS
                            </p>
                        </div>

                        <div className="block-lg mt-3">
                            <div className="flex gap-x-16">
                                <button
                                    className="secondary-gradient w-full"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <span className="flex items-center">
                                        <i className="fa-brands fa-app-store-ios"/>
                                        <p className="w-full">iOS</p>
                                        <i className="fa-light fa-arrow-up-right-from-square"/>
                                    </span>
                                </button>
                                <button
                                    className="secondary-gradient w-full"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                     <span className="flex items-center">
                                        <i className="fa-brands fa-google-play"/>
                                        <p className="w-full">Android</p>
                                        <i className="fa-light fa-arrow-up-right-from-square"/>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="block-lg">
                            <p className="italic m-0">
                                To get started, first download the app on your phone from your app
                                store. This is the most secure method.
                            </p>
                        </div>

                        <button className="primary-gradient w-full mt-8"
                                type="button">
                            <span>
                                <p>Set up Commonspace MFA</p>
                            </span>
                        </button>
                    </div>

                </div>
                {/*<div className="dashboard-wrapper mt-4">*/}
                {/*    <form>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <h5 className="header-small">Backup Codes</h5>*/}
                {/*            </div>*/}
                {/*            <div className="col">*/}
                {/*                <p className="italic text-end">Save your Backup Codes in a secure place. <a className="gradient-color-txt body-txtitalic--small" href="">Learn more.</a></p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col flex justify-center items-center my-4">*/}
                {/*                <button className="btn btn-secondary btn-md btn-disable" disabled type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*                    <i className="fa-light fa-binary"></i><span>View</span><i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*                </button>*/}
                {/*                <button className="btn btn-secondary btn-md ms-5 btn-disable" disabled type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*                    <i className="fa-light fa-file-binary"></i><span>Download</span><i className="fa-light fa-download"></i></i>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*        <div className="col flex items-center">*/}
                {/*            <p className="italic m-0">You have not generated any Backup Codes yet.</p>*/}
                {/*        </div>*/}
                {/*        <button className="btn btn-primary btn-md mt-4 mb-3 btn-warning" type="button">Set up Commonspace MFA</button>*/}
                {/*    </form>*/}
                {/*</div>*/}
                <div className="block-full card mt-8">

                    <div className="centerframe-flex items-center">
                        <div className="block-lg">
                            <h5>
                                Backup Codes
                            </h5>
                        </div>
                        <div className="block-lg ">
                            <p className="italic text-end">
                                Save your Backup Codes in a secure place.{' '}
                                <a className="gradient-color-txt body-txtitalic--small" href="">
                                    Learn more.
                                </a>
                            </p>
                        </div>

                        {backupcode && (
                            <section className={`alert banner ${alert?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
                                <div className="action">
                                    <i className="fa-solid fa-circle-check"/>
                                </div>
                                <div className="content">
                                    <hgroup>
                                        <h6>{alert?.variant === 'danger' ? 'Error' : 'Success'}</h6>
                                        <p>{alert.message}</p>
                                    </hgroup>
                                </div>
                            </section>
                        )}

                        <div className="block-lg mt-3">
                            <div className="flex gap-x-16">
                                <button
                                    className="secondary-gradient w-full"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getBackupCode}
                                >
                                    <span className="flex items-center">
                                        <i className="fa-light fa-binary"/>
                                        <p className="w-full">View</p>
                                        <i className="fa-light fa-arrow-up-right-from-square"/>
                                    </span>
                                </button>
                                <button
                                    className="secondary-gradient w-full"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={downloadBackupCode}
                                >
                                     <span className="flex items-center">
                                        <i className="fa-light fa-file-binary"/>
                                        <p className="w-full">Download</p>
                                        <i className="fa-light fa-download"/>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="block-lg">
                            <p className="italic m-0">
                                You have not generated any Backup Codes yet.
                            </p>
                        </div>

                        <button className="primary-gradient w-full mt-8"
                                type="button">
                             <span>
                                <p>Set up Commonspace MFA</p>
                            </span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default memo(Manage2FAContent);
