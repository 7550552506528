import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {localization, localizationEN, localizationJP} from "../constants/cs_localization";
import {TRANSLATOR_DEBUG} from "../constants/common";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: TRANSLATOR_DEBUG,
        fallbackLng: 'english',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            english: {
                translation: {
                    // here we will place our translations...
                    login_header: localizationEN.login_header,
                    login_header_details: localizationEN.login_header_details,
                    login_2_factor: localizationEN.login_2_factor,
                    login_trouble_logging_in: localizationEN.login_trouble_logging_in,
                    login_reset: localizationEN.login_reset,
                    username_placeholder: localizationEN.username_placeholder,
                    password_placeholder: localizationEN.password_placeholder,
                }
            },
            japanese: {
                translation: {
                    login_header: localizationJP.login_header,
                    login_header_details: localizationJP.login_header_details,
                    login_2_factor: localizationJP.login_2_factor,
                    login_trouble_logging_in: localizationJP.login_trouble_logging_in,
                    login_reset: localizationJP.login_reset,
                    username_placeholder: localizationJP.username_placeholder,
                    password_placeholder: localizationJP.password_placeholder,
                }
            }
        }
    });

export default i18n;