import { memo, useEffect } from 'react';
import DashboardContent from '../Dashboard/DashboardContent';

const Profile = () => {
	useEffect(() => {
		document.title = 'Commonspace Profile';
	}, []);

	return (
		<main id="cs-platform-main" className="cs-main main-theme">
			<section id="cs-platform-dash-dashboard-c1" className="cs-platform-dash px-section-safeview pb-section-safeview pt-gutter theme-transparent">
				<DashboardContent />
			</section>
		</main>
	);
};

export default memo(Profile);
