export const IMAGE_FILETYPES = ['.png', '.jpeg', '.jpg', '.gif', '.svg'];
export const VIDEO_FILETYPES = ['.mov', '.mp4'];
export const AUDIO_FILETYPES = ['.mp3', '.wav', '.ogg', '.webm', '.aac', '.flac'];
export const MIXED_FILETYPES = ['.png', '.jpeg', '.jpg', '.gif', '.svg', '.mov', '.mp4']

export const UPLOAD_FILE_TYPE = {
    AUDIO: 'audio',
    VIDEO: 'video',
    IMAGE: 'image'
};

export const ALLOWED_UPLOAD_SIZE_LETTERS = ['B', 'KB', 'MB'];

// IMAGE upload size
export const IMAGE_STANDARD_FILE_UPLOAD_SIZE = '4 MB';
export const IMAGE_LARGE_FILE_UPLOAD_SIZE = '8 MB';
export const IMAGE_STANDARD_SQUARE_FILE_UPLOAD_SIZE = '4 MB';
export const IMAGE_LARGE_STANDARD_FILE_UPLOAD_SIZE = '8 MB';

// VIDEO upload size
export const VIDEO_SMALL_FILE_UPLOAD_SIZE = '8 MB';
export const VIDEO_STANDARD_FILE_UPLOAD_SIZE = '16 MB';
export const VIDEO_STANDARD_SQUARE_FILE_UPLOAD_SIZE = '12 MB';

// IMAGE min dimensions
export const IMAGE_LOGO_MIN_HEIGHT = 64;
export const IMAGE_LOGO_MIN_WIDTH = 64;
export const IMAGE_LOGO_MAX_HEIGHT = 1920;
export const IMAGE_LOGO_MAX_WIDTH = 1920;

export const IMAGE_STANDARD_MIN_HEIGHT = 640;
export const IMAGE_STANDARD_MIN_WIDTH = 640;

export const IMAGE_LARGE_MIN_HEIGHT = 960;
export const IMAGE_LARGE_MIN_WIDTH = 960;

export const IMAGE_STANDARD_SQUARE_MIN_HEIGHT = 640;
export const IMAGE_STANDARD_SQUARE_MIN_WIDTH = 640;

export const IMAGE_LARGE_SQUARE_MIN_HEIGHT = 960;
export const IMAGE_LARGE_SQUARE_MIN_WIDTH = 960;

// IMAGE max dimensions
export const IMAGE_STANDARD_MAX_HEIGHT = 1920;
export const IMAGE_STANDARD_MAX_WIDTH = 1920;

export const IMAGE_LARGE_MAX_HEIGHT = 3840;
export const IMAGE_LARGE_MAX_WIDTH = 3840;

export const IMAGE_STANDARD_SQUARE_MAX_HEIGHT = 1920;
export const IMAGE_STANDARD_SQUARE_MAX_WIDTH = 1920;

export const IMAGE_LARGE_SQUARE_MAX_HEIGHT = 3840;
export const IMAGE_LARGE_SQUARE_MAX_WIDTH = 3840;

// VIDEO min dimensions
export const VIDEO_SMALL_MIN_HEIGHT = 360;
export const VIDEO_SMALL_MIN_WIDTH = 360;
export const VIDEO_STANDARD_MIN_HEIGHT = 720;
export const VIDEO_STANDARD_MIN_WIDTH = 720;
export const VIDEO_STANDARD_SQUARE_MIN_HEIGHT = 720;
export const VIDEO_STANDARD_SQUARE_MIN_WIDTH = 720;

// VIDEO max dimensions
export const VIDEO_SMALL_MAX_HEIGHT = 1280;
export const VIDEO_SMALL_MAX_WIDTH = 1280;
export const VIDEO_STANDARD_MAX_HEIGHT = 1920;
export const VIDEO_STANDARD_MAX_WIDTH = 1920;
export const VIDEO_STANDARD_SQUARE_MAX_HEIGHT = 1920;
export const VIDEO_STANDARD_SQUARE_MAX_WIDTH = 1920;
