import { createSlice} from "@reduxjs/toolkit";

export const channelSlice = createSlice({
    name: "channel",
    initialState: {
        post: null,
        editPost: null,
        comment: null,
        editComment: null,
        subComment: null,
        editSubComment: null,
        subCommentReply: null,
        editSubCommentReply: null,
        subCommentSubReply: null,
        editSubCommentSubReply: null,
    },
    reducers: {
        setPost: (state, action) => {
            state.post = action.payload;
        },
        setEditPost: (state, action) => {
            state.editPost = action.payload;
        },
        setComment: (state, action) => {
            state.comment = action.payload;
        },
        setEditComment: (state, action) => {
            state.editComment = action.payload;
        },
        setSubComment: (state, action) => {
            state.subComment = action.payload;
        },
        setEditSubComment: (state, action) => {
            state.editSubComment = action.payload;
        },
        setSubCommentReply: (state, action) => {
            state.subCommentReply = action.payload;
        },
        setEditSubCommentReply: (state, action) => {
            state.editSubCommentReply = action.payload;
        },
        setSubCommentSubReply: (state, action) => {
            state.subCommentSubReply = action.payload;
        },
        setEditSubCommentSubReply: (state, action) => {
            state.editSubCommentSubReply = action.payload;
        },
    }
});

export const {
    setPost,
    setEditPost,
    setComment,
    setEditComment,
    setSubComment,
    setEditSubComment,
    setSubCommentReply,
    setEditSubCommentReply,
    setSubCommentSubReply,
    setEditSubCommentSubReply,
} = channelSlice.actions;

export default channelSlice.reducer;