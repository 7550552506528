export const updateSocialLinks = (socialLinks = [], values) => {
    const valueMap = {
        website: values.website,
        instagram: values.instagram,
        youtube: values.youtube,
        tiktok: values.tiktok,
        twitch: values.twitch,
        facebook: values.facebook,
        linkedin: values.linkedin,
        x: values.x,
    };

    if (socialLinks.length === 0) {
        // Insert new items for each social type and link in the valueMap
        return Object.keys(valueMap).map(key => ({
            social_type: key,
            social_link: valueMap[key]
        }));
    }

    return socialLinks.map(socialLink => ({
        ...socialLink,
        social_link: valueMap[socialLink.social_type],
    }));
};