import { memo, useEffect } from 'react';

const Benefits = () => {
	return (
		<div className="block-full card">
			<p>Benefits</p>
		</div>
	);
};

export default memo(Benefits);
