import {memo, useCallback, useState} from "react";
import routes from "../../../routes";
import {Button, Form, Input} from "antd";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import Alert from "react-bootstrap/Alert";
import {isAlphaNumeric} from "../../../utils/form";
import {useSelector} from "react-redux";
import {emailResetPassword} from "../../../services/user";


const ResetPassword = (props) => {
    const {email, token} = props;
    const [form] = Form.useForm();
    const [emptyPass, setEmptyPass] = useState(true);
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const {size} = useSelector((state) => state.classSize);
    const [errorSize, setErrorSize] = useState('sm');
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleFormChanges = useCallback(async () => {
        if (form) {
            let emptyPass = true;
            if (form.getFieldValue('password')) {
                emptyPass = false;
            }
            setEmptyPass(emptyPass);

            await form
                .validateFields()
                .then(() => {
                    setFormHasError(false);
                })
                .catch((errors) => {
                    setFormHasError(true);
                });
        }
    }, []);

    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(async (values) => {

        try {
            const data = values;
            data.email = email;
            data.token = token;
            const response = await emailResetPassword(data);
            if (response && response.result) {
                setAlert({
                    variant: 'success',
                    message: response.message
                });
                setTimeout(() => {
                    window.location.replace(routes.login());
                }, 3000);

            } else {
                setAlert({variant: 'danger', message: response.message});
            }
        } catch (e) {
            setAlert({variant: 'danger', message: 'Something went wrong'});
        }

    }, []);

    return (
        <>
            <Form
                form={form}
                name="form"
                validateMessages={VALIDATE_MESSAGES}
                onFocus={() => {
                    setFormIsOnFocus(true);
                }}
                onBlur={() => {
                    setFormIsOnFocus(false);
                    handleFormOnBlur().then(() => {});
                }}
                autoComplete="off"
                onChange={handleFormChanges}
                onFinish={handleSubmit}
                className={'hide-antd-error-messages'}
            >
                <>
                    {alert && (
                        <div className="grid grid-cols-12 gap-8 my-2">
                            <div className="col-span-12">
                                <section className={`alert banner ${alert?.variant === 'danger' ? 'error' : 'success'} px-section-safeview my-2`}>
                                    <div className="action">
                                        <i className="fa-solid fa-circle-check"/>
                                    </div>
                                    <div className="content">
                                        <hgroup>
                                            <h6>{alert?.variant === 'danger' ? 'Error' : 'Success'}</h6>
                                            <p>{alert.message}</p>
                                        </hgroup>
                                    </div>
                                </section>
                            </div>
                        </div>
                    )}
                </>

                <div className={'input-group-com flex items-center my-5 input-error-' + errorSize}>
                    {/*<label className="text-label body-text--small">Create Password*</label>*/}
                    <Form.Item
                        name="password"
                        className='w-full stack-form-col'
                        validateTrigger="onBlur"
                        rules={[
                            {required: true},
                            ({}) => ({
                                validator(_, value) {
                                    if (value && value.length < 8) {
                                        return Promise.reject(
                                            new Error('Password must be 8 characters long or more.')
                                        ).catch(() => {
                                            setAlert({
                                                variant: 'danger',
                                                message: 'Password must be 8 characters long or more.'
                                            });
                                        });
                                    }
                                    if (isAlphaNumeric(value)) {
                                        setAlert(null);
                                        return Promise.resolve();
                                    }
                                    if (value && value.length > 0) {
                                        return Promise.reject(
                                            new Error('Password is case sensitive, must be at least 8 characters, and include a symbol and number.')
                                        ).catch(() => {
                                            setAlert({
                                                variant: 'danger',
                                                message: 'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
                                            });
                                        });
                                    }
                                    setAlert(null);
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <div className="form-input unlabeled">
                            <label htmlFor="email">Create Password</label>
                            <input id="password" name="password" type="password" required placeholder="Create Password"/>
                        </div>
                        {/*<Input*/}
                        {/*    type="password"*/}
                        {/*    className={'txt-long input-' + size}*/}
                        {/*    disabled={submitted}*/}
                        {/*    autoComplete="new-password"*/}
                        {/*/>*/}
                    </Form.Item>
                    {/*{emptyPass && (*/}
                    {/*    <>*/}
                    {/*        <span className="tool-tip">*/}
                    {/*            Must be a minimum of 8 case-sensitive characters including a symbol*/}
                    {/*            and a number.*/}
                    {/*        </span>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
                <div className="input-group-com  flex items-center input-with-btn my-5">
                    {/*<label className="text-label body-text--small">Confirm Password*</label>*/}
                    <Form.Item
                        name="confirmpassword"
                        className='w-full stack-form-col'
                        dependencies={['password']}
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password.'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        setAlert(null);
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Password does not match.')
                                    ).catch(() => {
                                        setAlert({
                                            variant: 'danger',
                                            message: 'Password does not match.'
                                        });
                                    });
                                }
                            })
                        ]}
                    >
                        <div className="form-input-combo">
                            <label htmlFor="password">Confirm Password</label>
                            <input id="confirm_password" name="confirm password" type="password" autoComplete="current-password" required placeholder="Confirm Password"/>
                            <button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                    <span><p>{submitted && (
                                        <i className="fa-solid fa-spinner fa-spin z-0 mr-2"/>
                                    )}
                                        Reset password</p></span>
                            </button>
                        </div>

                        {/*<Input*/}
                        {/*    // placeholder="Confirm Password*"*/}
                        {/*    type="password"*/}
                        {/*    className={'txt-long input-' + size}*/}
                        {/*    disabled={submitted}*/}
                        {/*/>*/}
                    </Form.Item>
                    {/*<Form.Item className="mb-0">*/}
                    {/*    <Button*/}
                    {/*        className={'btn btn-primary btn-' + size}*/}
                    {/*        type="submit"*/}
                    {/*        disabled={submitted || formHasError}*/}
                    {/*    >*/}
                    {/*        {submitted && <i className="fa-solid fa-spinner fa-spin"></i>}*/}
                    {/*        Reset password*/}
                    {/*    </Button>*/}
                    {/*</Form.Item>*/}
                </div>
            </Form>
        </>
    );
}

export default memo(ResetPassword);