import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES } from './const';
import { getUserData } from '../../services/general';
import * as actions from './actions';
import { removeAccessToken } from '../../utils/common';

function* getAuthUserInfo() {
	try {
		const res = yield call(getUserData);
		if (res.result) {
			const userData = res.user;
			// we can mutate/add attributes to user data that will be stored globally
			const authUserInfo = {
				...userData
			};
			yield put(actions.getAuthUserInfoSuccess({ authUserInfo }));
		} else {
			// removeAccessToken();
			yield put(actions.getAuthUserInfoFailed());
		}
	} catch (error) {
		// removeAccessToken();
		yield put(actions.getAuthUserInfoFailed());
	}
}

function* watchGetAuthUserInfo() {
	yield takeLatest(ACTION_TYPES.GET_AUTH_USER_INFO, getAuthUserInfo);
}

export default function* globalSagas() {
	yield all([watchGetAuthUserInfo()]);
}
