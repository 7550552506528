import { memo, useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { Button } from 'antd';
import { updateSpacePlan } from '../../../services/space';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const UpgradePlan = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const selectPlan = useCallback(
		async (isPro) => {
			const data = {
				space_id: spaceInfo.id,
				user_id: authUserInfo.id,
				plan: isPro ? 'pro' : 'basic'
			};

			if (isPro) {
				const url = routes.createCustomizeProSpace();
				data.last_step_link = url.replace(':space_username', space_username);
			} else {
				const url = routes.createSpaceLaunch();
				data.last_step_link = url.replace(':space_username', space_username);
			}

			const response = await updateSpacePlan(data);
			if (response && response.result) {
				dispatch(getSpaceInfo(space_username));

				if (isPro) {
					const url = routes.createCustomizeProSpace();
					navigate(url.replace(':space_username', space_username));
				} else {
					const url = routes.createSpaceLaunch();
					navigate(url.replace(':space_username', space_username));
				}
			}
		},
		[authUserInfo, spaceInfo]
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-plans-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<div className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Upgrade to Pro!</h1>
									<h3>Step 5 of 5</h3>
								</div>
								<p>{getSitewideLink('basic_space_upgrade', '')}
									{' '} is a great way to start and and will be launched at{' '}
									<a>
										common.space/@username
									</a>
									{' '} with Commonspace branding and theme. Basic Spaces have all
									standard features and are always {' '}
									{getSitewideLink('free_100_active_members', '')}
									{' '}For any month where you have 100+ monthly active members, it’s
									only $0.01 per monthly active member with a $1 USD minimum. Basic
									Spaces also have a higher sales transaction fee.{' '}
									{getSitewideLink('learn_more_flexible_pricing', '')}

								</p>
								<p>{getSitewideLink('pro_space_upgrade', '')}
									{' '} lets you customize your Space by using your own domain name, logo,
									branding, and styles, plus you get advanced features and lower rates
									for Membership and Item sales. Pro Spaces are only $29 per month and
									include up to 3,000 monthly active members. For any month where you have over that, it’s also only $0.01 for each additional monthly active member. You are never charged for inactive members.
								</p>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Select a Plan</h2>
										<p>No credit card needed to create your Space!</p>
									</div>
									{getSitewideLink('basic_vs_pro', 'button-secondary-gradient', true)}
								</div>

								<div className="cs-stack-plans stack-col">
									<article className="cs-plan-card rounded-card">
										<div className="cs-stack-plan-card rounded-card">
											<div className="cs-plan-intro">
												<i className="fa-regular fa-atom-simple"></i>
												<hgroup className="title">
													<h3>Basic Space</h3>
													<p>Everything you need to get started growing your community with memberships, publishing, and more.</p>
												</hgroup>
											</div>
											<hgroup className="cs-plan-pricing">
												<h3>$0<span>/month</span></h3>
												<p><span>Up to 100 monthly active members</span></p>
											</hgroup>
											<div className="cs-plan-pricing-details">
												<p>Additional Monthly Active Members are only $0.01/month<br />
													<span>$1 minimum</span>
												</p>
											</div>
											<hgroup className="cs-plan-rates">
												<h5>Sales Rates</h5>
												<p>15% for all On-Platform Sales<br />
                                            <span>plus 2.9% + 30¢ for credit card processing<br />
                                            No additional fees for crypto payment processing</span>
												</p>
												<p>Free Off-Platform Sales<br />
													<span>On-site redemptions and claiming from off-site transactions are free.</span>
												</p>
											</hgroup>
											<div className="cs-plan-features">
												<h5>Key Features</h5>
												<ul>
													<li>Basic Space on common.space/@username</li>
													<li>Commonspace branding, style, & navigation</li>
													<li>1 GB of media storage for video & downloads</li>
													<li>Unlimited Memberships, Benefits, & Items</li>
													<li>Unlimited Updates + 2 Important Updates/mo</li>
													<li>Unlimited Team Accounts</li>
													<li>Dedicated Customer Support</li>
												</ul>
											</div>
										</div>
									</article>

									<article className="cs-plan-card gradient rounded-card">
										<div className="cs-stack-plan-card rounded-card">
											<div className="cs-plan-intro">
												<i className="fa-regular fa-solar-system"/>
												<hgroup className="title">
													<h3>Pro Space</h3>
													<p>Level up with advanced features, your own branding, custom domain, and the best rates.</p>
												</hgroup>
											</div>
											<hgroup className="cs-plan-pricing">
												<h3>$29<span>/month</span></h3>
												<p><span>including 3,000 monthly active members</span></p>
											</hgroup>
											<div className="cs-plan-pricing-details">
												<p>Additional Monthly Active Members are only $0.01/month<br />
													<span>No minimum or maximums</span>
												</p>
											</div>
											<hgroup className="cs-plan-rates">
												<h5>Sales Rates</h5>
												<p>10% for all On-Platform Sales<br />
                                            <span>plus 2.9% + 30¢ for credit card processing<br />
                                            No additional fees for crypto payment processing</span>
												</p>
												<p>Free Off-Platform Sales<br />
													<span>On-site redemptions and claiming from off-site transactions are free.</span>
												</p>
											</hgroup>
											<div className="cs-plan-features">
												<h5>Key Features</h5>
												<ul>
													<li>Pro Space on your own domain</li>
													<li>Custom branding, style, layouts, & navigation</li>
													<li>30 GB of media storage for video & downloads</li>
													<li>Unlimited Memberships, Benefits, & Items</li>
													<li>Unlimited Updates + 4 Important Updates/mo</li>
													<li>Unlimited Team Accounts</li>
													<li>Dedicated Priority Customer Support</li>
													<li>Priority Space & Team Verification</li>
													<li>Advanced Features and Custom Content Blocks</li>
												</ul>
											</div>
										</div>
									</article>
								</div>
								<div className="stack-buttons col">
									<a className="button-primary-gradient" onClick={() => selectPlan(true)}><span><p>Upgrade to Pro!</p></span></a>
									<a className="button-secondary" onClick={() => selectPlan(false)}><span><p>Continue with Basic</p></span></a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{/*<main id="member-profile" className="flex">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center">*/}
			{/*				<h1 className="mb-4 mt-5 header-large">Upgrade to a Pro Space!</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">*/}
			{/*				<p className="body-txtitalic--large mt-5">Step 5 of 5</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12">*/}
			{/*				<p className="body-text--reg">*/}
			{/*					Your{' '}*/}
			{/*					/!*<a className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Basic Space*!/*/}
			{/*					/!*</a>*!/*/}
			{/*					{getSitewideLink('basic_space_upgrade', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*					{' '} is a great way to start and and will be launched at{' '}*/}
			{/*					<a className="gradient-color-txt body-txtitalic--reg">*/}
			{/*						common.space/@username*/}
			{/*					</a>*/}
			{/*					{' '} with Commonspace branding and visual style. Basic Spaces have all*/}
			{/*					standard features and are always free up to 100{' '}*/}
			{/*					/!*<a className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	monthly active members*!/*/}
			{/*					/!*</a>*!/*/}
			{/*					{getSitewideLink('monthly_active_members', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*					. For any month where you have 100+ monthly active members, it’s*/}
			{/*					only $0.01 per monthly active member with a $1 USD minimum. Basic*/}
			{/*					Spaces also have higher{' '}*/}
			{/*					/!*<a className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	transaction fees*!/*/}
			{/*					/!*</a>*!/*/}
			{/*					{getSitewideLink('transaction_fee', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*					{' '} for sales.*/}
			{/*				</p>*/}
			{/*				<p className="body-text--reg">*/}
			{/*					Upgrading to a{' '}*/}
			{/*					/!*<a className="gradient-color-txt body-txtitalic--reg">Pro Space</a>*!/*/}
			{/*					{getSitewideLink('pro_space_upgrade', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*					{' '} lets you customize your Space by using your own domain name, logo,*/}
			{/*					branding, and styles, plus you get advanced features and lower rates*/}
			{/*					for Membership and Item sales. Pro Spaces are only $29 per month and*/}
			{/*					include usage for 3,000 monthly active members. For any month where*/}
			{/*					you have over that, it’s also only $0.01 for each additional monthly*/}
			{/*					active member.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-5 items-center">*/}
			{/*			<div className="md:col-span-4 !order-last md:!order-first">*/}
			{/*				<h5 className="header-small">Get your Pro Space free for 90 days!</h5>*/}
			{/*			</div>*/}
			{/*			<div className="md:col-span-8 !order-first md:!order-last">*/}
			{/*				<p className="body-txtitalic--smallest color-reg">*/}
			{/*					If you select the Pro Space Plan today, you’ll get all monthly costs*/}
			{/*					waived for 90 days during our launch period. Your only cost will be*/}
			{/*					transaction fees on sales. Basic Spaces with 100+ active members*/}
			{/*					only get monthly fees waived for 30 days.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*			<div className="md:col-span-7 flex items-center justify-between !order-last md:!order-first gap-8">*/}
			{/*				<h5 className="header-medium">Select Your Plan</h5>*/}
			{/*				<span className="body-text--reg">No Credit Card Needed</span>*/}
			{/*			</div>*/}
			{/*			<div className="md:col-span-5 flex items-center !order-first md:!order-last">*/}
			{/*				/!*<button type="submit" className="btn btn-lg btn-secondary">*!/*/}
			{/*				/!*	Basic Vs Pro Full Comparison*!/*/}
			{/*				/!*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*!/*/}
			{/*				/!*</button>*!/*/}
			{/*				{getSitewideLink('basic_vs_pro', 'btn btn-lg btn-secondary')}*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-4 mt-5">*/}
			{/*			<div className="md:cols-span-1">*/}
			{/*				<div className="gradient-border-box plan-list text-center p-4 md:h-full">*/}
			{/*					<i className="fa-solid fa-atom-simple"></i>*/}
			{/*					<h3 className="header-medium">Basic Space Plan</h3>*/}
			{/*					<p className="body-text--reg color-reg">*/}
			{/*						Everything you need to get started growing your community,*/}
			{/*						selling memberships, and publishing content.*/}
			{/*					</p>*/}
			{/*					<h2 className="header-large mt-5">*/}
			{/*						$0<span className="header-small">/month</span>*/}
			{/*					</h2>*/}
			{/*					<p className="body-txtitalic--reg color-light">*/}
			{/*						up to 100 monthly active members*/}
			{/*					</p>*/}
			{/*					<h6 className="header-title color-reg mt-5">*/}
			{/*						Additional Monthly Active Members are only $0.01/Month!*/}
			{/*					</h6>*/}
			{/*					<p className="body-txtitalic--reg color-light">$1/Month Minimum</p>*/}
			{/*					<p className="header-small-title color-reg mt-5">Sales Rate</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						15% for all On-Platform Sales*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						plus 2.9% + 30¢ for credit card processing*/}
			{/*						<br />*/}
			{/*						No additional fees for crypto payment processing*/}
			{/*					</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						Off-Platform Sales are free!*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						Claiming Memberships and Items based on retail or outside*/}
			{/*						<br /> transactions are free, including existing minted NFTs.*/}
			{/*					</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						2% for Off-Platform Resales*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						Only applies to Memberships and Items with royalties enabled and*/}
			{/*						originally minted on-platform. Free for all others types!*/}
			{/*					</p>*/}
			{/*					<p className="header-small-title color-reg mt-5">*/}
			{/*						Included Features*/}
			{/*					</p>*/}
			{/*					<ul className="w-fit text-start m-auto">*/}
			{/*						<li className="color-reg">*/}
			{/*							Basic Space on common.space/@username*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Commonspace branding, style, & navigation*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							1 GB of media storage for video & downloads*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Unlimited Memberships, Benefits, & Items*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Unlimited Updates + 2 Important Updates/mo*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">Unlimited Team Accounts</li>*/}
			{/*						<li className="color-reg pb-5">Dedicated Customer Support</li>*/}
			{/*					</ul>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 mt-3 ">*/}
			{/*					<div className="col-span-1 md:hidden">*/}
			{/*						<Button*/}
			{/*							onClick={() => selectPlan(false)}*/}
			{/*							className="btn btn-lg btn-secondary"*/}
			{/*							type="submit"*/}
			{/*						>*/}
			{/*							Continue with Basic*/}
			{/*						</Button>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="md:cols-span-1">*/}
			{/*				<div className="gradient-border-box plan-list text-center p-4 md:h-full">*/}
			{/*					<i className="fa-solid fa-atom"></i>*/}
			{/*					<h3 className="header-medium">Pro Space Plan</h3>*/}
			{/*					<p className="body-text--reg color-reg">*/}
			{/*						Everything you need to get started growing your community,*/}
			{/*						selling memberships, and publishing content.*/}
			{/*					</p>*/}
			{/*					<h2 className="header-large mt-5">*/}
			{/*						$29<span className="header-small">/month</span>*/}
			{/*					</h2>*/}
			{/*					<p className="body-txtitalic--reg color-light">*/}
			{/*						up to 100 monthly active members*/}
			{/*					</p>*/}
			{/*					<h6 className="header-title color-reg mt-5">*/}
			{/*						Additional Monthly Active Members are only $0.01/Month!*/}
			{/*					</h6>*/}
			{/*					<p className="body-txtitalic--reg color-light">*/}
			{/*						No minimum or maximum limits*/}
			{/*					</p>*/}
			{/*					<p className="header-small-title color-reg mt-5">Sales Rate</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						5% for all On-Platform Sales*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						plus 2.9% + 30¢ for credit card processing*/}
			{/*						<br />*/}
			{/*						No additional fees for crypto payment processing*/}
			{/*					</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						Off-Platform Sales are free!*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						Claiming Memberships and Items based on retail or outside*/}
			{/*						<br /> transactions are free, including existing minted NFTs.*/}
			{/*					</p>*/}
			{/*					<p className="body-text--small color-reg m-0">*/}
			{/*						1% for Off-Platform Resales*/}
			{/*					</p>*/}
			{/*					<p className="body-txtitalic--small color-light">*/}
			{/*						Only applies to Memberships and Items with royalties enabled and*/}
			{/*						originally minted on-platform. Free for all others types!*/}
			{/*					</p>*/}
			{/*					<p className="header-small-title color-reg mt-5">*/}
			{/*						Included Features*/}
			{/*					</p>*/}
			{/*					<ul className="w-fit text-start m-auto">*/}
			{/*						<li className="color-reg">Pro Space on your own domain</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Custom branding, style, layouts, &amp; navigation*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							30 GB of media storage for video &amp; downloads*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Unlimited Memberships, Benefits, &amp; Items*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Unlimited Updates + 4 Important Updates/mo*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">Unlimited Team Accounts</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Dedicated Priority Customer Support*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Priority Space & Team Verification*/}
			{/*						</li>*/}
			{/*						<li className="color-reg">*/}
			{/*							Advanced Features and Custom Content Blocks*/}
			{/*						</li>*/}
			{/*					</ul>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 mt-3">*/}
			{/*					<div className="col-span-1 flex items-end  md:hidden">*/}
			{/*						<Button*/}
			{/*							onClick={() => selectPlan(true)}*/}
			{/*							className="btn btn-lg btn-primary"*/}
			{/*							type="submit"*/}
			{/*						>*/}
			{/*							Upgrade to Pro!*/}
			{/*						</Button>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className="grid grid-cols-2  gap-y-8 md:gap-y-0 md:gap-8  mt-5">*/}
			{/*			<div className="md:col-span-1 hidden md:flex">*/}
			{/*				<Button*/}
			{/*					onClick={() => selectPlan(false)}*/}
			{/*					className="btn btn-lg btn-secondary"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					Continue with Basic*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*			<div className="md:col-span-1 flex items-end hidden md:flex">*/}
			{/*				<Button*/}
			{/*					onClick={() => selectPlan(true)}*/}
			{/*					className="btn btn-lg btn-primary"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					Upgrade to Pro!*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(UpgradePlan);
