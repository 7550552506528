
/************************************
 *                                  *
 *                                  *
 *         CustomValidators         *
 *                                  *
 *             Utility              *
 *                                  *
 *                                  *
 ************************************/

import React from "react";
import {CurrencyType} from "./MembershipItemUtils";
import {CUSTOM_REGEX} from "../constants/custom_regex";


/**
 *
 * CustomValidators is a utility class for the validation
 *
 */
export class CustomValidators extends React.Component {

    /**
     * Returns the result of the discount validation
     * @param {rule} rule //is an object that contains the validation rules for a form field in Ant Design
     * @param {string} value
     * @returns {Promise<never>}
     */
    static discount = (rule, value) => {
        if ((typeof value !== 'undefined' &&
                value !== '' && isNaN(value)) ||
            (typeof value === 'string' &&
                value.match(/\./g))
        ) {
            return Promise.reject(
                new Error('Invalid value.')
            );
        }
        return Promise.resolve();
    }

    /**
     * Returns the result of the max per count validation
     * @param {rule} rule //is an object that contains the validation rules for a form field in Ant Design
     * @param {string} value
     * @returns {Promise<never>}
     */
    static maxPerCount =  (rule, value) => {
        if ((typeof value !== 'undefined' &&
                value !== '' && isNaN(value)) ||
            (typeof value === 'string' &&
                value.match(/\./g)) ||
            (value < 0)
        ) {
            return Promise.reject(
                new Error('Invalid value.')
            );
        }
        return Promise.resolve();
    }

    /**
     * Returns the result min max for monthly price validation
     * @param {string} currency
     * @returns {(function(*, *): (Promise<never>))|*}
     */
    static monthlyPriceMinMax = (currency) => (rule, value) => {
        if (
            typeof value !== 'undefined' &&
            value !== '' &&
            (isNaN(value) || value < 0)
        ) {
            return Promise.reject(
                new Error('Invalid value.')
            );
        }
        //Recurring Price Checking
        //Minimum price is $1 USD
        const floatPrice = parseFloat(value)
        if (currency === CurrencyType.USD) {
            if (floatPrice < 1) {
                return Promise.reject(
                    new Error('Minimum price is $1 USD')
                );
            }
        }
        return Promise.resolve();
    }

    /**
     * Returns the result min max for one time payment price validation
     * @param {string} currency
     * @returns {(function(*, *): (Promise<never>))|*}
     */
    static priceMinmax = (currency) => (rule, value) => {
        if (
            typeof value !== 'undefined' &&
            value !== '' &&
            (isNaN(value) || value < 0)
        ) {
            return Promise.reject(
                new Error('Invalid value.')
            );
        }
        //One Time Price Checking
        //Minimum price is $5 USD or 0 for free
        const floatPrice = parseFloat(value)
        if (currency === CurrencyType.USD) {
            if (floatPrice > 0 && floatPrice < 5) {
                return Promise.reject(
                    new Error('Minimum price is $5 USD')
                );
            }
        }
        return Promise.resolve();
    }

    static royaltyPercentage = (rule, value) => {
        if (value !== undefined) {
            if (typeof value !== 'string' && value) {
                value = value.toString();
            }
            if (
                isNaN(value) ||
                value < 0 || value > 20 ||
                (typeof value === 'string' &&
                    value.match(/\./g))
            ) {
                return Promise.reject(
                    new Error(value > 20 ? '20% maximum' : 'Invalid value.')
                );
            }
        }

        return Promise.resolve();
    }

    static percentage = (rule, value) => {
        if (value){
            if (CUSTOM_REGEX.PERCENTAGE_VALIDATION.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error('Invalid value.'));
            }
        } else {
            return  Promise.resolve();
        }
    }

    static emailUsername = (rule, value) => {
        if (CUSTOM_REGEX.STRICT_EMAIL_VALIDATION.test(value)) {
            return Promise.resolve();
        } else {
            if (CUSTOM_REGEX.USERNAME_VALIDATION.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error('Invalid email address or username.'));
            }
        }
    }

    static email = (rule, value) => {
        if (value){
            if (CUSTOM_REGEX.STRICT_EMAIL_VALIDATION.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error('Invalid email address.'));
            }
        } else {
            return  Promise.resolve();
        }
    }

}
