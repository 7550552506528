import React, {memo, useCallback, useEffect, useState} from 'react';
import ReactPlayer from 'react-player/lazy';
import {Button} from 'antd';
import routes from '../../../routes';
import {useNavigate} from 'react-router-dom';
import default_items_img from '../../../assets/images/form_background.png';
import {getCommunityBtnText} from "../../../components/commons/helpers/MembershipItemHelpers";
import {MEMBERSHIP_TYPES} from "../../../constants/space";
import default_bg from "../../../assets/images/form_background.png";

const Communities = (parentProps) => {
    const {data, user, spaceData, updateGlobalActiveTab} = parentProps;
    const navigate = useNavigate();
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [loadOnce, setLoadOnce] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        if (!loadOnce) {
            setLoadOnce(true);
            if (data && (data?.photo || data?.video)) {
                if (data?.photo) {
                    const bg = {
                        backgroundImage: 'url(' + data?.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (data?.video) {
                    const bg = {
                        backgroundImage: 'none'
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [data]);



    const goToMembership = useCallback(async () => {
        if (data?.collection_type === 'membership') {
            let url = routes.spaceMembersProductsMembership();
            if (data?.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
                updateGlobalActiveTab('claim_membership');
            } else {
                url = routes.spaceMembershipBuy();
                updateGlobalActiveTab('buy_membership');
            }
            const fin_url =
                url.replace(':space_username', '@' + spaceData?.username) + '?id=' + data?.id;
            navigate(fin_url, { replace: true });
        }
        if (data?.collection_type === 'item') {
            let url = routes.spaceMembersProductsItem();
            if (data?.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
                updateGlobalActiveTab('claim_item');
            } else {
                url = routes.spaceItemBuy();
                updateGlobalActiveTab('buy_item');
            }
            const fin_url =
                url.replace(':space_username', '@' + spaceData?.username) + '?id=' + data?.id;
            navigate(fin_url, { replace: true });
        }
    }, [data, spaceData]);

    return (
        <article className={`cs-product-membership card block-md  ${data?.video ? '!p-0 relative' : ''}`} style={{backgroundImage: `url('${data?.photo}')`}}>
            {data?.video && (
                <ReactPlayer
                    playing={true}
                    loop={true}
                    muted={true}
                    controls={false}
                    width="100%"
                    height="100%"
                    className="videoPreview absolute"
                    url={data?.video}
                />
            )}
            {data && data?.id ? (
                <div className={`shader space-y-card rounded-card ${data?.video ? '!m-0 relative' : ''}`}>
                    <hgroup>
                        <h4>{data?.name} <i className="fa-regular fa-square-user"/></h4>
                        <p>{data?.summary}</p>
                    </hgroup>
                    <a className={`button-secondary-gradient-alt`}
                        onClick={()=> {
                            if (!isPaid) {
                                goToMembership().then(r => {});
                            }
                        }}
                    >
                        <span><p>{getCommunityBtnText(user, data, false)}</p></span>
                    </a>
                </div>
            ) : (
                <>
                    {spaceData?.viewing_user_is_owner && (
                        <div className="shader space-y-card rounded-card">
                            <h6 className="header-title text-center">
                                Create additional Memberships & Items <br/>
                                from your Dashboard
                            </h6>
                        </div>
                    )}
                </>
            )}
        </article>

    );
};

export default memo(Communities);
