import { memo, useState,Fragment, useEffect } from 'react';
import {getListIcon} from "../../../utils/list";
import {CARD_TYPES} from "../../../constants/payment_method";
import { Listbox, Transition } from '@headlessui/react';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }
const DropdownWithCards = (parentProps) => {
    const {
        title,
        isAddNew = true,
        displayError,
        icon,
        selectedValue,
        listItems,
        onDropdownChange,
        customButtonClass,
        dropDownClass,
        defaultOption
    } = parentProps;

    const [filteredListItems, setFilteredListItems] = useState([]);
    const [selected, setSelected] = useState();

    // const [selected, setSelected] = useState(defaultOption && defaultOption[0] ? defaultOption[0] : null);

    const getSelectedValue = (data) => {
        return data.find((element) => element.value === selectedValue);
    }

    const handleListClick = (item) => {
        onDropdownChange(item);
    };

    // let filterCards = (items) => {
    //     let filteredCards = [{label: 'Select', value: 'default', disabled: true}]
    //     if (items && items.payment_methods) {
    //         items.payment_methods.map((item) => {
    //             filteredCards.push({
    //                 label: item.card_type + " ending in " + item.last_four,
    //                 value: item.last_four,
    //                 cardObject: item,
    //                 disabled: false
    //             });
    //         });
    //     }
    //     if (isAddNew) filteredCards.push({label: 'Add New', value: 'add', disabled: false})
    //
    //     return filteredCards;
    // }

    const DropDownItems = (data) => {
        const items = data.props;
        let listItems = [];
        listItems = filterCards(items).map((item, key) => (
            <div key={'div-parent-key-' + key}>
                <li
                    key={'parent-key-' + key}
                    onClick={() => handleListClick(item)}
                    className={item.disabled ? 'disabled' : ''}
                >
                    <div className="flex w-full">
                        {/*{item.cardObject != null &&*/}
                        {/*    <i className={'col-2 pt-3 text-center card-ico ' + getListIcon(CARD_TYPES, item.cardObject.card_type)}/>}*/}
                        <span className="col-10 dropdown-item ">{item.label}</span>
                    </div>

                </li>
            </div>
        ));

        return <>{listItems}</>;
    };

    const DisplaySelected = (data, icon) => {
        const items = data.props;
        let label = '';
        let selectedItem = {};
        const list = filterCards(items)
        if (list.length) {
            list.forEach((item) => {
                if (item.value === selectedValue) {
                    label = item.label;
                    selectedItem = item;
                }
            });
        }

        return (
            <>
                {selectedItem.cardObject != null &&
                    <i className={'col-2 pt-1 text-center card-ico ' + getListIcon(CARD_TYPES, selectedItem.cardObject.card_type)}/>}
                <span className=''>{label}</span>
            </>
        );
    };

    useEffect(() => {
        let filteredArray = [{label: 'Select', value: 'default', disabled: true}]
        if (listItems && listItems.length > 0) {
            listItems.map((item) => {
                filteredArray.push({
                    label: item.card_type + " ending in " + item.last_four,
                    value: item.last_four,
                    cardObject: item,
                    disabled: false
                });
            });
        }
        if (isAddNew) filteredArray.push({label: 'Add New', value: 'add', disabled: false})
        setFilteredListItems(filteredArray);
    },[listItems])

    useEffect(() => {
        setSelected(getSelectedValue(filteredListItems))
    },[filteredListItems])


    return (
        // <div className={'dropdown ' + dropDownClass}>
        //     {displayError && <span className="error-msg">{title} is required.</span>}
        //     <Listbox >
		// 	<Listbox.Button className={'btn dropdown-toggle ' + customButtonClass}>
		// 		<DisplaySelected props={listItems} />{' '}
		// 		<i className="fa-solid fa-caret-down right pr-2"></i>
		// 	</Listbox.Button>
        //     <Listbox.Options>
		// 		 <Listbox.Option className={classNames("text-start dropdown-menu absolute z-50 gradient-color-border border-solid border rounded-lg px-2  min-h-[133px] overflow-auto md:min-h-fit w-auto border-transparent",
		// 	title ==='Language' || title === 'Location' ? 'invisible': '') }				 
		// 	   >
		// 		<DropDownItems props={listItems} />
		// 	   </Listbox.Option>
		// 	</Listbox.Options>
        //     </Listbox>
                
        //     {/* <ul className="dropdown-menu">
        //         <DropDownItems props={listItems}/>
        //     </ul> */}
        // </div>

        <Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
        <>
        <div className="relative mt-2">
            <Listbox.Button className="relative h-10 rounded-[50px] w-full cursor-default btn-secondary text-base font-['notoSansSemiBold'] " >
                <span className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2'><i className={icon}></i></span>
                <span className="truncate text-center">{selected?.label}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <i className="fa-solid fa-caret-down text-gray-400 right pr-2"></i>
                </span>
            </Listbox.Button>
            <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                    <Listbox.Options className={classNames("text-start dropdown-menu absolute z-[999999] gradient-color-border border-solid border rounded-lg px-2  overflow-auto min-h-fit w-full text-base font-['notoSansSemiBold']  border-transparent",
							title ==='Language' || title === 'Location' ? 'invisible': '') }>
								{filteredListItems.map((item, key) => (
								<Listbox.Option
									key={key}
									onClick={() => handleListClick(item)}
									className={({ active }) =>
									classNames(
										active ? 'bg-indigo-600 text-white' : 'text-gray-900',
										'relative cursor-default select-none py-2 pl-3 pr-9 gradient-color-txt ',
										item.disabled ? 'disabled' : ''
									)
									}
									value={item}
								>
                                    {({ selected, active }) => (
                                        <>
                                            <span className={classNames(selected ? 'font-semibold text-fuchsia-700' : 'font-normal', 'block truncate', 'dropdown-item flex items-center')}>
                                            {item?.label}
                                            </span>

                                            {selected ? (
                                            <span
                                                className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                            >
                                            </span>
                                            ) : null}
                                        </>
                                    )}
								</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
                </>
                )}
            </Listbox>
    );
};

export default memo(DropdownWithCards);