import { memo, useEffect, useCallback, useState } from 'react';
import DashboardContent from './DashboardContent';
import {getDataByUsername} from "../../../src/services/general";
import './SpaceDashboard.scss';
import {useSelector} from "react-redux";

const SpaceDashboard = ({viewUsername}) => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
    const [spaceData, setSpaceData] = useState(null)
	useEffect(() => {
		document.title = 'Commonspace Space Dashboard';
		// console.log('dashboard')
		// console.log(viewUsername)
        fetchViewData(viewUsername)
	}, [viewUsername]);

    useEffect(()=> {
        // console.log(spaceData)
    }, [spaceData])

    const fetchViewData = useCallback(
		async (username) => {
			try {
				const data = {
					username: username
				};
				const response = await getDataByUsername(data);
				if (response.result) {
                    setSpaceData(response.space)
				} else {

				}
			} catch (error) {
				console.log(error);
			}
		},
		[]
	);

    

	return (
		// <main id="cs-space-main" className={`cs-main ${spaceData && spaceData.pro_style && spaceData.pro_style?.dashboard_theme === "main" ? 'main-theme' : 'main-theme-alt'}`}>
		// 	<section id="cs-profile-dash-dashboard-c1" className={`px-section-safeview pb-section-safeview pt-gutter ${spaceData && spaceData.pro_style && spaceData.pro_style?.channels_theme === 'main'? 'theme-transparent' : 'theme-alt-transparent'}`}>
        <main id="cs-space-main" className="cs-main main-theme">
			<section id="cs-profile-dash-dashboard-c1" className="px-section-safeview pb-section-safeview pt-gutter theme-transparent">
				{authUserInfo && (
					<DashboardContent />
				)}
			</section>
		</main>
	);
};

export default memo(SpaceDashboard);
