import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import TextareaAutosize from 'react-textarea-autosize';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { addItemBenefit } from '../../../../services/space';
import {CurrencyType} from "../../../../utils/MembershipItemUtils";
import {CustomValidators} from "../../../../utils/CustomValidators";
import {setBenefitMonthlyPrice} from "../../../../components/commons/helpers/MembershipItemHelpers";


const AddMonthlyBenefit = (props) => {
	const { itemData, spaceInfo, authUserInfo } = props;
	const [addMonthlyBenefitForm] = Form.useForm();
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);

	const handleBenefitFormOnBlur = useCallback(async () => {
		await addMonthlyBenefitForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleBenefitFormChanges = useCallback(async () => {
		await addMonthlyBenefitForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	});

	const handleBenefitFormSubmit = useCallback(
		async (values) => {
			setAlert(null);

			const data = values;
			data.item_id = itemData.id;
			data.currency = 'USD';

			if (itemData) {
				try {
					if (authUserInfo) {
						data.user_id = authUserInfo.id;
						data.space_id = spaceInfo.id;
						setSubmitted(true);

						const response = await addItemBenefit(data);
						if (response && response.result) {
							setAlert({
								variant: 'success',
								message: response.message
							});
						} else {
							setAlert({
								variant: 'danger',
								message: response.message
							});
						}
						setSubmitted(false);
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				setAlert({
					variant: 'danger',
					message: 'No membership details'
				});
			}
		},
		[authUserInfo, spaceInfo, itemData]
	);

	useEffect(() => {}, [authUserInfo, spaceInfo, itemData]);

	return (
		<div className="block-full">
			<div className="memberships">
				<div className="dashboard-wrapper card mb-4">
					<div className="grid grid-cols-12 gap-2 md:gap-8">
						<div className="col-span-12">
							<h3 className="header-medium">Add Benefits to your Item</h3>
						</div>
					</div>
					<div className="grid grid-cols-12  gap-2 md:gap-8 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								<a className="body-txtitalic--small gradient-color-txt">Benefits</a>{' '}
								provide customizable access, redemptions, privileges, and perks for
								your Members. There are many types of Benefits which you can add and
								customize. You can add, remove, and edit Benefits at any time.{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Learn more about Benefits.
								</a>
							</p>
						</div>
					</div>
				</div>
				<Form
					form={addMonthlyBenefitForm}
					name="addMonthBenefitItem"
					id="addMonthBenefitItem"
					validateMessages={VALIDATE_MESSAGES}
					onFocus={() => {
						setFormIsOnFocus(true);
					}}
					onBlur={() => {
						setFormIsOnFocus(false);
						handleBenefitFormOnBlur().then(r => {});
					}}
					onChange={handleBenefitFormChanges}
					onFinish={handleBenefitFormSubmit}
					className={formIsOnFocus ? 'hide-antd-error-messages card' : 'card'}
				>
					<div className="dashboard-wrapper mt-5">
						<div className="grid grid-cols-12 gap-2 md:gap-8">
							<div className="col-span-12 md:col-span-6">
								<h3 className="header-small">Benefits for {itemData.name}</h3>
							</div>
							<div className="col-span-12 md:col-span-6">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									View Guide
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<button className="secondary-gradient w-full"><span><p>Member Access</p></span></button>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									The Member Access Benefit provides basic “Members Only” access
									to Updates and Members sections in your Space. This is included
									in every Membership you create and cannot be edited.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<button className="secondary-gradient w-full"><span><p>Monthly Subscription</p></span></button>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									The Monthly Subscription Benefit is required when the Expiration
									Date option is enabled.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
							<div className="col-span-12 md:col-span-8 items-center">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Title*</label>
									<Form.Item
										label="Title"
										name="title"
										className="mb-0 hidden-label w-full"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input
											placeholder="Add Title"
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
								{(addMonthlyBenefitForm.getFieldsError()[0]?.errors.length > 0) && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{addMonthlyBenefitForm.getFieldsError()[0]?.errors.length > 0 ? addMonthlyBenefitForm.getFieldsError()[0]?.errors : ''}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-4 flex align-top">
								<p className="body-txtitalic--smallest">
									Share details on the value of the subscription and what Members
									can expect.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Currency*</label>
									<input
										placeholder="Start typing your currency and select from the list."
										className="w-full"
										value="USD"
										disabled
									/>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Your Currency options are based on the details specified on your
									Membership/Item. You can select from the same currency of the
									Membership/Item or a related cryptocurrency.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Monthly Price*</label>
									<Form.Item
										label="Monthly Price"
										name="monthly_price"
										className="mb-0 hidden-label w-full"
										validateTrigger="onBlur"
										onChange={(event) => setBenefitMonthlyPrice(event, addMonthlyBenefitForm, "monthly_price")}
										rules={[
											{ required: true },
											{ validator: CustomValidators.monthlyPriceMinMax(CurrencyType.USD)}
										]}
									>
										<Input
											placeholder="Minimum is based on selected Currency."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
								{(addMonthlyBenefitForm.getFieldsError()[1]?.errors.length > 0) && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{addMonthlyBenefitForm.getFieldsError()[1]?.errors.length > 0 ? addMonthlyBenefitForm.getFieldsError()[1]?.errors : ''}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									For traditional currencies, you can specify up to 2 decimal
									places, i.e. ¥500 JPY or $5.00 USD. For cryptocurrencies, you
									can specify up to 8 decimal places, i.e. ethereUm 0.12345678
									ETH. Minimums vary by Currency.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Included Months*</label>
									<Form.Item
										label="Included Months"
										name="included_months"
										className="mb-0 hidden-label w-full"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														(typeof value !== 'undefined' &&
															value !== '' &&
															(isNaN(value) || value < 1)) ||
														(typeof value === 'string' &&
															value.match(/\./g))
													) {
														return Promise.reject(
															new Error('Invalid value.')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											placeholder="Whole numbers only, 1 or more."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
								{(addMonthlyBenefitForm.getFieldsError()[2]?.errors.length > 0) && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{addMonthlyBenefitForm.getFieldsError()[2]?.errors.length > 0 ? addMonthlyBenefitForm.getFieldsError()[2]?.errors : ''}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									You must include at least 1 Month. Once activated, the
									subscription starts and when the Months are used up, the Monthly
									Subscription must be renewed or topped up.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
							<div className="col-span-12 md:col-span-8 items-center">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Description*</label>
									<Form.Item
										label="Description"
										name="description"
										className="mb-0 hidden-label w-full"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<TextareaAutosize
											minRows={3}
											placeholder="Up to 160 characters, including spaces."
											className={"w-full bg-transparent border-none no-scrollbar"}
											maxLength={160}
											disabled={submitted}
										/>
									</Form.Item>
								</div>
								{(addMonthlyBenefitForm.getFieldsError()[3]?.errors.length > 0) && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{addMonthlyBenefitForm.getFieldsError()[3]?.errors.length > 0 ? addMonthlyBenefitForm.getFieldsError()[3]?.errors : ''}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-4 flex align-top">
								<p className="body-txtitalic--smallest">
									Share details on the value of the subscription and what Members
									can expect.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-input w-full mt-2">
									<label htmlFor="text">Renewal Discount</label>
									<Form.Item
										label="Renewal Discount"
										name="renewal_discount"
										className="mb-0 hidden-label w-full"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: CustomValidators.discount }
										]}
									>
										<Input
											placeholder="Whole numbers only, up to 15. Saved as a percent, e.g. “15” equals 15%."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
								{(addMonthlyBenefitForm.getFieldsError()[4]?.errors.length > 0) && (
									<div className="alert callout warning">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{addMonthlyBenefitForm.getFieldsError()[4]?.errors.length > 0 ? addMonthlyBenefitForm.getFieldsError()[4]?.errors : ''}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									<a className="body-txtitalic--small gradient-color-txt">
										You can offer discounts for longer renewals.
									</a>{' '}
									The percent discount is added at each renewal option of 3, 6,
									and 12 months. e.g. a 5% Renewal Discount would give 5% off 3
									Month, 10% off 6 Month, and 15% off 12 Month renewals.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<>
								{alert && (
									<div className="col-span-12 mb-3">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								)}
							</>
							<div className="col-span-12">
								<Form.Item className="mb-0">
									<button
										className="primary-gradient w-full"
										type="submit"
										disabled={submitted || formHasError}
									>
										<span><p>{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
											Save</p></span>
									</button>
								</Form.Item>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default memo(AddMonthlyBenefit);
