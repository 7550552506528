import {memo, useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'antd';

import { shortenNumber } from '../../../../../utils/number';
import default_space_image from '../../../../../assets/images/profile_card_bg.png';
import default_space_bg from '../../../../../assets/images/profile_spaces_bg.png';
import default_user_profile from '../../../../../assets/images/default_user_profile.png';
import routes from '../../../../../routes';

const SpaceItem = (parentProps) => {
	const { data } = parentProps;
	const [items, setItems] = useState([]);
	const [memberships, setMemberships] = useState([]);

	let defaultBg = {
		backgroundImage: 'url(' + default_space_bg + ')'
	};
	if (data) {
		if (data.background_photo_small) {
			defaultBg = {
				backgroundImage: 'url(' + data.background_photo_small + ')'
			};
		} else {
			if (data.background_photo) {
				defaultBg = {
					backgroundImage: 'url(' + data.background_photo + ')'
				};
			}
		}
	}

	const parseSpaceItems = useCallback(async ()=> {
		if (data.items != null) {
			let items = data.items.filter(item => item.status === 'active');
			setItems(items);
		}
	}, [data]);

	const parseSpaceMemberships = useCallback(async ()=> {
		if (data.memberships != null) {
			let memberships = data.memberships.filter(membership => membership.status === 'active');
			setMemberships(memberships);
		}
	}, [data]);

	const handleImageError = useCallback((ev) => {
		ev.target.src = default_space_image;
	}, []);

	const handleMemberImageError = useCallback((ev) => {
		ev.target.src = default_user_profile;
	}, []);

	const goToSpacePage = () => {
		const url = routes.viewUserSpace() + data.username;
		window.location.href = url;
	};

	useEffect(()=>{
		parseSpaceItems().then(r => {} );
		parseSpaceMemberships().then(r => {} );
	},[data])

	useEffect(()=>{

	},[memberships, items]);
	return (
		<>
			{/*{memberships.length > 0 &&*/}
				<div className="mt-4">
					<div className="profile-spaces" style={defaultBg}>
						<div className="profile-spaces-content">
							<div className="grid grid-cols-12  items-center">
								<div className="col-span-10">
									{/*<h3 className="btn-tertiary">*/}
									{/*	<i className="fa-light fa-solar-system"></i>*/}
									{/*	<p className="w-3/4 two-line-ellipsis">{data.name}</p>*/}
									{/*</h3>*/}
									<h3 className="spacename-header mt-3">
										<i className="fa-light fa-solar-system"></i>
										<span className="line-ellipsis">{data.name}</span>
									</h3>
								</div>
								<div className="col-span-2">
									<Link onClick={goToSpacePage} className="btn btn-tertiary btn-md">
										<i className="fa-light fa-arrow-up-right-from-square"></i>
									</Link>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-3 mt-4 ">
								<div className="col-span-12 mt-3 md:col-span-6">
									<img
										alt="space-image"
										className="img-spaces"
										src={data.photo_thumb ? data.photo_thumb : default_space_image}
										onError={handleImageError}
									/>
								</div>
								<div className="col-span-12 mt-3 md:col-span-6">
									<div className="ms-1">
										<h6 className="body-text-reg mt-2">@{data.username}</h6>
										<h6 className="header-small-title mt-2">{data.welcome_title}</h6>
										<p className="body-txtitalic--small m-0">
											
											{data.viewing_user_is_owner ? (
												
												<> {data.created_on ? 'Member since ' + moment(data.created_on).format('MMMM YYYY') : ''}</>
												// <>{moment(data.created_on).format('MMMM YYYY')}</>
												// <>{moment(data.activation_date).format('MM/DD/YY')}</>
											) : (
												// <>{moment(data.joined_on).format('MMMM YYYY')}</>
												
													<> {data.activation_date ? 'Member since ' + moment(data.activation_date).format('MMMM YYYY') : ''}</>
												
											)}
										</p>
										<p className="body-text--small mt-4">
											{/*<i*/}
											{/*	className={*/}
											{/*		'me-2 ' +*/}
											{/*		(data.hot_icon*/}
											{/*			? data.hot_icon*/}
											{/*			: 'fa-solid fa-waveform-lines')*/}
											{/*	}*/}
											{/*></i>*/}
											<label>
												{/*{shortenNumber(data.metadata.hot_count)}{' '}*/}
												{/*{data.hot_label ? data.hot_label : 'Unsound'}*/}
											</label>
										</p>
										<p className="body-text--small mt-2">
											{/*<i className="fa-solid fa-ranking-star me-2"></i>*/}
											<label>
												{/*Rank{' '}*/}
												{/*{data.metadata.rank ? '#' + data.metadata.rank : 'None'}*/}
											</label>
										</p>
										<div className="flex flex-row items-center justify-start mt-3 lg:mt-4">
											<ul className="polygon-spaces flex items-center">
												{memberships &&
													memberships.length > 0 &&
													<li key={0}>
														<Link to="#" className="square" style={{zIndex: items.length ? items.length + 1 : 1}}>
															<img
																className="img-thumb-square"
																alt={
																	memberships[0].photo_medium
																		? memberships[0].photo_medium
																		: ''
																}
																src={
																	memberships[0] &&
																	memberships[0].photo_medium
																		? memberships[0].photo_medium
																		: default_user_profile
																}
																onError={handleMemberImageError}
															/>
														</Link>
													</li>
												}
												{items &&
													items.length > 0 &&
													items.slice(0, 3).map((item, index) => (
														<li key={index + 1}>
															<Link to="#" className="hex" style={{zIndex: items.length - index}}>
																<img
																	alt={
																		item.photo_medium
																			? item.photo_medium
																			: ''
																	}
																	src={
																		item &&
																		item.photo_medium
																			? item.photo_medium
																			: default_user_profile
																	}
																	onError={handleMemberImageError}
																/>
															</Link>
														</li>
													))
												}
											</ul>
											{items && items.length > 3 && (
												<Link to="#" className="body-txtitalic--smallest ms-1">
													+{shortenNumber(items.length - 3)} more
												</Link>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-overlay"></div>
					</div>
				</div>
			{/*}*/}
		</>
	);
};

export default memo(SpaceItem);
