export const RECAPTCHA_ACTION = {
	LOGIN: 'LOGIN',
	MAGIC_LOGIN: 'MAGIC_LOGIN',
	SIGN_UP: 'SIGN_UP',
	WALLET_SETUP: 'WALLET_SETUP',
	WALLET_PASSWORD_SETUP: 'WALLET_PASSWORD_SETUP',
	REGISTER: 'REGISTER',
	MAGIC_REGISTER: 'MAGIC_REGISTER',
	EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
	MEMBER_PROFILE_CREATE: 'MEMBER_PROFILE_CREATE',
	MEMBER_PROFILE_UPDATE: 'MEMBER_PROFILE_UPDATE',
	PROFILE_ABOUT_ME_UPDATE: 'PROFILE_ABOUT_ME_UPDATE',
	PROFILE_VISIBILITY_UPDATE: 'PROFILE_VISIBILITY_UPDATE',
	USER_SETTINGS_BASICS_UPDATE: 'USER_SETTINGS_BASICS_UPDATE',
	USER_SETTINGS_SECURITY_UPDATE: 'USER_SETTINGS_SECURITY_UPDATE',
	USER_SETTINGS_CONTACTS_UPDATE: 'USER_SETTINGS_CONTACTS_UPDATE',
	USER_SETTINGS_2FA_SMS_UPDATE: 'USER_SETTINGS_2FA_SMS_UPDATE',
	USER_SETTINGS_2FA_GOOGLE_CODE_UPDATE: 'USER_SETTINGS_2FA_GOOGLE_CODE_UPDATE',
	USER_SETTINGS_2FA_STATUS_UPDATE: 'USER_SETTINGS_2FA_STATUS_UPDATE',
	USER_NOTIFICATION_PREFERENCES_UPDATE: 'USER_NOTIFICATION_PREFERENCES_UPDATE',
	SPACE_CREATE: 'SPACE_CREATE',
	SPACE_CREATE_ADD_DETAILS: 'SPACE_CREATE_ADD_DETAILS',
	SPACE_CREATE_ADD_MEMBERSHIPS: 'SPACE_CREATE_ADD_MEMBERSHIPS',
	SPACE_CREATE_ADD_BENEFITS: 'SPACE_CREATE_ADD_BENEFITS',
	SPACE_CREATE_ADD_FIRST_UPDATE: 'SPACE_CREATE_ADD_FIRST_UPDATE',
	SPACE_PLAN_UPDATE: 'SPACE_PLAN_UPDATE',
	SPACE_PRO_STYLE_SET: 'SPACE_PRO_STYLE_SET',
	SPACE_PRO_NAVIGATIONS_UPDATE: 'SPACE_PRO_NAVIGATIONS_UPDATE',
	SPACE_MEMBERSHIP_CLAIM: 'SPACE_MEMBERSHIP_CLAIM',
	SPACE_MEMBERSHIP_JOIN: 'SPACE_MEMBERSHIP_JOIN',
	SPACE_MEMBERSHIP_CLAIM_AND_SIGNUP: 'SPACE_MEMBERSHIP_CLAIM_AND_SIGNUP',
	SPACE_MEMBERSHIP_CLAIM_AND_SETUP_SHARING: 'SPACE_MEMBERSHIP_CLAIM_AND_SETUP_SHARING',
	USER_SETTINGS_ADD_WALLET: 'USER_SETTINGS_ADD_WALLET'
};
