import { memo, useState } from 'react';
import routes from '../../../../routes';

import { shortenNumber } from '../../../../utils/number';

const Top8 = (parentProps) => {
	const { data, index } = parentProps;
    const goTo = (type) => {
      
        const name = data.type === 'user' ?  data.username : data.space_name;
        let url = ""
        if(type === "username"){
            url = routes.viewUserSpace() + name;
        }else{
            url = routes.viewUserSpace() + name + '/' + type;
        }
        window.location.replace(url) 
    }
	return (
        <div className={`cs-top8-item stack-row order-1`}>
            <a  className="cs-top8-avatar" onClick={() => goTo('username')}>
            <img src={data.photo} alt=""/>
            </a>
            <div className="cs-stack-top8-info stack-col">
                <h4><a onClick={() => goTo('username')}>{data.username}</a></h4>
                <div className="cs-stack-top8-stats stack-row">
                    <p><i className="fa-solid fa-user-group" onClick={() => goTo('followers')}></i>{data.followers}</p>
                    <p><i className="fa-light fa-user-group" onClick={() => goTo('followings')}></i>{data.followings}</p>
                </div>
            </div>
        </div>
			
	);
};

export default memo(Top8);
