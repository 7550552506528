import { ACTION_TYPES } from './const';

const initialState = {
	spaceInfo: null,
	spaceInfoLoading: null
};

function spaceReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.GET_SPACE_INFO:
			return { ...state, spaceInfoLoading: 'fetching' };
		case ACTION_TYPES.GET_SPACE_INFO_SUCCESS:
			return { ...state, ...action.spaceInfo, spaceInfoLoading: 'done' };
		case ACTION_TYPES.GET_SPACE_INFO_FAILED:
			return { ...state, spaceInfoLoading: 'failed' };
		default:
			return state;
	}
}

export default spaceReducer;
