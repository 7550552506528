import { memo, useEffect } from 'react';

import routes from '../../routes';
import { removeAccessToken } from '../../utils/common';
import {getLocalStorage, removeLocalStorage} from "../../utils/storage";
import {ROOT_DOMAIN} from "../../constants/common";

const Logout = () => {
	removeAccessToken('user');

	useEffect(() => {
		document.title = 'Commonspace Logout';
		if (getLocalStorage('redirect_link')) {
			removeLocalStorage('redirect_link')
		}

		if (ROOT_DOMAIN !== window.location.hostname) {
			const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
			const protocol = isLocal ? 'http://' : 'https://';
			window.location.href = `${protocol}${ROOT_DOMAIN}${routes.logout()}`;
		} else {
			window.location.replace(routes.login());
		}
	}, []);

	return (
		<main id="dashboard" className="flex">
			<div className="container mx-auto min-h-screen md:min-h-[540px]">
				<div className="grid grid-cols-12 justify-start"></div>
			</div>
		</main>
	);
};

export default memo(Logout);
