import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getSpaceItemData, getSpaceUsernameByDomain} from '../../services/space';
import default_items_img from '../../assets/images/form_background.png';
import routes from '../../routes';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { Button } from 'antd';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus} from "../../services/payment_method";
import Markdown from 'react-markdown';
import { getLocalStorage, removeLocalStorage } from '../../utils/storage';
import ReactPlayer from "react-player/lazy";
import {ROOT_DOMAIN} from "../../constants/common";

const SpaceBuyItem = (props) => {
	const { hasGradient } = props;
	const currentLocation = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const itemId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [submitted, setSubmitted] = useState(false);
	const [loadItemOnce, setLoadItemOnce] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [itemDetails, setItemDetails] = useState(null);
	const [isItemLoaded, setItemLoaded] = useState(false);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [status, setStatus] = useState('');
    const [itemBenefits, setItemBenefits] = useState();
	const [spaceUsername, setSpaceUsername] = useState(null);

	const getMembershipDetailsRequest = useCallback(async (domain) => {
		const data = {
			space_domain: domain
		}
		const response = await getSpaceUsernameByDomain(data);
		if (response && response.result) {
			setSpaceUsername(response.space_username);
		}

	}, []);

	const scrollToClick = () => {
		const element = document.getElementById('cs-space-product-item-c2');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	const getItem = useCallback(
		async (space_id, item_id) => {
			if (!item_id) return;

			const data = {
				space_id: space_id,
				item_id: item_id
			}

			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}

			const response = await getSpaceItemData(data);
			if (response && response.result) {
				const itemData = response.item;
				// setIsPaid(itemData.is_paid);
				setItemDetails(itemData);
				setItemLoaded(true);
				if (itemData.photo || itemData.video) {
					if (itemData.photo) {
						const bg = {
							backgroundImage: 'url(' + itemData.photo + ')',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover'
						};
						setCollectionAssetBGImage(bg);
						setCollectionAssetType('image');
					}
					if (itemData.video) {
						const bg = {
							backgroundImage: 'none'
						};
						setCollectionAssetBGImage(bg);
						setCollectionAssetType('video');
					}
				} else {
					const bg = {
						backgroundImage: 'url(' + default_items_img + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
			}
		},
		[authUserInfo, itemDetails]
	);

	const checkStatus = useCallback(async (space_id, item_id) => {
		if (!item_id) return;
		const data = {
			space_id: space_id,
			item_id: item_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			const sessionStatus = response.session_status;
			if (transaction) {
				if (sessionStatus !== 'open') {
					setStatus(transaction.status);
				}
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	const proceedItemPayment = useCallback(async () => {
		if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (spaceUsername) {
				setSubmitted(true);
				let url = routes.spaceItemPaymentMethod();
                if(getLocalStorage('redirect_link')){
                    removeLocalStorage('redirect_link')
                }
				const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
				navigate(fin_url);
                
			}
		} else {
			let url = routes.spaceItemPaymentMethod();
                if(getLocalStorage('redirect_link')){
                    removeLocalStorage('redirect_link')
                }
                
				const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
				navigate(fin_url);
		}

	}, [spaceUsername]);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + spaceUsername;
		window.location.replace(url);
	}, [spaceUsername]);

	useEffect(() =>{
		let benefits = spaceInfo?.items.find((x) => x.id === itemId);
		setItemBenefits(benefits?.benefits)

	}, [spaceInfo])

	useEffect(() => {
		// console.log(itemBenefits)
}, [itemBenefits])


	useEffect(() => {
		if (spaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done' && !isItemLoaded) {
				if (spaceInfo && spaceInfo.id) {
					getItem(spaceInfo.id, itemId).then((r) => {});
					setSpaceName(spaceInfo.name);
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(spaceUsername));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		if (itemDetails != null) {
			if (authUserInfo != null && !loadItemOnce) {
				setLoadItemOnce(true);
				getItem(spaceInfo.id, itemId).then((r) => {});
				checkStatus(spaceInfo.id, itemId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (itemDetails.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}
		// console.log('isDisabled: '+isDisabled)

	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		spaceUsername,
		initialLoad,
		itemId,
		isItemLoaded,
		loadItemOnce,
		itemDetails,
		isDisabled,
		status
	]);

	useEffect(() => {
		if (ROOT_DOMAIN !== window.location.hostname) {
			getMembershipDetailsRequest(window.location.hostname).then(r => {});
		} else {
			const path = currentLocation.pathname;
			if (path && path.startsWith('/@') && path.length > 3) {
				const clean_path = path.replace('/@', '');
				const path_exp = clean_path.split('/');
				setSpaceUsername(path_exp[0]);
			}
		}
	}, [currentLocation.pathname]);

	return (
		<>
			{!itemDetails ? (
				<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
					<div className="centerframe-flex frame-modal">
						<div className="block-lg modal-block">
							<i className="fa-regular fa-solar-system"/>
						</div>
					</div>
				</section>
			) : (
				<section id="cs-space-product-item-c1" className="p-section-safeview theme">
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-product-cover-media" className="block-lg rounded-card">
							<a>
								{collectionAssetType && collectionAssetType === 'video' ? (
									<ReactPlayer
										playing={true}
										loop={true}
										muted={true}
										controls={false}
										width="100%"
										height="592px"
										className={`videoPreview max-h-element`}
										url={itemDetails.video}
									/>
								) : (
									<img className="max-h-element rounded-card" src={itemDetails?.photo}/>
								)}
							</a>
						</div>
						<div id="cs-product-info" className="block-lg">
							<hgroup className="stack-col">
								<h2>{itemDetails ? itemDetails.name : ''}</h2>
								<p className="cs-product-info-summary">{itemDetails ? itemDetails.summary : ''}</p>
								<p className="cs-product-info-listing">{itemDetails ? itemDetails.listing_text : ''}</p>
							</hgroup>

							<div className="cs-product-pricing-details stack-row">
								<div className="stack-col-base">
									<h2>{getDetailsPrice(itemDetails)}</h2>
									{/* {itemDetails.max_supply > 0 && (
									<p>24 of 48 remaining</p>
								)} */}

								</div>
								<div className="stack-col-base">
									<h4><i className="fa-regular fa-hexagon-image"></i>{getDetailsGift(false, itemDetails)}</h4>
									<h4><i className="fa-regular fa-gift"></i>{itemDetails.benefits_count} Benefit{itemDetails.benefits_count > 1? 's': ''}</h4>
								</div>
							</div>

							<div id="cs-stack-product-actions" className="stack-row">
								<a  className={(submitted || isDisabled) ? 'button-primary-gradient pointer-events-none' : 'button-primary-gradient'} onClick={proceedItemPayment}><span><p>{getCommunityBtnText(authUserInfo, itemDetails, true, false, status, true)}</p></span></a>
								<a className="button-secondary-gradient" onClick={scrollToClick}><span><p>Details</p></span></a>
							</div>

						</div>
					</div>
				</section>
			)}
			{itemDetails && (
				<section id="cs-space-product-item-c2" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">

						<div className="block-full">
							<div className="cs-rte-output space-y-card">
								{itemDetails && itemDetails.description &&
									(
										<Markdown>{itemDetails.description}</Markdown>
									)
								}
							</div>
						</div>
						<div className="block-full">
							<div id="cs-product-benefits" className="stack-col">
								<div className="datarow title">
									<h3>Benefits</h3>
									<p>This Item provides Benefits for <a onClick={goToSpace} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a></p>
								</div>

								{itemBenefits && itemBenefits.map((data, index) => (
									<div className="datarow">
										<p key={index}>{data.title}</p>
										<p>{data.description} <span>1 redemption</span></p>
									</div>
								))}



							</div>

							<div id="cs-product-traits" className="stack-col">
								{/* <div className="datarow title">
                                <h3>Traits</h3>
                                <p>This Item has 3 traits</p>
                            </div>
                            <div className="datarow">
                                <p>Hidden Content</p>
                                <p>Viewable by Owner</p>
                            </div>
                            <div className="datarow">
                                <p>Properties</p>
                                <p>Status: VIP</p>
                            </div>
                            <div className="datarow">
                                <p>Boosts</p>
                                <p>+1k Community Points</p>
                            </div> */}
							</div>

							<div id="cs-product-technicals" className="stack-row-wrap">
								<div className="datarow title">
									<h3>Additional Details</h3>
									<p>Space and technical details</p>
								</div>
								<div className="datarow">
									<p>Connected Space</p>
									<p>{spaceInfo?.name}</p>
								</div>
								<div className="datarow">
									<p>Space URL</p>
									<p><a href="friendsofmike.com" target="_blank">{spaceInfo?.website}</a></p>
								</div>
								<div className="datarow">
									<p>Account Limit</p>
									<p>{itemDetails?.max_per_account}</p>
								</div>
								<div className="datarow">
									{/* <p>Transferable</p>
                                <p>Until September 1, 2024</p> */}
								</div>
							</div>
						</div>

					</div>
				</section>
			)}
		</>
	);
};

export default memo(SpaceBuyItem);
