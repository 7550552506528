import React, {memo, useState} from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";
import {STRIPE_KEY} from "../../../../../constants/common";

const StripeOnboarding = (props) => {

    const {userID, spaceID, submitted, setSubmitted, setIsOnBoardingSubmitted } = props;

    // We use `useState` to ensure the Connect instance is only initialized once
    const [stripeConnectInstance] = useState(() => {
        const formData = new FormData();
        // TODO: change to use selected user
        formData.append('user_id', userID);
        formData.append('space_id', spaceID);

        const fetchClientSecret = async () => {

            if (!submitted) {
                setSubmitted(true);
                // Fetch the AccountSession client secret
                const response = await fetch(
                    `${process.env.REACT_APP_API_DOMAIN}/api/payment_gateway/account_session/create`,
                    { method: 'POST', body: formData }
                );
                // console.log(response);
                if (!response.ok) {
                    // Handle errors on the client side here
                    const { error } = await response.json();
                    // console.error('An error occurred: ', error);
                    document.querySelector('#error').removeAttribute('hidden');
                    setSubmitted(false);
                    return undefined;
                } else {
                    const { client_secret: clientSecret } = await response.json();
                    // document.querySelector('#error').setAttribute('hidden', '');
                    // console.log(clientSecret);
                    setSubmitted(false);
                    return clientSecret;
                }
            } else {
                setSubmitted(false);
                return undefined;
            }

        };

        // console.log(process.env.REACT_APP_STRIPE_KEY)
        // console.log(process.env.REACT_APP_API_DOMAIN)

        return loadConnectAndInitialize({
            // This is your test publishable API key.
            publishableKey: process.env.REACT_APP_STRIPE_KEY,
            fetchClientSecret: fetchClientSecret
        });
    });

    return (
        <>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectAccountOnboarding
                    onExit={() => {
                        console.log('The account has exited onboarding');
                        setIsOnBoardingSubmitted(true);
                    }}
                    // Optional: make sure to follow our policy instructions above
                    // fullTermsOfServiceUrl="{{URL}}"
                    // recipientTermsOfServiceUrl="{{URL}}"
                    // privacyPolicyUrl="{{URL}}"
                    // skipTermsOfServiceCollection={false}
                    // collectionOptions={{
                    //   fields: 'eventually_due',
                    //   futureRequirements: 'include',
                    // }}
                />
            </ConnectComponentsProvider>
        </>
    );
}

export default memo(StripeOnboarding);