import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { addSpaceContact, sendEmailSpaceContact } from '../../../../services/space';
import Alert from 'react-bootstrap/Alert';
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";
import {CustomValidators} from "../../../../utils/CustomValidators";

const SpaceContact = (props) => {
	const [contactForm] = Form.useForm();
	const [isContactSubmit, setContactSubmit] = useState(false);
	const [alert, setAlert] = useState(null);
	const { spaceData, space_info_slug } = props;
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(false);

	const handleContactFormOnBlur = useCallback(async () => {
		await contactForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleContactSubmit = useCallback(
		async (values) => {
			handleContactFormOnBlur().then(r => {});
			const data = values;
			setContactSubmit(true);
			data.space_id = spaceData.id;
			data.space_info_slug = space_info_slug;

			try {
				const response = await addSpaceContact(data);
				if (response && response.result) {
					await sendEmail(data);
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
				}
				setContactSubmit(false);
			} catch (error) {
				setContactSubmit(false);
				setAlert({
					variant: 'danger',
					message: error
				});
				console.log(error);
			}

			setTimeout(function () {
				setAlert(false);
			}, 2000);
		},
		[spaceData, isContactSubmit, alert]
	);

	const sendEmail = async (values) => {
		try {
			const result = await sendEmailSpaceContact(values);
			if (result && result.result) {
				contactForm.resetFields();
				setAlert({
					variant: 'success',
					message: result.message
				});
			} else {
				setAlert({
					variant: 'danger',
					message: result.message
				});
			}
			setContactSubmit(false);
		} catch (error) {
			setContactSubmit(false);
			setAlert({
				variant: 'danger',
				message: error
			});
			console.log(error);
		}
	};

	return (
		<>
			<Form
				form={contactForm}
				name='contactForm'
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					// setTimeout(() => {
					setFormIsOnFocus(false);
					handleContactFormOnBlur().then(r => {});
					// }, 300);
				}}
				onFinish={handleContactSubmit}
				className="cs-stack-contact-form stack-col"
			>
				<div className="stack-row">
					<div className="form-input air">
						<label htmlFor="name">Name</label>
						<Form.Item
							label='Name'
							name='name'
							validateTrigger="onBlur"
							rules={[{ required: true, type: 'string' }]}
							className="w-full"
						>
							<FormFieldStacked
								formType='input'
								type="text"
								disabled={isContactSubmit}
								value={contactForm.getFieldValue('name')}
							/>
						</Form.Item>
						{contactForm.getFieldError('name')?.length > 0 && (
							<div className="alert callout warning ">
								<div className="action">
									<i className="fa-solid fa-triangle-exclamation"></i>
								</div>
								<div className="content">
									<div>
										<h6>{contactForm.getFieldError('name')[0]}</h6>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="form-input air">
						<label htmlFor="email">Email</label>
						<Form.Item
							label='Email'
							name='email'
							validateTrigger="onBlur"
							className="w-full"
							rules={[
								{ required: true },
								{ validator: CustomValidators.email }
							]}
						>
							<FormFieldStacked
								formType='input'
								type="text"
								disabled={isContactSubmit}
								value={contactForm.getFieldValue('email')}
							/>
						</Form.Item>
						{contactForm.getFieldError('email')?.length > 0 && (
							<div className="alert callout warning ">
								<div className="action">
									<i className="fa-solid fa-triangle-exclamation"></i>
								</div>
								<div className="content">
									<div>
										<h6>{contactForm.getFieldError('email')[0]}</h6>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="form-input air">
					<label htmlFor="subject">Subject</label>
					<Form.Item
						label='Subject'
						name='subject'
						validateTrigger="onBlur"
						rules={[{ required: true, type: 'string' }]}
						className="w-full"
					>
						<FormFieldStacked
							formType='input'
							type="text"
							disabled={isContactSubmit}
							value={contactForm.getFieldValue('subject')}
						/>
					</Form.Item>
					{contactForm.getFieldError('subject')?.length > 0 && (
						<div className="alert callout warning ">
							<div className="action">
								<i className="fa-solid fa-triangle-exclamation"></i>
							</div>
							<div className="content">
								<div>
									<h6>{contactForm.getFieldError('subject')[0]}</h6>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="form-textarea air">
					<label htmlFor="message">Message</label>
					<Form.Item
						label='Message'
						name='message'
						validateTrigger="onBlur"
						className="w-full"
						rules={[
							{ required: true },
							{
								max: 255,
								message: 'Must not exceed 255 characters.'
							}
						]}
					>
						<FormFieldStacked
							formType='textarea'
							rows={3}
							disabled={isContactSubmit}
							value={contactForm.getFieldValue('message')}
						/>
					</Form.Item>
					{contactForm.getFieldError('message')?.length > 0 && (
						<div className="alert callout warning ">
							<div className="action">
								<i className="fa-solid fa-triangle-exclamation"></i>
							</div>
							<div className="content">
								<div>
									<h6>{contactForm.getFieldError('message')[0]}</h6>
								</div>
							</div>
						</div>
					)}
				</div>
				{alert && (
					<section className={"alert banner px-section-safeview" + (alert.variant === 'success' ? ' success' : ' error')}>
						<div className="action">
							<i className="fa-solid fa-circle-check"></i>
						</div>
						<div className="content">
							<hgroup>
								<h6></h6>
								<p>{alert.message}</p>
							</hgroup>
						</div>
						<div className="action">
							<a onClick={() => {setAlert(null)}}>
								<i className="fa-solid fa-xmark"></i>
							</a>
						</div>
					</section>
				)}
				<button
					className={"primary-gradient" + (formHasError ? ' disabled' : '')}
					type='submit'
					disabled={isContactSubmit || formHasError}
				>
					<span>
						<p>
							{isContactSubmit && (
								<i className="fa-solid fa-spinner fa-spin"></i>
							)}
							Submit
						</p>
					</span>
				</button>
			</Form>
			{/*<section id="contact" className={'p-3'}>*/}
			{/*	<h3 className="leading-tight header-large">Contact</h3>*/}

			{/*	<Form*/}
			{/*		form={contactForm}*/}
			{/*		name={'contactForm'}*/}
			{/*		id={'contactForm'}*/}
			{/*		validateMessages={VALIDATE_MESSAGES}*/}
			{/*		onFinish={handleContactSubmit}*/}
			{/*		className={''}*/}
			{/*	>*/}
			{/*		<div className={'grid grid-cols-12 gap-2 md:gap-8 '}>*/}
			{/*			<div className={'col-span-12 md:col-span-6'}>*/}
			{/*				<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>*/}
			{/*					<label className={'body-text--small'}>Name*</label>*/}
			{/*					<Form.Item*/}
			{/*						name={'name'}*/}
			{/*						onFocus={hideTooltip}*/}
			{/*						onBlur={showTooltip}*/}
			{/*						rules={[{ required: true, type: 'string' }]}*/}
			{/*					>*/}
			{/*						<Input className={'input-md'} disabled={isContactSubmit} />*/}
			{/*					</Form.Item>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className={'col-span-12 md:col-span-6'}>*/}
			{/*				<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>*/}
			{/*					<label className={'body-text--small'}>Email*</label>*/}
			{/*					<Form.Item*/}
			{/*						name={'email'}*/}
			{/*						onFocus={hideTooltip}*/}
			{/*						onBlur={showTooltip}*/}
			{/*						rules={[{ required: true, type: 'email' }]}*/}
			{/*					>*/}
			{/*						<Input className={'input-md'} disabled={isContactSubmit} />*/}
			{/*					</Form.Item>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className={'grid grid-cols-12 gap-2 md:gap-8 '}>*/}
			{/*			<div className={'col-span-12'}>*/}
			{/*				<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>*/}
			{/*					<label className={'body-text--small'}>Subject*</label>*/}
			{/*					<Form.Item*/}
			{/*						name={'subject'}*/}
			{/*						onFocus={hideTooltip}*/}
			{/*						onBlur={showTooltip}*/}
			{/*						rules={[{ required: true, type: 'string' }]}*/}
			{/*					>*/}
			{/*						<Input className={'input-md '} disabled={isContactSubmit} />*/}
			{/*					</Form.Item>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className={'grid grid-cols-12 gap-2 md:gap-8'}>*/}
			{/*			<div className={'col-span-12'}>*/}
			{/*				<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>*/}
			{/*					<label className={'body-text--small'}>Message*</label>*/}
			{/*					<Form.Item*/}
			{/*						name={'message'}*/}
			{/*						onFocus={hideTooltip}*/}
			{/*						onBlur={showTooltip}*/}
			{/*						rules={[{ required: true, type: 'string' }]}*/}
			{/*					>*/}
			{/*						<Input className={'input-md'} disabled={isContactSubmit} />*/}
			{/*					</Form.Item>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className={'grid grid-cols-12 gap-2 md:gap-8 mt-8'}>*/}
			{/*			<div className={'col-span-12'}>*/}
			{/*				{alert && (*/}
			{/*					<div className="mb-1">*/}
			{/*						<Alert*/}
			{/*							key={alert.variant}*/}
			{/*							variant={alert.variant}*/}
			{/*							className={*/}
			{/*								'custom-alert ' +*/}
			{/*								(alert.variant === 'danger'*/}
			{/*									? 'custom-alert-danger'*/}
			{/*									: 'custom-alert-success')*/}
			{/*							}*/}
			{/*						>*/}
			{/*							{alert.message}*/}
			{/*						</Alert>*/}
			{/*					</div>*/}
			{/*				)}*/}

			{/*				<Form.Item>*/}
			{/*					<Button*/}
			{/*						className={'btn btn-secondary btn-lg'}*/}
			{/*						type={'submit'}*/}
			{/*						disabled={isContactSubmit}*/}
			{/*					>*/}
			{/*						{isContactSubmit && (*/}
			{/*							<i className={'fa-solid fa-spinner fa-spin'} />*/}
			{/*						)}*/}
			{/*						Submit*/}
			{/*					</Button>*/}
			{/*				</Form.Item>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</Form>*/}
			{/*</section>*/}
		</>
	);
};

export default memo(SpaceContact);
