import { memo, useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, InputNumber } from 'antd';
import moment from 'moment/moment';
import dayjs from 'dayjs';

import { VALIDATE_MESSAGES } from '../../../../../constants/common';
import { CARD_TYPES } from '../../../../../constants/payment_method';
import { hideTooltip, showTooltip } from '../../../../../utils/input';
import { getListDisplay, getListIcon } from '../../../../../utils/list';
import DropdownWithCountries from '../../../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries';
import DropdownWithRegions from '../../../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions';
import { getCode, getName } from 'country-list';
import Alert from "react-bootstrap/Alert";
import FormFieldStacked from '../../../../../components/commons/FormFieldStacked';

const Card = (parentProps) => {
	const { data, onEventClick, updateId, submittedUpdateCardForm, alert } = parentProps;
	const [editCardForm] = Form.useForm();
	const expiry_mm = data.expiry_mm < 10 ? '0' + data.expiry_mm.toString() : data.expiry_mm;
	const expiry_yy_str = data.expiry_yy.toString();
	const expiry_yy = expiry_yy_str[2] + '' + expiry_yy_str[3];
	const added_on = moment(data.added_on).format('MM/YY');
	const expiry_date = data.expiry_yy + '-' + expiry_mm + '-01';
	const showUpdateCardForm = data.id === updateId;

	const [cardNumber, setCardNumber] = useState(null);
	const [expiry, setExpiry] = useState(null);
	const [cvc, setCvc] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [streetAddress, setStreetAddress] = useState(null);
	const [city, setCity] = useState(null);
	const [address, setAddress] = useState(null);
	const [zip, setZip] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const { getCode, getName } = require('country-list');

	const handleFormChanges = useCallback(async () => {
		setCardNumber(editCardForm.getFieldValue('card_number'));
		setExpiry(editCardForm.getFieldValue('expiry'));
		setCvc(editCardForm.getFieldValue('cvc'));
		setFullName(editCardForm.getFieldValue('full_name'));
		setStreetAddress(editCardForm.getFieldValue('street_address'));
		setCity(editCardForm.getFieldValue('city'));
		setAddress(editCardForm.getFieldValue('address'));
		setZip(editCardForm.getFieldValue('zip'));
		setPhoneNumber(editCardForm.getFieldValue('phone_number'));
	}, []);

	const submitUpdateCardForm = useCallback(
		async (values) => {
			try {
				const obj = values;
				setDisplayDropDownError(true);

				if (!selectedCountry || selectedCountry === '') {
					return;
				}
				if (!selectedRegion || selectedRegion === '') {
					return;
				}

				obj.payment_method_id = data.id;

				let countryCode = getCode(selectedCountry);
				if (!countryCode) {
					if (selectedCountry === 'United States') {
						countryCode = getCode('United States of America');
					}
				}

				obj.country = countryCode;
				obj.state = selectedRegion;
				obj.expiry_mm = values.expiry.format('MM');
				obj.expiry_yy = values.expiry.format('YYYY');

				delete obj.expiry;

				onEventClick('submit-update', obj);
			} catch (error) {
				console.log(error);
			}
		},
		[selectedCountry, selectedRegion]
	);

	const deletePaymentMethod = useCallback(async () => {
		const obj = {
			payment_method_id: data.id
		};
		onEventClick('remove', obj);
	}, [data]);

	const updatePaymentMethod = useCallback(async () => {
		if (updateId) {
			onEventClick('cancel-update', data);
		} else {
			onEventClick('update', data);
		}
	}, [data, updateId]);

	const cancelUpdateCardForm = useCallback(async () => {
		onEventClick('cancel-update', data);
	}, [data]);

	useEffect(() => {
		if (data) {
			editCardForm.setFieldsValue({
				card_number: 'XXXX XXXX XXXX ' + data.last_four,
				expiry: dayjs(expiry_date),
				full_name: data.full_name,
				street_address: data.street_address,
				city: data.city,
				address: data.address,
				zip: data.zip,
				phone_number: data.phone_number
			});
			setCardNumber(data.card_number);
			setExpiry(dayjs(expiry_date));
			setFullName(data.full_name);
			setStreetAddress(data.street_address);
			setCity(data.city);
			setAddress(data.address);
			setZip(data.zip);
			setPhoneNumber(data.phone_number);

			if (data.country) {
				let countryName = getName(data.country);
				if (countryName === 'United States of America') {
					countryName = 'United States';
				}
				setSelectedCountry(countryName);
			}

			setSelectedRegion(data.state);
		}
	}, [data]);

	return (
		<>
			<div className="row-view-wrapper mt-4 !bg-[#ffffff80]">
				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
					<div className="col-span-12 md:col-span-4 md:flex items-center">
						<i className={'card-ico ' + getListIcon(CARD_TYPES, data.card_type)}></i>
						<label className="header-small">
							{data.card_type ? (
								<>{getListDisplay(CARD_TYPES, data.card_type)}</>
							) : (
								<>Card</>
							)}{' '}
							ending in {data.last_four}
						</label>
					</div>
					<div className="col-span-12 md:col-span-4 md:flex items-center">
						<p className="body-txtitalic--smallest m-0">
							Added {added_on}{' '}
							<span className="exp ms-2">
								Expires {expiry_mm}/{expiry_yy}
							</span>
						</p>
					</div>
					<div className="col-span-12 md:col-span-4 flex items-center justify-start md:justify-end">
						<Button className="btn-row-view" onClick={deletePaymentMethod}>
							<i className="fa-regular fa-trash-can"></i>
						</Button>
						<Button className="btn-row-view ms-4" onClick={updatePaymentMethod}>
							<i className="fa-light fa-money-check-pen"></i>
						</Button>
					</div>
				</div>
				<div
					className={'dashboard-wrapper !bg-[#ffffff80] ' + (!showUpdateCardForm ? 'hide-form' : '')}
				>
					<Form
						form={editCardForm}
						name="editCardForm"
						validateMessages={VALIDATE_MESSAGES}
						onChange={handleFormChanges}
						onFinish={submitUpdateCardForm}
					>
						<div className="grid grid-cols-12 pt-3">
							<div className="col-span-6">
								<h5 className="header-small">Update Credit Card</h5>
							</div>
							<div className="col-span-6">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									Secured by Stripe
								</p>
							</div>
						</div>
						<div className='grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 items-end'>
							<>
								{alert && (
									<div className="col-span-12">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								)}
							</>
							
							<div className="col-span-12 md:col-span-6 !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Card Number
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Card Number"
										name="card_number"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>										
											<FormFieldStacked
											formType='input'
											type="text"	
											placeholder='15 or 16 digits'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Expiry
								</label>
								<div className="relative rounded-none h-[40px] w-full text-gray-900 flex items-center ">
									<Form.Item
										label="Expiry"
										name="expiry"
										className="mb-0 hidden-label h-[40px] w-full "
										rules={[
											{
												type: 'object',
												required: true
											}
										]}
									>
										<DatePicker
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="body-text--small !text-lg h-[40px] w-full !bg-[#ffffff80] border-none"
											picker="month"
											format="MM/YY"
											placeholder="MM/YY"
										/>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								CVV/CVC
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="CVV/CVC"
										name="cvc"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
										
										<FormFieldStacked
										formType='input'
										type="number"
										placeholder='3 or 4 digits'>											
										</FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
						</div>
						<div className='grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 items-end'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Full Name
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Full Name"
										name="full_name"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
									
											<FormFieldStacked
											formType='input'
											type="text"	
											placeholder='As shown on credit card'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Country
								</label>
								<div className="relative rounded-none border-none ">
									<DropdownWithCountries
										title="Country"
										displayError={
											displayDropDownError &&
											(!selectedCountry || selectedCountry === '')
										}
										value={selectedCountry}
										onDropdownChange={setSelectedCountry}
									></DropdownWithCountries>
								</div>
								
							</div>	
							
						</div>
						<div className='grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 items-end'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Street Address
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Street Address"
										name="street_address"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
						
										<FormFieldStacked
											formType='input'
											type="text"	
											placeholder='Number and Street'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								State
								</label>
								<div className="relative rounded-none border-0 ">
									<DropdownWithRegions
										title="State"
										displayError={
											displayDropDownError &&
											(!selectedRegion || selectedRegion === '')
										}
										country={selectedCountry}
										value={selectedRegion}
										onDropdownChange={setSelectedRegion}
									></DropdownWithRegions>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								APT/STE/BLDG
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="APT/STE/BLDG"
										name="address"
										className="mb-0 hidden-label"
									>
									
											<FormFieldStacked
											formType='input'
											type="text"	
											placeholder='OPTIONAL'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
						</div>
						<div className='grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Zip
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Zip"
										name="zip"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>									
									<FormFieldStacked
									formType='input'
									type="number"
									placeholder='5 digits'>											
									</FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Phone
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Phone"
										name="phone_number"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
								
										<FormFieldStacked
										formType='input'
										type="text"	
										placeholder='10 digits'
										></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
						</div>	
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 pt-4">
							<div className="col-span-6">
								<Button
									className="btn btn-secondary btn-md"
									disabled={submittedUpdateCardForm}
									onClick={cancelUpdateCardForm}
								>
									{submittedUpdateCardForm && (
										<>
											<i className="fa-solid fa-spin fa-spinner"></i>
										</>
									)}
									Cancel
								</Button>
							</div>
							<div className="col-span-6">
								<Form.Item>
									<Button
										className="btn btn-primary btn-md"
										type="submit"
										disabled={submittedUpdateCardForm}
									>
										{submittedUpdateCardForm && (
											<>
												<i className="fa-solid fa-spin fa-spinner"></i>
											</>
										)}
										Verify
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};

export default memo(Card);
