import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSpaceInformations, setSpaceInformation, setSpacePageInformation} from '../../../services/space';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import ReactQuill from 'react-quill';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const AddPages = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [aboutPageForm] = Form.useForm();
	const [faqPageForm] = Form.useForm();
	const [supportPageForm] = Form.useForm();
	const [communityGuidelinesPageForm] = Form.useForm();
	const [tosPageForm] = Form.useForm();
	const [privacyPageForm] = Form.useForm();
	const [email, setEmail] = useState('');
	const [aboutPageContent, setAboutPageContent] = useState('');
	const [faqPageContent, setFaqPageContent] = useState('');
	const [supportPageContent, setSupportPageContent] = useState('');
	const [communityGuidelinesPageContent, setCommunityGuidelinesPageContent] = useState('');
	const [tosPageContent, setTosPageContent] = useState('');
	const [privacyPageContent, setPrivacyPageContent] = useState('');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [spaceInformations, setSpaceInformations] = useState([]);
	const [isInfoLoaded, setIsInfoLoaded] = useState(false);
	const [updateOnce, setUpdateOnce] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(false);

	const [submitType, setSubmitType] = useState('');

	const [formIsOnFocusAbout, setFormIsOnFocusAbout] = useState(false);
	const [formIsOnFocusFaq, setFormIsOnFocusFaq] = useState(false);
	const [formIsOnFocusSupport, setFormIsOnFocusSupport] = useState(false);
	const [formIsOnFocusTos, setFormIsOnFocusTos] = useState(false);
	const [formIsOnFocusPrivacy, setFormIsOnFocusPrivacy] = useState(false);

	const [formHasErrorAbout, setFormHasErrorAbout] = useState(true);
	const [formHasErrorFaq, setFormHasErrorFaq] = useState(true);
	const [formHasErrorSupport, setFormHasErrorSupport] = useState(true);
	const [formHasErrorTos, setFormHasErrorTos] = useState(true);
	const [formHasErrorPrivacy, setFormHasErrorPrivacy] = useState(true);

	// const [isSaveGoBack, setSaveGoback] = useState(false);

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
		[{ font: [] }],
		[{ header: 1 }, { header: 2 }], // custom button values
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
		[{ direction: 'rtl' }], // text direction
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],
		['clean'] // remove formatting button
	];

	const handleFormChanges = useCallback(async () => {
		setEmail(aboutPageForm.getFieldValue('email'));

		await aboutPageForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleQuery = async (values, page_title) => {
		setIsLoading(true);
		const data = values;
		if (authUserInfo && spaceInfo) {
			try {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;
				data.page_title = page_title;
				data.page_slug = (page_title + data.space_id)
					.toLowerCase()
					.replace(/\s/g, '');
				if (data.page_slug === 'about' + data.space_id) {
					data.email = aboutPageForm.getFieldValue('email');
					data.page_content = aboutPageContent;
				} else if (data.page_slug === 'frequentlyaskedquestions' + data.space_id) {
					data.page_content = faqPageContent;
				} else if (data.page_slug === 'getsupport' + data.space_id) {
					data.page_content = supportPageContent;
				} else if (data.page_slug === 'communityguidelines' + data.space_id) {
					data.page_content = communityGuidelinesPageContent;
				} else if (data.page_slug === 'termsofservice' + data.space_id) {
					data.page_content = tosPageContent;
				} else if (data.page_slug === 'privacypolicy' + data.space_id) {
					data.page_content = privacyPageContent;
				}
				const spaceInformation = await setSpaceInformation(data);
				if (spaceInformation) {
					setIsLoading(false);
					// setIsLoading(false);
					// setSelectedCategory(slug);
					// form.setFieldValue('category', '');
					// setModalOpen(false);
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				// setIsLoading(false);
				setIsLoading(false);
				console.log(error);
			}
		} else {
			setIsLoading(false);
		}
	};

	const handleArrayQuery = async (isSaveGoBack) => {
		const data = {};
		setIsLoading(true);
		// console.log('isSaveGoBack: '+isSaveGoBack)
		if (authUserInfo && spaceInfo) {
			try {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;
				data.email = aboutPageForm.getFieldValue('email');


				data.page_slug_about = 'about'+spaceInfo.id;
				data.page_slug_faq = 'frequentlyaskedquestions'+spaceInfo.id;
				data.page_slug_support = 'getsupport'+spaceInfo.id;
				data.page_slug_tos = 'termsofservice'+spaceInfo.id;
				data.page_slug_privacy = 'privacypolicy'+spaceInfo.id;


				// data.page_slug_about = getSlug('about', authUserInfo.id, spaceInfo.id);
				// data.page_slug_faq = getSlug('faq', authUserInfo.id, spaceInfo.id);
				// data.page_slug_support = getSlug('support', authUserInfo.id, spaceInfo.id);
				// data.page_slug_tos = getSlug('tos', authUserInfo.id, spaceInfo.id);
				// data.page_slug_privacy = getSlug('privacy', authUserInfo.id, spaceInfo.id);

				data.page_content_about = aboutPageContent;
				data.page_content_faq = faqPageContent;
				data.page_content_support = supportPageContent;
				data.page_content_tos = tosPageContent;
				data.page_content_privacy = privacyPageContent;

				const url = routes.createSpacePayment();
				data.last_step_link = url.replace(':space_username', space_username);

				const spaceInformation = await setSpacePageInformation(data);
				if (spaceInformation) {
					if (isSaveGoBack) {
						// console.log('createSpaceAddBusiness')
						const url = routes.createSpaceAddBusiness();
						navigate(url.replace(':space_username', space_username));
					}else {
						// console.log('createSpacePayment')
						const url = routes.createSpacePayment();
						navigate(url.replace(':space_username', space_username));
					}

					setIsLoading(false);
					// setSelectedCategory(slug);
					// form.setFieldValue('category', '');
					// setModalOpen(false);
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
	};

	const getSlug = (title, usdId, spaceId) => {
		return (title + usdId + spaceId + '').toLowerCase().replace(/\s/g, '');
	};

	const handleArraySubmit = useCallback(async (isSaveGoBack) => {
		handleArrayQuery(isSaveGoBack);
	}, [
		authUserInfo,
		spaceInfo,
		aboutPageContent,
		faqPageContent,
		supportPageContent,
		communityGuidelinesPageContent,
		tosPageContent,
		privacyPageContent,
		email
	]);

	const handleSubmitAbout = useCallback(
		async (values) => {
			handleQuery(values, 'About');
		},
		[authUserInfo, spaceInfo, aboutPageContent, email]
	);

	const handleSubmitFaq = useCallback(
		async (values) => {
			handleQuery(values, 'Frequently Asked Questions');
		},
		[authUserInfo, spaceInfo, faqPageContent]
	);

	const handleSubmitSupport = useCallback(
		async (values) => {
			handleQuery(values, 'Get Support');
		},
		[authUserInfo, spaceInfo, supportPageContent]
	);

	const handleSubmitCommunityGuideline = useCallback(
		async (values) => {
			handleQuery(values, 'Community Guidelines');
		},
		[authUserInfo, spaceInfo, communityGuidelinesPageContent]
	);

	const handleSubmitTos = useCallback(
		async (values) => {
			handleQuery(values, 'Terms of Service');
		},
		[authUserInfo, spaceInfo, tosPageContent]
	);

	const handleSubmitPrivacy = useCallback(
		async (values) => {
			handleQuery(values, 'Privacy Policy');
		},
		[authUserInfo, spaceInfo, privacyPageContent]
	);

	const saveContinue = () => {
		// setSaveGoback(false);
		// console.log('saveContinue: false', isSaveGoBack);
		setSubmitType('save_continue');
		handleArraySubmit(false);
	};

	const saveBack = () => {
		// setSaveGoback(true);
		// console.log('saveBack: true', isSaveGoBack);
		handleArraySubmit(true);
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		if (!isInfoLoaded && authUserInfo && spaceInfo) {
			fetchSpaceInformations();
		}

		if (!updateOnce && isInfoLoaded && spaceInformations != null && spaceInformations.length > 0) {
			spaceInformations.map((item) => {
				if (item.page_slug === 'about' + item.space_id) {
					setEmail(item.email);
					setAboutPageContent(item.page_content);
				} else if (item.page_slug === 'frequentlyaskedquestions' + item.space_id) {
					setFaqPageContent(item.page_content);
				} else if (item.page_slug === 'getsupport' + item.space_id) {
					setSupportPageContent(item.page_content);
				} else if (item.page_slug === 'communityguidelines' + item.space_id) {
					setCommunityGuidelinesPageContent(item.page_content);
				} else if (item.page_slug === 'termsofservice' + item.space_id) {
					setTosPageContent(item.page_content);
				} else if (item.page_slug === 'privacypolicy' + item.space_id) {
					setPrivacyPageContent(item.page_content);
				}
			})

			setUpdateOnce(true);
		}
		aboutPageForm.setFieldValue('about', aboutPageContent);
		aboutPageForm.setFieldValue('email', email);
		faqPageForm.setFieldValue('faq', faqPageContent);
		supportPageForm.setFieldValue('supportContent', supportPageContent);
		communityGuidelinesPageForm.setFieldValue('communityguidelines', communityGuidelinesPageContent);
		tosPageForm.setFieldValue('tos', tosPageContent);
		privacyPageForm.setFieldValue('privacy', privacyPageContent)
		getFormContentError();

		document.title = 'Commonspace Spaces';
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		isLoading,
		spaceInformations,
		isInfoLoaded,
		email,
		aboutPageContent,
		faqPageContent,
		supportPageContent,
		communityGuidelinesPageContent,
		tosPageContent,
		privacyPageContent,
		// isSaveGoBack
	]);




	const fetchSpaceInformations = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					// user_id: authUserInfo.id,
					space_id: spaceInfo.id
				};

				const response = await getSpaceInformations(data);
				if (response && response.result) {
					if (response.space_informations) {
						setSpaceInformations(response.space_informations);
						setIsInfoLoaded(true);
					} else {
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo, spaceInfo, spaceInformations]);

	const handleFormOnBlur = useCallback(async (form) => {
		await form
			.validateFields()
			.then(() => {
				getFormError(form, false);
			})
			.catch((errors) => {
				getFormError(form, true);
			});
	}, []);

	const getFormError = (form, value) => {
		if (form === aboutPageForm) {
			setFormHasErrorAbout(value);
		}
	}

	const getFormContentError = () => {
		const about = aboutPageForm.getFieldValue('about');
		const email = aboutPageForm.getFieldValue('email')
		setFormHasErrorAbout(about === '' || email === '');

		const faq = faqPageForm.getFieldValue('faq');
		setFormHasErrorFaq(faq === '');

		const supportContent = supportPageForm.getFieldValue('supportContent');
		setFormHasErrorSupport(supportContent === '');

		const tos = tosPageForm.getFieldValue('tos');
		setFormHasErrorTos(tos === '');

		const privacy = privacyPageForm.getFieldValue('privacy');
		setFormHasErrorPrivacy(privacy === '');
	}

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<div className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Information Pages</h1>
									<h3>Step 2 of 5</h3>
								</div>
								<p>
									Like any website, your Space needs to have About, FAQ, Support,
									Terms of Service, and Privacy Policy pages. This is important to
									help visitors and members, to protect your business, and to comply
									with privacy regulations. All pages are required and you can update
									them at any time. Be sure to review each page as you complete them.{' '}
									{getSitewideLink('additional_details_info_page', '')}
								</p>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>About Page</h2>
									</div>
									{getSitewideLink('view_guide_information_page', 'button-secondary-gradient', true)}
								</div>
								<p>
									Your About Page introduces yourself and/or your business or
									organization. You can add any content you like and often this
									includes your mission, story, history, team members, company
									information, and more. It can also be helpful to explain the
									purpose of your Space for your community and how Members can use
									the site. This page includes a Contact Form which anyone can
									fill out which will be sent to the email you specify below.
								</p>
								<Form
									form={aboutPageForm}
									name="aboutPageForm"
									id="aboutPageForm"
									onFinish={handleSubmitAbout}
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocusAbout(true);
									}}
									onBlur={() => {
										setFormIsOnFocusAbout(false);
										handleFormOnBlur(aboutPageForm);
									}}
									onChange={() => {
										handleFormChanges(aboutPageForm);
									}}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>About Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												For Basic Spaces, the About Page is linked on the main
												navigation.
											</p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={aboutPageContent}
												onChange={setAboutPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												For Pro Spaces can be configured to appear on the main
												navigation and/or in the footer links.
											</p>
											<p>
												You can style your text, add images, videos, and more. Be
												sure to save frequently if you’re adding lots of content.
											</p>
										</div>
									</div>
									<Form.Item
										name="email"
										validateTrigger="onBlur"
										rules={[{ required: true, type: 'email' }]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Form Email*</label>
												<input
													id="text"
													name="text"
													type="text"
													value={email}
													placeholder="Enter the email that will receive Contact and Support Form inquiries."
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This email will receive all completed Contact and Support
													Form inquiries and can be changed as needed.
												</p>
											</div>
										</div>
									</Form.Item>
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>FAQ Page</h2>
									</div>
								</div>
								<p>
									A Frequently Asked Questions Page helps visitors and Members
									find answers to common questions they may have about your Space.
									You can include details about how your Memberships will work,
									how Members can use your Custom Benefits, or how Items tie into
									your business or products. It’s a good idea to update this
									regularly to minimize any support requests you get and to help
									create a great member experience.
								</p>
								<Form
									form={faqPageForm}
									name="faqPageForm"
									id="faqPageForm"
									onFinish={handleSubmitFaq}
									validateMessages={VALIDATE_MESSAGES}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>FAQ Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												For Basic Spaces, the FAQ Page is part of the Support section
												link on the main navigation.
											</p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={faqPageContent}
												onChange={setFaqPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												For Pro Spaces it can be configured to appear on the main
												navigation and/or in the default location in the footer
												links.
											</p>
											<p>
												You can style your text, add images, videos, and more. Be
												sure to save frequently if you are adding lots of content.
											</p>
										</div>
									</div>
									<div className="formrow">
										<div></div>
										<div className="cs-formrow-helper-none">
											<p>
												{getSitewideLink('guide_recommended_questions', '')}
											</p>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Support Page</h2>
									</div>
								</div>
								<p>
									Your Support Page should guide visitors and Members on how to
									get help and can also include links to resources, updates, or
									other pages for further information. There is also an automatic
									link button to your Contact Form. If you have an existing
									support site/system or want list different emails/links for
									specific uses, you can include it here as an image button or
									link too.
								</p>
								<Form
									form={supportPageForm}
									name="supportPageForm"
									id="supportPageForm"
									onFinish={handleSubmitSupport}
									validateMessages={VALIDATE_MESSAGES}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>Support Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												For Basic Spaces, the Support Page link on the main
												navigation.
											</p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={supportPageContent}
												onChange={setSupportPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												For Pro Spaces it, can be configured to appear on the main
												navigation and/or in the default location in the footer
												links.
											</p>
											<p>
												You can style your text, add images, videos, and more. Be
												sure to save frequently if you are adding lots of content.
											</p>
										</div>
									</div>
									<div className="formrow">
										<div></div>
										<div className="cs-formrow-helper-none">
											<p>
												{getSitewideLink('member_get_technical_support', '')}
											</p>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Community Guidelines Page</h2>
									</div>
								</div>
								<p></p>
								<Form
									form={communityGuidelinesPageForm}
									name="communityGuidelinesPageForm"
									id="communityGuidelinesPageForm"
									onFinish={handleSubmitCommunityGuideline}
									validateMessages={VALIDATE_MESSAGES}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>Community Guidelines Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p></p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={communityGuidelinesPageContent}
												onChange={setCommunityGuidelinesPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p></p>
											<p></p>
										</div>
									</div>
									{/*<div className="formrow">*/}
									{/*	<div></div>*/}
									{/*	<div className="cs-formrow-helper-none">*/}
									{/*		<p></p>*/}
									{/*	</div>*/}
									{/*</div>*/}
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Terms of Service Page</h2>
									</div>
								</div>
								<p>
									Your Terms of Service is a very important legal page which
									states the way in which your products, services and/or content
									on your Space may be used by visitors and Members. It is agreed
									to by Members on Signup and is legally binding. The purpose of
									this is to dictate how users can use your Space and protect you
									from potential liabilities.{' '}
									{getSitewideLink('space_hosted_by_commonspace', '')}
								</p>
								<Form
									form={tosPageForm}
									name="tosPageForm"
									id="tosPageForm"
									onFinish={handleSubmitTos}
									validateMessages={VALIDATE_MESSAGES}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>Terms of Service Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												For all Spaces, the Terms of Service is linked and agreed to by
												Members during Member Signup.
											</p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={tosPageContent}
												onChange={setTosPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												For Basic Spaces, it is also linked from the Support Page.
											</p>
											<p>
												For Pro Spaces, the default link location is in the footer
												links.
											</p>
											<p>
												You can style your text, add images, videos, and more. Be
												sure to save frequently if you are adding lots of content.
											</p>
										</div>
									</div>
									<div className="formrow">
										<div></div>
										<div className="cs-formrow-helper-none">
											<p>
												{getSitewideLink('need_help_template', '')}
											</p>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>

							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Privacy Policy Page</h2>
									</div>
								</div>
								<p>
									Your Privacy Policy page is an important legally binding page
									that tells users how and why you're collecting their
									information. When users signup, they can opt-in to sharing their
									personal information, contact details, and activity data with
									you. This gives you valuable information which will help you run
									your business and manage your Space, but you must disclose how
									you will use that data. Each country may also have local
									regulations that apply.
								</p>
								<Form
									form={privacyPageForm}
									name="privacyPageForm"
									id="privacyPageForm"
									onFinish={handleSubmitPrivacy}
									validateMessages={VALIDATE_MESSAGES}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>Privacy Policy Page Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												For all Spaces, the Privacy Policy is linked and agreed to by
												Members during Member Signup.
											</p>
										</div>
									</div>

									<div className="formrow">
										<div className="form-textarea air">
											<ReactQuill
												theme="snow"
												modules={{
													toolbar: toolbarOptions
												}}
												value={privacyPageContent}
												onChange={setPrivacyPageContent}
											/>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												For Basic Spaces, it is also linked from the Privacy Policy
												Page.
											</p>
											<p>
												For Pro Spaces, the default link location is in the footer
											</p>
											<p>
												You can style your text, add images, videos, and more. Be
												sure to save frequently if you are adding lots of content.
											</p>
										</div>
									</div>
									<div className="formrow">
										<div></div>
										<div className="cs-formrow-helper-none">
											<p>
												{getSitewideLink('need_help_template', '')}
											</p>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setSubmitType('')}
											>
													<span>
														<p>
															{isLoading && submitType === '' && (
																<i className="fa-solid fa-spinner fa-spin"></i>
															)}
															Save Page Content
														</p>
													</span>
											</Button>
											<p></p>
										</div>
									</Form.Item>
								</Form>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow">
									<Button
										onClick={()=> saveContinue()}
										className="primary-gradient"
										type="submit"
									>
										<span>
											<p>
												{isLoading && submitType === 'save_continue' &&  <i className="fa-solid fa-spinner fa-spin"></i>}
												Save All Pages & Continue
											</p>
										</span>
									</Button>
									<Button
										onClick={() => saveBack()}
										className="secondary-gradient"
										type="submit"
									>
										<span>
											<p>
												Save & Go Back
											</p>
										</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{/*<main className="dashboard-wrapper flex manage-space">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-12 gap-3">*/}
			{/*			<div className="col-span-12 md:col-span-8 flex items-center">*/}
			{/*				<h1 className="mb-4 mt-5 header-large">Information Pages</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">*/}
			{/*				<p className="body-txtitalic--large mt-4">Step 2 of 5</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12">*/}
			{/*				<h5 className="body-text--reg">*/}
			{/*					Like any website, your Space needs to have About, FAQ, Support,*/}
			{/*					Terms of Service, and Privacy Policy pages. This is important to*/}
			{/*					help visitors and members, to protect your business, and to comply*/}
			{/*					with privacy regulations. All pages are required and you can update*/}
			{/*					them at any time. Be sure to review each page as you complete them.{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Get additional details on setting up your Information Pages.*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'additional_details_info_page',*/}
			{/*						'gradient-color-txt body-txtitalic--reg'*/}
			{/*					)}*/}
			{/*				</h5>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">*/}
			{/*					<h5 className="header-medium leading-normal">About Page</h5>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">*/}
			{/*					/!*<button type="submit" className="btn btn-lg btn-secondary">*!/*/}
			{/*					/!*	View Guide*!/*/}
			{/*					/!*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*!/*/}
			{/*					/!*</button>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'view_guide_information_page',*/}
			{/*						'btn btn-lg btn-secondary'*/}
			{/*					)}*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 gap-3 mt-5">*/}
			{/*				<div className="col-span-1">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Your About Page introduces yourself and/or your business or*/}
			{/*						organization. You can add any content you like and often this*/}
			{/*						includes your mission, story, history, team members, company*/}
			{/*						information, and more. It can also be helpful to explain the*/}
			{/*						purpose of your Space for your community and how Members can use*/}
			{/*						the site. This page includes a Contact Form which anyone can*/}
			{/*						fill out which will be sent to the email you specify below.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h3 className="header-title">About Page Content</h3>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4">*/}
			{/*					<h3 className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						For Basic Spaces, the About Page is linked on the main*/}
			{/*						navigation.*/}
			{/*					</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={aboutPageForm}*/}
			{/*				name="aboutPageForm"*/}
			{/*				id="aboutPageForm"*/}
			{/*				onFinish={handleSubmitAbout}*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setFormIsOnFocusAbout(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					setFormIsOnFocusAbout(false);*/}
			{/*					handleFormOnBlur(aboutPageForm);*/}
			{/*				}}*/}
			{/*				onChange={() => {*/}
			{/*					handleFormChanges(aboutPageForm);*/}
			{/*				}}*/}
			{/*				className={formIsOnFocusAbout ? 'hide-antd-error-messages' : 'antd-error-messages-23'}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 !mt-4 md:!mt-12">*/}
			{/*					<div className="md:col-span-8 infopages-txteditor !order-last md:!order-first">*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={aboutPageContent}*/}
			{/*							onChange={setAboutPageContent}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<div className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							For Pro Spaces can be configured to appear on the main*/}
			{/*							navigation and/or in the footer links.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							You can style your text, add images, videos, and more. Be*/}
			{/*							sure to save frequently if you’re adding lots of content.*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*							Form Email**/}
			{/*						</label>*/}
			{/*						<div className="relative rounded-none ">*/}
			{/*							<Form.Item*/}
			{/*								label="Form Email"*/}
			{/*								name="email"*/}
			{/*								className="mb-0 hidden-label"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[{ required: true, type: 'email' }]}*/}
			{/*							>*/}
			{/*								<input*/}
			{/*									type="email"*/}
			{/*									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"*/}
			{/*									value={email}*/}
			{/*									placeholder="Enter the email that will receive Contact and Support Form inquiries."*/}
			{/*									onChange={(e) => setEmail(e.target.value)}*/}
			{/*									/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					*/}
			{/*					</div>*/}
			{/*					*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							This email will receive all completed Contact and Support*/}
			{/*							Form inquiries and can be changed as needed.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								onClick={() => setSubmitType('')}*/}
			{/*								className="btn btn-secondary btn-md"*/}
			{/*								type="submit"*/}
			{/*							>*/}
			{/*								{isLoading && submitType === '' && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Save Page Content*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}

			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h5 className="header-medium leading-normal">FAQ Page</h5>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 mt-5">*/}
			{/*				<div className="col-span-1">*/}
			{/*					<p className="body-text--small">*/}
			{/*						A Frequently Asked Questions Page helps visitors and Members*/}
			{/*						find answers to common questions they may have about your Space.*/}
			{/*						You can include details about how your Memberships will work,*/}
			{/*						how Members can use your Custom Benefits, or how Items tie into*/}
			{/*						your business or products. It’s a good idea to update this*/}
			{/*						regularly to minimize any support requests you get and to help*/}
			{/*						create a great member experience.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h3 className="header-title">FAQ Page Content</h3>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4">*/}
			{/*					<h3 className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						For Basic Spaces, the FAQ Page is part of the Support section*/}
			{/*						link on the main navigation.*/}
			{/*					</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={faqPageForm}*/}
			{/*				name="faqPageForm"*/}
			{/*				id="faqPageForm"*/}
			{/*				onFinish={handleSubmitFaq}*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 !mt-4 md:!mt-12">*/}
			{/*					<div className="md:col-span-8 infopages-txteditor !order-last md:!order-first">*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={faqPageContent}*/}
			{/*							onChange={setFaqPageContent}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<div className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							For Pro Spaces it can be configured to appear on the main*/}
			{/*							navigation and/or in the default location in the footer*/}
			{/*							links.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							You can style your text, add images, videos, and more. Be*/}
			{/*							sure to save frequently if you are adding lots of content.*/}
			{/*						</div>*/}

			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	View the Guide for recommended questions, examples, and a starter*!/*/}
			{/*							/!*	template that you can copy and paste.*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink(*/}
			{/*								'guide_recommended_questions',*/}
			{/*								'gradient-color-txt body-txtitalic--reg'*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								onClick={() => setSubmitType('')}*/}
			{/*								className="btn btn-secondary btn-md"*/}
			{/*								type="submit"*/}
			{/*							>*/}
			{/*								{isLoading && submitType === '' && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Save Page Content*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}

			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h5 className="header-medium">Support Page</h5>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 mt-5">*/}
			{/*				<div className="col-span-1">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Your Support Page should guide visitors and Members on how to*/}
			{/*						get help and can also include links to resources, updates, or*/}
			{/*						other pages for further information. There is also an automatic*/}
			{/*						link button to your Contact Form. If you have an existing*/}
			{/*						support site/system or want list different emails/links for*/}
			{/*						specific uses, you can include it here as an image button or*/}
			{/*						link too.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h3 className="header-title">Support Page Content</h3>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4">*/}
			{/*					<h3 className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						For Basic Spaces, the the Support Page link on the main*/}
			{/*						navigation.*/}
			{/*					</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={supportPageForm}*/}
			{/*				name="supportPageForm"*/}
			{/*				id="supportPageForm"*/}
			{/*				onFinish={handleSubmitSupport}*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 !mt-4 md:!mt-12">*/}
			{/*					<div className="md:col-span-8 infopages-txteditor !order-last md:!order-first">*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={supportPageContent}*/}
			{/*							onChange={setSupportPageContent}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<div className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							For Pro Spaces it, can be configured to appear on the main*/}
			{/*							navigation and/or in the default location in the footer*/}
			{/*							links.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							You can style your text, add images, videos, and more. Be*/}
			{/*							sure to save frequently if you are adding lots of content.*/}
			{/*						</div>*/}

			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Any Member can get Technical SpaceSupport for their user account from Commonspace,*!/*/}
			{/*							/!*	however all other support must be handled by you and your team. Commonspace*!/*/}
			{/*							/!*	provides additional support to you and your team only.*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink(*/}
			{/*								'member_get_technical_support',*/}
			{/*								'gradient-color-txt body-txtitalic--reg'*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								onClick={() => setSubmitType('')}*/}
			{/*								className="btn btn-secondary btn-md"*/}
			{/*								type="submit"*/}
			{/*							>*/}
			{/*								{isLoading && submitType === '' && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Save Page Content*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}

			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h5 className="header-medium">Terms of Service Page</h5>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 mt-5">*/}
			{/*				<div className="col-span-1">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Your Terms of Service is a very important legal page which*/}
			{/*						states the way in which your products, services and/or content*/}
			{/*						on your Space may be used by visitors and Members. It is agreed*/}
			{/*						to by Members on Signup and is legally binding. The purpose of*/}
			{/*						this is to dictate how users can use your Space and protect you*/}
			{/*						from potential liabilities.{' '}*/}
			{/*						/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*						/!*	As your Space is hosted by and operates on Commonspace, we also include*!/*/}
			{/*						/!*	this additional section on your page.*!/*/}
			{/*						/!*</Link>*!/*/}
			{/*						{getSitewideLink(*/}
			{/*							'space_hosted_by_commonspace',*/}
			{/*							'gradient-color-txt body-txtitalic--reg'*/}
			{/*						)}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h3 className="header-title">Terms of Service Page Content</h3>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4">*/}
			{/*					<h3 className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						For all Spaces, the Terms of Service is linked and agreed to by*/}
			{/*						Members during Member Signup.*/}
			{/*					</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={tosPageForm}*/}
			{/*				name="tosPageForm"*/}
			{/*				id="tosPageForm"*/}
			{/*				onFinish={handleSubmitTos}*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 !mt-4 md:!mt-12">*/}
			{/*					<div className="md:col-span-8 infopages-txteditor !order-last md:!order-first">*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={tosPageContent}*/}
			{/*							onChange={setTosPageContent}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<div className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							For Basic Spaces, it is also linked from the Support Page.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							For Pro Spaces, the default link location is in the footer*/}
			{/*							links.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							You can style your text, add images, videos, and more. Be*/}
			{/*							sure to save frequently if you are adding lots of content.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Need help? View the Guide for recommendations and a starter template that you can copy and paste.*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink(*/}
			{/*								'need_help_template',*/}
			{/*								'gradient-color-txt body-txtitalic--reg'*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								onClick={() => setSubmitType('')}*/}
			{/*								className="btn btn-secondary btn-md"*/}
			{/*								type="submit"*/}
			{/*							>*/}
			{/*								{isLoading && submitType === '' && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Save Page Content*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}

			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h5 className="header-medium">Privacy Policy Page</h5>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 mt-5">*/}
			{/*				<div className="col-span-1">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Your Privacy Policy page is an important legally binding page*/}
			{/*						that tells users how and why you're collecting their*/}
			{/*						information. When users signup, they can opt-in to sharing their*/}
			{/*						personal information, contact details, and activity data with*/}
			{/*						you. This gives you valuable information which will help you run*/}
			{/*						your business and manage your Space, but you must disclose how*/}
			{/*						you will use that data. Each country may also have local*/}
			{/*						regulations that apply.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<h3 className="header-title">Privacy Policy Page Content</h3>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4">*/}
			{/*					<h3 className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						For all Spaces, the Privacy Policy is linked and agreed to by*/}
			{/*						Members during Member Signup.*/}
			{/*					</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={privacyPageForm}*/}
			{/*				name="privacyPageForm"*/}
			{/*				id="privacyPageForm"*/}
			{/*				onFinish={handleSubmitPrivacy}*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-3 !mt-4 md:!mt-12">*/}
			{/*					<div className="md:col-span-8 infopages-txteditor !order-last md:!order-first">*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={privacyPageContent}*/}
			{/*							onChange={setPrivacyPageContent}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<div className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							For Basic Spaces, it is also linked from the Privacy Policy*/}
			{/*							Page.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							For Pro Spaces, the default link location is in the footer*/}
			{/*							links.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							You can style your text, add images, videos, and more. Be*/}
			{/*							sure to save frequently if you are adding lots of content.*/}
			{/*						</div>*/}
			{/*						<div className="body-txtitalic--smallest color-reg !mt-4 md:!mt-12">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Need help? View the Guide for recommendations and a starter template that you can copy and paste.*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink(*/}
			{/*								'need_help_template',*/}
			{/*								'gradient-color-txt body-txtitalic--reg'*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 mt-5 items-center">*/}
			{/*					<div className="md:col-span-8">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<Button*/}
			{/*								className="btn btn-secondary btn-md"*/}
			{/*								type="submit"*/}
			{/*								onClick={() => setSubmitType('')}*/}
			{/*							>*/}
			{/*								{isLoading && submitType === '' && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Save Page Content*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}

			{/*		<div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-5">*/}
			{/*			<div className="md:col-span-8">*/}
			{/*				<Button*/}
			{/*					onClick={()=> saveContinue()}*/}
			{/*					className="btn btn-lg btn-primary"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					{isLoading && submitType === 'save_continue' &&  <i className="fa-solid fa-spinner fa-spin"></i>}*/}
			{/*					Save All Pages & Continue*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*			<div className="md:col-span-4 flex items-end">*/}
			{/*				<Button*/}
			{/*					onClick={() => saveBack()}*/}
			{/*					className="btn btn-lg btn-secondary"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					Save & Go Back*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(AddPages);
