import { createSlice} from "@reduxjs/toolkit";

export const footerInViewSlice = createSlice({
    name: "footerInView",
    initialState: {
        isFooterInViewPort: false,
    },
    reducers: {
        setIsFooterInViewPort: (state, action) => {
            state.isFooterInViewPort = action.payload;
        }
    }
});

export const {
    setIsFooterInViewPort
} = footerInViewSlice.actions;

export default footerInViewSlice.reducer;