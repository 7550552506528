import { memo } from 'react';

import SpaceItem from './SpaceItem';

const Spaces = (props) => {
	const { spaces } = props;

	return (
		<>
			<div className="col-span-12">
				<div className="grid lg:grid-cols-2 md:grid-cols-1 gap-8 mt-4 mx-3">
					{spaces && spaces.map((data, index) => (
						<SpaceItem key={index} data={data}></SpaceItem>
					))}
				</div>
			</div>
		</>
	);
};

export default memo(Spaces);
