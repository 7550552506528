import React, {useState, useCallback, useEffect} from 'react';

import AppLayout from './containers/AppLayout';
import fav from './assets/images/cs-favicon.png';
import {useLocation, useSearchParams} from "react-router-dom";
import {getDataByUsername} from "./services/general";
import {ROOT_DOMAIN} from "./constants/common";
import {customDomainRedirectChecker} from "./utils/CustomDomainRedirectChecker";
import {setAccessToken} from "./utils/common";
// This is the main component of the application
const App = () => {
    const currentLocation = useLocation();
    const path = currentLocation.pathname;
    const [space, setSpace] = useState(null);
    const [params] = useSearchParams();
    const queryString = window.location.search.slice(1);

    if (params.get('rd_at') && params.get('rd_rt')) {
        const data = {
            access_token: params.get('rd_at'),
            refresh_token: params.get('rd_rt')
        }
        setAccessToken(data);

        // redirect to space view page using domain
        const hostname = window.location.hostname;
        const isLocal = hostname === 'localhost' || hostname === '127.0.0.1';
        const protocol = isLocal ? 'http://' : 'https://';
        const port = isLocal ? ':3004' : '';

        if (path.includes('/buy/payment-method')) {
            if (params.get('id')) {
                window.location.href = `${protocol}${hostname}${port}` + path + '?id=' + params.get('id');
            } else {
                window.location.href = `${protocol}${hostname}${port}` + path;
            }
        } else {
            window.location.href = `${protocol}${hostname}${port}`;
        }
    } else {
        // ON FIRST LOAD CHECK ROUTES; REDIRECT OR ALLOW SPACE VIEWS WITH CUSTOM DOMAINS
        customDomainRedirectChecker(path, queryString);
    }

    
    useEffect(() => {
        if(!path.includes('@')){
            favicon.setAttribute("href", fav);
            appicon.setAttribute("href", fav);
        }else{
            // check routes


            const match = path.match(/@([^/]+)/);
            if (match) {
                const username = match[1];
                fetchViewData(username);
            }
        }
    }, [path]);



    useEffect(() => {
        if(space && space.pro_style){
            if(space.pro_style.favIcon){
                favicon.setAttribute("href", space?.pro_style?.favIcon);
                appicon.setAttribute("href", space?.pro_style?.favIcon);
            }else{
                favicon.setAttribute("href", fav);
                appicon.setAttribute("href", fav);
            }
        }else{
            favicon.setAttribute("href", fav);
            appicon.setAttribute("href", fav);
        }
    }, [space]);

    const fetchViewData = useCallback(
        async (username) => {
            try {
                const data = {
                    username: username
                };

                const response = await getDataByUsername(data);
                if (response.result) {
                    setSpace(response.space);
                } else {

                }
                // setIsSpaceLoaded(true);

            } catch (error) {
                console.log(error);
            }
        },
        [space]
    );

	// Render the AppLayout component, which contains the layout of the app
	return <AppLayout />;
};

export default App;