import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../../routes';
import {
    CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS,
    CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS,
    CUSTOM_SPACE_FOREGROUND_POSITIONS,
    CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS,
    CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS,
    CUSTOM_SPACE_FOREGROUND_SECTION_POSITIONS,
    CUSTOM_SPACE_THEME,
    CUSTOM_SPACE_BACKGROUND_DIRECTION,
    CUSTOM_SPACE_FONT_WEIGHT,
    CUSTOM_BANNER_VISIBILITY,
    CUSTOM_THEME_TYPE
} from '../../../constants/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {setSpaceProStyle, updateLastStepLink} from '../../../services/space';
import UploadDraggerV2 from '../../../components/commons/UploadDraggerV2';
import { GOOGLE_FONT_KEY } from '../../../constants/common';
import {
    IMAGE_FILETYPES,
    VIDEO_FILETYPES,
    IMAGE_LARGE_MIN_HEIGHT,
    IMAGE_LARGE_MIN_WIDTH,
    IMAGE_LARGE_MAX_HEIGHT,
    IMAGE_LARGE_MAX_WIDTH,
    VIDEO_STANDARD_MIN_HEIGHT,
    VIDEO_STANDARD_MIN_WIDTH,
    VIDEO_STANDARD_MAX_HEIGHT,
    VIDEO_STANDARD_MAX_WIDTH,
    IMAGE_STANDARD_FILE_UPLOAD_SIZE,
    IMAGE_LARGE_FILE_UPLOAD_SIZE,
    VIDEO_STANDARD_FILE_UPLOAD_SIZE,
    IMAGE_LOGO_MAX_HEIGHT,
    IMAGE_LOGO_MAX_WIDTH,
    IMAGE_LOGO_MIN_HEIGHT,
    IMAGE_LOGO_MIN_WIDTH
} from '../../../constants/file';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import ColorPickerAndInput from '../../../components/commons/ColorPickerAndInput';
import header_layout1 from '../../../../src/assets/images/cs-create-sample-header-standard.png';
import header_layout2 from '../../../../src/assets/images/cs-create-sample-header-split.png';
import './CustomizeProSpace.scss';
import { hideTooltip, showTooltip } from '../../../utils/input';
import FontsDropdownWithSearch from '../../../components/commons/FontsDropdownWithSearch';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const CustomizeProSpace = ({dropSection}) => {
    const { space_username } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [initialLoad, setInitialLoad] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [alert, setAlert] = useState(null);
    const [logoImage, setLogoImage] = useState(null);
    const [logoVideo, setLogoVideo] = useState(null);
    const [altThemeLogoVideo, setAltThemeLogoVideo] = useState(null);
    const [altThemeLogoVideoBlobPreview, setAltThemeLogoVideoBlobPreview] = useState(null);
    const [logoImageBlobPreview, setLogoImageBlobPreview] = useState(null);
    const [logoVideoBlobPreview, setLogoVideoBlobPreview] = useState(null);
    const [logoImageError, setLogoImageError] = useState(null);
    const [logoVideoError, setLogoVideoError] = useState(null);
    const [altThemeLogoVideoError, setAltThemeLogoVideoError] = useState(null);

    const [lightBackgroundImage, setLightBackgroundImage] = useState(null);
    const [lightBackgroundImageBlobPreview, setLightBackgroundImageBlobPreview] = useState(null);
    const [lightBackgroundVideo, setLightBackgroundVideo] = useState(null);
    const [lightBackgroundVideoBlobPreview, setLightBackgroundVideoBlobPreview] = useState(null);
    const [darkBackgroundImage, setDarkBackgroundImage] = useState(null);
    const [darkBackgroundImageBlobPreview, setDarkBackgroundImageBlobPreview] = useState(null);
    const [darkBackgroundVideo, setDarkBackgroundVideo] = useState(null);
    const [darkBackgroundVideoBlobPreview, setDarkBackgroundVideoBlobPreview] = useState(null);
    const [headerBackgroundImage, setHeaderBackgroundImage] = useState(null);
    const [headerBackgroundImageBlobPreview, setHeaderBackgroundImageBlobPreview] = useState(null);
    const [headerBackgroundVideo, setHeaderBackgroundVideo] = useState(null);
    const [headerBackgroundVideoBlobPreview, setHeaderBackgroundVideoBlobPreview] = useState(null);

    const [updatesBannerImage, setUpdatesBannerImage] = useState(null);
    const [updatesBannerImageBlobPreview, setSectionBannerImageBlobPreview] = useState(null);

    const [membersBannerImage, setMembersBannerImage] = useState(null);
    const [membersBannerImageBlobPreview, setMembersBannerImageBlobPreview] = useState(null);

    const [benefitsBannerImage, setBenefitsBannerImage] = useState(null);
    const [benefitsBannerImageBlobPreview, setBenefitsBannerImageBlobPreview] = useState(null);

    const [accessBannerImage, setAccessBannerImage] = useState(null);
    const [accessBannerImageBlobPreview, setAccessBannerImageBlobPreview] = useState(null);

    const [channelBannerImage, setChannelBannerImage] = useState(null);
    const [channelBannerImageBlobPreview, setChannelBannerImageBlobPreview] = useState(null);

    const [informationBannerImage, setInformationBannerImage] = useState(null);
    const [informationBannerImageBlobPreview, setInformationBannerImageBlobPreview] = useState(null);

    const [appIcon, setAppIcon] = useState(null);
    const [appIconBlobPreview, setAppIconBlobPreview] = useState(null);
    const [favIconBlobPreview, setFavIconBlobPreview] = useState(null);
    const [favIcon, setFavIcon] = useState(null);
    const [openGraphImageBlobPreview, setOpenGraphImageBlobPreview] = useState(null);
    const [openGraphImage, setOpenGraphImage] = useState(null);
    const [headerForegroundImage, setHeaderForegroundImage] = useState(null);
    const [headerForegroundImageBlobPreview, setHeaderForegroundImageBlobPreview] = useState(null);
    const [bannerBackgroundImage, setBannerBackgroundImage] = useState(null);
    const [bannerBackgroundImageBlobPreview, setBannerBackgroundImageBlobPreview] = useState(null);
    const [bannerBackgroundVideo, setBannerBackgroundVideo] = useState(null);
    const [bannerBackgroundVideoBlobPreview, setBannerBackgroundVideoBlobPreview] = useState(null);
    const [homeBannerImage, setHomeBannerImage] = useState(null);
    const [homeBannerImageBlobPreview, setHomeBannerImageBlobPreview] = useState(null);

    const [selectedBackgroundDirection, setSelectedBackgroundDirection] = useState(null);
    const [selectedLightImagePosition, setSelectedLightImagePosition] = useState(null);
    const [selectedLightVideoPosition, setSelectedLightVideoPosition] = useState(null);
    const [selectedDarkImagePosition, setSelectedDarkImagePosition] = useState(null);
    const [selectedBannerBackgroundImagePosition, setSelectedBannerBackgroundImagePosition] = useState(null);
    const [selectedBannerBackgroundVideoPosition, setSelectedBannerBackgroundVideoPosition] = useState(null);
    const [selectedDarkVideoPosition, setSelectedDarkVideoPosition] = useState(null);
    const [selectedForegroundPosition, setSelectedForegroundPosition] = useState(null);
    const [foregroundText, setForegroundText] = useState('');
    const [headerLayout, setHeaderLayout] = useState('standard');
    const [hasGradientChecked, setHasGradientChecked] = useState(false);
    const [logoImageIsRemoved, setLogoImageIsRemoved] = useState(false);

    const [logoVideoIsRemoved, setLogoVideoIsRemoved] = useState(false);
    const [altThemeLogoVideoIsRemoved, setAltThemeLogoVideoIsRemoved] = useState(false);
    const [lightBackgroundImageIsRemoved, setLightBackgroundImageIsRemoved] = useState(false);
    const [lightBackgroundVideoIsRemoved, setLightBackgroundVideoIsRemoved] = useState(false);
    const [darkBackgroundImageIsRemoved, setDarkBackgroundImageIsRemoved] = useState(false);
    const [darkBackgroundVideoIsRemoved, setDarkBackgroundVideoIsRemoved] = useState(false);
    const [headerBackgroundImageIsRemoved, setHeaderBackgroundImageIsRemoved] = useState(false);
    const [headerBackgroundVideoIsRemoved, setHeaderBackgroundVideoIsRemoved] = useState(false);
    const [headerForegroundImageIsRemoved, setheaderForegroundImageIsRemoved] = useState(false);
    const [bannerBackgroundImageIsRemoved, setBannerBackgroundImageIsRemoved] = useState(false);
    const [bannerBackgroundVideoIsRemoved, setBannerBackgroundVideoIsRemoved] = useState(false);
    const [homeBannerImageIsRemoved, setHomeBannerImageIsRemoved] = useState(false);
    const [updatesBannerImageIsRemoved, setUpdatesBannerImageIsRemoved] = useState(false);
    const [membersBannerImageIsRemoved, setMembersBannerImageIsRemoved] = useState(false);
    const [benefitsBannerImageIsRemoved, setBenefitsBannerImageIsRemoved] = useState(false);
    const [accessBannerImageIsRemoved, setAccessBannerImageIsRemoved] = useState(false);
    const [channelBannerImageIsRemoved, setChannelBannerImageIsRemoved] = useState(false);
    const [informationBannerImageIsRemoved, setInformationBannerImageIsRemoved] = useState(false);

    const [favIconImageIsRemoved, setFavIconImageIsRemoved] = useState(false);
    const [appIconImageIsRemoved, setAppIconImageIsRemoved] = useState(false);
    const [openGraphImageIsRemoved, setOpenGraphImageIsRemoved] = useState(false);

    //use state for color picker
    const [keyColor, setKeyColor] = useState('#FB03B9');
    const [keyEndColor, setKeyEndColor] = useState('#3883FF');
    const [primaryColor, setPrimaryColor] = useState('#0C0C0C');
    const [secondaryColor, setSecondaryColor] = useState('#888888');
    const [backgroundColor, setBackgroundColor] = useState('#FFE6F8');
    const [backgroundEndColor, setBackgroundEndColor] = useState('#EBF3FF');
    const [invertColor, setInvertColor] = useState('#FFFFFF');
    const [disableColor, setDisableColor] = useState('#E7E7E7');

    //usestate new colors from DF

    const [keyAltColor, setKeyAltColor] = useState('#FB03B9');
    const [keyAltEndColor, setKeyAltEndColor] = useState('#3883FF');
    const [primaryAltColor, setPrimaryAltColor] = useState('#0C0C0C');
    const [secondaryAltColor, setSecondaryAltColor] = useState('#888888');
    const [invertAltColor, setInvertAltColor] = useState('#FFFFFF');
    const [disableAltColor, setDisableAltColor] = useState('#E7E7E7');
    const [backgroundAltColor, setBackgroundAltColor] = useState('#FFE6F8');
    const [backgroundAltEndColor, setBackgroundAltEndColor] = useState('#EBF3FF');

    const [primaryHeaderColor, setPrimaryHeaderColor] = useState('#0C0C0C');
    const [backgroundHeaderColor, setBackgroundHeaderColor] = useState('#FFFFFF');
    const [navigationHeaderFontColor, setNavigationHeaderFontColor] = useState('#888888');

    const [primaryFooterColor, setPrimaryFooterColor] = useState('#0C0C0C');
    const [backgroundFooterColor, setBackgroundFooterColor] = useState('#FFFFFF');
    const [navigationFooterFontColor, setNavigationFooterFontColor] = useState('#888888');

    const [navigationHeaderWeight, setNavigationHeaderWeight] = useState('bold');
    const [navigationFooterWeight, setNavigationFooterWeight] = useState('bold');

    const [headingFontWeight, setHeadingFontWeight] = useState('bold')
    const [bodyFontWeight, setBodyFontWeight] = useState('bold')

    const [selectedBannerContentPosition, setSelectedBannerContentPosition] = useState(null);

    const [headerTheme, setHeaderTheme] = useState('main')
    const [footerTheme, setFooterTheme] = useState('main')
    const [homeTheme, setHomeTheme] = useState('main')
    const [updatesTheme, setUpdatesTheme] = useState('main')
    const [homeBannerVisibility, setHomeBannerVisibility] = useState('show')
    const [updatesBannerVisibility, setUpdatesBannerVisibility] = useState('show')
    const [membersBannerVisibility, setMembersBannerVisibility] = useState('show')
    const [channelsBannerVisibility, setChannelsBannerVisibility] = useState('show')
    const [benefitsBannerVisibility, setBenefitsBannerVisibility] = useState('show')
    const [informationBannerVisibility, setInformationBannerVisibility] = useState('show')
    const [accessBannerVisibility, setAccessBannerVisibility] = useState('show')
    const [benefitsTheme, setBenefitsTheme] = useState('main')
    const [membersTheme, setMembersTheme] = useState('main')
    const [accessTheme, setAccessTheme] = useState('main')
    const [channelsTheme, setChannelsTheme] = useState('main')
    const [informationTheme, setInformationTheme] = useState('main')

    //use state for google fonts
    const [font1, setFont1] = useState('DIN 2014');
    const [font2, setFont2] = useState('Noto Sans');

    const foregroundTextRef = useRef(null);



    const [selectedBackgroundAltDirection, setSelectedBackgroundAltDirection] = useState(null);
    const [homeThemeType, setHomeThemeType] = useState('single')
    const [dashboardTheme, setDashboardTheme] = useState('main')

    const [lightBackgroundImageError, setLightBackgroundImageError] = useState(null);
    const [lightBackgroundVideoError, setLightBackgroundVideoError] = useState(null);
    const [darkBackgroundImageError, setDarkBackgroundImageError] = useState(null);
    const [darkBackgroundVideoError, setDarkBackgroundVideoError] = useState(null);
    const [headerBackgroundImageError, setHeaderBackgroundImageError] = useState(null);
    const [headerBackgroundVideoError, setHeaderBackgroundVideoError] = useState(null);
    const [favIconImageError, setFavIconImageError] = useState(null);
    const [appIconImageError, setAppIconImageError] = useState(null);
    const [openGraphImageError, setOpenGraphImageError] = useState(null);
    const [bannerBackgroundImageError, setBannerBackgroundImageError] = useState(null);
    const [bannerBackgroundVideoError, setBannerBackgroundVideoError] = useState(null);
    const [updatesBannerImageError, setUpdatesBannerImageError] = useState(null);
    const [membersBannerImageError, setMembersBannerImageError] = useState(null);
    const [benefitsBannerImageError, setBenefitsBannerImageError] = useState(null);
    const [accessBannerImageError, setAccessBannerImageError] = useState(null);
    const [channelBannerImageError, setChannelBannerImageError] = useState(null);
    const [informationBannerImageError, setInformationBannerImageError] = useState(null);
    const [headerForegroundImageError, setHeaderForegroundImageError] = useState(null);

    const [lightImagePositionError, setLightImagePositionError] = useState(null);
    const [lightVideoPositionError, setLightVideoPositionError] = useState(null);
    const [darkImagePositionError, setDarkImagePositionError] = useState(null);
    const [bannerBackgroundImagePositionError, setBannerBackgroundImagePositionError] = useState(null);
    const [bannerBackgroundVideoPositionError, setBannerBackgroundVideoPositionError] = useState(null);
    const [darkVideoPositionError, setDarkVideoPositionError] = useState(null);
    const [foregroundPositionError, setForegroundPositionError] = useState(null);
    const [backgroundDirectionError, setBackgroundDirectionError] = useState(null);
    const [backgroundAltDirectionError, setBackgroundAltDirectionError] = useState(null);

    const [isContinueClicked, setIsContinueClicked] = useState(false)


    useEffect(() => {
        if(selectedBackgroundDirection !== null){
            setBackgroundDirectionError('');
        }
        if(selectedBackgroundAltDirection !== null){
            setBackgroundAltDirectionError('');
        }
        if(selectedLightImagePosition !== null){
            setLightImagePositionError('');
        }
        if(selectedLightVideoPosition !== null){
            setLightVideoPositionError('');
        }
        if(selectedDarkImagePosition !== null){
            setBannerBackgroundImagePositionError ('');
        }
        if(selectedDarkVideoPosition !== null){
            setDarkVideoPositionError('');
        }
        if(selectedBannerBackgroundImagePosition !== null){
            setBannerBackgroundImagePositionError('');
        }

        if(selectedDarkVideoPosition !== null){
            setDarkVideoPositionError('');
        }
        if(selectedForegroundPosition !== null){
            setForegroundPositionError('');
        }

        if(selectedDarkVideoPosition !== null){
            setBannerBackgroundVideoPositionError('');
        }

        if(selectedDarkImagePosition !== null){
            setBannerBackgroundImagePositionError('');
        }


    }, [selectedBackgroundDirection, selectedBackgroundAltDirection, selectedLightImagePosition, selectedLightVideoPosition, selectedDarkImagePosition, selectedDarkVideoPosition, selectedBannerBackgroundImagePosition, selectedBannerBackgroundVideoPosition])


    useEffect(() => {
        // console.log("H " + membersBannerImage)
        // console.log("F " + membersBannerImageIsRemoved)
    }, [navigationHeaderWeight, navigationFooterWeight, membersBannerImage, membersBannerImageIsRemoved])

    const handleLogoImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setLogoImageError('Main Theme Logo is Invalid.');
            }
            setLogoImageIsRemoved(true);
        } else {
            setLogoImageIsRemoved(false);
            if(!error){
                setLogoImageError(null);
            }
        }

        setLogoImage(file);
        setLogoImageBlobPreview(blobPreview);
    };

    const  handleLogoVideoUploadFileChange = (file, blobPreview) => {
        if (!file) {
            setLogoVideoIsRemoved(true);
        } else {
            setLogoVideoIsRemoved(false);
        }

        setLogoVideo(file);
        setLogoVideoBlobPreview(blobPreview);
    };

    const  handleAltThemeLogoVideoUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setAltThemeLogoVideoError('Alt Theme Logo is Invalid.');
            }
            setAltThemeLogoVideoIsRemoved(true);
        } else {
            setAltThemeLogoVideoIsRemoved(false);
            if(!error){
                setAltThemeLogoVideoError(null);
            }
        }

        setAltThemeLogoVideo(file);
        setAltThemeLogoVideoBlobPreview(blobPreview);
    };

    const  handleOpenGraphImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setOpenGraphImageError('Open Graph Image is Invalid.');
            }
            setOpenGraphImageIsRemoved(true);
        } else {
            setOpenGraphImageIsRemoved(false);
            if(!error){
                setOpenGraphImageError(null);
            }
        }

        setOpenGraphImage(file);
        setOpenGraphImageBlobPreview(blobPreview);
    };

    const handleLightBackgroundImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setLightBackgroundImageError('Background Image is Invalid.');
            }
            setLightBackgroundImageIsRemoved(true);
        } else {
            setLightBackgroundImageIsRemoved(false);
            if(!error){
                setLightBackgroundImageError(null);
            }
        }

        setLightBackgroundImage(file);
        setLightBackgroundImageBlobPreview(blobPreview);
    };

    const handleLightBackgroundVideoUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setLightBackgroundVideoError('Background Video is Invalid.');
            }
            setLightBackgroundVideoIsRemoved(true);
        } else {
            setLightBackgroundVideoIsRemoved(false);
            if(!error){
                setLightBackgroundVideoError(null);
            }
        }

        setLightBackgroundVideo(file);
        setLightBackgroundVideoBlobPreview(blobPreview);
    };

    const handleDarkBackgroundImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setDarkBackgroundImageError('Background Alt Image is Invalid.');
            }
            setDarkBackgroundImageIsRemoved(true);
        } else {
            setDarkBackgroundImageIsRemoved(false);
            if(!error){
                setDarkBackgroundImageError(null);
            }
        }

        setDarkBackgroundImage(file);
        setDarkBackgroundImageBlobPreview(blobPreview);
    };

    const handleDarkBackgroundVideoUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setDarkBackgroundVideoError('Background Alt Video is Invalid.');
            }
            setDarkBackgroundVideoIsRemoved(true);
        } else {
            setDarkBackgroundVideoIsRemoved(false);
            if(!error){
                setDarkBackgroundVideoError(null);
            }
        }

        setDarkBackgroundVideo(file);
        setDarkBackgroundVideoBlobPreview(blobPreview);
    };

    const handleHeaderBackgroundImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setHeaderBackgroundImageError('Header Logo is Invalid.');
            }
            setHeaderBackgroundImageIsRemoved(true);
        } else {
            setHeaderBackgroundImageIsRemoved(false);
            if(!error){
                setHeaderBackgroundImageError(null);
            }
        }

        setHeaderBackgroundImage(file);
        setHeaderBackgroundImageBlobPreview(blobPreview);
    };


    const handleFavIconUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setFavIconImageError('Fav Icon is Invalid.');
            }
            setFavIconImageIsRemoved(true);
        } else {
            setFavIconImageIsRemoved(false);
            if(!error){
                setFavIconImageError(null);
            }
        }

        setFavIcon(file);
        setFavIconBlobPreview(blobPreview);
    };

    const handleHeaderBackgroundVideoUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setHeaderBackgroundVideoError('Footer Logo is Invalid.');
            }
            setHeaderBackgroundVideoIsRemoved(true);
        } else {
            setHeaderBackgroundVideoIsRemoved(false);
            if(!error){
                setHeaderBackgroundVideoError(null);
            }
        }

        setHeaderBackgroundVideo(file);
        setHeaderBackgroundVideoBlobPreview(blobPreview);
    };

    const handleAppIconUploadFileChange = (file, blobPreview,error = false) => {
        if (!file) {
            if(error){
                setAppIconImageError('App Icon is Invalid.');
            }
            setAppIconImageIsRemoved(true);
        } else {
            setAppIconImageIsRemoved(false);
            if(!error){
                setAppIconImageError(null);
            }
        }

        setAppIcon(file);
        setAppIconBlobPreview(blobPreview);
    };

    const copyFromMainTheme = useCallback(() => {
        setKeyAltColor(keyColor);
        setKeyAltEndColor(keyEndColor);
        setPrimaryAltColor(primaryColor);
        setSecondaryAltColor(secondaryColor);
        setInvertAltColor(invertColor);
        setDisableAltColor(disableColor);
        setBackgroundAltColor(backgroundColor);
        setBackgroundAltEndColor(backgroundEndColor);

        setSelectedDarkImagePosition(selectedLightImagePosition);
        setSelectedDarkVideoPosition(selectedLightVideoPosition);
        setSelectedBackgroundAltDirection(selectedBackgroundDirection);




    }, [lightBackgroundImage, lightBackgroundImageBlobPreview, lightBackgroundVideo, lightBackgroundVideoBlobPreview, selectedLightImagePosition, selectedLightVideoPosition, selectedBackgroundDirection]);

    const handleHeaderForegroundImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setHeaderForegroundImageError('Home Banner Image is Invalid.');
            }
            setheaderForegroundImageIsRemoved(true);
        } else {
            setheaderForegroundImageIsRemoved(false);
            if(!error){
                setHeaderForegroundImageError(null);
            }
        }

        setHeaderForegroundImage(file);
        setHeaderForegroundImageBlobPreview(blobPreview);
    };

    const handleBannerBackgroundImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file || file === null) {
            if(error){
                setBannerBackgroundImageError('Banner Background Image is Invalid.');
            }
            setBannerBackgroundImageIsRemoved(true);
        } else {
            setBannerBackgroundImageIsRemoved(false);
            if(!error){
                setBannerBackgroundImageError(null);
            }
        }

        setBannerBackgroundImage(file);
        setBannerBackgroundImageBlobPreview(blobPreview);
    };

    const handleHomeBannerImageUploadFileChange = (file, blobPreview) => {
        if (!file) {
            setHomeBannerImageIsRemoved(true);
        } else {
            setHomeBannerImageIsRemoved(false);
        }

        setHomeBannerImage(file);
        setHomeBannerImageBlobPreview(blobPreview);
    };

    const handleUpdatesBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        // console.log(file);
        if (!file) {
            if(error){
                setUpdatesBannerImageError('Updates Banner Image is Invalid.');
            }
            setUpdatesBannerImageIsRemoved(true);
        } else {
            setUpdatesBannerImageIsRemoved(false);
            if(!error){
                setUpdatesBannerImageError(null);
            }
        }

        setUpdatesBannerImage(file);
        setSectionBannerImageBlobPreview(blobPreview);
    };

    const handleMembersBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setMembersBannerImageError('Members Banner Image is Invalid.');
            }
            setMembersBannerImageIsRemoved(true);
        } else {
            setMembersBannerImageIsRemoved(false);
            if(!error){
                setMembersBannerImageError(null);
            }
        }

        setMembersBannerImage(file);
        setMembersBannerImageBlobPreview(blobPreview);
    };

    const handleBenefitsBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setBenefitsBannerImageError('Benefits Banner Image is Invalid.');
            }
            setBenefitsBannerImageIsRemoved(true);
        } else {
            setBenefitsBannerImageIsRemoved(false);
            if(!error){
                setBenefitsBannerImageError(null);
            }
        }

        setBenefitsBannerImage(file);
        setBenefitsBannerImageBlobPreview(blobPreview);
    };

    const handleAccessBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setAccessBannerImageError('Access Banner Image is Invalid.');
            }
            setAccessBannerImageIsRemoved(true);
        } else {
            setAccessBannerImageIsRemoved(false);
            if(!error){
                setAccessBannerImageError(null);
            }
        }

        setAccessBannerImage(file);
        setAccessBannerImageBlobPreview(blobPreview);
    };

    const handleChannelBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setChannelBannerImageError('Channel Banner Image is Invalid.');
            }
            setChannelBannerImageIsRemoved(true);
        } else {
            setChannelBannerImageIsRemoved(false);
            if(!error){
                setChannelBannerImageError(null);
            }
        }

        setChannelBannerImage(file);
        setChannelBannerImageBlobPreview(blobPreview);
    };

    const handleInformationBannerImageUploadFileChange = (file, blobPreview, error = false) => {
        if (!file) {
            if(error){
                setInformationBannerImageError('Information Banner Image is Invalid.');
            }
            setInformationBannerImageIsRemoved(true);
        } else {
            setInformationBannerImageIsRemoved(false);
            if(!error){
                setInformationBannerImageError(null);
            }
        }

        setInformationBannerImage(file);
        setInformationBannerImageBlobPreview(blobPreview);
    };

    useEffect(() => {
        // console.log("bannerBackgroundVideoIsRemoved ", bannerBackgroundVideoIsRemoved)
    }, [bannerBackgroundVideoIsRemoved])

    const handleBannerBackgroundVideoUploadFileChange = (file, blobPreview, error = false) => {
        // console.log("file ", file)
        if (!file) {
            // console.log("here")
            if(error){
                setBannerBackgroundVideoError('Banner Background Video is Invalid.');
            }
            setBannerBackgroundVideoIsRemoved(true);
        } else {
            setBannerBackgroundVideoIsRemoved(false);
            if(!error){
                setBannerBackgroundVideoError(null);
            }
        }


        setBannerBackgroundVideo(file);
        setBannerBackgroundVideoBlobPreview(blobPreview);
    };

    const handleUpdateLastStepLink = useCallback(async () => {
            const data = {};
            // include last step link data for continuing the space creation
            const url = routes.createCustomizeProNav();
            data.last_step_link = url.replace(':space_username', space_username);
            try {
                if (authUserInfo) {
                    data.user_id = authUserInfo.id;
                    data.space_id = spaceInfo.id;
                    const response = await updateLastStepLink(data);
                    if (response && response.result) {
                        saveAndContinue();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        [space_username, authUserInfo, spaceInfo]
    );


    const saveAndContinue = useCallback(() => {
        if (spaceInfo && spaceInfo.plan === 'pro' && !spaceInfo.pro_style) {
            document.getElementById('color-themes').click();
        }

        let url = routes.createCustomizeProNav();
        if (dropSection) {
            url = routes.spaceManageSpace();
        }
        navigate(url.replace(':space_username', space_username));
    }, [spaceInfo]);

    const saveAndGoBack = () => {
        const url = routes.createSpaceUpgradePlan();
        navigate(url.replace(':space_username', space_username));
    }

    const submitStyles = useCallback(
        async (section, e) => {
            e.preventDefault()
            // console.log("test")
            let hasError = false;

            if (hasError) {
                setIsContinueClicked(false)
                return;
            }
            // console.log("isContinueClicked"+ isContinueClicked)
            const data = {
                space_id: spaceInfo.id,
                user_id: authUserInfo.id,
                logo: logoImage,
                key_color: keyColor,
                has_gradient: hasGradientChecked,
                gradient_color: keyEndColor,
                title_font: font1,
                body_font: font2,
                light_text_color: primaryColor,
                light_background_color: secondaryColor,
                light_background_image: lightBackgroundImage,
                light_background_video: lightBackgroundVideo,
                light_image_position: selectedLightImagePosition,
                light_video_position: selectedLightVideoPosition,
                dark_text_color: keyAltColor,
                dark_background_color: keyAltEndColor,
                dark_background_image: darkBackgroundImage,
                dark_background_video: darkBackgroundVideo,
                dark_image_position: selectedDarkImagePosition,
                dark_video_position: selectedDarkVideoPosition,
                header_layout: headerLayout,
                header_background_image: headerBackgroundImage,
                header_background_video: headerBackgroundVideo,
                header_foreground_image: headerForegroundImage,
                foreground_text: foregroundText,
                foreground_position: selectedForegroundPosition,

                invertColor: invertColor,
                disableColor: disableColor,
                backgroundColor: backgroundColor,
                backgroundEndColor: backgroundEndColor,
                primaryHeaderColor: primaryHeaderColor,
                backgroundHeaderColor: backgroundHeaderColor,
                navigationHeaderFontColor: navigationHeaderFontColor,
                navigationHeaderWeight: navigationHeaderWeight,

                primaryFooterColor: primaryFooterColor,
                backgroundFooterColor: backgroundFooterColor,
                navigationFooterFontColor: navigationFooterFontColor,
                navigationFooterWeight: navigationFooterWeight,
                logoVideo: logoVideo,
                favIcon: favIcon,
                appIcon: appIcon,

                headingFontWeight: headingFontWeight,
                bodyFontWeight: bodyFontWeight,

                homeBannerImage: homeBannerImage,
                bannerBackgroundImage: bannerBackgroundImage,
                bannerBackgroundVideo: bannerBackgroundVideo,
                selectedBannerContentPosition: selectedBannerContentPosition,
                updatesBannerImage: updatesBannerImage,
                membersBannerImage: membersBannerImage,
                benefitsBannerImage: benefitsBannerImage,
                accessBannerImage: accessBannerImage,
                channelBannerImage: channelBannerImage,
                informationBannerImage: informationBannerImage,
                altThemeLogoVideo: altThemeLogoVideo,

                selectedBannerBackgroundImagePosition:selectedBannerBackgroundImagePosition,
                selectedBannerBackgroundVideoPosition: selectedBannerBackgroundVideoPosition,
                headerTheme: headerTheme,
                footerTheme: footerTheme,
                homeTheme: homeTheme,
                updatesTheme: updatesTheme,
                benefitsTheme: benefitsTheme,
                homeBannerVisibility: homeBannerVisibility,
                updatesBannerVisibility: updatesBannerVisibility,
                benefitsBannerVisibility: benefitsBannerVisibility,
                channelsBannerVisibility: channelsBannerVisibility,
                membersBannerVisibility: membersBannerVisibility,
                informationBannerVisibility: informationBannerVisibility,
                accessBannerVisibility: accessBannerVisibility,
                membersTheme: membersTheme,
                accessTheme: accessTheme,
                channelsTheme: channelsTheme,
                informationTheme: informationTheme,
                selectedBackgroundDirection: selectedBackgroundDirection,
                openGraphImage: openGraphImage,

                primaryAltColor: primaryAltColor,
                secondaryAltColor:secondaryAltColor,
                invertAltColor: invertAltColor,
                disableAltColor: disableAltColor,
                backgroundAltColor:backgroundAltColor,
                backgroundAltEndColor:backgroundAltEndColor,
                selectedBackgroundAltDirection: selectedBackgroundAltDirection,
                dashboardTheme:dashboardTheme,
                homeThemeType: homeThemeType

            };
            if (altThemeLogoVideoIsRemoved){
                data.alt_theme_logo_video_is_empty = true;
            }

            if (bannerBackgroundImageIsRemoved){
                data.banner_background_image_is_empty = true;
            }
            // console.log("bannerBackgroundVideoIsRemoved ", bannerBackgroundVideoIsRemoved)
            if (bannerBackgroundVideoIsRemoved){
                data.banner_background_video_is_empty = true;
            }

            if (homeBannerImageIsRemoved){
                data.home_banner_image_is_empty = true;
            }

            if (updatesBannerImageIsRemoved) {
                data.updates_banner_image_is_empty = true;
            }

            if (membersBannerImageIsRemoved){
                data.members_banner_image_is_empty = true;
            }

            if (benefitsBannerImageIsRemoved){
                data.benefits_banner_image_is_empty = true;
            }

            if (accessBannerImageIsRemoved){
                data.access_banner_image_is_empty = true;
            }

            if (channelBannerImageIsRemoved){
                data.channels_banner_image_is_empty = true;
            }

            if (informationBannerImageIsRemoved){
                data.information_banner_image_is_empty = true;
            }

            if (favIconImageIsRemoved){
                data.favicon_is_empty = true;
            }

            if (appIconImageIsRemoved){
                data.appicon_is_empty = true;
            }

            if (openGraphImageIsRemoved){
                data.open_graph_image_is_empty = true;
            }

            if (logoVideoIsRemoved) {
                data.logo_video_is_empty = true;
            }

            if (logoImageIsRemoved) {
                data.logo_is_empty = true;
            }

            if (lightBackgroundImageIsRemoved) {
                data.light_background_image_is_empty = true;
            }

            if (lightBackgroundVideoIsRemoved) {
                data.light_background_video_is_empty = true;
            }

            if (darkBackgroundImageIsRemoved) {
                data.dark_background_image_is_empty = true;
            }

            if (darkBackgroundVideoIsRemoved) {
                data.dark_background_video_is_empty = true;
            }

            if (headerBackgroundImageIsRemoved) {
                data.header_background_image_is_empty = true;
            }

            if (headerBackgroundVideoIsRemoved) {
                data.header_background_video_is_empty = true;
            }

            if (headerForegroundImageIsRemoved) {
                data.header_foreground_image_is_empty = true;
            }

            try {
                setSubmitted(true);
                const response = await setSpaceProStyle(data);
                if (response && response.result) {
                    if(!dropSection){
                        dispatch(getSpaceInfo(space_username));
                    }
                } else {
                    setAlert({
                        variant: 'danger',
                        message: response.message
                    });
                    window.scrollTo(0, 0);
                    setIsContinueClicked(false);
                }
                setSubmitted(false);
            } catch (error) {
                console.log(error);
            }
        },
        [
            space_username,
            authUserInfo,
            spaceInfo,
            logoImage,
            logoImageBlobPreview,
            logoVideoBlobPreview,
            keyColor,
            keyEndColor,
            font1,
            font2,
            primaryColor,
            secondaryColor,
            lightBackgroundImage,
            lightBackgroundVideo,
            homeBannerVisibility,
            updatesBannerVisibility,
            benefitsBannerVisibility,
            membersBannerVisibility,
            channelsBannerVisibility,
            informationBannerVisibility,
            accessBannerVisibility,
            selectedLightImagePosition,
            selectedLightVideoPosition,
            keyAltColor,
            keyAltEndColor,
            darkBackgroundImage,
            darkBackgroundVideo,
            selectedDarkImagePosition,
            selectedDarkVideoPosition,
            headerLayout,
            headerBackgroundImage,
            headerBackgroundVideo,
            headerForegroundImage,
            foregroundText,
            selectedForegroundPosition,
            hasGradientChecked,
            logoImageIsRemoved,
            lightBackgroundImageIsRemoved,
            lightBackgroundVideoIsRemoved,
            darkBackgroundImageIsRemoved,
            darkBackgroundVideoIsRemoved,
            headerBackgroundImageIsRemoved,
            headerBackgroundVideoIsRemoved,
            headerForegroundImageIsRemoved,
            altThemeLogoVideoIsRemoved,
            membersBannerImageIsRemoved,
            openGraphImageIsRemoved,
            bannerBackgroundVideoIsRemoved,
            bannerBackgroundImageIsRemoved,

            logoImageIsRemoved,
            lightBackgroundImageIsRemoved,
            lightBackgroundVideoIsRemoved,
            darkBackgroundImageIsRemoved,
            darkBackgroundVideoIsRemoved,
            headerBackgroundImageIsRemoved,
            headerBackgroundVideoIsRemoved,
            headerForegroundImageIsRemoved,
            updatesBannerImageIsRemoved,
            membersBannerImageIsRemoved,
            openGraphImageIsRemoved,
            logoVideoIsRemoved,
            informationBannerImageIsRemoved,
            homeBannerImageIsRemoved,
            favIconImageIsRemoved,
            channelBannerImageIsRemoved,
            accessBannerImageIsRemoved,
            bannerBackgroundImageIsRemoved,
            bannerBackgroundVideoIsRemoved,
            benefitsBannerImageIsRemoved,
            altThemeLogoVideoIsRemoved,
            appIconImageIsRemoved,

            invertColor,
            disableColor,
            backgroundColor,
            backgroundEndColor,
            primaryHeaderColor,
            backgroundHeaderColor,
            navigationHeaderFontColor,
            navigationHeaderWeight,
            primaryFooterColor,
            backgroundFooterColor,
            navigationFooterFontColor,
            navigationFooterWeight,
            logoVideo,
            favIcon,
            appIcon,
            headingFontWeight,
            bodyFontWeight,
            homeBannerImage,
            bannerBackgroundImage,
            bannerBackgroundVideo,
            selectedBannerContentPosition,
            updatesBannerImage,
            membersBannerImage,
            benefitsBannerImage,
            accessBannerImage,
            channelBannerImage,
            informationBannerImage,
            selectedBannerBackgroundImagePosition,
            selectedBannerBackgroundVideoPosition,
            headerTheme,
            footerTheme,
            homeTheme,
            updatesTheme,
            benefitsTheme,
            membersTheme,
            accessTheme,
            channelsTheme,
            informationTheme,
            selectedBackgroundDirection,
            openGraphImage,
            altThemeLogoVideo,

            keyAltColor,
            secondaryAltColor,
            invertAltColor,
            disableAltColor,
            backgroundAltColor,
            backgroundAltEndColor,
            selectedBackgroundAltDirection,
            dashboardTheme,
            homeThemeType
        ]
    );



    const setFormSpaceData = useCallback(
        async (createdSpace) => {
            if (createdSpace) {
                if (createdSpace.plan === 'pro') {
                    // set space data to forms here
                    if (createdSpace.pro_style) {
                        const pro_style = createdSpace.pro_style;
                        setKeyColor(pro_style?.key_color ?? keyColor);
                        setHasGradientChecked(pro_style?.has_gradient);
                        setKeyEndColor(pro_style?.gradient_color ?? keyEndColor);
                        setPrimaryColor(pro_style?.light_text_color ?? primaryColor);
                        setSecondaryColor(pro_style?.light_background_color ?? secondaryColor);
                        setSelectedLightImagePosition(pro_style?.light_image_position ?? selectedLightImagePosition);
                        setSelectedLightVideoPosition(pro_style?.light_video_position ?? selectedLightVideoPosition);
                        setKeyAltColor(pro_style?.dark_text_color ?? keyAltColor);
                        setKeyAltEndColor(pro_style?.dark_background_color ?? keyAltEndColor);
                        setSelectedDarkImagePosition(pro_style?.dark_image_position ?? selectedDarkImagePosition);
                        setSelectedDarkVideoPosition(pro_style?.dark_video_position ?? selectedDarkVideoPosition);
                        setHeaderLayout(pro_style?.header_layout ?? headerLayout);
                        setForegroundText(pro_style?.foreground_text ?? foregroundText);
                        setSelectedForegroundPosition(pro_style?.foreground_position ?? selectedForegroundPosition);
                        setInvertColor(pro_style?.invert_color ?? invertColor)
                        setHeaderTheme(pro_style?.header_theme ?? headerTheme)
                        setAccessTheme(pro_style?.access_theme ?? accessTheme)
                        setBackgroundColor(pro_style?.background_color ?? backgroundColor)
                        setBackgroundEndColor(pro_style?.background_end_color ?? backgroundEndColor)
                        setBackgroundFooterColor(pro_style?.background_footer_color ?? backgroundFooterColor)
                        setBackgroundHeaderColor(pro_style?.background_header_color ?? backgroundHeaderColor)
                        setBenefitsTheme(pro_style?.benefits_theme ?? benefitsTheme)
                        setHomeBannerVisibility(pro_style?.home_banner_visibility ?? homeBannerVisibility)
                        setUpdatesBannerVisibility(pro_style?.updates_banner_visibility ?? updatesBannerVisibility)
                        setBenefitsBannerVisibility(pro_style?.benefits_banner_visibility ?? benefitsBannerVisibility)
                        setChannelsBannerVisibility(pro_style?.channels_banner_visibility ?? channelsBannerVisibility)
                        setInformationBannerVisibility(pro_style?.information_banner_visibility ?? informationBannerVisibility)
                        setMembersBannerVisibility(pro_style?.members_banner_visibility ?? membersBannerVisibility)
                        setAccessBannerVisibility(pro_style?.access_banner_visibility ?? accessBannerVisibility)
                        setChannelsTheme(pro_style?.channels_theme ?? channelsTheme)
                        setDisableColor(pro_style?.disable_color ?? disableColor)
                        setFooterTheme(pro_style?.footer_theme ?? footerTheme)
                        setHeadingFontWeight(pro_style?.heading_font_weight ?? headingFontWeight)
                        setHomeTheme(pro_style?.home_theme ?? homeTheme)
                        setInformationTheme(pro_style?.infomation_theme ?? informationTheme)
                        setMembersTheme(pro_style?.members_theme ?? membersTheme)
                        setNavigationFooterFontColor(pro_style?.navigation_footer_font_color ?? navigationFooterFontColor)
                        setNavigationFooterWeight(pro_style?.navigation_footer_font_weight ?? navigationFooterWeight)
                        setNavigationHeaderFontColor(pro_style?.navigation_header_font_color ?? navigationHeaderFontColor)
                        setNavigationHeaderWeight(pro_style?.navigation_header_font_weight ?? navigationHeaderWeight)
                        setPrimaryFooterColor(pro_style?.primary_footer_color ?? primaryFooterColor)
                        setPrimaryHeaderColor(pro_style?.primary_header_color ?? primaryHeaderColor)
                        setSelectedBackgroundDirection(pro_style?.selected_background_direction ?? selectedBackgroundDirection)
                        setSelectedBannerBackgroundImagePosition(pro_style?.selected_banner_background_image_position ?? selectedBannerBackgroundImagePosition)
                        setSelectedBannerBackgroundVideoPosition(pro_style?.selected_banner_background_video_position ?? selectedBannerBackgroundVideoPosition)
                        setSelectedBannerContentPosition(pro_style?.selected_banner_content_position ?? selectedBannerContentPosition)

                        setPrimaryAltColor(pro_style?.primaryAltColor ?? primaryAltColor);
                        setSecondaryAltColor(pro_style?.secondaryAltColor ?? secondaryAltColor);
                        setInvertAltColor(pro_style?.invertAltColor ?? invertAltColor);
                        setDisableAltColor(pro_style?.disableAltColor ?? disableAltColor);
                        setBackgroundAltColor(pro_style?.backgroundAltColor ?? backgroundAltColor);
                        setBackgroundAltEndColor(pro_style?.backgroundAltEndColor ?? backgroundAltEndColor);
                        setSelectedBackgroundAltDirection(pro_style?.selected_background_alt_direction ?? selectedBackgroundAltDirection)
                        setDashboardTheme(pro_style?.dashboardTheme ?? dashboardTheme)
                        setHomeThemeType(pro_style?.homeThemeType ?? homeThemeType)

                        if (pro_style?.altThemeLogoVideo ){
                            if(!altThemeLogoVideoIsRemoved){
                                setAltThemeLogoVideoBlobPreview(pro_style?.altThemeLogoVideo)
                            }
                        }

                        if (pro_style?.appIcon ){
                            if(!appIconImageIsRemoved){
                                setAppIconBlobPreview(pro_style?.appIcon)
                            }
                        }
                        if (pro_style?.updatesBannerImage && !updatesBannerImageIsRemoved){
                            setSectionBannerImageBlobPreview(pro_style?.updatesBannerImage)
                        }

                        if (pro_style?.membersBannerImage && !membersBannerImageIsRemoved){
                            setMembersBannerImageBlobPreview(pro_style?.membersBannerImage)
                        }

                        if (pro_style?.openGraphImage && !openGraphImageIsRemoved){
                            setOpenGraphImageBlobPreview(pro_style?.openGraphImage)
                        }

                        if (pro_style?.logoVideo && !logoVideoIsRemoved){
                            setLogoVideoBlobPreview(pro_style?.logoVideo)
                        }

                        if (pro_style?.informationBannerImage && !informationBannerImageIsRemoved){
                            setInformationBannerImageBlobPreview(pro_style?.informationBannerImage)
                        }

                        if (pro_style?.homeBannerImage && !homeBannerImageIsRemoved){
                            setHomeBannerImageBlobPreview(pro_style?.homeBannerImage)
                        }

                        if (pro_style?.favIcon && !favIconImageIsRemoved){
                            setFavIconBlobPreview(pro_style?.favIcon)
                        }

                        if (pro_style?.channelBannerImage && !channelBannerImageIsRemoved){
                            setChannelBannerImageBlobPreview(pro_style?.channelBannerImage)
                        }

                        if (pro_style?.accessBannerImage && !accessBannerImageIsRemoved){
                            setAccessBannerImageBlobPreview(pro_style?.accessBannerImage)
                        }

                        if (pro_style?.bannerBackgroundImage && !bannerBackgroundImageIsRemoved){
                            setBannerBackgroundImageBlobPreview(pro_style?.bannerBackgroundImage)
                        }

                        if (pro_style?.bannerBackgroundVideo && !bannerBackgroundVideoIsRemoved){
                            setBannerBackgroundVideoBlobPreview(pro_style?.bannerBackgroundVideo)
                        }

                        if (pro_style?.benefitsBannerImage && !benefitsBannerImageIsRemoved){
                            setBenefitsBannerImageBlobPreview(pro_style?.benefitsBannerImage)
                        }

                        if (pro_style?.logo) {
                            if (!logoImageIsRemoved) {
                                setLogoImageBlobPreview(pro_style?.logo);
                            }
                        }

                        if (pro_style.light_background_image) {
                            if (!lightBackgroundImageIsRemoved) {
                                setLightBackgroundImageBlobPreview(
                                    pro_style?.light_background_image
                                );
                            }
                        }

                        if (pro_style?.light_background_video) {
                            if (!lightBackgroundVideoIsRemoved) {
                                setLightBackgroundVideoBlobPreview(
                                    pro_style?.light_background_video
                                );
                            }
                        }

                        if (pro_style?.dark_background_image) {
                            if (!darkBackgroundImageIsRemoved) {
                                setDarkBackgroundImageBlobPreview(pro_style?.dark_background_image);
                            }
                        }

                        if (pro_style?.dark_background_video) {
                            if (!darkBackgroundVideoIsRemoved) {
                                setDarkBackgroundVideoBlobPreview(pro_style?.dark_background_video);
                            }
                        }

                        if (pro_style?.header_background_image) {
                            if (!headerBackgroundImageIsRemoved) {
                                setHeaderBackgroundImageBlobPreview(
                                    pro_style?.header_background_image
                                );
                            }
                        }

                        if (pro_style?.header_background_video) {
                            if (!headerBackgroundVideoIsRemoved) {
                                setHeaderBackgroundVideoBlobPreview(
                                    pro_style?.header_background_video
                                );
                            }
                        }

                        if (pro_style?.header_foreground_image) {
                            if (!headerForegroundImageIsRemoved) {
                                setHeaderForegroundImageBlobPreview(
                                    pro_style?.header_foreground_image
                                );
                            }
                        }

                        // added delay on setting font value to allow api fetch to finish
                        setTimeout(() => {
                            setFont1(pro_style?.title_font);
                            setFont2(pro_style?.body_font);
                        }, 200);
                    }else{
                        // setLightBackgroundImageBlobPreview(createdSpace.background_photo)
                        // setLightBackgroundVideoBlobPreview(createdSpace.background_video)
                        // setBannerBackgroundImageBlobPreview(createdSpace.background_photo)
                        // setBannerBackgroundVideoBlobPreview(createdSpace.background_video)
                    }
                } else {
                    const url = routes.createSpaceLaunch();
                    navigate(url.replace(':space_username', space_username));
                }
            }
        },
        [
            logoImageIsRemoved,
            lightBackgroundImageIsRemoved,
            lightBackgroundVideoIsRemoved,
            darkBackgroundImageIsRemoved,
            darkBackgroundVideoIsRemoved,
            headerBackgroundImageIsRemoved,
            headerBackgroundVideoIsRemoved,
            headerForegroundImageIsRemoved,
            updatesBannerImageIsRemoved,
            membersBannerImageIsRemoved,
            openGraphImageIsRemoved,
            logoVideoIsRemoved,
            informationBannerImageIsRemoved,
            homeBannerImageIsRemoved,
            favIconImageIsRemoved,
            channelBannerImageIsRemoved,
            accessBannerImageIsRemoved,
            bannerBackgroundImageIsRemoved,
            bannerBackgroundVideoIsRemoved,
            benefitsBannerImageIsRemoved,
            altThemeLogoVideoIsRemoved,
            appIconImageIsRemoved
        ]
    );

    const tooltipFocused = (input) => {
        if (input === 'foreground_text') {
            foregroundTextRef.current.focus();
        }
    };

    const onChangeHasGradient = (e) => {
        setHasGradientChecked(e.target.checked);
    };

    useEffect(() => {
        if (spaceInfoLoading && spaceInfoLoading === 'done') {
            if (spaceInfo && spaceInfo.id) {
                setFormSpaceData(spaceInfo).then((r) => {});
            }
        } else {
            if (!spaceInfoLoading && initialLoad) {
                setInitialLoad(false);
                dispatch(getSpaceInfo(space_username));
            }
            if (spaceInfoLoading === 'failed') {
                navigate(routes.createSpace());
            }
        }

        document.title = 'Commonspace Spaces';
    }, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

    useEffect(() => {
        // console.log(dropSection)
        // console.log(dropSection)
        var colorThemeTitle = document.getElementById('cs-theming-colors-title');
        var headerFooterTitle = document.getElementById('cs-theming-headerfooter-title');
        var pagesTitle = document.getElementById('cs-theming-pages-title');

        var themingHeaderFooterToggle = document.getElementById('cs-theming-headerfooter-toggle');
        var themingHeaderFooterContent = document.getElementById('cs-theming-headerfooter-content');

        var themingColorsToggle = document.getElementById('cs-theming-colors-toggle');
        var themingColorsContent = document.getElementById('cs-theming-colors-content');


        var themingBrandToggle = document.getElementById('cs-theming-brand-toggle');
        var themingBrandContent = document.getElementById('cs-theming-brand-content');

        var typographyToggle = document.getElementById('cs-theming-typography-toggle');
        var typographyContent = document.getElementById('cs-theming-typography-content');

        var pagesToggle = document.getElementById('cs-theming-pages-toggle');
        var pagesContent = document.getElementById('cs-theming-pages-content');
        if(dropSection == "#logo-identity"){
            // toggleThemeColor();

            if(themingHeaderFooterToggle.classList.contains('active')){
                themingHeaderFooterToggle.classList.toggle('active');
                themingColorsContent.classList.toggle('hide');
            }

            if(themingBrandToggle.classList.contains('active')){
                themingBrandToggle.classList.toggle('active');
                themingBrandContent.classList.toggle('hide');
            }

            if(typographyToggle.classList.contains('active')){
                typographyToggle.classList.toggle('active');
                typographyContent.classList.toggle('hide');
            }

            if(pagesToggle.classList.contains('active')){
                pagesToggle.classList.toggle('active');
                pagesContent.classList.toggle('hide');
            }
            colorThemeTitle.scrollIntoView({ behavior: 'smooth' });
        }else if(dropSection == "#homepage"){
            toggleHeaderAndFooter();

            if(themingColorsToggle.classList.contains('active')){
                themingColorsToggle.classList.toggle('active');
                themingColorsContent.classList.toggle('hide');
            }

            if(themingBrandToggle.classList.contains('active')){
                themingBrandToggle.classList.toggle('active');
                themingBrandContent.classList.toggle('hide');
            }

            if(typographyToggle.classList.contains('active')){
                typographyToggle.classList.toggle('active');
                typographyContent.classList.toggle('hide');
            }

            if(pagesToggle.classList.contains('active')){
                pagesToggle.classList.toggle('active');
                pagesContent.classList.toggle('hide');
            }
            headerFooterTitle.scrollIntoView({ behavior: 'smooth' });

        }else if(dropSection == "#sections"){
            togglePages();
            if(themingColorsToggle.classList.contains('active')){
                themingColorsToggle.classList.toggle('active');
                themingColorsContent.classList.toggle('hide');
            }

            if(themingBrandToggle.classList.contains('active')){
                themingBrandToggle.classList.toggle('active');
                themingBrandContent.classList.toggle('hide');
            }

            if(typographyToggle.classList.contains('active')){
                typographyToggle.classList.toggle('active');
                typographyContent.classList.toggle('hide');
            }

            if(themingHeaderFooterToggle.classList.contains('active')){
                themingHeaderFooterToggle.classList.toggle('active');
                themingHeaderFooterContent.classList.toggle('hide');
            }

            pagesTitle.scrollIntoView({ behavior: 'smooth' });
        }
    }, [dropSection])

    const toggleThemeColor = () => {
        var themingColorsToggle = document.getElementById('cs-theming-colors-toggle');
        var themingColorsContent = document.getElementById('cs-theming-colors-content');

        themingColorsToggle.classList.toggle('active');
        themingColorsContent.classList.toggle('hide');
    }

    const toggleHeaderAndFooter = () => {
        var themingHeaderFooterToggle = document.getElementById('cs-theming-headerfooter-toggle');
        var themingHeaderFooterContent = document.getElementById('cs-theming-headerfooter-content');

        themingHeaderFooterToggle.classList.toggle('active');
        themingHeaderFooterContent.classList.toggle('hide');
    }

    const toggleBrandElements = () => {
        var themingBrandToggle = document.getElementById('cs-theming-brand-toggle');
        var themingBrandContent = document.getElementById('cs-theming-brand-content');

        themingBrandToggle.classList.toggle('active');
        themingBrandContent.classList.toggle('hide');
    }

    const toggleTypography = () => {
        var typographyToggle = document.getElementById('cs-theming-typography-toggle');
        var typographyContent = document.getElementById('cs-theming-typography-content');

        typographyToggle.classList.toggle('active');
        typographyContent.classList.toggle('hide');
    }

    const togglePages = () => {
        var pagesToggle = document.getElementById('cs-theming-pages-toggle');
        var pagesContent = document.getElementById('cs-theming-pages-content');

        pagesToggle.classList.toggle('active');
        pagesContent.classList.toggle('hide');
    }


    return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-platform-create-pro-theming-c1" className={`p-section-safeview theme-transparent ${dropSection ? '!bg-white' : ''}`}>
                <div className="centerframe-grid gap-2gutter">
                    <div className="block-full">
                        <div className="cs-stack-heading">
                            <div className="stack-row split">
                                <h1>Customize Your Pro Space</h1>
                                {!dropSection && <h3>Step 1 of 2</h3>}
                            </div>
                            <p>
                                Your {getSitewideLink('space_customize_pro_space', '')} is now upgraded to Pro! You can now fully customize your branding, domain, navigation, layout, styles, and access many more advanced features. Theming, which you can explore below, sets up your Space's branding. You can do this now, or manage all your Pro features at any time from your Dashboard. {getSitewideLink('learn_more_pro_features', '')}
                            </p>
                        </div>
                        <div className="cs-stack-form-guided">
                            <div className="formrow title">
                                <div>
                                    <h2>Theming</h2>
                                    <p></p>
                                </div>
                                {getSitewideLink('view_guide_customize_pro', 'button-secondary-gradient', true)}
                                {/*<a href="#link-via-admin" className="button-secondary-gradient"><span><p>Pro Theming Guide <i className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>*/}
                            </div>
                            <div className="block-full">
                                <p>Your Space has color themes which are used across each section, along with additional customizations for specific pages. You can design your Space now or continue and do it later from your Dashboard. Use the <i className="fa-solid fa-angle-down"></i> and <i className="fa-solid fa-angle-up"></i> buttons to expand each theming section, and for more details {getSitewideLink('theming_guide_customize_pro_space', '')}</p>
                            </div>
                            <form id="cs-theming-colors-panel" className="cs-panel">
                                <div className="cs-panel-heading">
                                    <div id="cs-theming-colors-title" className="cs-panel-title">
                                        <h3>Color Themes</h3>
                                        <a onClick={toggleThemeColor} id="cs-theming-colors-toggle" target="_blank" className="button-icon square gradient cs-panel-toggle active"><span><p><i className="fa-regular fa-angle-down"></i></p></span></a>
                                    </div>
                                </div>
                                <div id="cs-theming-colors-content"  className="cs-panel-content">
                                    <div className="block-full">
                                        <p>
                                            The main content of each page in your Space uses customizable color themes. A common approach is to use light and dark themes, but you can also choose any other combination you prefer. Most pages will use a single theme, but some pages, such as your homepage, can optionally use a different theme for each page section. These themes primarily apply to the content between the Header and Footer, as those have separate settings. Each theme has a color and background settings, and you can optionally layer Background Colors with transparent Background Images for more advanced styles.
                                        </p>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Main Theme</h4>
                                        <div>
                                            <p>Required</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">

                                            <ColorPickerAndInput
                                                label="Key Color"
                                                color={keyColor}
                                                onColorChange={(value) => setKeyColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Key End Color"
                                                color={keyEndColor}
                                                onColorChange={(value) => setKeyEndColor(value)}
                                            ></ColorPickerAndInput>

                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Key Color is your key brand color for buttons, titles, & links.</p>
                                            <p>Optional Key End Color can create a gradient for your Key Color applications. Leave blank to use a solid Key Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">

                                            <ColorPickerAndInput
                                                label="Primary Color"
                                                color={primaryColor}
                                                onColorChange={(value) => setPrimaryColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Secondary Color"
                                                color={secondaryColor}
                                                onColorChange={(value) => setSecondaryColor(value)}
                                            ></ColorPickerAndInput>

                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Primary Color is used for most general text and titles.</p>
                                            <p>Secondary Color is used for helper text and notes, like this sentence.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">

                                            <ColorPickerAndInput
                                                label="Invert Color"
                                                color={invertColor}
                                                onColorChange={(value) => setInvertColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Disable Color"
                                                color={disableColor}
                                                onColorChange={(value) => setDisableColor(value)}
                                            ></ColorPickerAndInput>

                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Invert Color is usually opposite of Primary Color or same as Background Color, and mainly used for buttons and hovers.</p>
                                            <p>Disable Color is usually lighter than Secondary Color but darker than Background Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">

                                            <ColorPickerAndInput
                                                label="Background Color"
                                                color={backgroundColor}
                                                onColorChange={(value) => setBackgroundColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Background End Color"
                                                color={backgroundEndColor}
                                                onColorChange={(value) => setBackgroundEndColor(value)}
                                            ></ColorPickerAndInput>

                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Background Color can be a solid color, or can be combined to create a gradient.</p>
                                            <p>Optional Background End Color creates a gradient for your main background. Leave blank to use a solid Background Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div>
                                            <DropdownWithItems
                                                customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                icon="fa-light fa-border-outer"
                                                title="Gradient Direction"
                                                selectedValue={selectedBackgroundDirection}
                                                listItems={CUSTOM_SPACE_BACKGROUND_DIRECTION}
                                                onDropdownChange={(value) =>
                                                    setSelectedBackgroundDirection(value)}
                                            />
                                            {backgroundDirectionError && (
                                                <div className="alert callout warning items-center">
                                                    <div className="action">
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>
                                                            <h6>{backgroundDirectionError}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <p>If you are using a Background End Color to create a gradient, you can specify a direction here.</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setLightBackgroundImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    shape="video"
                                                    hideText="true"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={lightBackgroundImageBlobPreview}
                                                    onUploadFileChange={handleLightBackgroundImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />

                                                <p className="cs-label">Background Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {lightBackgroundImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{lightBackgroundImageError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setLightBackgroundVideoError}
                                                    hideDragIcon="true"
                                                    shape="video"
                                                    hideText="true"
                                                    uploadFileTypes={VIDEO_FILETYPES.toString()}
                                                    uploadedFilePreview={lightBackgroundVideoBlobPreview}
                                                    onUploadFileChange={handleLightBackgroundVideoUploadFileChange}
                                                    minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                                    minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                                    maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                                    maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                                    uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                                />
                                                <p className="cs-label">Background Video</p>
                                                <p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
                                                {lightBackgroundVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{lightBackgroundVideoError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Background Image (up to 8MB) is optional and display over your Background Color(s). A square or wide format is preferred.</p>
                                            <p>Background Video (up to 12MB) is optional and replaces Background Image on supported devices. A square or wide format is preferred.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Image Position"
                                                    selectedValue={selectedLightImagePosition}
                                                    listItems={CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedLightImagePosition(value)
                                                    }
                                                />
                                                {lightImagePositionError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{lightImagePositionError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Video Position"
                                                    selectedValue={selectedLightVideoPosition}
                                                    listItems={CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedLightVideoPosition(value)
                                                    }
                                                />
                                                {lightVideoPositionError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{lightVideoPositionError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Set your media display position.</p>
                                            <p>The Center setting is recommended.</p>
                                        </div>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Alt Theme</h4>
                                        <div>
                                            <p><a onClick={copyFromMainTheme} className="icon-link"><i className="fa-regular fa-copy"></i> Copy from Main Theme</a></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Key Alt Color"
                                                color={keyAltColor}
                                                onColorChange={(value) => setKeyAltColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Key Alt End Color"
                                                color={keyAltEndColor}
                                                onColorChange={(value) => setKeyAltEndColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Key Alt Color an alternative brand color for buttons, titles, & links.</p>
                                            <p>Optional Key Alt End Color can create a gradient for your Key Alt Color applications. Leave blank to use a solid Key Alt Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Primary Alt Color"
                                                color={primaryAltColor}
                                                onColorChange={(value) => setPrimaryAltColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Secondary Alt Color"
                                                color={secondaryAltColor}
                                                onColorChange={(value) => setSecondaryAltColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Primary Alt Color is used for most general text and titles.</p>
                                            <p>Secondary Alt Color is used for helper text and notes, like this sentence.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Invert Alt Color"
                                                color={invertAltColor}
                                                onColorChange={(value) => setInvertAltColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Disable Alt Color"
                                                color={disableAltColor}
                                                onColorChange={(value) => setDisableAltColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Invert Alt Color is usually opposite of Primary Alt Color or same as Background Alt Color, and mainly used for buttons and hovers.</p>
                                            <p>Disable Alt Color is usually lighter than Secondary Alt Color but darker than Background Alt Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Background Alt Color"
                                                color={backgroundAltColor}
                                                onColorChange={(value) => setBackgroundAltColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Background Alt End Color"
                                                color={backgroundAltEndColor}
                                                onColorChange={(value) => setBackgroundAltEndColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Background Alt Color can be a solid color, or can be combined to create a gradient.</p>
                                            <p>Optional Background Alt End Color creates a gradient for your Alt Theme Background. Leave blank to use a solid Background Alt Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div>
                                            <DropdownWithItems
                                                customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                icon="fa-light fa-border-outer"
                                                title="Background Direction"
                                                selectedValue={selectedBackgroundAltDirection}
                                                listItems={CUSTOM_SPACE_BACKGROUND_DIRECTION}
                                                onDropdownChange={(value) =>
                                                    setSelectedBackgroundAltDirection(value)
                                                }
                                            />
                                            {backgroundAltDirectionError && (
                                                <div className="alert callout warning items-center">
                                                    <div className="action">
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>
                                                            <h6>{backgroundAltDirectionError}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <p>If you are using a Background End Color to create a gradient, you can specify a direction here.</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setDarkBackgroundImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={darkBackgroundImageBlobPreview}
                                                    onUploadFileChange={handleDarkBackgroundImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Background Alt Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {darkBackgroundImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{darkBackgroundImageError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setDarkBackgroundVideoError}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={VIDEO_FILETYPES.toString()}
                                                    uploadedFilePreview={darkBackgroundVideoBlobPreview}
                                                    onUploadFileChange={handleDarkBackgroundVideoUploadFileChange}
                                                    minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                                    minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                                    maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                                    maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                                    uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                                />
                                                <p className="cs-label">Background Alt Video</p>
                                                <p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
                                                {darkBackgroundVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{darkBackgroundVideoError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Background Alt Image (up to 8MB) is optional and display over your Background Color(s). A square or wide format is preferred.</p>
                                            <p>Background Alt Video (up to 12MB) is optional and replaces Background Image on supported devices. A square or wide format is preferred.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    title="Image Position"
                                                    selectedValue={selectedDarkImagePosition}
                                                    listItems={CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedDarkImagePosition(value)
                                                    }
                                                />
                                                {bannerBackgroundImagePositionError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{bannerBackgroundImagePositionError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    title="Video Position"
                                                    selectedValue={selectedDarkVideoPosition}
                                                    listItems={CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedDarkVideoPosition(value)
                                                    }
                                                />
                                                {bannerBackgroundVideoPositionError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{bannerBackgroundVideoPositionError}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Set your media display position.</p>
                                            <p>The Center setting is recommended.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <hr/>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <button type="button" id="color-themes" className="primary-gradient" onClick={(e) => submitStyles( 'color-themes', e)} disabled={submitted}>
                                            <span><p>
                                                {submitted && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save Color Themes
                                            </p></span>
                                        </button>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form id="cs-theming-headerfooter-panel" className="cs-panel">
                                <div className="cs-panel-heading">
                                    <div id="cs-theming-headerfooter-title" className="cs-panel-title">
                                        <h3>Header & Footer</h3>
                                        <a onClick={toggleHeaderAndFooter}  id="cs-theming-headerfooter-toggle" target="_blank" className="button-icon square gradient cs-panel-toggle"><span><p><i className="fa-regular fa-angle-down"></i></p></span></a>
                                    </div>
                                </div>
                                <div id="cs-theming-headerfooter-content" className="cs-panel-content hide">
                                    <div className="block-full">
                                        <p>Your Header and Footer are based on your Color Themes, but you can also customize their background, navigation links, and other settings independently. Additionally, your Header has different layout options.</p>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Header</h4>
                                        <div>
                                            <p>Required</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={headerTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setHeaderTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select Main or Alt Theme as your base Header theme and update colors below.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Primary Color"
                                                color={primaryHeaderColor}
                                                onColorChange={(value) => setPrimaryHeaderColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Background Color"
                                                color={backgroundHeaderColor}
                                                onColorChange={(value) => setBackgroundHeaderColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Apply a Header-only Primary Color for general text.</p>
                                            <p>Apply a Header-only Background Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Navigation Font Color"
                                                color={navigationHeaderFontColor}
                                                onColorChange={(value) => setNavigationHeaderFontColor(value)}
                                            ></ColorPickerAndInput>
                                            <div className="cs-form-fieldset-card-row">
                                                <div className="cs-form-radio-set-block air">
                                                    <p className="cs-label">Navigation Font Weight</p>
                                                    <fieldset className="form-radio-set card-block row gradient secondary set-input-hidden" aria-label="Options">
                                                        <div className="form-stack-radio-options">
                                                            <label htmlFor="font_header_nav_weight_bold" className="form-radio-option" onClick={() => setNavigationHeaderWeight('bold')}>
                                                                <span>
                                                                    <input id="font_header_nav_weight_bold" type="radio" name="font_header_nav_weight" value="bold" className="hidden" checked={navigationHeaderWeight == 'bold'}/>
                                                                    <p aria-label="font_header_nav_weight_bold" aria-description="Bold Header Navigation Font" className="!font-bold !not-italic">Bold</p>
                                                                </span>
                                                            </label>
                                                            <label htmlFor="font_header_nav_weight_normal" className="form-radio-option" onClick={() => setNavigationHeaderWeight('normal')}>
                                                                <span>
                                                                    <input id="font_header_nav_weight_normal" type="radio" name="font_header_nav_weight" value="font_header_nav_weight_normal" className="hidden" checked={navigationHeaderWeight == 'normal'}/>
                                                                    <p aria-label="font_header_nav_weight_normal" aria-description="Normal Header Navigation Font" className="!font-normal !not-italic">Normal</p>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Apply a Header-only Nav Link Color. The color on hover will match your Theme's Key Color.</p>
                                            <p>Change the Font Weight for your Nav Links.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-form-fieldset-card-row">
                                            <div className="cs-form-radio-set-stack">
                                                <fieldset className="form-radio-set card-stack gradient secondary set-input-hidden" aria-label="Options">
                                                    <div className="form-stack-radio-options stack-row">
                                                        <label htmlFor="header_layout_standard" className="form-radio-option" onClick={() => setHeaderLayout('standard')}>
                                                            <div>
                                                                <span>
                                                                    <input id="header_layout_standard" type="radio" name="header_layout" value="standard" className="hidden" checked={headerLayout == 'standard'}/>
                                                                    <img src={header_layout1}/>
                                                                    <p aria-label="header_layout_standard" aria-description="Standard Header Layout">Standard Layout</p>
                                                                </span>
                                                            </div>
                                                        </label>
                                                        <label htmlFor="header_layout_split" className="form-radio-option" onClick={() => setHeaderLayout('split')}>
                                                            <img src={header_layout2}/>
                                                            <div>
                                                                <span>
                                                                    <input id="header_layout_split" type="radio" name="header_layout" value="split" className="hidden" checked={headerLayout == 'split'}/>

                                                                    <p aria-label="header_layout_split" aria-description="Split Header Layout">Split Layout</p>
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Standard Layout combines your Navigation with your logo and menus at the top as a single bar.</p>
                                            <p>Split Layout floats your logo and menus at the top and moves navigation down. Requires using a Home Banner.</p>
                                        </div>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Footer</h4>
                                        <div>
                                            <p>Required</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={footerTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setFooterTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select Main or Alt Theme as your base Footer theme and update colors below.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Primary Color"
                                                color={primaryFooterColor}
                                                onColorChange={(value) => setPrimaryFooterColor(value)}
                                            ></ColorPickerAndInput>
                                            <ColorPickerAndInput
                                                label="Background Color"
                                                color={backgroundFooterColor}
                                                onColorChange={(value) => setBackgroundFooterColor(value)}
                                            ></ColorPickerAndInput>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Apply a Footer-only Primary Color for general text.</p>
                                            <p>Apply a Footer-only Background Color.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <ColorPickerAndInput
                                                label="Navigation Font Color"
                                                color={navigationFooterFontColor}
                                                onColorChange={(value) => setNavigationFooterFontColor(value)}
                                            ></ColorPickerAndInput>
                                            <div className="cs-form-fieldset-card-row">
                                                <div className="cs-form-radio-set-block air">
                                                    <p className="cs-label">Navigation Font Weight</p>
                                                    <fieldset className="form-radio-set card-block row gradient secondary set-input-hidden" aria-label="Options">
                                                        <div className="form-stack-radio-options">
                                                            <label htmlFor="font_footer_nav_weight_bold" className="form-radio-option" onClick={() => setNavigationFooterWeight('bold')}>
                                                                <span>
                                                                    <input id="font_footer_nav_weight_bold" type="radio" name="font_footer_nav_weight" value="bold" className="hidden" checked={navigationFooterWeight == 'bold'}/>
                                                                    <p aria-label="font_footer_nav_weight_bold" aria-description="Bold Footer Navigation Font" className="!font-bold !not-italic">Bold</p>
                                                                </span>
                                                            </label>
                                                            <label htmlFor="font_footer_nav_weight_normal" className="form-radio-option" onClick={() => setNavigationFooterWeight('normal')}>
                                                                <span>
                                                                    <input id="font_footer_nav_weight_normal" type="radio" name="font_footer_nav_weight" value="font_header_nav_weight_normal" className="hidden" checked={navigationFooterWeight == 'normal'}/>
                                                                    <p aria-label="font_footer_nav_weight_normal" aria-description="Normal Footer Navigation Font" className="!font-normal !not-italic">Normal</p>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Apply a Footer-only Nav Link Color. The color on hover will match your Theme's Key Color.</p>
                                            <p>Change the Font Weight for your Nav Links.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <hr/>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <button type="button" className="primary-gradient" onClick={(e) => submitStyles( 'header-footer',e)} disabled={submitted}>
                                            <span><p>
                                                {submitted && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save Header & Footer
                                            </p></span>
                                        </button>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form id="cs-theming-brand-panel" className="cs-panel">
                                <div className="cs-panel-heading">
                                    <div id="cs-theming-brand-title" className="cs-panel-title">
                                        <h3>Brand Elements</h3>
                                        <a  onClick={toggleBrandElements} id="cs-theming-brand-toggle" target="_blank" className="button-icon square gradient cs-panel-toggle"><span><p><i className="fa-regular fa-angle-down"></i></p></span></a>
                                    </div>
                                </div>
                                <div id="cs-theming-brand-content" className="cs-panel-content hide">
                                    <div className="block-full">
                                        <p>Set up your Logo applications and Site icons and images. You can specify different logo usage for each theme and location. For example, you may want a full logo on your Header and a symbol-only logo on your Footer, or use a different color logo to match each of your Color Themes. You can also upload an Open Graph Preview Image, which is displayed in social media and messaging apps when any links to your Space are shared.</p>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Logos</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setLogoImageError}
                                                    cropperAspectRatio={1 / 1}
                                                    hideDragIcon="true"
                                                    shape="video"
                                                    hideText="true"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={logoImageBlobPreview}
                                                    onUploadFileChange={handleLogoImageUploadFileChange}
                                                    minHeight={IMAGE_LOGO_MIN_HEIGHT}
                                                    minWidth={IMAGE_LOGO_MIN_WIDTH}
                                                    maxHeight={IMAGE_LOGO_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LOGO_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                    imagePreviewCustomClassName={'centered-image-preview'}
                                                />
                                                <p className="cs-label">Main Theme Logo</p>
                                                <p className="cs-helper">SVG preferred or up to 1600px PNG</p>
                                                {logoImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{logoImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setAltThemeLogoVideoError}
                                                    cropperAspectRatio={1 / 1}
                                                    hideDragIcon="true"
                                                    shape="video"
                                                    hideText="true"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={altThemeLogoVideoBlobPreview}
                                                    onUploadFileChange={handleAltThemeLogoVideoUploadFileChange}
                                                    minHeight={IMAGE_LOGO_MIN_HEIGHT}
                                                    minWidth={IMAGE_LOGO_MIN_WIDTH}
                                                    maxHeight={IMAGE_LOGO_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LOGO_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                    imagePreviewCustomClassName={'centered-image-preview'}

                                                />
                                                <p className="cs-label">Alt Theme Logo</p>
                                                <p className="cs-helper">SVG preferred or up to 1600px PNG</p>
                                                {altThemeLogoVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{altThemeLogoVideoError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Set a logo to use with your Main Theme, preferably as a vector SVG file and wide format.</p>
                                            <p>Set a logo to use with your Alt Theme. If not specified, your Main Theme logo will be used.</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setHeaderBackgroundImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={headerBackgroundImageBlobPreview}
                                                    onUploadFileChange={handleHeaderBackgroundImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Header Logo</p>
                                                <p className="cs-helper">SVG preferred or up to 1600px PNG</p>
                                                {headerBackgroundImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{headerBackgroundImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setHeaderBackgroundVideoError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={headerBackgroundVideoBlobPreview}
                                                    onUploadFileChange={handleHeaderBackgroundVideoUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Footer Logo</p>
                                                <p className="cs-helper">SVG preferred or up to 1600px PNG</p>
                                                {headerBackgroundVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{headerBackgroundVideoError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Specify a logo to use on your Header instead of your Theme's matching logo.</p>
                                            <p>Specify a logo to use on your Footer instead of your Theme's matching logo.</p>
                                        </div>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Icons</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setFavIconImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={favIconBlobPreview}
                                                    onUploadFileChange={handleFavIconUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Favicon</p>
                                                <p className="cs-helper">Optional 1024px PNG</p>
                                                {favIconImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{favIconImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setAppIconImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={appIconBlobPreview}
                                                    onUploadFileChange={handleAppIconUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">App Icon</p>
                                                <p className="cs-helper">Optional 1024px PNG</p>
                                                {appIconImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{appIconImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Your Favicon displays in web browsers' tabs, bookmarks, and saved shorcuts.</p>
                                            <p>Your App Icon is used when your Space URL is saved to phones and other devices, and commonly matches the Favicon.</p>
                                        </div>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Images</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setOpenGraphImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={openGraphImageBlobPreview}
                                                    onUploadFileChange={handleOpenGraphImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Open Graph Preview</p>
                                                <p className="cs-helper">Optional up to 2400px PNG or JPG</p>
                                                {openGraphImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{openGraphImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Your Open Graph Image should be a square or wide image up to 8MB. A square format is preferred, though some sites and apps may crop to a wide 1.91:1 ratio rectangle.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <hr/>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <button type="button" className="primary-gradient" onClick={(e) => submitStyles( 'brand-elements',e)} disabled={submitted}>
                                            <span><p>
                                                {submitted && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save Brand Elements
                                            </p></span>
                                        </button>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form id="cs-theming-typography-panel" className="cs-panel">

                                <div className="cs-panel-heading">
                                    <div id="cs-theming-typography-title" className="cs-panel-title">
                                        <h3>Typography</h3>
                                        <a onClick={toggleTypography} id="cs-theming-typography-toggle" target="_blank" className="button-icon square gradient cs-panel-toggle"><span><p><i className="fa-regular fa-angle-down"></i></p></span></a>
                                    </div>
                                </div>

                                <div id="cs-theming-typography-content" className="cs-panel-content hide">

                                    <div className="block-full">
                                        <p>You can select your Fonts for your Space which are applied as Heading and Body Fonts. Your Heading Font applies to all titles, such as "Fonts" seen above this paragraph, and uses one weight which is commonly bold. Your Body Font applies to all other text, including your navigation, and has two weights.</p>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Heading Font</h4>
                                        <div>
                                            <p>Required</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            {/* <button  type="button"  id="cscxl-font-picker" className='icon-dropdown-gradient-alt secondary' aria-expanded="false" aria-haspopup="true"> */}
                                            {/* <button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-book-font"></i><p>DIN 2014</p><i className="fa-solid fa-caret-down"></i></span></button> */}

                                            <FontsDropdownWithSearch
                                                componentId="titleFont"
                                                selectedValue={font1}
                                                onDropdownChange={setFont1}
                                            ></FontsDropdownWithSearch>
                                            {/* </button> */}
                                        </div>
                                        <div>
                                            <p>Set display position.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Bold"
                                                    selectedValue={headingFontWeight}
                                                    listItems={CUSTOM_SPACE_FONT_WEIGHT}
                                                    onDropdownChange={(value) =>
                                                        setHeadingFontWeight(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Set display position.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Body Font</h4>
                                        <div>
                                            <p>Required</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        {/* added z-10 to prevent font-picker from being overlapped*/}
                                        <div className="cs-stack-form-inputs stack-row z-10">
                                            <FontsDropdownWithSearch
                                                componentId="bodyFont"
                                                selectedValue={font2}
                                                onDropdownChange={setFont2}
                                            ></FontsDropdownWithSearch>
                                        </div>
                                        <div>
                                            <p>Set display position.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-text"></i><p>Regular</p><i className="fa-solid fa-caret-down"></i></span></button>
                                            </div>
                                        </div>
                                        <div>
                                            <p>Set display position.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-bold"></i><p>SemiBold</p><i className="fa-solid fa-caret-down"></i></span></button>
                                            </div>
                                        </div>
                                        <div>
                                            <p>Set display position.</p>
                                        </div>
                                    </div>

                                    <div className="formrow">
                                        <hr/>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <button type="button" className="primary-gradient" onClick={(e) => submitStyles( 'typography',e)} disabled={submitted}>
                                            <span><p>
                                                {submitted && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save Typography
                                            </p></span>
                                        </button>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form id="cs-theming-pages-panel" className="cs-panel">

                                <div className="cs-panel-heading">
                                    <div id="cs-theming-pages-title" className="cs-panel-title">
                                        <h3>Sections & Pages</h3>
                                        <a onClick={togglePages} id="cs-theming-pages-toggle" target="_blank" className="button-icon square gradient cs-panel-toggle"><span><p><i className="fa-regular fa-angle-down"></i></p></span></a>
                                    </div>
                                </div>

                                <div id="cs-theming-pages-content" className="cs-panel-content hide">

                                    <div className="block-full">
                                        <p>You can specify which theme and optional Banner Image to apply to each of your Sections and Pages. Additionally, your Homepage can use a single or dual theme for the top and bottom halves and your Home Banner has extra customizable options which can be layered.</p>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Home</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={homeTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setHomeTheme(value)
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Single Theme"
                                                    selectedValue={homeThemeType}
                                                    listItems={CUSTOM_THEME_TYPE}
                                                    onDropdownChange={(value) =>
                                                        setHomeThemeType(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Select a Color Theme for your Homepage.</p>
                                            <p>Apply a Single Theme for the whole page, or select Dual Theme to apply
                                                your other theme to the lower half.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={homeBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setHomeBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a large Banner on your Homepage. This is required to use Split Header.</p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setBannerBackgroundImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={bannerBackgroundImageBlobPreview}
                                                    onUploadFileChange={handleBannerBackgroundImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Banner Background Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {bannerBackgroundImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{bannerBackgroundImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setBannerBackgroundVideoError}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={VIDEO_FILETYPES.toString()}
                                                    uploadedFilePreview={bannerBackgroundVideoBlobPreview}
                                                    onUploadFileChange={handleBannerBackgroundVideoUploadFileChange}
                                                    minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                                    minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                                    maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                                    maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                                    uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                                />
                                                <p className="cs-label">Banner Background Video</p>
                                                <p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
                                                {bannerBackgroundVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{bannerBackgroundVideoError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Banner Background Image (up to 8MB) displays below your Header, and is used with the optional Split Layout Header setting. A wide format is preferred.</p>
                                            <p>Banner Background Video (up to 16MB) is optional and replaces Banner Background Image on supported devices. A wide format is preferred.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

                                                    title="Image Position"
                                                    selectedValue={selectedBannerBackgroundImagePosition}
                                                    listItems={CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedBannerBackgroundImagePosition(value)
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

                                                    title="Video Position"
                                                    selectedValue={selectedBannerBackgroundVideoPosition}
                                                    listItems={CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedBannerBackgroundVideoPosition(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Set your Background Image Position.</p>
                                            <p>Set your Background Video Position.</p>
                                        </div>
                                    </div>

                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setHeaderForegroundImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={headerForegroundImageBlobPreview}
                                                    onUploadFileChange={handleHeaderForegroundImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Home Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {headerForegroundImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{headerForegroundImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-stack-form-inputs stack-col">
                                                <div className="form-input air">
                                                    <label htmlFor="text">Banner Text</label>
                                                    <input id="text" name="text" type="text" onChange={(value) => setForegroundText(value.target.value)} value={foregroundText} required placeholder="Banner Text."/>
                                                </div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Content Position"
                                                    selectedValue={selectedBannerContentPosition}
                                                    listItems={CUSTOM_SPACE_FOREGROUND_SECTION_POSITIONS}
                                                    onDropdownChange={(value) =>
                                                        setSelectedBannerContentPosition(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="cs-formrow-helper-split">
                                            <p>Home Banner Image (up to 8MB) is optional and displays over your Banner
                                                Background. This will replace Banner Text if present.</p>
                                            <p>Optional Banner Text will display over your Banner Background in your
                                                Heading Font. Content Position will apply to both Banner Image and
                                                Text.</p>
                                        </div>
                                    </div>


                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Updates</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={updatesTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setUpdatesTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Updates section pages.</p>
                                        </div>
                                    </div>

                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={updatesBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setUpdatesBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setUpdatesBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={updatesBannerImageBlobPreview}
                                                    onUploadFileChange={handleUpdatesBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Updates Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {updatesBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{updatesBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Benefits</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={benefitsTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setBenefitsTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Benefits section pages.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={benefitsBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setBenefitsBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setBenefitsBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={benefitsBannerImageBlobPreview}
                                                    onUploadFileChange={handleBenefitsBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />

                                                <p className="cs-label">Benefits Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {benefitsBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{benefitsBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Members</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={membersTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setMembersTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Members section pages.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={membersBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setMembersBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setMembersBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={membersBannerImageBlobPreview}
                                                    onUploadFileChange={handleMembersBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />

                                                <p className="cs-label">Members Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {membersBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{membersBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Access</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={accessTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setAccessTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Access section pages.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={accessBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setAccessBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setAccessBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={accessBannerImageBlobPreview}
                                                    onUploadFileChange={handleAccessBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Access Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {accessBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{accessBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Channels</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={channelsTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setChannelsTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Channels section pages.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={channelsBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setChannelsBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setChannelBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={channelBannerImageBlobPreview}
                                                    onUploadFileChange={handleChannelBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Channel Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {channelBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{channelBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>

                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Information</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={informationTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setInformationTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Information section pages.</p>
                                        </div>
                                    </div>

                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={informationBannerVisibility}
                                                    listItems={CUSTOM_BANNER_VISIBILITY}
                                                    onDropdownChange={(value) =>
                                                        setInformationBannerVisibility(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optionally display a Banner on this section's pages. </p>
                                        </div>
                                    </div>
                                    <div className="formrow media">
                                        <div className="cs-stack-form-media stack-row">
                                            <div className="cs-stack-form-media-item">
                                                <UploadDraggerV2
                                                    onError={setInformationBannerImageError}
                                                    cropperAspectRatio={16 / 9}
                                                    hideDragIcon="true"
                                                    hideText="true"
                                                    shape="video"
                                                    uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                    uploadedFilePreview={informationBannerImageBlobPreview}
                                                    onUploadFileChange={handleInformationBannerImageUploadFileChange}
                                                    minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                    minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                    maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                    maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                    uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                    disableCropper={true}
                                                />
                                                <p className="cs-label">Information Banner Image</p>
                                                <p className="cs-helper">Optional up to 3840px JPEG or PNG</p>
                                                {informationBannerImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{informationBannerImageError.message}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <p>Optional Banner Image for display across the top of this section's pages. A wide format is preferred.</p>
                                        </div>
                                    </div>
                                    <div className="formrow subtitle">
                                        <h4 className="hr-inline gradient">Dashboard</h4>
                                        <div>
                                            <p>Optional</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <div className="cs-stack-form-inputs stack-row">
                                            <div>
                                                <DropdownWithItems
                                                    customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
                                                    icon="fa-light fa-border-outer"
                                                    title="Main Theme"
                                                    selectedValue={dashboardTheme}
                                                    listItems={CUSTOM_SPACE_THEME}
                                                    onDropdownChange={(value) =>
                                                        setDashboardTheme(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Select which Color Theme to apply to your Member Dashboard pages.</p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <hr/>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="formrow">
                                        <button type="button" className="primary-gradient" onClick={(e) => submitStyles( 'pages',e)} disabled={submitted}>
                                            <span><p>
                                                {submitted && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save Pages
                                            </p></span>
                                        </button>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>

                                    <div className="formrow">
                                        <div><hr/></div>
                                        <div className="cs-formrow-helper-none">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <div className="formrow title">
                                <a onClick={handleUpdateLastStepLink} className="button-secondary-gradient"><span><p>
                                {!dropSection && 'Continue'}
                                    {dropSection && 'Back'}
                                </p></span></a>
                                <a href={routes.viewUserSpace() + (dropSection ? space_username.replace('@', '') : space_username)} className="button-secondary-gradient"><span><p>Preview Your Space <i className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        // <>
        // 	<main id="member-profile" className="flex">
        // 		<div className="container !py-20">
        // 			<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8">
        // 				<div className="col-span-12 md:col-span-6 flex items-center">
        // 					<h1 className="mb-4 mt-8 header-large">Customize Your Pro Space</h1>
        // 				</div>
        // 				<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
        // 					<p className="body-txtitalic--large ">Step 1 of 2</p>
        // 				</div>
        // 				<div className="col-span-12">
        // 					<p className="body-text--reg">
        // 						Your Space has upgraded to a{' '}
        // 						{getSitewideLink('pro_space_upgrade')}! You’ve unlocked full
        // 						customization for your branding, layout, and style, as well as more
        // 						advanced features. To get started, set up your customization
        // 						settings below. Also more custom features are launching in March and
        // 						April with our Beta rollout.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 mt-8 items-center">
        // 				<div className="grid grid-cols-1 sm:grid-cols-12  !h-[40px] !rounded-none">
        // 					<div className="sm:col-span-8 my-auto !order-last sm:!order-first">
        // 						<h5 className="leading-tight header-medium">Logo &amp; Key Color</h5>
        // 					</div>
        // 					<div className="sm:col-span-4 my-auto !order-first sm:!order-last flex justify-center items-center">
        // 						{getSitewideLink(
        // 							'view_guide_customize_pro',
        // 							'btn btn-lg btn-secondary'
        // 						)}
        // 					</div>
        // 				</div>
        // 				<div className="col-span-12">
        // 					<p className="body-text--small mt-8">
        // 						Your Space has upgraded to a Pro Space! You’ve unlocked full
        // 						customization for your branding, layout, and style, as well as more
        // 						advanced features. To get started, set up your customization
        // 						settings below. Also more custom features are launching in March and
        // 						April with our Beta rollout.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							passedError={logoImageError}
        // 							cropperAspectRatio={1 / 1}
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={IMAGE_FILETYPES.toString()}
        // 							uploadedFilePreview={logoImageBlobPreview}
        // 							onUploadFileChange={handleLogoImageUploadFileChange}
        // 							minHeight={IMAGE_LOGO_MIN_HEIGHT}
        // 							minWidth={IMAGE_LOGO_MIN_WIDTH}
        // 							maxHeight={IMAGE_LOGO_MAX_HEIGHT}
        // 							maxWidth={IMAGE_LOGO_MAX_WIDTH}
        // 							uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
        // 							disableCropper={true}
        // 							imagePreviewCustomClassName={'centered-image-preview'}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Pro Space Logo File*
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						SVG preferred, or 64px height or better PNG, GIF, or JPG.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="md:hidden md:col-span-4">
        // 						<p className="body-txtitalic--smallest color-reg m-0">
        // 							Select your Key Color by entering a color hex code or using the
        // 							selector by clicking the color square.
        // 						</p>
        // 					</div>
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
        // 						<label className="body-text--small w-full gradient-color-txt ms-4">
        // 							Key Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							color={keyColor}
        // 							onColorChange={(value) => setKeyColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center  has-gradient-group">
        // 						<Checkbox
        // 							checked={hasGradientChecked}
        // 							className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        // 							onChange={(value) => onChangeHasGradient(value)}
        // 						></Checkbox>
        // 						<label className="has-gradient-label pt-[12px]">Has Gradient</label>
        // 					</div>
        // 					<div className="md:hidden md:col-span-4">
        // 						<p className="body-txtitalic--smallest color-reg">
        // 							OPTIONAL FOR GRADIENT ONLY. If you would like your Key Color to be a
        // 							gradient, your first color will be the Start Color and this color
        // 							will be the End Color of the gradient.
        // 						</p>
        // 					</div>
        // 					<div
        // 						className={
        // 							'input-group-com flex !rounded-none h-[40px] items-center justify-between ' +
        // 							(!hasGradientChecked ? 'disable-picker' : '')
        // 						}
        // 					>
        // 						<label className="body-text--small w-full gradient-color-txt ms-4">
        // 							Gradient Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							className={!hasGradientChecked ? 'disable-picker' : ''}
        // 							color={keyEndColor}
        // 							onColorChange={(value) => setKeyEndColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 				</div>
        // 				<div className="hidden md:block md:col-span-4">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						Select your Key Color by entering a color hex code or using the
        // 						selector by clicking the color square.
        // 					</p>
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						OPTIONAL FOR GRADIENT ONLY. If you would like your Key Color to be a
        // 						gradient, your first color will be the Start Color and this color
        // 						will be the End Color of the gradient.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 mt-8 items-center">
        // 				<div className="col-span-1">
        // 					<h5 className="leading-tight header-medium">Fonts</h5>
        // 				</div>
        // 				<div className="col-span-1">
        // 					<p className="body-text--small mt-8">
        // 						Your Space has upgraded to a Pro Space! You’ve unlocked full
        // 						customization for your branding, layout, and style, as well as more
        // 						advanced features. To get started, set up your customization
        // 						settings below. Also more custom features are launching in March and
        // 						April with our Beta rollout.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
        // 				<div className="md:col-span-4 !order-2 md:!order-1">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
        // 						<label className="body-text--small w-1/4 gradient-color-txt ms-4">
        // 							Title Font*
        // 						</label>

        // 						<FontsDropdownWithSearch
        // 							componentId="titleFont"
        // 							selectedValue={font1}
        // 							onDropdownChange={setFont1}
        // 						></FontsDropdownWithSearch>
        // 					</div>
        // 				</div>

        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
        // 						<label className="body-text--small w-1/4 gradient-color-txt ms-4">
        // 							Body Font*
        // 						</label>
        // 						<FontsDropdownWithSearch
        // 							componentId="bodyFont"
        // 							selectedValue={font2}
        // 							onDropdownChange={setFont2}
        // 						></FontsDropdownWithSearch>
        // 					</div>
        // 				</div>
        // 				<div className="md:col-span-4 !order-1 md:!order-3">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						Some fonts may have different sizing, so be sure to review how they
        // 						look on your Space before launching.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 mt-8 items-center">
        // 				<div className="col-span-1">
        // 					<h5 className="leading-tight header-medium">Section Colors & Backgrounds</h5>
        // 				</div>
        // 				<div className="col-span-1 mt-8">
        // 					<p className="body-text--small">
        // 						Your Pro Space has different sections which use your Key Color with
        // 						a Text Color and a Background Color. These have light and dark
        // 						styles and you can select your Text Color and Background Color, plus
        // 						an optional Background Image or Video for each. When selecting your
        // 						Backgrounds, be sure that your Key Color will still stand out. Any
        // 						Background Images or Video which will show on top of Background
        // 						Colors.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 sm:grid-cols-12 mt-8 items-center">
        // 				<div className="sm:col-span-8 !order-last sm:!order-first">
        // 					<h5 className="header-small">Light Section Text & Background Colors</h5>
        // 				</div>
        // 				<div className="sm:col-span-4 !order-first sm:!order-last">
        // 					<p className="body-txtitalic--smallest color-reg">
        // 						Your Light Section should have a dark Text Color on a light
        // 						Background Color.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">

        // 				<div className="md:col-span-4 !order-2 md:!order-1 rounded-none">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
        // 						<label className="body-text--small w-full gradient-color-txt ms-4">
        // 							Text Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							color={primaryColor}
        // 							onColorChange={(value) => setPrimaryColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 				</div>
        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between overflow-hidden text-ellipsis">
        // 						<label className="body-text--small w-full gradient-color-txt ms-4 ">
        // 							Background

        // 							Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							color={secondaryColor}
        // 							onColorChange={(value) => setSecondaryColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 				</div>
        // 				<div className="md:col-span-4 !order-1 md:!order-3">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						Default Light Section Text Color is #0C0C0C for dark grey text on a
        // 						#FFFFFF white Background Color.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Background Image (up to 8MB) for your Section will
        // 						display over the Background Color and transparency is supported.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							cropperAspectRatio={16 / 9}
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={IMAGE_FILETYPES.toString()}
        // 							uploadedFilePreview={lightBackgroundImageBlobPreview}
        // 							onUploadFileChange={handleLightBackgroundImageUploadFileChange}
        // 							minHeight={IMAGE_LARGE_MIN_HEIGHT}
        // 							minWidth={IMAGE_LARGE_MIN_WIDTH}
        // 							maxHeight={IMAGE_LARGE_MAX_HEIGHT}
        // 							maxWidth={IMAGE_LARGE_MAX_WIDTH}
        // 							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
        // 							disableCropper={true}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Image
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 3840px square or wide. JPEG or PNG.
        // 					</p>
        // 				</div>
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Background Video (up to 16MB) for your Section will
        // 						display over the Background Color instead of the Background Image on
        // 						limited supported devices.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={VIDEO_FILETYPES.toString()}
        // 							uploadedFilePreview={lightBackgroundVideoBlobPreview}
        // 							onUploadFileChange={handleLightBackgroundVideoUploadFileChange}
        // 							minHeight={VIDEO_STANDARD_MIN_HEIGHT}
        // 							minWidth={VIDEO_STANDARD_MIN_WIDTH}
        // 							maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
        // 							maxWidth={VIDEO_STANDARD_MAX_WIDTH}
        // 							uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Video
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 1920px/1080px, square or wide. MOV or MP4.
        // 					</p>
        // 				</div>
        // 				<div className="hidden md:block md:col-span-4">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Background Image (up to 8MB) for your Section will
        // 						display over the Background Color and transparency is supported.
        // 					</p>
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Background Video (up to 16MB) for your Section will
        // 						display over the Background Color instead of the Background Image on
        // 						limited supported devices.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
        // 				<div className="md:col-span-4 my-auto !order-last md:!order-first">
        // 					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Image Position
        // 					</label>
        // 					<div className="relative rounded-none ">
        // 						<DropdownWithItems
        // 							customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

        // 							title="Image Position"
        // 							selectedValue={selectedLightImagePosition}
        // 							listItems={CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS}
        // 							onDropdownChange={(value) =>
        // 							setSelectedLightImagePosition(value)
        // 						}
        // 						/>
        // 					</div>

        // 				</div>

        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="md:col-span-4 my-auto !order-last md:!order-first">
        // 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Video Position
        // 						</label>
        // 						<div className="relative rounded-none ">
        // 							<DropdownWithItems
        // 								customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

        // 								title="Video Position"
        // 								selectedValue={selectedLightVideoPosition}
        // 								listItems={CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS}
        // 								onDropdownChange={(value) =>
        // 									setSelectedLightVideoPosition(value)
        // 								}
        // 							/>
        // 						</div>

        // 					</div>

        // 				</div>
        // 				<div className="md:col-span-4 !order-1 md:!order-3">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						You can choose how your Background Image or Video will display. Fill
        // 						is the default and is scaled to the page size, or choose Top,
        // 						Center, or Bottom with no scaling.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 sm:grid-cols-12 mt-8 items-center">
        // 				<div className="sm:col-span-8 !order-last sm:!order-first">
        // 					<h5 className="header-small">Dark Section Text & Background Colors</h5>
        // 				</div>
        // 				<div className="sm:col-span-4 !order-first sm:!order-last">
        // 					<p className="body-txtitalic--smallest color-reg">
        // 						Your Dark Section should have a light Text Color on a dark
        // 						Background Color.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
        // 				<div className="md:col-span-4 !order-2 md:!order-1">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
        // 						<label className="body-text--small w-full gradient-color-txt ms-4">
        // 							Text Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							color={keyAltColor}
        // 							onColorChange={(value) => setKeyAltColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 				</div>
        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between overflow-hidden text-ellipsis">
        // 						<label className="body-text--small w-full gradient-color-txt ms-4">
        // 							Background
        // 							<br />
        // 							Color
        // 						</label>
        // 						<ColorPickerAndInput
        // 							color={keyAltEndColor}
        // 							onColorChange={(value) => setKeyAltEndColor(value)}
        // 						></ColorPickerAndInput>
        // 					</div>
        // 				</div>
        // 				<div className="md:col-span-4 !order-1 md:!order-3">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						Default Dark Section Text Color is #FFFFFF” white text on a #0C0C0C
        // 						dark grey Background Color.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8">
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Background Image (up to 8MB) for your Section will
        // 						display over the Background Color and transparency is supported.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							cropperAspectRatio={16 / 9}
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={IMAGE_FILETYPES.toString()}
        // 							uploadedFilePreview={darkBackgroundImageBlobPreview}
        // 							onUploadFileChange={handleDarkBackgroundImageUploadFileChange}
        // 							minHeight={IMAGE_LARGE_MIN_HEIGHT}
        // 							minWidth={IMAGE_LARGE_MIN_WIDTH}
        // 							maxHeight={IMAGE_LARGE_MAX_HEIGHT}
        // 							maxWidth={IMAGE_LARGE_MAX_WIDTH}
        // 							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
        // 							disableCropper={true}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Image
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 3840px square or wide. JPEG or PNG.
        // 					</p>
        // 				</div>
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Background Video (up to 16MB) for your Section will
        // 						display over the Background Color instead of the Background Image on
        // 						limited supported devices.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={VIDEO_FILETYPES.toString()}
        // 							uploadedFilePreview={darkBackgroundVideoBlobPreview}
        // 							onUploadFileChange={handleDarkBackgroundVideoUploadFileChange}
        // 							minHeight={VIDEO_STANDARD_MIN_HEIGHT}
        // 							minWidth={VIDEO_STANDARD_MIN_WIDTH}
        // 							maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
        // 							maxWidth={VIDEO_STANDARD_MAX_WIDTH}
        // 							uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Video
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 1920px/1080px, square or wide. MOV or MP4.
        // 					</p>
        // 				</div>
        // 				<div className="hidden md:grid md:col-span-4">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Background Image (up to 8MB) for your Section will
        // 						display over the Background Color and transparency is supported.
        // 					</p>
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Background Video (up to 16MB) for your Section will
        // 						display over the Background Color instead of the Background Image on
        // 						limited supported devices.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="md:col-span-4 my-auto !order-last md:!order-first">
        // 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Image Position
        // 						</label>
        // 						<div className="relative rounded-none ">
        // 							<DropdownWithItems
        // 								customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

        // 								title="Image Position"
        // 								selectedValue={selectedDarkImagePosition}
        // 								listItems={CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS}
        // 								onDropdownChange={(value) =>
        // 									setSelectedDarkImagePosition(value)
        // 								}
        // 							/>
        // 						</div>

        // 					</div>

        // 				</div>
        // 				<div className="md:col-span-4 !order-3 md:!order-2">
        // 					<div className="md:col-span-4 my-auto !order-last md:!order-first">
        // 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Video Position
        // 						</label>
        // 						<div className="relative rounded-none ">
        // 							<DropdownWithItems
        // 								customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

        // 								title="Video Position"
        // 								selectedValue={selectedDarkVideoPosition}
        // 								listItems={CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS}
        // 								onDropdownChange={(value) =>
        // 									setSelectedDarkVideoPosition(value)
        // 								}
        // 							/>
        // 						</div>

        // 					</div>

        // 				</div>


        // 				<div className="md:col-span-4 !order-1 md:!order-3">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						You can choose how your Background Image or Video will display. Fill
        // 						is the default and scales to the page size, or choose Top, Center,
        // 						or Bottom with no scaling.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 mt-8 items-center">
        // 				<div className="col-span-1">
        // 					<h5 className="leading-tight header-medium">Homepage Header Layout</h5>
        // 				</div>
        // 				<div className="col-span-1 mt-8">
        // 					<p className="body-text--small">
        // 						Pro Spaces have their own Homepage Header with custom branding and
        // 						navigation, versus Basic Spaces which use the Commonspace Header.
        // 						You can choose from the Standard or the Split Pro Header for your
        // 						homepage. The Standard Pro Header brings the focus to the homepage
        // 						content, versus the Split Pro Header which focuses on the branding
        // 						and requires a background image that won’t blend in with the logo
        // 						and buttons. Images below for example only.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
        // 				<div className="md:col-span-6">
        // 					<img className="img-fluid mx-auto" src={header_layout1} />
        // 				</div>
        // 				<div className="md:hidden">
        // 					<Button
        // 						className={
        // 							'btn btn-lg ' +
        // 							(headerLayout === 'standard' ? 'btn-primary' : 'btn-secondary')
        // 						}
        // 						onClick={() => setHeaderLayout('standard')}
        // 					>
        // 						Select Standard Header
        // 					</Button>
        // 				</div>
        // 				<div className="md:col-span-6 !mt-8 md:!mt-0">
        // 					<img className="img-fluid mx-auto" src={header_layout2} />
        // 				</div>
        // 				<div className="md:hidden">
        // 					<Button
        // 						className={
        // 							'btn btn-lg ' +
        // 							(headerLayout === 'split' ? 'btn-primary' : 'btn-secondary')
        // 						}
        // 						onClick={() => setHeaderLayout('split')}
        // 					>
        // 						Select Split Header
        // 					</Button>
        // 				</div>
        // 			</div>

        // 			<div className="hidden md:grid grid-cols-2  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
        // 				<div className="col-span-1">
        // 					<Button
        // 						className={
        // 							'btn btn-lg ' +
        // 							(headerLayout === 'standard' ? 'btn-primary' : 'btn-secondary')
        // 						}
        // 						onClick={() => setHeaderLayout('standard')}
        // 					>
        // 						Select Standard Header
        // 					</Button>
        // 				</div>
        // 				<div className="col-span-1">
        // 					<Button
        // 						className={
        // 							'btn btn-lg ' +
        // 							(headerLayout === 'split' ? 'btn-primary' : 'btn-secondary')
        // 						}
        // 						onClick={() => setHeaderLayout('split')}
        // 					>
        // 						Select Split Header
        // 					</Button>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 mt-8 items-center">
        // 				<div className="col-span-1">
        // 					<h5 className="leading-tight header-medium">
        // 						Homepage Header Background & Foreground
        // 					</h5>
        // 				</div>
        // 				<div className="col-span-1 mt-8">
        // 					<p className="body-text--small">
        // 						Your Homepage Header can have a custom Background and Foreground.
        // 						The default is to use your Space Profile Background but you can
        // 						optionally choose a different Image or Video below. In addition, you
        // 						can choose an optional Foreground Image or Text which will display
        // 						over the Background on your Homepage only. In the example in the
        // 						section above, the Background is the sky image with the head and the
        // 						Foreground is the DRMVRSE logo.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Header Background Image (up to 8MB) will replace your
        // 						default Space Profile Background for the Homepage only.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							cropperAspectRatio={16 / 9}
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={IMAGE_FILETYPES.toString()}
        // 							uploadedFilePreview={headerBackgroundImageBlobPreview}
        // 							onUploadFileChange={handleHeaderBackgroundImageUploadFileChange}
        // 							minHeight={IMAGE_LARGE_MIN_HEIGHT}
        // 							minWidth={IMAGE_LARGE_MIN_WIDTH}
        // 							maxHeight={IMAGE_LARGE_MAX_HEIGHT}
        // 							maxWidth={IMAGE_LARGE_MAX_WIDTH}
        // 							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
        // 							disableCropper={true}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Image
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 3840px square or wide. JPEG or PNG.
        // 					</p>
        // 				</div>
        // 				<div className="md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Header Background Video (up to 16MB) will replace the
        // 						Header Background Image on limited supported devices.
        // 					</p>
        // 				</div>
        // 				<div className="md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={VIDEO_FILETYPES.toString()}
        // 							uploadedFilePreview={headerBackgroundVideoBlobPreview}
        // 							onUploadFileChange={handleHeaderBackgroundVideoUploadFileChange}
        // 							minHeight={VIDEO_STANDARD_MIN_HEIGHT}
        // 							minWidth={VIDEO_STANDARD_MIN_WIDTH}
        // 							maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
        // 							maxWidth={VIDEO_STANDARD_MAX_WIDTH}
        // 							uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Background Video
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 1920px/1080px, square or wide. MOV or MP4.
        // 					</p>
        // 				</div>
        // 				<div className="hidden md:grid md:col-span-4">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Header Background Image (up to 8MB) will replace your
        // 						default Space Profile Background for the Homepage only.
        // 					</p>
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The optional Header Background Video (up to 16MB) will replace the
        // 						Header Background Image on limited supported devices.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
        // 				<div className="col-span-12 md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Header Foreground Image (up to 8MB) will display over
        // 						the Header Background for the Homepage only.
        // 					</p>
        // 				</div>
        // 				<div className="col-span-12 md:col-span-4">
        // 					<div className="upload-rec">
        // 						<UploadDragger
        // 							cropperAspectRatio={16 / 9}
        // 							hideDragIcon="true"
        // 							hideText="true"
        // 							uploadFileTypes={IMAGE_FILETYPES.toString()}
        // 							uploadedFilePreview={headerForegroundImageBlobPreview}
        // 							onUploadFileChange={handleHeaderForegroundImageUploadFileChange}
        // 							minHeight={IMAGE_LARGE_MIN_HEIGHT}
        // 							minWidth={IMAGE_LARGE_MIN_WIDTH}
        // 							maxHeight={IMAGE_LARGE_MAX_HEIGHT}
        // 							maxWidth={IMAGE_LARGE_MAX_WIDTH}
        // 							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
        // 							disableCropper={true}
        // 						/>
        // 						<i className="fa-regular fa-plus"></i>
        // 					</div>
        // 					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
        // 						Foreground Image
        // 					</p>
        // 					<p className="text-center body-txtitalic--smallest">
        // 						Up to 3840px square or wide. JPEG or PNG.
        // 					</p>
        // 				</div>
        // 				<div className="col-span-12 md:hidden">
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The Foreground Image can be positioned as a scaled Fill or as a Top,
        // 						Center (Default), or Bottom position with no scaling. The Optional
        // 						Foreground Text will replace the Foreground Image in the middle
        // 						center if added.
        // 					</p>
        // 				</div>
        // 				<div className="col-span-12 md:col-span-4 ">
        // 					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Foreground Text*
        // 					</label>
        // 					<div className="relative rounded-none ">

        // 						<input
        // 							onFocus={hideTooltip}
        // 							onBlur={showTooltip}
        // 							type="text"
        // 							className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
        // 							placeholder="&nbsp;"
        // 							onChange={(e) => setForegroundText(e.target.value)}
        // 							value={foregroundText}
        // 							ref={foregroundTextRef}
        // 							maxLength={128}
        // 							/>
        // 					</div>
        // 					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
        // 						Foreground Position*
        // 						</label>
        // 						<div className="relative rounded-none ">
        // 							<DropdownWithItems
        // 								customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"

        // 								title="Foreground Position"
        // 								selectedValue={selectedForegroundPosition}
        // 								listItems={CUSTOM_SPACE_FOREGROUND_POSITIONS}
        // 								onDropdownChange={(value) =>
        // 									setSelectedForegroundPosition(value)
        // 								}
        // 							/>
        // 						</div>

        // 				</div>

        // 				<div className="hidden md:grid md:col-span-4">
        // 					<p className="body-txtitalic--smallest color-reg m-0">
        // 						The optional Header Foreground Image (up to 8MB) will display over
        // 						the Header Background for the Homepage only.
        // 					</p>
        // 					<p className="body-txtitalic--smallest color-reg mt-8">
        // 						The Foreground Image can be positioned as a scaled Fill or as a Top,
        // 						Center (Default), or Bottom position with no scaling. The Optional
        // 						Foreground Text will replace the Foreground Image in the middle
        // 						center if added.
        // 					</p>
        // 				</div>
        // 			</div>
        // 			<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8">
        // 				<div className="md:col-span-8">
        // 					<Button
        // 						onClick={() => submitStyles(true)}
        // 						className="btn btn-lg btn-primary"
        // 						type="button"
        // 						disabled={submitted}
        // 					>
        // 						{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
        // 						Save & Continue
        // 					</Button>
        // 				</div>
        // 				<div className="md:col-span-4 flex items-end">
        // 					<Button
        // 						onClick={() => submitStyles(false)}
        // 						className="btn btn-lg btn-secondary"
        // 						type="button"
        // 						disabled={submitted}
        // 					>
        // 						{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
        // 						Save & Go Back
        // 					</Button>
        // 				</div>
        // 			</div>
        // 		</div>
        // 	</main>
        // </>
    );
};


export default memo(CustomizeProSpace);
