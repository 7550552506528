export const localizationEN = {
    login_header: "Login",
    login_header_details: "Enter your email or username to login.",
    login_2_factor: "If you have 2 Factor Authentication enabled, have your method available.",
    login_trouble_logging_in: "Trouble logging in?",
    login_reset: "Reset password here.",
    username_placeholder: "Email or Username",
    password_placeholder: "password",
    email_is_required: "Email is required.",
    login_2fa_code: "Enter 2FA App Code",
    confirm_login: "Confirm & Login",
    login_with_wallet: "Login with wallet",
    login_with_email: "Login with email",
    secure_enabled: "Secure Authentication Enabled",
    password_associated_email: "Enter your password associated with your email. If you have 2 Factor Authentication enabled, be sure to have your method available.",
    enter_the_2fa: "Enter the 2FA code associated with your account from your app. This may be from Google Authenticator, Authy, or whichever app you set up.",
};

export const localizationJP = {
    login_header: "ログイン",
    login_header_details: "メールアドレスまたはユーザー名を入力してログインしてください。",
    login_2_factor: "2要素認証が有効な場合は、認証方法を準備してください。",
    login_trouble_logging_in: "ログインに問題がありますか？",
    login_reset: "ここでパスワードをリセットします。",
    username_placeholder: "メールアドレスまたはユーザー名",
    password_placeholder: "パスワード",
    email_is_required: "メールアドレスは必須です。",
    login_2fa_code: "2FAアプリコードを入力してください",
    confirm_login: "確認してログイン",
    login_with_wallet: "ウォレットでログイン",
    login_with_email: "メールでログイン",
    secure_enabled: "セキュア認証が有効",
    password_associated_email: "メールアドレスに関連付けられたパスワードを入力してください。2要素認証が有効な場合は、認証方法を準備してください。",
    enter_the_2fa: "アプリからアカウントに関連する2FAコードを入力してください。これはGoogle Authenticator、Authy、または設定した他のアプリかもしれません。",
};
