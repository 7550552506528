export const GENDERS = [
	{
		label: 'Male',
		value: 'male'
	},
	{
		label: 'Female',
		value: 'female'
	},
	{
		label: 'Non Binary',
		value: 'non_binary'
	},
	{
		label: 'Gender Neutral',
		value: 'gender_neutral'
	},
	{
		label: 'Gender Fluid',
		value: 'gender_fluid'
	},
	{
		label: 'Decline to State',
		value: 'decline_to_state'
	},
	{
		label: 'Other',
		value: 'other'
	}
];

export const PRONOUNS = [
	{
		label: 'She/Her',
		value: 'she_her'
	},
	{
		label: 'He/Him',
		value: 'he_him'
	},
	{
		label: 'They/Them',
		value: 'they_them'
	},
	{
		label: 'Other',
		value: 'other'
	}
];

export const LANGUAGE = [
	{
		label: 'English',
		value: 'english'
	},
	{
		label: 'Japanese',
		value: 'japanese'
	}
];

const lngs = {
	en: { nativeName: 'English' },
	jp: { nativeName: 'Japanese' }
};

export const LOCATION = [
	{

		label: 'United States',
		value: 'United States'

	}
]

export const VISIBILITY = [
	{
		label: 'Private',
		value: 'private',
		icon: 'fa-light fa-lock'
	},
	{
		label: 'Public',
		value: 'public',
		icon: 'fa-light fa-earth-americas'
	}
];

export const VISIBILITY_SUB_SETTINGS = [
	{
		label: 'Public',
		value: 'public',
		icon: 'fa-light fa-earth-americas'
	},
	{
		label: 'Followers Only',
		value: 'followers',
		icon: 'fa-light fa-users'
	},
	{
		label: 'Mutuals Only',
		value: 'mutuals',
		icon: 'fa-light fa-people-arrows'
	},
	{
		label: 'Hidden',
		value: 'hidden',
		icon: 'fa-light fa-lock'
	}
];

export const VISIBILITY_SUB_PRIVATE_SETTINGS = [
	{
		label: 'Followers Only',
		value: 'followers',
		icon: 'fa-light fa-users'
	},
	{
		label: 'Mutuals Only',
		value: 'mutuals',
		icon: 'fa-regular fa-people-arrows'
	},
	{
		label: 'Hidden',
		value: 'hidden',
		icon: 'fa-regular fa-lock'
	}
];

export const GENDER_LIST_WITH_DEFAULT = GENDERS;
if (!GENDER_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	GENDER_LIST_WITH_DEFAULT.unshift({
		label: 'Gender',
		value: 'default',
		disabled: true
	});
}

export const PRONOUNS_LIST_WITH_DEFAULT = PRONOUNS;
if (!PRONOUNS_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	PRONOUNS_LIST_WITH_DEFAULT.unshift({
		label: 'Pronouns',
		value: 'default',
		disabled: true
	});
}

export const VISIBILITY_LIST_WITH_DEFAULT = VISIBILITY;
if (!VISIBILITY_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	VISIBILITY_LIST_WITH_DEFAULT.unshift({
		label: 'Visibility',
		value: 'default',
		disabled: true
	});
}

export const VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT = VISIBILITY_SUB_SETTINGS;
if (!VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT.unshift({
		label: 'Visibility',
		value: 'default',
		disabled: true
	});
}

export const VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT = VISIBILITY_SUB_PRIVATE_SETTINGS;
if (!VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT.unshift({
		label: 'Visibility',
		value: 'default',
		disabled: true
	});
}

export const getListIcon = (list, value) => {
	const res = list.find((x) => x.value === value);
	if (res) {
		return res.icon;
	}
	return '';
};
