import { postRequest } from './base';
import { RECAPTCHA_ACTION } from '../constants/recaptcha';

// This function sends a request to refresh the authentication token
export const refresh_token = async (data) => {
	// Send a POST request to '/api/auth/token/refresh' with the provided data
	return postRequest('/api/auth/token/refresh', data);
};

// This function sends a login request
export const login = async (data) => {
	// Send a POST request to '/api/user/login' with the provided data and a reCAPTCHA action for login
	// return postRequest('/api/user/login', data, RECAPTCHA_ACTION.LOGIN);
	return postRequest('/api/user/login', data);
};

// This function sends a request to link a social account
export const social_link = async (data) => {
	// Send a POST request to '/api/user/social_link' with the provided data
	return postRequest('/api/user/social_link', data);
};

// This function sends a request for magic login
export const magic_login = async (data) => {
	// Send a POST request to '/api/user/magic_login' with the provided data and a reCAPTCHA action for magic login
	// return postRequest('/api/user/magic_login', data, RECAPTCHA_ACTION.MAGIC_LOGIN);
	return postRequest('/api/user/magic_login', data);
};