import { postRequest, getRequest } from './base';

export const followUser = async (data) => {
	return postRequest('/api/user/follow', data);
};

export const unfollowUser = async (data) => {
	return postRequest('/api/user/unfollow', data);
};

export const getFollowersList = async (params) => {
	return getRequest('/api/user/followers/list/get', params);
};

export const getFollowingsList = async (params) => {
	return getRequest('/api/user/followings/list/get', params);
};

export const getMutualsList = async (params) => {
	return getRequest('/api/user/mutuals/list/get', params);
};
