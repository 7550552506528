import { ACTION_TYPES } from './const';

export const getSpaceInfo = (space_username, viewing_user_id=null, is_manage_mode=false) => ({
	type: ACTION_TYPES.GET_SPACE_INFO,
	username: space_username,
	viewing_user_id: viewing_user_id,
	is_manage_mode: is_manage_mode
});

export const getSpaceInfoSuccess = (spaceInfo) => ({
	type: ACTION_TYPES.GET_SPACE_INFO_SUCCESS,
	spaceInfo: spaceInfo
});

export const getSpaceInfoFailed = (message) => ({
	type: ACTION_TYPES.GET_SPACE_INFO_FAILED,
	message: message
});
