import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';

import './../SpaceView.scss';
import default_profile_card_bg from '../../../assets/images/profile_header.png';
import { subscribeNewsletter } from '../../../services/space';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import { hideTooltip, showTooltip } from '../../../utils/input';
import routes from '../../../routes';
import SocialLinks from "../../../components/commons/SocialLinks";

const SpacePasswordProtected = (props) => {
	const navigate = useNavigate();
	const { spaceData, authUserInfo, onPasswordSubmit, displayPasswordError, globalActiveTab } = props;
	const [continueForm, subscriptionForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [alertPassword, setAlertPassword] = useState(null);
	const [submittedPassword, setSubmittedPassword] = useState(false);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);

	let space_bg = null;
	let cardBackgroundType = 'image';
	if (spaceData.background_photo || spaceData.background_video) {
		if (spaceData.background_photo) {
			space_bg = {
				backgroundImage: 'url(' + spaceData.background_photo + ')'
			};
			cardBackgroundType = 'image';
		}
		if (spaceData.background_video) {
			cardBackgroundType = 'video';
		}
	} else {
		space_bg = {
			backgroundImage: 'url(' + default_profile_card_bg + ')'
		};
		cardBackgroundType = 'image';
	}

	const handlePasswordFormSubmit = useCallback(
		async (values) => {
			setSubmittedPassword(true);
			setAlert(null);
			setAlertPassword(null);

			if (!values.password) {
				return;
			}

			const data = {
				username: spaceData.username,
				password: values.password
			};

			onPasswordSubmit(data);
			setSubmittedPassword(false);
		},
		[spaceData, authUserInfo]
	);

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		setSubmittedSubscription(true);
		setAlert(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await subscribeNewsletter(data);
		if (response && response.result) {
			setAlert({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlert({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedSubscription(false);
	}, []);

	const redirectToLogin = useCallback(async () => {
		navigate(routes.login());
	}, []);

	useEffect(() => {
		if (displayPasswordError) {
			setAlertPassword({
				variant: 'danger',
				message: 'Incorrect space password.'
			});
		}
	}, [displayPasswordError]);

	return (
		<>
			<main id="cs-space-main" className={`space-view-pro cs-main`}>
				<section id="cs-profile-banner" className={`h-5row p-0 bg-cover-center relative`} style={{backgroundImage: `url(${spaceData.background_photo_medium})`}}>
					<div className={`p-section-safeview bg-primary-shader-light backdrop-blur-lg relative h-5row`}>
					</div>
				</section>
				<div className="w-full my-8">
					<div className="container pt-3 pb-3 mb-5 text-center">
						<h3 className="w-full">
							Welcome to the {spaceData.name}
						</h3>
					</div>

					<div className="tab-content space-profile-url" id="pills-tabContent">
						<div className="tab-pane show active">
							<div className="container">
								<div className="grid grid-cols-12 flex justify-center mt-5 mb-5">
									<div className="col-span-6 col-start-4 text-center">
										<h1>Password Required</h1>
										{!authUserInfo && (
											<>
												<p className="body-text-black">
													Log in or enter site password to continue.
												</p>

												<button
													className="primary-gradient w-full mt-4"
													onClick={redirectToLogin}
												>
													<span><p>Login</p></span>
												</button>
											</>
										)}

										<Form
											form={continueForm}
											name="continueForm"
											id="continueForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handlePasswordFormSubmit}
											className={'space-settings-error pt-3'}
										>
											<>
												{alertPassword && (
													<div className={`alert callout ${alertPassword.variant === 'danger' ? 'error': 'success'} !m-0`}>
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{alertPassword.message}</h6>
															</div>
														</div>
													</div>
													// <div className="grid grid-cols-12 m-0 p-0">
													// 	<div className="col-span-8 m-0 p-0">
													// 		<Alert
													// 			key={alertPassword.variant}
													// 			variant={alertPassword.variant}
													// 			className={
													// 				'custom-alert ' +
													// 				(alertPassword.variant ===
													// 				'danger'
													// 					? 'custom-alert-danger'
													// 					: 'custom-alert-success')
													// 			}
													// 		>
													// 			{alertPassword.message}
													// 		</Alert>
													// 	</div>
													// </div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Password"
													name="password"
													rules={[{ required: true }]}
												>
													<div className="form-input-combo w-full">
														{/*<label htmlFor="password">Password</label>*/}
														<input id="password"
															   name="password"
															   type="password"
															   className="w-full"
															   onFocus={hideTooltip}
															   onBlur={showTooltip}
															   autoComplete="current-password"
															   required
															   placeholder="Enter site password for access."
														/>
														<button disabled={submittedPassword} type="submit" className="primary-gradient w-3/4">
															<span><p>
																{submittedPassword && (
																	<i className="fa-solid fa-spinner fa-spin"></i>
																)}
																Continue
															</p></span>
														</button>
													</div>
												</Form.Item>
											</div>
										</Form>

										<Form
											form={subscriptionForm}
											name="subscriptionForm"
											id="subscriptionForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleSubscriptionFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												setAlert(null);
											}}
										>
											<>
												{alert && (
													<div className={`alert callout ${alert.variant === 'danger' ? 'error': 'success'} !m-0`}>
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{alert.message}</h6>
															</div>
														</div>
													</div>
													// <div className="grid grid-cols-12 m-0 p-0">
													// 	<div className="col-span-8 m-0 p-0">
													// 		<Alert
													// 			key={alert.variant}
													// 			variant={alert.variant}
													// 			className={
													// 				'custom-alert ' +
													// 				(alert.variant === 'danger'
													// 					? 'custom-alert-danger'
													// 					: 'custom-alert-success')
													// 			}
													// 		>
													// 			{alert.message}
													// 		</Alert>
													// 	</div>
													// </div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													rules={[{ required: true }]}
												>
													<div className="form-input-combo w-full">
														{/*<label htmlFor="password">Password</label>*/}
														<input id="email"
															   name="email"
															   type="email"
															   className="w-full"
															   onFocus={hideTooltip}
															   onBlur={showTooltip}
															   autoComplete="current-password"
															   required
															   disabled={submittedSubscription}
															   placeholder="Enter email to subscribe to newsletter."
														/>
														<button disabled={submittedSubscription} type="submit" className="secondary-gradient w-3/4">
															<span><p>
																{submittedSubscription && (
																	<i className="fa-solid fa-spinner fa-spin"></i>
																)}
																Subscribe
															</p></span>
														</button>
													</div>
												</Form.Item>
											</div>
											{(spaceData && spaceData?.social_links) && (
												<SocialLinks
													socialLinks={spaceData.social_links}
													parentClassName="cs-stack-link-icons stack-row-wrap"
													childClassName="button-icon-round-gradient-alt"
												/>
											)}
											{/*<ul className="list-socials pt-2 flex list-none">*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-twitter isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-instagram isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-facebook isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-tiktok isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-youtube isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-linkedin isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-discord isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-brands fa-twitch isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*	<li>*/}
											{/*		/!* <a href=""> *!/*/}
											{/*		<i className="fa-regular fa-globe isDisabled"></i>*/}
											{/*		/!* </a> *!/*/}
											{/*	</li>*/}
											{/*</ul>*/}
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpacePasswordProtected);
