export const CARD_TYPES = [
	{
		label: 'Visa',
		value: 'visa',
		icon: 'fa fa-brands fa-cc-visa'
	},
	{
		label: 'Mastercard',
		value: 'mastercard',
		icon: 'fa fa-brands fa-cc-mastercard'
	},
	{
		label: 'Amex',
		value: 'american_express',
		icon: 'fa fa-brands fa-cc-amex'
	},
	{
		label: 'Discover',
		value: 'discover_divers',
		icon: 'fa fa-brands fa-cc-discover'
	},
	{
		label: 'JCB',
		value: 'japan_credit_bureau',
		icon: 'fa fa-brands fa-cc-jcb'
	},
	{
		label: 'Card',
		value: 'other',
		icon: 'fa fa-credit-card'
	}
];
