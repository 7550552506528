import { memo, useEffect, useState } from 'react';
import sample_profile_image from '../../../../assets/images/user_profile_image.png';
import sample_header from '../../../../assets/images/profile_header.png';
import sample_item from '../../../../assets/images/manage_img.png';
import { Link } from 'react-router-dom';
import { getListDisplay } from '../../../../utils/list';
import { PRONOUNS } from '../../../../constants/profile';
import { shortenNumber } from '../../../../utils/number';
import moment from 'moment/moment';
import BanUserModal from '../../../../components/commons/Modals/BanUser/BanUserModal';
import {displayPageContentLoader} from "../../../../states/pageContentLoader";
import {useDispatch} from "react-redux";
const ManageMember = (props) => {
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const { memberData, spaceInfo, getMemberDataRequest, toUpdateDataId } = props;

	const profile_header = {
		backgroundImage: 'url(' + sample_header + ')'
	};

	const sample_items = {
		backgroundImage: 'url(' + sample_item + ')'
	};

	useEffect(() => {
		dispatch(displayPageContentLoader());
	}, []);

	useEffect(() => {
		// console.log('blocked: ' + JSON.stringify(memberData));
		// console.log('blocked: ' + memberData.blocked);
		// console.log("test");
	}, [memberData]);

	return (
		<div className="block-full card">
			{memberData && (
				<div className="manage-members">
					<div className="dashboard-profile">
						<div style={profile_header} className="manage-members-header">
							<div className="row bring-front">
								<div className="grid grid-cols-12">
									<div className='col-span-12 md:col-span-2'>
										<div>
											<img
												className="manage-members-profile"
												src={memberData.user.profile.photo_thumb}
											/>
											<h2 className="text-white mt-2 text-center">
												{memberData.user.profile.username
													? '@' + memberData.user.profile.username
													: memberData.user.profile.full_name}
											</h2>
										</div>
									</div>

									<div className='col-span-12 md:col-span-7 p-0'>
										<div className="ms-4">
											<h1 className="text-white header-large color-lightest">
												{memberData.user.profile.display_name}
												<span className="text-white body-txtitalic--small">
												{getListDisplay(
													PRONOUNS,
													memberData.user.profile.pronouns
												)}
											</span>
											</h1>
											<p className="body-txtitalic--smallest color-lightest mt-4 text-white">
												{memberData.user.profile.intro}
											</p>
											<ul className="text-white list-socials pt-2">
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitter isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-instagram isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-facebook isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-tiktok isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-youtube isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-linkedin isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-discord isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitch isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-regular fa-globe isDisabled"></i>
													{/* </a> */}
												</li>
											</ul>
										</div>

									</div>
									<div className="col-span-12 md:col-span-3 ico-p">
										<div className="flex justify-between">
											<Link className="btn btn-md btn-tertiary me-3" to="">
												<i className="fa-light fa-arrow-up-right-from-square text-white"></i>
											</Link>
											<Link className="btn btn-md btn-tertiary me-3" to="">
												<i className="fa-light fa-envelope text-white"></i>
											</Link>
											<Link
												className={
													(memberData != null && memberData.blocked
														? 'btn-tertiary-blocked '
														: 'btn-tertiary ') + 'btn btn-md'
												}
												to=""
											>
												<i
													className="fa-light fa-ban text-white"
													onClick={() => setModalOpen(true)}
												></i>
											</Link>
										</div>
										<p className="mt-5 body-text--small color-lightest text-white">
											<i className="fa-solid fa-calendar text-white"></i> Member since{' '}
											{moment(memberData.joined_on).format('LL')}
										</p>
										<p className="body-text--small color-lightest text-white">
											<i className="fa-solid fa-user-group text-white"></i>{' '}
											{shortenNumber(memberData.user.followers_count)} Followers
										</p>
										<p className="body-text--small color-lightest text-white">
											<i className="fa-light fa-user-group text-white"></i>{' '}
											{shortenNumber(memberData.user.followings_count)} Following
										</p>
										<p className="body-text--small color-lightest text-white">
											<i className="fa-solid fa-solar-system text-white"></i>{' '}
											{shortenNumber(memberData.user.spaces_count)} Spaces
										</p>
									</div>
								</div>

							</div>
							<div className="bg-overlay"></div>
						</div>
						<div className="manage-members-container">
							<div className="row">
								<div className="col-8">
									<h2>2 Membership</h2>
								</div>
								<div className="col-4 flex justify-between gap-x-4">
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-gifts"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-list-timeline"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-square-plus"/></p></span>
									</Link>
								</div>
							</div>
							<div className="row">
								<div className="col-2 mt-4">
									<div
										className="manage-members-card flex items-center"
										style={sample_items}
									>
										<div className="text-center bring-front">
											<p className="header-small-title color-lightest m-0 text-white">
												Edition#1 key
											</p>
											<p className="body-txtitalic--smallest color-lightest m-0 text-white">
												DRMVRSE Membership 2023
											</p>
										</div>
										<div className="bg-overlay"></div>
									</div>
								</div>
								<div className="col-2 mt-4">
									<div
										className="manage-members-card flex items-center"
										style={sample_items}
									>
										<div className="text-center bring-front">
											<p className="header-small-title color-lightest m-0 text-white">
												Edition#1 key
											</p>
											<p className="body-txtitalic--smallest color-lightest m-0 text-white">
												DRMVRSE Membership 2023
											</p>
										</div>
										<div className="bg-overlay"></div>
									</div>
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-8">
									<h2>2 Items</h2>
								</div>
								<div className="col-4 flex justify-between gap-x-4">
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-gifts"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-list-timeline"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-hexagon-plus"/></p></span>
									</Link>
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-2 mt-4">
									<div
										className="manage-members-card flex items-center"
										style={sample_items}
									>
										<div className="text-center bring-front">
											<p className="header-small-title color-lightest m-0 text-white">
												Edition#1 key
											</p>
											<p className="body-txtitalic--smallest color-lightest m-0 text-white">
												DRMVRSE Membership 2023
											</p>
										</div>
										<div className="bg-overlay"></div>
									</div>
								</div>
								<div className="col-2 mt-4">
									<div
										className="manage-members-card flex items-center"
										style={sample_items}
									>
										<div className="text-center bring-front">
											<p className="header-small-title color-lightest m-0 text-white">
												Edition#1 key
											</p>
											<p className="body-txtitalic--smallest color-lightest m-0 text-white">
												DRMVRSE Membership 2023
											</p>
										</div>
										<div className="bg-overlay"></div>
									</div>
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-8">
									<h2>67,231 Unsound</h2>
								</div>
								<div className="col-4 flex justify-between gap-x-4">
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-star"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-list-timeline"/></p></span>
									</Link>
									<Link className="button-secondary-gradient w-full" to="">
										<span><p><i className="fa-light fa-waveform-lines"/></p></span>
									</Link>
								</div>
							</div>
						</div>
					</div>

					<BanUserModal
						modalOpen={modalOpen}
						setModalOpen={setModalOpen}
						member={memberData}
						space={spaceInfo}
						getMemberDataRequest={getMemberDataRequest}
						toUpdateDataId={toUpdateDataId}
					/>
				</div>
			)}
		</div>
	);
};

export default memo(ManageMember);
