export const CUSTOM_REGEX = {
    IDENTIFY_VALID_DOMAIN: /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/,
	IDENTIFY_VALID_URL: /^(https?:\/\/)?([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/\S*)?(#\S*)?$/,
    IDENTIFY_VALID_YOUTUBE_URL: /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    DETECT_INVALID_PROTOCOL: /^((?!https?:\/\/)[a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
    STRICT_EMAIL_VALIDATION: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    EXTEND_EMAIL_VALIDATION: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    USERNAME_VALIDATION: /^@((\S)+)/,
    PERCENTAGE_VALIDATION: /^(0*100{1,1}?%?)$|(^0*\d{0,2}?%?)$/,
    TWO_DECIMAL_PLACES: /([0-9]*[.]{0,1}[0-9]{0,2})/s,
    CHANNEL_NAME_VALIDATION:/^[0-9a-zA-Z_-]{0,24}$/,  //alphanumeric with hyphen and underscore input with 24 max characters
    NO_ACCESS_UPDATE_PATH : /@[^/]+\/update\/\d+\/[^/]+/,  // Path for Update when opened in space
    SPACE_VERIFY_EMAIL_PATH : /@[^/]+\/verify-email/,  // Path for Space Verify Email when opened in space
};
