import { memo } from 'react';
import { showTooltip } from '../../../utils/input';
import { CUSTOM_REGEX } from '../../../constants/custom_regex';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";

const FeaturedLinks = (parentProps) => {
	const { data, index, space } = parentProps;
	const link_bg = {
		backgroundImage:
			'url(' + (data.video != null && data.video !== '' ? data.video : data.photo_medium) + ')'
	};

	const gotoFeaturedLink = (url) => {
		window.open(url, '_blank');
	};

	const goToBlankPage = (url) => {
		if (url)
			if (url.includes('common.space/@'+space.username))
				return '';
			else
				return '_blank';
		return '';
	};

	return (
		<>
			{/*<div*/}
			{/*	className={*/}
			{/*		'items-center justify-center ' +*/}
			{/*		(index > 2 ? 'mt-4' : '')*/}
			{/*	}*/}
			{/*>*/}
			{/*	<div*/}
			{/*		className="card-links"*/}
			{/*		style={link_bg}*/}
			{/*		onClick={() => gotoFeaturedLink(data.url)}*/}
			{/*	>*/}
			{/*		{data.video != null && data.video !== '' && (*/}
			{/*			<video autoPlay muted loop className="card-links-video">*/}
			{/*				<source src={data.video} type="video/mp4" />*/}
			{/*			</video>*/}
			{/*		)}*/}
			{/*		<div className="details">*/}
			{/*			<h5 className="header-title color-lightest">{data.title}</h5>*/}
			{/*			<p className="body-txtitalic--smallest color-lightest">*/}
			{/*				{data.description}*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*		<div className="bg-overlay"></div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<article className="block-md card bg-image-cover h-3row content-center text-center hover:opacity-90 p-0"*/}
			{/*		 style={{*/}
			{/*			 position: 'relative',*/}
			{/*			 overflow: 'hidden',*/}
			{/*			 backgroundImage: `linear-gradient(to top, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0.5) 100%), url('${data.photo}')`*/}
			{/*		 }}*/}
			{/*		 onClick={() => gotoFeaturedLink(data.url)}*/}
			{/*>*/}
			{/*	<>*/}
			{/*		<video autoPlay loop muted style={{*/}
			{/*			position: 'absolute',*/}
			{/*			top: '50%',*/}
			{/*			left: '50%',*/}
			{/*			transform: 'translate(-50%, -50%)',*/}
			{/*			height: '100%',*/}
			{/*			width: '100%',*/}
			{/*			objectFit: 'cover', // Add this line*/}
			{/*			zIndex: 0*/}
			{/*		}}>*/}
			{/*			<source src={data.video} type="video/mp4" />*/}
			{/*			Your browser does not support the video tag.*/}
			{/*		</video>*/}
			{/*		/!* <div style={{*/}
			{/*			position: 'absolute',*/}
			{/*			top: -28,*/}
			{/*			left: 0,*/}
			{/*			width: '100%',*/}
			{/*			height: '100%',*/}
			{/*			backgroundImage: `linear-gradient(to top, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0.5) 100%)`,*/}
			{/*			zIndex: 1*/}
			{/*		}}></div> *!/*/}
			{/*	</>*/}
			{/*	<a href="#" target="_self" className="inline-block w-full h-full place-content-center text-center relative rounded-card p-card z-1">*/}
			{/*		<hgroup className="title">*/}
			{/*			<h4 className="text-invert">{data.title}</h4>*/}
			{/*			<p className="italic text-invert">{data.description}</p>*/}
			{/*		</hgroup>*/}
			{/*	</a>*/}
			{/*</article>*/}

			<article
				key={index}
				className="cs-featured-link block-md card relative"
				style={{backgroundImage: `url('${data.photo}')`
				}}
				// onClick={() => gotoFeaturedLink(data.url)}
			>
				{data.video && (
					<ReactPlayer
						playing={true}
						loop={true}
						controls={false}
						muted={true}
						width="100%"
						height="100%"
						className="videoPreview absolute"
						url={data.video}
					/>
					// <video autoPlay loop muted style={{
					// 	position: 'absolute',
					// 	top: '50%',
					// 	left: '50%',
					// 	transform: 'translate(-50%, -50%)',
					// 	height: '100%',
					// 	width: '100%',
					// 	objectFit: 'cover',
					// 	zIndex: 0
					// }}>
					// 	<source src={data.video} type="video/mp4" />
					// 	Your browser does not support the video tag.
					// </video>
				)}
				<a
					href={data.url ? data.url : "#"}
					target={goToBlankPage(data.url)}
					className={"rounded-card p-card relative" + (data.video ? ' !mt-0' : '')}>
					<hgroup className="title">
						<h4>{data.title}</h4>
						<p>{data.description}</p>
					</hgroup>
				</a>
			</article>

		</>
	);
};

export default memo(FeaturedLinks);
