import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';
import { getMySpaceCollections } from '../../../../services/user';
import routes from '../../../../routes';
import CustomPagination from "../../../../components/commons/CustomPagination";

const ViewCollection = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [activeTab, setActiveTab] = useState('all');
	const [allItemsList, setAllItemsList] = useState([]);
	const [itemsList, setItemsList] = useState([]);
	const [membershipsList, setMembershipsList] = useState([]);
	const [itemsCount, setItemsCount] = useState(0);
	const [membershipKeysCount, setMembershipKeysCount] = useState(0);
	const [allItemsCount, setAllItemsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const pageSize = 4;

	const setItemBackground = (data) => {
		return {
			backgroundImage: 'url(' + data.photo + ')'
		};
	};

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + spaceInfo.username;
		window.location.replace(url);
	}, [spaceInfo]);

	const goToClaim = (data) => {
		if (data.collection_type === 'membership') {
			let url = routes.spaceMembersProductsMembership();
			window.location.href =
				url.replace(':space_username', '@' + space_username) + '?id=' + data.id;
		} else {
			console.log('go to item claim');
		}
	};

	const getCollections = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const params = {
					user_id: authUserInfo.id,
					space_id: spaceInfo.id
				};

				const response = await getMySpaceCollections(params);
				if (response && response.result) {
					setAllItemsList(response.all_items);
					setItemsList(response.items);
					setMembershipsList(response.memberships);
					setItemsCount(response.total_items_count);
					setMembershipKeysCount(response.total_memberships_count);
					setAllItemsCount(response.total_all_items_count);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo, spaceInfo]);

	const onPageChange = (page) => {
		setCurrentPage(page);
		// const section = document.getElementById('cs-dash-card-nav-collection');
		// if (section) {
		// 	section.scrollIntoView({ behavior: 'smooth' });
		// }
	};

	const onCollectionTabMenuClicked = (tab) => {
		setActiveTab(tab);
		setCurrentPage(0);
	};

	const collectionsTab = (value, label, count) => {
		return (
			<a
				onClick={() => {
					onCollectionTabMenuClicked(value);
				}}
				className={"button rounded-corners"
					+ (activeTab === value ? " active" : "")}
			>
				<span><p>{count + " " + label}</p></span>
			</a>
		)
	};

	const collections = (items) => {
		return (
			<>
				{
					items.slice(currentPage*pageSize, (currentPage*pageSize)+pageSize).map((data, index) =>
						(
							<a
								onClick={() => goToClaim(data)}
								className="cs-dash-card-product cs-product-item card block-smx"
								style={setItemBackground(data)}
								key={index}
							>
								<div className="shader space-y-card rounded-card">
									<hgroup>
										<h4>{data.name}</h4>
										<p>{data.summary}</p>
									</hgroup>
								</div>
							</a>
						)
					)
				}
				{collectionsPagination(items)}
			</>
		)
	};

	const collectionsPagination = (items) => {
		return (
			<div className="cs-pagination block-full">
				<CustomPagination
					items={items.length}
					currentPage={currentPage}
					pageSize={pageSize}
					onPageChange={onPageChange}
				></CustomPagination>
			</div>
		)
	};

	useEffect(() => {
		if (space_username) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					const backgroundImage = {
						backgroundImage: 'url(' + spaceInfo.background_photo + ')'
					};
					setBackgroundImage(backgroundImage);
					getCollections().then((r) => {});
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, space_username, spaceInfo, spaceInfoLoading]);

	useEffect(() => {}, [currentPage]);

	return (
		<>
			<div className="cs-dash-card cs-banner-card block-full card" style={backgroundImage}>
				<div className="cs-banner-card-shader p-card rounded-card stack-col">
					<div className="formrow">
						<div className="cs-banner-card-info stack-col">
							<h3><i className="fa-regular fa-solar-system"></i> {spaceInfo?.name ?? ''}</h3>
						</div>
						<div className="cs-banner-card-controls stack-col">
							<a onClick={() => goToSpace()} className="button secondary distribute">
								<span>
									<i className="fa-light fa-solar-system"></i>
									<p>Access Space</p>
									<i className="fa-light fa-arrow-up-right-from-square"></i>
								</span>
							</a>
						</div>
					</div>
					<div id="cs-dash-card-nav-collection" className="cs-nav-tabs">
						<nav className="cs-nav-buttons-row">
							<div className="stack-row">
								{collectionsTab('all', 'Collected', allItemsCount)}
								{collectionsTab('memberships', 'Membership', membershipKeysCount)}
								{collectionsTab('items', 'Items', itemsCount)}
							</div>
						</nav>
					</div>
				</div>
			</div>
			{authUserInfo && (
				<>
					{activeTab === 'all' && (
						spaceInfo && allItemsList &&
						(
							collections(allItemsList)
						)
					)}
					{activeTab === 'memberships' && (
						spaceInfo && membershipsList &&
						(
							collections(membershipsList)
						)
					)}
					{activeTab === 'items' && (
						spaceInfo && itemsList &&
						(
							collections(itemsList)
						)
					)}
				</>
			)}
		</>



		// <div id="cscxl-dashboard" className="collections-content-container ,spac">
		// 	<div className="dashboard-wrapper block-full card space-manage-bg" style={backgroundImage}>
		// 		<div className="grid grid-cols-12 md:gap-8 items-center">
		// 			<div className="col-span-6">
		// 				<h3>
		// 					<i className="fa-light fa-solar-system"></i>{' '}
		// 					{spaceInfo ? spaceInfo.name : ''}
		// 				</h3>
		// 			</div>
		// 			<div className="col-span-6">
		// 				<Link className="btn btn-tertiary btn-md" onClick={() => goToSpace()}>
		// 					<i className="fa-light fa-solar-system"></i>
		// 					<span className="w-3/4 text-center">Access Space</span>
		// 					<i className="fa-light fa-arrow-up-right-from-square"></i>
		// 				</Link>
		// 			</div>
		// 		</div>
		// 		<ul className="nav nav-pills mt-5 grid grid-flow-row grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1">
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'all' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('all');
		// 					}}
		// 				>
		// 					All Keys & Items({allItemsCount})
		// 				</Button>
		// 			</li>
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'memberships' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('memberships');
		// 					}}
		// 				>
		// 					Membership Keys({membershipKeysCount})
		// 				</Button>
		// 			</li>
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'items' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('items');
		// 					}}
		// 				>
		// 					Items({itemsCount})
		// 				</Button>
		// 			</li>
		// 		</ul>
		// 		<div className="bg-overlay"></div>
		// 	</div>
		//
		// 	{/*<div className="block-full card">*/}
		// 	{/*	*/}
		// 	{/*</div>*/}
		// 	{authUserInfo && (
		// 		<>
		// 			<div className="grid grid-cols-12 gap-8 mt-5">
		// 				{activeTab === 'all' && (
		// 					<>
		// 						{spaceInfo &&
		// 							allItemsList &&
		// 							allItemsList.map((data, index) => (
		// 								<div key={index} className="col-span-6 md:col-span-3">
		// 									<Link onClick={() => goToClaim(data)}>
		// 										<div
		// 											className="collection-content"
		// 											style={setItemBackground(data)}
		// 										>
		// 											<div className="collection-info">
		// 												<h6 className="header-small-title text-center">
		// 													{data.name}
		// 												</h6>
		// 												<p className="body-txtitalic--smallest text-center">
		// 													{data.summary}
		// 												</p>
		// 											</div>
		// 										</div>
		// 									</Link>
		// 								</div>
		// 							))}
		// 					</>
		// 				)}
		// 				{activeTab === 'memberships' && (
		// 					<>
		// 						{spaceInfo &&
		// 							membershipsList &&
		// 							membershipsList.map((data, index) => (
		// 								<div key={index} className="col-span-6 md:col-span-3">
		// 									<Link onClick={() => goToClaim(data)}>
		// 										<div
		// 											className="collection-content"
		// 											style={setItemBackground(data)}
		// 										>
		// 											<div className="collection-info">
		// 												<h6 className="header-small-title text-center">
		// 													{data.name}
		// 												</h6>
		// 												<p className="body-txtitalic--smallest text-center">
		// 													{data.summary}
		// 												</p>
		// 											</div>
		// 										</div>
		// 									</Link>
		// 								</div>
		// 							))}
		// 					</>
		// 				)}
		// 				{activeTab === 'items' && (
		// 					<>
		// 						{spaceInfo &&
		// 							itemsList &&
		// 							itemsList.map((data, index) => (
		// 								<div key={index} className="col-span-6 md:col-span-3">
		// 									<Link onClick={() => goToClaim(data)}>
		// 										<div
		// 											className="collection-content"
		// 											style={setItemBackground(data)}
		// 										>
		// 											<div className="collection-info">
		// 												<h6 className="header-small-title text-center">
		// 													{data.name}
		// 												</h6>
		// 												<p className="body-txtitalic--smallest text-center">
		// 													{data.summary}
		// 												</p>
		// 											</div>
		// 										</div>
		// 									</Link>
		// 								</div>
		// 							))}
		// 					</>
		// 				)}
		// 			</div>
		// 		</>
		// 	)}
		// </div>
	);
};

export default memo(ViewCollection);
