import { createSlice} from "@reduxjs/toolkit";

export const classSizeSlice = createSlice({
    name: "classSize",
    initialState: {
        size: 'lg',
        screenSize: 0,
    },
    reducers: {
        classSmall: (state) => {
            state.size = 'sm';
        },
        classMedium:(state) => {
            state.size = 'md';
        },
        classLarge:(state) => {
            state.size = 'lg';
        },
        setScreenSize:(state,action) => {
            state.screenSize = action.payload;
        }
    }
});

export const { classSmall , classMedium, classLarge, setScreenSize} = classSizeSlice.actions;

export default classSizeSlice.reducer;