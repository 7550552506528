import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import TextareaAutosize from 'react-textarea-autosize';
import FormFieldStacked from '../../../../components/commons/FormFieldStacked';

import {
	MAGIC_OAUTH_KEY,
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_NETWORK
} from '../../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../../constants/file';
import {GENDERS, PRONOUNS, VISIBILITY_LIST_WITH_DEFAULT} from '../../../../constants/profile';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { updateProfile } from '../../../../services/user';
import { checkUsernameAvailability } from '../../../../services/general';
import { getAuthUserInfo } from '../../../../states/general/actions';
import UploadDragger from '../../../../components/commons/UploadDragger';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { shortenNumber } from '../../../../utils/number';
import { getListDisplay } from '../../../../utils/list';
import { CUSTOM_REGEX } from '../../../../constants/custom_regex';
import {
	displayPageContentLoader,
	hidePageContentLoader
} from '../../../../states/pageContentLoader';
import Alert from 'react-bootstrap/Alert';
import ReactPlayer from "react-player/lazy";
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";
import default_profile_card_bg from "../../../../assets/images/profile_header.png";
import SocialLinks from "../../../../components/commons/SocialLinks";
import {updateSocialLinks} from "../../../../utils/socialLinkUtils";

const ProfileEdit = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const gendersList = GENDERS;
	const pronounsList = PRONOUNS;
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(false);
	const [profilePhoto, setProfilePhoto] = useState(null);
	const [profilePhotoBlobPreview, setProfilePhotoBlobPreview] = useState(null);
	const [profileVideo, setProfileVideo] = useState(null);
	const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
	const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
	const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] =
		useState(null);
	const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] =
		useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackground, setCardBackground] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [username, setUsername] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [location, setLocation] = useState(null);
	const [bio, setBio] = useState(null);
	const [website, setWebsite] = useState(null);
	const [selectedGender, setSelectedGender] = useState('default');
	const [selectedPronouns, setSelectedPronouns] = useState('default');
	const [selectedVisibility, setSelectedVisibility] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
	const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
	const [alert, setAlert] = useState(null);
	const { size } = useSelector((state) => state.classSize);

    const [usernameHasError, setUserNameHasError] = useState(null);
    const [displayNameHasError, setDisplayNameHasError] = useState(null);
    
    const [profileImageHasError, setProfileImageHasError] = useState(null);
    const [profileVideoHasError, setProfileVideoHasError] = useState(null);
    const [profileBGImageHasError, setProfileBGImageHasError] = useState(null);
    const [profileBGVideoHasError, setProfileBGVideoHasError] = useState(null);

	const previewRef = useRef(null);
	const [previewDimensions, setPreviewDimensions] = useState({width: '', height: ''})
	const [socialLinks, setSocialLinks] = useState([]);

    useEffect(() => {

    }, [
		usernameHasError,
		profileImageHasError,
		profileVideoHasError,
		profileBGImageHasError,
		profileBGVideoHasError,
		socialLinks
	])

	useEffect(() => {
		setSocialLinks(authUserInfo?.profile?.social_links)
	}, [authUserInfo])

	if (!gendersList.find((x) => x.value === 'default')) {
		gendersList.unshift({
			label: 'Gender',
			value: 'default',
			disabled: true
		});
	}

	if (!pronounsList.find((x) => x.value === 'default')) {
		pronounsList.unshift({
			label: 'Pronouns',
			value: 'default',
			disabled: true
		});
	}

	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setUsername(form.getFieldValue('username'));
		setDisplayName(form.getFieldValue('display_name'));
		setLocation(form.getFieldValue('location'));
		setBio(form.getFieldValue('bio'));

		const websiteValue = form.getFieldValue('website');
		setWebsite(websiteValue);
		const instagramValue = form.getFieldValue('instagram');
		const youtubeValue = form.getFieldValue('youtube');
		const tiktokValue = form.getFieldValue('tiktok');
		const twitchValue = form.getFieldValue('twitch');
		const facebookValue = form.getFieldValue('facebook');
		const linkedinValue = form.getFieldValue('linkedin');
		const xValue = form.getFieldValue('x');

		/* Social links onChange parser for the active indicator */
		const values = {
			website: websiteValue,
			instagram: instagramValue,
			youtube: youtubeValue,
			tiktok: tiktokValue,
			twitch: twitchValue,
			facebook: facebookValue,
			linkedin: linkedinValue,
			x: xValue
		};
		const updatedSocialLinks = updateSocialLinks(socialLinks, values);
		setSocialLinks(updatedSocialLinks);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, [socialLinks]);

	const websiteOnBlur = (event) => {
		showTooltip(event);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
			event.target.value = 'https://' + event.target.value;
			form.setFieldValue('website', event.target.value);
		}
	};

	const handleSubmit = useCallback(
		async (values) => {
			console.log('submit')
			setDisplayDropDownError(true);

			const data = values;
            // console.log(data)

			//include url protocol when url protocol is missing
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
				data.website = 'https://' + data.website;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.instagram)) {
				data.instagram = 'https://' + data.instagram;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.youtube)) {
				data.youtube = 'https://' + data.youtube;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.tiktok)) {
				data.tiktok = 'https://' + data.tiktok;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.twitch)) {
				data.twitch = 'https://' + data.twitch;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.facebook)) {
				data.facebook = 'https://' + data.facebook;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.linkedin)) {
				data.linkedin = 'https://' + data.linkedin;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.x)) {
				data.x = 'https://' + data.x;
			}

			if (selectedGender !== 'default') {
				data.gender = selectedGender;
			}
			if (selectedPronouns !== 'default') {
				data.pronouns = selectedPronouns;
			}
			if (selectedVisibility !== 'default') {
				data.visibility = selectedVisibility;
			}

			if (profilePhoto) {
				data.photo = profilePhoto;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (profileVideo) {
				data.video = profileVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			if (profileBackgroundPhoto) {
				data.background_photo = profileBackgroundPhoto;
			} else {
				if (photoBGIsRemoved) {
					data.background_photo_is_empty = true;
				}
			}

			if (profileBackgroundVideo) {
				data.background_video = profileBackgroundVideo;
			} else {
				if (videoBGIsRemoved) {
					data.background_video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					setSubmitted(true);
					data.user_id = authUserInfo.id;
					const resultProfile = await updateProfile(data);
					if (resultProfile) {
						dispatch(getAuthUserInfo());
						setSubmitted(false);
						setAlert({
							variant: 'success',
							message: resultProfile.message.toString()
						});
						setTimeout(() => {
							setAlert(null);
						}, 5000);
					} else {
						setAlert({
							variant: 'danger',
							message: 'Failed to update profile'
						});
						setTimeout(() => {
							setAlert(null);
						}, 5000);
					}
				}
			} catch (error) {
				console.log(error);
				setAlert({
					variant: 'danger',
					message: 'Something went wrong when updating profile'
				});
				setTimeout(() => {
					setAlert(null);
				}, 5000);
			}
		},
		[
			authUserInfo,
			selectedGender,
			selectedPronouns,
			selectedVisibility,
			profilePhoto,
			profileVideo,
			profileBackgroundPhoto,
			profileBackgroundVideo,
			photoIsRemoved,
			videoIsRemoved,
			photoBGIsRemoved,
			videoBGIsRemoved,
			alert
		]
	);

    const setProfileImageUploadErrors = (error) => {
        setProfileImageHasError(error?.message)
    }
    const setProfileVideoUploadErrors = (error) => {
        setProfileVideoHasError(error?.message)
    }
    const setProfileBGImageUploadErrors = (error) => {
        setProfileBGImageHasError(error?.message)
    }
    const setProfileBGVideoUploadErrors = (error) => {
        setProfileBGVideoHasError(error?.message)
    }

	const handleProfilePhotoUploadFileChange = (file, blobPreview) => {
        console.log(file)
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setProfilePhoto(file);
		setProfilePhotoBlobPreview(blobPreview);

		form.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const handleProfileVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoIsRemoved(true);
			} else {
				setVideoIsRemoved(false);
			}

			if (blobPreview && profileVideoBlobPreview && blobPreview !== profileVideoBlobPreview) {
				setProfileVideo(null);
				setProfileVideoBlobPreview(null);

				setTimeout(() => {
					setProfileVideo(file);
					setProfileVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileVideo(file);
				setProfileVideoBlobPreview(blobPreview);
			}

			form.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[profileVideoBlobPreview]
	);

	const handleProfileBackgroundPhotoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoBGIsRemoved(true);
		} else {
			setPhotoBGIsRemoved(false);
		}

		setProfileBackgroundPhoto(file);
		setProfileBackgroundPhotoBlobPreview(blobPreview);

		form.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const handleProfileBackgroundVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoBGIsRemoved(true);
			} else {
				setVideoBGIsRemoved(false);
			}

			if (
				blobPreview &&
				profileBackgroundVideoBlobPreview &&
				blobPreview !== profileBackgroundVideoBlobPreview
			) {
				setProfileBackgroundVideo(null);
				setProfileBackgroundVideoBlobPreview(null);

				setTimeout(() => {
					setProfileBackgroundVideo(file);
					setProfileBackgroundVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileBackgroundVideo(file);
				setProfileBackgroundVideoBlobPreview(blobPreview);
			}

			form.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[profileBackgroundVideoBlobPreview]
	);

	const checkLoginRedir = async () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const provider = queryParameters.get('provider') ?? 'No Provider';
		if (provider !== 'No Provider') {
			const result = await magicOauth.oauth.getRedirectResult();
			let profile = result.oauth.userInfo.profile ?? 'No Profile found';
			if (provider === 'tweeter' || provider === 'discord') {
				profile = result.oauth.userInfo.preferredUsername ?? 'No Profile found';
			}
			let email = authUserInfo.email ?? result.oauth.userInfo.email ?? 'No Email found';
			// console.log(profile);
			// console.log(email);

			let data = {
				email: email,
				social_type: provider,
				social_link: profile
			};
			await social_link(data);
		}
	};

	const linkGoogle = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'google' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkFacebook = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'facebook' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkTweeter = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'tweeter' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkDiscord = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'discord' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const setFormProfileData = async (data) => {
		form.setFieldsValue({
			username: data.profile.username,
			display_name: data.profile.display_name,
			location: data.profile.location,
			bio: data.profile.intro,
			website: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'website') : null,
			instagram: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'instagram') : null,
			youtube: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'youtube') : null,
			tiktok: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'tiktok') : null,
			twitch: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'twitch') : null,
			facebook: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'facebook') : null,
			linkedin: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'linkedin') : null,
			x: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'x') : null
		});
		setUsername(data.profile.username);
		setDisplayName(data.profile.display_name);
		setLocation(data.profile.location);
		setBio(data.profile.intro);
		setWebsite(data.profile.url);
		if (data.profile.gender) {
			setSelectedGender(data.profile.gender);
		}
		if (data.profile.pronouns) {
			setSelectedPronouns(data.profile.pronouns);
		}
		if (data.profile.visibility.privacy) {
			setSelectedVisibility(data.profile.visibility.privacy);
		}
		if (data.profile.photo_large) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(data.profile.photo_thumb);
		}
		if (data.profile.video) {
			setProfileAssetType('video');
			setProfileVideoBlobPreview(data.profile.video);
		}
		if (data.profile.background_photo) {
			setProfileBackgroundPhotoBlobPreview(data.profile.background_photo);
			const space_bg = {
				backgroundImage: 'url(' + data.profile.background_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (data.profile.background_video) {
			setProfileBackgroundVideoBlobPreview(data.profile.background_video);
			setCardBackgroundType('video');
		}

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const updateProfilePhotoAsset = async (photo, video, bg_photo, bg_video) => {
		if (!video && photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(photo);
		}
		if (video) {
			setProfileAssetType('video');
			setProfileVideoBlobPreview(video);
		}
		if (!video && !photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(null);
			setProfileVideoBlobPreview(null);
		}
		if (!bg_video && bg_photo) {
			setProfileBackgroundPhotoBlobPreview(bg_photo);
			const space_bg = {
				backgroundImage: 'url(' + bg_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (bg_video) {
			setProfileBackgroundVideoBlobPreview(bg_video);
			setCardBackgroundType('video');
		}
		if (!bg_video && !bg_photo) {
			setCardBackgroundType('image');
			setCardBackground({
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			});
			setProfileBackgroundVideoBlobPreview(null);
		}
	};

	const checkSocialLink = (socialLinks, socialLinkType) => {
		let active = null
		socialLinks.map((socialLink)=>{
			if (socialLink.social_type === socialLinkType && socialLink.social_link && socialLink.social_link?.trim() !== null)
				active = socialLink.social_link
		})
		return active
	};

	useEffect(() => {
		if (authUserInfo) {
			dispatch(hidePageContentLoader());
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo && initialLoad) {
			setInitialLoad(false);
			setFormProfileData(authUserInfo).then((r) => {});
		}

		checkLoginRedir();

		if (!initialLoad) {
			updateProfilePhotoAsset(
				profilePhotoBlobPreview,
				profileVideoBlobPreview,
				profileBackgroundPhotoBlobPreview,
				profileBackgroundVideoBlobPreview
			);
		}
	}, [
		loading,
		authUserInfo,
		initialLoad,
		profilePhotoBlobPreview,
		profileVideoBlobPreview,
		profileBackgroundPhotoBlobPreview,
		profileBackgroundVideoBlobPreview
	]);

	useEffect(() => {
		if (previewRef.current) {
			setPreviewDimensions({
				width: previewRef.current.offsetWidth + 'px',
				height: previewRef.current.offsetHeight + 'px'
			});
		}
	}, [previewRef]);

	return (
		<>
			<div className="cs-dash-card block-full card-base">
				<div className="cs-profile-edit stack-col p-card">
					<h2>
						Edit {authUserInfo && authUserInfo.profile.username && '@' + authUserInfo.profile.username}
					</h2>

					<Form
						form={form}
						name="memberProfileForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setFormIsOnFocus(false);
							handleFormOnBlur().then(r => {});
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={"stack-col" + (formIsOnFocus ? ' hide-antd-error-messages' : '')}
					>
						<div className="cs-stack-form-media stack-row">
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									cropperAspectRatio={1 / 1}
									shape="circle"
									hideDragIcon="true"
									onError={setProfileImageUploadErrors}
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={profilePhotoBlobPreview}
									onUploadFileChange={handleProfilePhotoUploadFileChange}
									minHeight={IMAGE_STANDARD_MIN_HEIGHT}
									minWidth={IMAGE_STANDARD_MIN_WIDTH}
									maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
									maxWidth={IMAGE_STANDARD_MAX_WIDTH}
									uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<p className="cs-label">Profile Image</p>
								<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
								{profileImageHasError && (
									<div className="alert callout warning items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileImageHasError}</h6>
											</div>
										</div>

									</div>
								)}
							</div>

							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									shape="circle"
									hideDragIcon="true"
									hideText="true"
									onError={setProfileVideoUploadErrors}
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={profileVideoBlobPreview}
									onUploadFileChange={handleProfileVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<p className="cs-label">Profile Video</p>
								<p className="cs-helper">Up to 1920px/1080p square preferred MOV or MP4</p>
								{profileVideoHasError && (
									<div className="alert callout warning items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileVideoHasError}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="cs-stack-form-media stack-row">
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									onError={setProfileBGImageUploadErrors}
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={profileBackgroundPhotoBlobPreview}
									onUploadFileChange={
										handleProfileBackgroundPhotoUploadFileChange
									}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
									shape="aspect-cs-preview"
								/>
								<p className="cs-label">Background Image</p>
								<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
								{profileBGImageHasError && (
									<div className="alert callout warning items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileBGImageHasError}</h6>
											</div>
										</div>

									</div>
								)}
							</div>

							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									hideDragIcon="true"
									hideText="true"
									onError={setProfileBGVideoUploadErrors}
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={profileBackgroundVideoBlobPreview}
									onUploadFileChange={
										handleProfileBackgroundVideoUploadFileChange
									}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									shape="aspect-cs-preview"
								/>
								<p className="cs-label">Background Video</p>
								<p className="cs-helper">Up to 1920px/1080p square or wide MOV or MP4</p>
								{profileBGVideoHasError && (
									<div className="alert callout warning items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{profileBGVideoHasError}</h6>
											</div>
										</div>

									</div>
								)}
							</div>
						</div>
						<div className="form-input air">
							<label htmlFor="username">Username</label>
							{/*<input type="text" name="username" id="username" autoComplete="username" required*/}
							{/*	   placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."/>*/}
							<Form.Item
								label="Username"
								name="username"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{ required: true },
									{
										min: 2,
										message: 'Must be minimum 2 characters.'
									},
									{
										max: 32,
										message: 'Must not exceed 32 characters.'
									},
									({ getFieldValue }) => ({
										async validator(_, value) {
											// TODO: find way to trigger username API check on form submit to avoid flood requests
											// if (!formIsOnFocus && value) {
											if (value) {
												const data = {
													current_user_id:
													authUserInfo.id,
													username: username
												};

												let available = false;
												const response =
													await checkUsernameAvailability(
														data
													);
												if (response && response.result) {
													available =
														response.availability;
												}

												if (!available) {
													setUserNameHasError('Username not available.')
													// return Promise.reject(
													//     new Error(
													//         'Username not available.'
													//     )
													// );
												}else{
													setUserNameHasError(null)
												}
											}

											if (
												value.match(/\ /g) ||
												/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
													value
												)
											) {
												setUserNameHasError('Invalid Input')
												// return Promise.reject(
												//     new Error('Invalid Input')
												// );
											}else{
												setUserNameHasError(null)
											}

											return Promise.resolve();
										}
									})
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={32}
									disabled={submitted}
									placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."
								/>
							</Form.Item>
							{(usernameHasError !== null || form.getFieldsError()[0]?.errors.length > 0) && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{usernameHasError !== null ? usernameHasError : form.getFieldsError()[0]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="displayname">Display Name</label>
							<Form.Item
								label="Display Name"
								name="display_name"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{ required: true },
									{
										max: 64,
										message: 'Must not exceed 64 characters.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={64}
									disabled={submitted}
									placeholder="Up to 64 characters."
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[1]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[1]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="location">Location</label>
							<Form.Item
								label="Location"
								name="location"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 32,
										message: 'Must not exceed 32 characters.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={32}
									disabled={submitted}
									placeholder="Up to 32 characters. For Profile display only, separate from private City."
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[2]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[2]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-textarea air">
							<label htmlFor="shortbio">Short Bio</label>
							<Form.Item
								label="Short Bio"
								name="bio"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 255,
										message: 'Must not exceed 255 characters.'
									}
								]}
							>
								<FormFieldStacked
									formType='textarea'
									type="text"
									maxLength={255}
									disabled={submitted}
									placeholder="Up to 255 characters, including spaces."
									rows={3}
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[3]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[3]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="website">Website</label>
							<Form.Item
								label="Website"
								name="website"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="site.com"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[4]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[4]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="instagram">Instagram</label>
							<Form.Item
								label="Instagram"
								name="instagram"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="instagram.com/username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[5]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[5]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="youtube">Youtube</label>
							<Form.Item
								label="Youtube"
								name="youtube"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="youtube.com/@handle"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[6]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[6]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="tiktok">TikTok</label>
							<Form.Item
								label="TikTok"
								name="tiktok"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="tiktok.com/@username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[7]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[7]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="twitch">Twitch</label>
							<Form.Item
								label="Twitch"
								name="twitch"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="twitch.tv/username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[8]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[8]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="facebook">Facebook</label>
							<Form.Item
								label="Facebook"
								name="facebook"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="fb.com/username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[9]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[9]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="linkedin">LinkedIn</label>
							<Form.Item
								label="LinkedIn"
								name="linkedin"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="linkedin.com/in/username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[10]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[10]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="form-input air">
							<label htmlFor="x">X</label>
							<Form.Item
								label="X"
								name="x"
								validateTrigger="onBlur"
								className="w-full"
								rules={[
									{
										max: 100,
										message: 'Must not exceed 100 characters.'
									},
									{
										pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
										message: 'Please enter a valid URL.'
									}
								]}
							>
								<FormFieldStacked
									formType='input'
									type="text"
									maxLength={100}
									disabled={submitted}
									placeholder="x.com/username"
								></FormFieldStacked>
							</Form.Item>
							{form.getFieldsError()[11]?.errors.length > 0 && (
								<div className="alert callout warning">
									<div className="action">
										<i className="fa-solid fa-triangle-exclamation"></i>
									</div>
									<div className="content">
										<div>
											<h6>{form.getFieldsError()[11]?.errors}</h6>
										</div>
									</div>
								</div>
							)}
						</div>
						{/*<div className="cs-stack-form-link-icons">*/}
						{/*	<p>Links</p>*/}
						{/*	<div className="cs-stack-link-icons stack-row-wrap">*/}
						{/*		<a href="https://x.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt active"><span><p><i*/}
						{/*			className="fa-brands fa-x-twitter"></i></p></span></a>*/}
						{/*		<a href="https://instagram.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-brands fa-instagram"></i></p></span></a>*/}
						{/*		<a href="https://facebook.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-brands fa-facebook-f"></i></p></span></a>*/}
						{/*		<a href="https://tiktok.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-brands fa-tiktok"></i></p></span></a>*/}
						{/*		<a href="https://linkedin.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-brands fa-linkedin-in"></i></p></span></a>*/}
						{/*		<a href="https://twitch.tv" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-brands fa-twitch"></i></p></span></a>*/}
						{/*		<a href="https://google.com" target="_blank"*/}
						{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
						{/*			className="fa-regular fa-globe"></i></p></span></a>*/}
						{/*	</div>*/}
						{/*</div>*/}

						<div className="cs-stack-form-buttons">
							<div className="cs-stack-form-buttons-set">
								<DropdownWithItems
									title="Gender"
									customButtonClass="icon-dropdown-gradient-alt secondary"
									icon="fa-light fa-person-half-dress"
									selectedValue={selectedGender}
									listItems={gendersList}
									onDropdownChange={setSelectedGender}
								/>
								<DropdownWithItems
									title="Pronouns"
									customButtonClass="icon-dropdown-gradient-alt secondary"
									icon="fa-light fa-user"
									selectedValue={selectedPronouns}
									listItems={pronounsList}
									onDropdownChange={setSelectedPronouns}
								/>
								<DropdownWithItems
									title="Visibility"
									customButtonClass="icon-dropdown-gradient-alt secondary"
									icon="fa-light fa-eye"
									selectedValue={selectedVisibility}
									listItems={VISIBILITY_LIST_WITH_DEFAULT}
									onDropdownChange={setSelectedVisibility}
								/>
							</div>
						</div>
						{alert && (
							// <div className={"alert callout"  + (alert.variant === 'success' ? ' success' : ' error')}>
							// 	<div className="icon">
							// 		<i className="fa-solid fa-circle-check"></i>
							// 	</div>
							// 	<div className="content">
							// 		<div>
							// 			<h6></h6>
							// 			<p>{alert.message}</p>
							// 		</div>
							// 	</div>
							// </div>
							<section className={"alert banner px-section-safeview" + (alert.variant === 'success' ? ' success' : ' error')}>
								<div className="action">
									<i className="fa-solid fa-circle-check"></i>
								</div>
								<div className="content">
									<hgroup>
										<h6></h6>
										<p>{alert.message}</p>
									</hgroup>
									{/*<a href="#alertlink" class="button">Link Button</a>*/}
								</div>
								<div className="action">
									<a onClick={() => {setAlert(null)}}>
										<i className="fa-solid fa-xmark"></i>
									</a>
								</div>
							</section>
							// <div className="grid grid-cols-12 md:gap-8 my-2 mb-8">
							// 	<div className="col-span-12">
							// 		<Alert
							// 			key={alert.variant}
							// 			variant={alert.variant}
							// 			className={
							// 			'custom-alert ' +
							// 				(alert.variant === 'danger'
							// 					? 'custom-alert-danger'
							// 					: 'custom-alert-success')
							// 		}
							// 		>
							// 			{alert.message}
							// 		</Alert>
							// 	</div>
							// </div>
						)}
						<Form.Item className="">
							<button disabled={submitted || formHasError} type="submit" className="w-full">
								<a
									className="button-primary-gradient w-full"
								>
								<span>
									<p>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
										)}
										Save Profile
									</p>
								</span>
								</a>
							</button>
						</Form.Item>
					</Form>
				</div>

				<div className="cs-profile-preview rounded-r-card bg-cover-center" style={profileBackgroundVideoBlobPreview ? null : cardBackground}>
					{profileBackgroundVideoBlobPreview && (
						<video
							autoPlay
							muted
							loop
							src={profileBackgroundVideoBlobPreview}
						/>
					)}
					<div className="cs-stack-profile-preview rounded-r-card stack-col p-card shader">
						<div className="form-input-media lg circle air nohover"
							 style={ profileVideoBlobPreview ? null : {
								 backgroundImage: 'url(' + profilePhotoBlobPreview + ')'
							 } }
						>
							{profileVideoBlobPreview && (
								<video
									autoPlay
									muted
									loop
									src={profileVideoBlobPreview}
								/>
							)}
							<a href="#">
							</a>
						</div>
						<hgroup>
							<p className="text-p1 active">@{username ? username : 'username'}</p>
							<h1>{displayName ? displayName : 'Display Name'}</h1>
							<p className="text-p2 italic">{selectedPronouns ? getListDisplay(PRONOUNS, selectedPronouns) : 'Pronouns'}</p>
						</hgroup>
						<p className="italic">{bio ? bio : 'Short Bio goes here'}</p>
						<SocialLinks
							socialLinks={socialLinks}
							parentClassName="cs-stack-icons stack-row-wrap"
							childClassName=""
							removeATag={true}
						/>
						{/*<div className="cs-stack-icons stack-row-wrap">*/}
						{/*	<i className="fa-brands fa-x-twitter"></i>*/}
						{/*	<i className="fa-brands fa-instagram active"></i>*/}
						{/*	<i className="fa-brands fa-facebook-f"></i>*/}
						{/*	<i className="fa-brands fa-tiktok"></i>*/}
						{/*	<i className="fa-brands fa-linkedin-in"></i>*/}
						{/*	<i className="fa-brands fa-twitch"></i>*/}
						{/*	<i className="fa-regular fa-globe"></i>*/}
						{/*</div>*/}
					</div>
				</div>

				{/*<div*/}
				{/*	ref={previewRef}*/}
				{/*	className="cs-profile-preview rounded-r-card bg-cover-center" style={cardBackground}*/}
				{/*>*/}
				{/*	{(cardBackgroundType && cardBackgroundType === 'video') && (*/}
				{/*		<ReactPlayer*/}
				{/*			id="cscxl-profile-edit-video-bg"*/}
				{/*			playing={true}*/}
				{/*			loop={true}*/}
				{/*			muted={true}*/}
				{/*			controls={false}*/}
				{/*			height="100%"*/}
				{/*			width="100%"*/}
				{/*			className=""*/}
				{/*			url={profileBackgroundVideoBlobPreview}*/}
				{/*			style={*/}
				{/*				{*/}
				{/*					position: 'absolute',*/}
				{/*					maxWidth: previewDimensions.width,*/}
				{/*					maxHeight: previewDimensions.height*/}
				{/*				} }*/}
				{/*		/>*/}
				{/*	)}*/}
				{/*	<div*/}
				{/*		className="cs-stack-profile-preview rounded-r-card stack-col p-card shader">*/}
				{/*		<div*/}
				{/*			className="form-input-media lg circle air nohover"*/}
				{/*			style={{*/}
				{/*				backgroundImage: 'url(' + profilePhotoBlobPreview + ')'*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			{(profileAssetType && profileAssetType === 'video') &&*/}
				{/*				<a>*/}
				{/*					<ReactPlayer*/}
				{/*						width="100%"*/}
				{/*						height="100%"*/}
				{/*						playing={true}*/}
				{/*						loop={true}*/}
				{/*						muted={true}*/}
				{/*						className={``}*/}
				{/*						controls={false}*/}
				{/*						url={profileVideoBlobPreview}*/}
				{/*						style={*/}
				{/*							{*/}
				{/*								width: '100%',*/}
				{/*								height: '100%',*/}
				{/*								objectFit: 'cover',*/}
				{/*								borderRadius: '9999px'*/}
				{/*							} }*/}
				{/*					/>*/}
				{/*				</a>*/}
				{/*			}*/}
				{/*		</div>*/}
				{/*		<hgroup>*/}
				{/*			<p className="text-p1 active">@{username ? username : 'username'}</p>*/}
				{/*			<h1>{displayName ? displayName : 'Display Name'}</h1>*/}
				{/*			<p className="text-p2 italic">*/}
				{/*				{selectedPronouns ? getListDisplay(PRONOUNS, selectedPronouns) : 'Pronouns'}*/}
				{/*			</p>*/}
				{/*		</hgroup>*/}
				{/*		<p className="italic">{bio ? bio : 'Short Bio goes here'}</p>*/}
				{/*		<div className="cs-stack-icons stack-row-wrap">*/}
				{/*			<i className="fa-brands fa-x-twitter"></i>*/}
				{/*			<i className="fa-brands fa-instagram active"></i>*/}
				{/*			<i className="fa-brands fa-facebook-f"></i>*/}
				{/*			<i className="fa-brands fa-tiktok"></i>*/}
				{/*			<i className="fa-brands fa-linkedin-in"></i>*/}
				{/*			<i className="fa-brands fa-twitch"></i>*/}
				{/*			<i className="fa-regular fa-globe"></i>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		{/*<main id="cs-space-main" className="cs-main main-theme">*/}
		{/*	<section id="cs-profile-create-c1" className="p-section-safeview theme-transparent">*/}
		{/*		<div className="centerframe-grid gap-2gutter">*/}
		{/*			<div id="cs-profile-create-main" className="block-lg order-1">*/}
		{/*				<hgroup className="heading">*/}
		{/*					<h1>Editing{' '}*/}
		{/*						{authUserInfo &&*/}
		{/*							authUserInfo.profile.username &&*/}
		{/*							'@' + authUserInfo.profile.username}</h1>*/}
		{/*					/!*<p className="text-p2">Your profile will be visible to friends, community members, and the public, based on your preferences. Images and videos are optional.</p>*!/*/}
		{/*				</hgroup>*/}
		{/*				<div className="stack-col">*/}
		{/*					<div className="cs-stack-form-media stack-row">*/}
		{/*						<div className="cs-stack-form-media-item">*/}
		{/*							<div className="form-input-media circle md air" >*/}
		{/*								<UploadDraggerV2*/}
		{/*									cropperAspectRatio={1 / 1}*/}
		{/*									shape="circle"*/}
		{/*									hideDragIcon="true"*/}
		{/*									onError={setProfileImageUploadErrors}*/}
		{/*									hideText="true"*/}
		{/*									uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
		{/*									uploadedFilePreview={profilePhotoBlobPreview}*/}
		{/*									onUploadFileChange={handleProfilePhotoUploadFileChange}*/}
		{/*									minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
		{/*									minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
		{/*									maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
		{/*									maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
		{/*									uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
		{/*									disableCropper={true}*/}
		{/*								/>*/}
		{/*							</div>*/}

		{/*							<p className="cs-label">Profile Image</p>*/}
		{/*							<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>*/}
		{/*							{profileImageHasError && (*/}
		{/*								<div className="alert callout warning items-center">*/}
		{/*									<div className="action">*/}
		{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*									</div>*/}
		{/*									<div className="content">*/}
		{/*										<div>*/}
		{/*											<p>{profileImageHasError}</p>*/}
		{/*										</div>*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*							)}*/}
		{/*						</div>*/}

		{/*						<div className="cs-stack-form-media-item">*/}
		{/*							<div className="form-input-media circle md air">*/}
		{/*								<UploadDraggerV2*/}
		{/*									shape="circle"*/}
		{/*									hideDragIcon="true"*/}
		{/*									hideText="true"*/}
		{/*									onError={setProfileVideoUploadErrors}*/}
		{/*									uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
		{/*									uploadedFilePreview={profileVideoBlobPreview}*/}
		{/*									onUploadFileChange={handleProfileVideoUploadFileChange}*/}
		{/*									minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
		{/*									minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
		{/*									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
		{/*									maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
		{/*									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
		{/*								/>*/}
		{/*							</div>*/}
		{/*							<p className="cs-label">Profile Video</p>*/}
		{/*							<p className="cs-helper">Up to 1920px/1080p square preferred MOV or MP4</p>*/}
		{/*							{profileVideoHasError && (*/}
		{/*								<div className="alert callout warning items-center">*/}
		{/*									<div className="action">*/}
		{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*									</div>*/}
		{/*									<div className="content">*/}
		{/*										<div>*/}
		{/*											<p>{profileVideoHasError}</p>*/}
		{/*										</div>*/}
		{/*									</div>*/}
		{/*								</div>*/}
		{/*							)}*/}
		{/*						</div>*/}
		{/*					</div>*/}
		{/*					<div className="cs-stack-form-media stack-row">*/}
		{/*						<div className="cs-stack-form-media-item">*/}
		{/*							<div className="form-input-media md air aspect-cs-preview">*/}
		{/*								<UploadDraggerV2*/}
		{/*									cropperAspectRatio={16 / 9}*/}
		{/*									hideDragIcon="true"*/}
		{/*									hideText="true"*/}
		{/*									onError={setProfileBGImageUploadErrors}*/}
		{/*									uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
		{/*									uploadedFilePreview={profileBackgroundPhotoBlobPreview}*/}
		{/*									onUploadFileChange={*/}
		{/*										handleProfileBackgroundPhotoUploadFileChange*/}
		{/*									}*/}
		{/*									minHeight={IMAGE_LARGE_MIN_HEIGHT}*/}
		{/*									minWidth={IMAGE_LARGE_MIN_WIDTH}*/}
		{/*									maxHeight={IMAGE_LARGE_MAX_HEIGHT}*/}
		{/*									maxWidth={IMAGE_LARGE_MAX_WIDTH}*/}
		{/*									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}*/}
		{/*									disableCropper={true}*/}
		{/*									shape="video"*/}
		{/*								/>*/}
		{/*							</div>*/}
		{/*							<p className="cs-label">Background Image</p>*/}
		{/*							<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>*/}
		{/*							{profileBGImageHasError && (*/}
		{/*								<div className="alert callout warning items-center">*/}
		{/*									<div className="action">*/}
		{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*									</div>*/}
		{/*									<div className="content">*/}
		{/*										<div>*/}
		{/*											<p>{profileBGImageHasError}</p>*/}
		{/*										</div>*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*							)}*/}
		{/*						</div>*/}

		{/*						<div className="cs-stack-form-media-item">*/}
		{/*							<div className="form-input-media md air aspect-cs-preview">*/}
		{/*								<UploadDraggerV2*/}
		{/*									hideDragIcon="true"*/}
		{/*									hideText="true"*/}
		{/*									onError={setProfileBGVideoUploadErrors}*/}
		{/*									uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
		{/*									uploadedFilePreview={profileBackgroundVideoBlobPreview}*/}
		{/*									onUploadFileChange={*/}
		{/*										handleProfileBackgroundVideoUploadFileChange*/}
		{/*									}*/}
		{/*									minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
		{/*									minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
		{/*									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
		{/*									maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
		{/*									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
		{/*									shape="video"*/}
		{/*								/>*/}
		{/*							</div>*/}
		{/*							<p className="cs-label">Background Video</p>*/}
		{/*							<p className="cs-helper">Up to 1920px/1080p square or wide MOV or MP4</p>*/}
		{/*							{profileBGVideoHasError && (*/}
		{/*								<div className="alert callout warning items-center">*/}
		{/*									<div className="action">*/}
		{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*									</div>*/}
		{/*									<div className="content">*/}
		{/*										<div>*/}
		{/*											<p>{profileBGVideoHasError}</p>*/}
		{/*										</div>*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*							)}*/}

		{/*						</div>*/}

		{/*					</div>*/}
		{/*					<Form*/}
		{/*						form={form}*/}
		{/*						name="memberProfileForm"*/}
		{/*						validateMessages={VALIDATE_MESSAGES}*/}
		{/*						onFocus={() => {*/}
		{/*							setFormIsOnFocus(true);*/}
		{/*						}}*/}
		{/*						onBlur={() => {*/}
		{/*							setTimeout(() => {*/}
		{/*								setFormIsOnFocus(false);*/}
		{/*								handleFormOnBlur().then(r => {});*/}
		{/*							}, 100);*/}
		{/*						}}*/}
		{/*						onChange={handleFormChanges}*/}
		{/*						onFinish={handleSubmit}*/}
		{/*						className={formIsOnFocus ? 'hide-antd-error-messages' : ''}*/}
		{/*					>*/}
		{/*						<div className="grid grid-cols-12 md:gap-8">*/}
		{/*							<div className="col-span-12">*/}
		{/*								<div className="mb-8">*/}
		{/*									<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">*/}
		{/*										Username**/}
		{/*									</label>*/}
		{/*									<div className="relative  rounded-none ">*/}
		{/*										<Form.Item*/}
		{/*											name="username"*/}
		{/*											validateTrigger="onBlur"*/}
		{/*											rules={[*/}
		{/*												{ required: true },*/}
		{/*												{*/}
		{/*													min: 2,*/}
		{/*													message: 'Must be minimum 2 characters.'*/}
		{/*												},*/}
		{/*												{*/}
		{/*													max: 32,*/}
		{/*													message: 'Must not exceed 32 characters.'*/}
		{/*												},*/}
		{/*												({ getFieldValue }) => ({*/}
		{/*													async validator(_, value) {*/}
		{/*														// TODO: find way to trigger username API check on form submit to avoid flood requests*/}
		{/*														// if (!formIsOnFocus && value) {*/}
		{/*														if (value) {*/}
		{/*															const data = {*/}
		{/*																current_user_id:*/}
		{/*																authUserInfo.id,*/}
		{/*																username: username*/}
		{/*															};*/}

		{/*															let available = false;*/}
		{/*															const response =*/}
		{/*																await checkUsernameAvailability(*/}
		{/*																	data*/}
		{/*																);*/}
		{/*															if (response && response.result) {*/}
		{/*																available =*/}
		{/*																	response.availability;*/}
		{/*															}*/}

		{/*															if (!available) {*/}
		{/*																setUserNameHasError('Username not available.')*/}
		{/*																// return Promise.reject(*/}
		{/*																//     new Error(*/}
		{/*																//         'Username not available.'*/}
		{/*																//     )*/}
		{/*																// );*/}
		{/*															}else{*/}
		{/*																setUserNameHasError(null)*/}
		{/*															}*/}
		{/*														}*/}

		{/*														if (*/}
		{/*															value.match(/\ /g) ||*/}
		{/*															/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(*/}
		{/*																value*/}
		{/*															)*/}
		{/*														) {*/}
		{/*															setUserNameHasError('Invalid Input')*/}
		{/*															// return Promise.reject(*/}
		{/*															//     new Error('Invalid Input')*/}
		{/*															// );*/}
		{/*														}else{*/}
		{/*															setUserNameHasError(null)*/}
		{/*														}*/}

		{/*														return Promise.resolve();*/}
		{/*													}*/}
		{/*												})*/}
		{/*											]}*/}
		{/*										>*/}
		{/*											<FormFieldStacked*/}
		{/*												formType='input'*/}
		{/*												type="text"*/}
		{/*												maxLength={32}*/}
		{/*												disabled={submitted}*/}
		{/*												// onChange={console.log(form.getFieldsError()[0])}*/}
		{/*												placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."*/}
		{/*											/>*/}
		{/*										</Form.Item>*/}
		{/*										{(usernameHasError !== null || form.getFieldsError()[0]?.errors.length > 0) && (*/}
		{/*											<div className="alert callout warning">*/}
		{/*												<div className="action">*/}
		{/*													<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*												</div>*/}
		{/*												<div className="content">*/}
		{/*													<div>*/}
		{/*														<h6>{usernameHasError !== null ? usernameHasError : form.getFieldsError()[0]?.errors}</h6>*/}

		{/*													</div>*/}
		{/*												</div>*/}

		{/*											</div>*/}
		{/*										)}*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*								<div className="mb-8">*/}
		{/*									<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">*/}
		{/*										Display Name**/}
		{/*									</label>*/}
		{/*									<div className="relative  rounded-none ">*/}
		{/*										<Form.Item*/}
		{/*											name="display_name"*/}
		{/*											validateTrigger="onBlur"*/}
		{/*											rules={[*/}
		{/*												{ required: true },*/}
		{/*												{*/}
		{/*													max: 32,*/}
		{/*													message: 'Must not exceed 32 characters.'*/}
		{/*												}*/}
		{/*											]}*/}
		{/*										>*/}
		{/*											<FormFieldStacked*/}
		{/*												formType='input'*/}
		{/*												type="text"*/}
		{/*												maxLength={32}*/}
		{/*												disabled={submitted}*/}
		{/*												placeholder="Up to 32 characters."*/}
		{/*											></FormFieldStacked>*/}
		{/*										</Form.Item>*/}
		{/*										{form.getFieldsError()[1]?.errors.length > 0 && (*/}
		{/*											<div className="alert callout warning">*/}
		{/*												<div className="action">*/}
		{/*													<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*												</div>*/}
		{/*												<div className="content">*/}
		{/*													<div>*/}
		{/*														<h6>{form.getFieldsError()[1]?.errors}</h6>*/}

		{/*													</div>*/}
		{/*												</div>*/}

		{/*											</div>*/}
		{/*										)}*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*								<div className="mb-8">*/}
		{/*									<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">*/}
		{/*										Location*/}
		{/*									</label>*/}
		{/*									<div className="relative  rounded-none ">*/}
		{/*										<Form.Item*/}
		{/*											name="location"*/}
		{/*											validateTrigger="onBlur"*/}
		{/*											rules={[*/}
		{/*												{*/}
		{/*													max: 32,*/}
		{/*													message: 'Must not exceed 32 characters.'*/}
		{/*												}*/}
		{/*											]}*/}
		{/*										>*/}
		{/*											<FormFieldStacked*/}
		{/*												formType='input'*/}
		{/*												type="text"*/}
		{/*												maxLength={32}*/}
		{/*												disabled={submitted}*/}
		{/*												placeholder="Up to 32 characters. This displays on your Profile and is separate from your private City location."*/}
		{/*											></FormFieldStacked>*/}
		{/*										</Form.Item>*/}
		{/*										{form.getFieldsError()[2]?.errors.length > 0 && (*/}
		{/*											<div className="alert callout warning">*/}
		{/*												<div className="action">*/}
		{/*													<i className="fa-solid fa-triangle-exclamation"></i>*/}
		{/*												</div>*/}
		{/*												<div className="content">*/}
		{/*													<div>*/}
		{/*														<h6>{form.getFieldsError()[2]?.errors}</h6>*/}
		{/*													</div>*/}
		{/*												</div>*/}
		{/*											</div>*/}
		{/*										)}*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*								<div className="mb-8">*/}
		{/*									<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">*/}
		{/*										Bio*/}
		{/*									</label>*/}
		{/*									<div className="relative  rounded-none ">*/}
		{/*										<Form.Item*/}
		{/*											name="bio"*/}
		{/*											validateTrigger="onBlur"*/}
		{/*											rules={[*/}
		{/*												{*/}
		{/*													max: 255,*/}
		{/*													message: 'Must not exceed 255 characters.'*/}
		{/*												}*/}
		{/*											]}*/}
		{/*										>*/}
		{/*											<FormFieldStacked*/}
		{/*												formType='textarea'*/}
		{/*												type="text"*/}
		{/*												maxLength={255}*/}
		{/*												disabled={submitted}*/}
		{/*												placeholder="Up to 255 characters."*/}
		{/*											></FormFieldStacked>*/}
		{/*										</Form.Item>*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*								<div className="mb-8">*/}
		{/*									<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">*/}
		{/*										Website*/}
		{/*									</label>*/}
		{/*									<div className="relative  rounded-none ">*/}
		{/*										<Form.Item*/}
		{/*											name="website"*/}
		{/*											onBlur={websiteOnBlur}*/}
		{/*											validateTrigger="onBlur"*/}
		{/*											rules={[*/}
		{/*												{*/}
		{/*													// type: 'url',*/}
		{/*													pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,*/}
		{/*													message: 'Please Enter a valid URL'*/}
		{/*												}*/}
		{/*											]}*/}
		{/*										>*/}
		{/*											<FormFieldStacked*/}
		{/*												formType='input'*/}
		{/*												type="text"*/}
		{/*												disabled={submitted}*/}
		{/*												placeholder="www.domain.com">*/}
		{/*											</FormFieldStacked>*/}
		{/*										</Form.Item>*/}
		{/*									</div>*/}

		{/*								</div>*/}
		{/*								<div className="cs-stack-form-link-icons mb-8">*/}
		{/*									<p>Links</p>*/}
		{/*									<div className="cs-stack-link-icons stack-row-wrap">*/}
		{/*										<a href="https://x.com" target="_blank" className="button-icon-round-gradient-alt active"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*/}
		{/*										<a href="https://instagram.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*/}
		{/*										<a href="https://facebook.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*/}
		{/*										<a href="https://tiktok.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-tiktok"></i></p></span></a>*/}
		{/*										<a href="https://linkedin.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>*/}
		{/*										<a href="https://twitch.tv" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-twitch"></i></p></span></a>*/}
		{/*										<a href="" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-regular fa-globe"></i></p></span></a>*/}
		{/*									</div>*/}
		{/*								</div>*/}


		{/*							</div>*/}
		{/*						</div>*/}
		{/*						<div className="grid grid-cols-12 md:gap-8 cs-stack-form-buttons">*/}
		{/*							<div className="col-span-6 xl:col-span-4 mb-8">*/}
		{/*								<DropdownWithItems*/}
		{/*									title="Gender"*/}
		{/*									customButtonClass="btn-lg btn-secondary w-full   h-[40px] rounded-full text-base cursor-pointer  z-50  text-white flex place-content-around mx-auto px-[2px] py-[2px]"*/}
		{/*									icon="fa-regular fa-person-half-dress gradient-color-txt pl-[15px] !text-base"*/}
		{/*									selectedValue={selectedGender}*/}
		{/*									listItems={gendersList}*/}
		{/*									onDropdownChange={setSelectedGender}*/}
		{/*								/>*/}
		{/*							</div>*/}
		{/*							<div className="col-span-6 xl:col-span-4 mb-8">*/}
		{/*								<DropdownWithItems*/}
		{/*									title="Pronouns"*/}
		{/*									customButtonClass="btn-lg btn-secondary w-full  h-[40px] rounded-full text-base cursor-pointer  z-50  text-white flex place-content-around mx-auto px-[2px] py-[2px]"*/}
		{/*									icon="fa-regular fa-user gradient-color-txt pl-[15px] !text-base"*/}
		{/*									selectedValue={selectedPronouns}*/}
		{/*									listItems={pronounsList}*/}
		{/*									onDropdownChange={setSelectedPronouns}*/}
		{/*								/>*/}
		{/*							</div>*/}

		{/*						</div>*/}
		{/*						{alert && (*/}
		{/*							<div className="grid grid-cols-12 md:gap-8 my-2 mb-8">*/}
		{/*								<div className="col-span-12">*/}
		{/*									<Alert*/}
		{/*										key={alert.variant}*/}
		{/*										variant={alert.variant}*/}
		{/*										className={*/}
		{/*											'custom-alert ' +*/}
		{/*											(alert.variant === 'danger'*/}
		{/*												? 'custom-alert-danger'*/}
		{/*												: 'custom-alert-success')*/}
		{/*										}*/}
		{/*									>*/}
		{/*										{alert.message}*/}
		{/*									</Alert>*/}
		{/*								</div>*/}
		{/*							</div>*/}
		{/*						)}*/}
		{/*						<Form.Item className="w-full">*/}
		{/*							<button disabled={submitted || formHasError} type="submit" className="primary-gradient w-full">*/}
		{/*								<span>*/}
		{/*									<p>*/}
		{/*										{submitted && (*/}
		{/*											<i className="fa-solid fa-spinner fa-spin  mr-2"></i>*/}
		{/*										)}*/}
		{/*										Save Profile*/}
		{/*									</p>*/}
		{/*								</span>*/}
		{/*							</button>*/}
		{/*						</Form.Item>*/}
		{/*					</Form>*/}
		{/*				</div>*/}
		{/*			</div>*/}
		{/*			<aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block relative" >*/}
		{/*				<div className="cs-profile-preview card sticky top-2gutter-xl" style={cardBackground}>*/}
		{/*					{cardBackgroundType && cardBackgroundType === 'video' && profileBackgroundVideoBlobPreview && (*/}
		{/*						<video*/}
		{/*							autoPlay*/}
		{/*							muted*/}
		{/*							loop*/}
		{/*							className="video-profile-background curve absolute"*/}
		{/*						>*/}
		{/*							<source*/}
		{/*								src={profileBackgroundVideoBlobPreview}*/}
		{/*								type="video/mp4"*/}
		{/*							/>*/}
		{/*						</video>*/}
		{/*					)}*/}
		{/*					<div className="cs-stack-profile-preview stack-col relative">*/}
		{/*						<div className="form-input-media lg circle air nohover overflow-hidden" >*/}
		{/*							{profileAssetType && profileAssetType === 'video' ? (*/}
		{/*								<div className="video-profile-background-container">*/}
		{/*									<video*/}
		{/*										autoPlay*/}
		{/*										muted*/}
		{/*										loop*/}
		{/*										className="video-profile-avatar"*/}
		{/*									>*/}
		{/*										<source*/}
		{/*											src={profileVideoBlobPreview}*/}
		{/*											type="video/mp4"*/}
		{/*										/>*/}
		{/*									</video>*/}
		{/*								</div>*/}
		{/*							) : (*/}
		{/*								profilePhotoBlobPreview && (*/}
		{/*									<img*/}
		{/*										className="h-full w-full"*/}
		{/*										src={profilePhotoBlobPreview}*/}
		{/*									/>*/}
		{/*								)*/}
		{/*							)}*/}
		{/*						</div>*/}
		{/*						<hgroup className='z-index-ontop'>*/}
		{/*							<p className="text-p1 active">@{username ? username : 'username'}</p>*/}
		{/*							<h1>{displayName ? displayName : 'Display Name'}</h1>*/}
		{/*							<p className="text-p2 italic">{selectedPronouns*/}
		{/*								? getListDisplay(PRONOUNS, selectedPronouns)*/}
		{/*								: 'Pronouns'}</p>*/}
		{/*						</hgroup>*/}
		{/*						<p className="italic z-index-ontop">{bio ? bio : 'Short Bio goes here'}</p>*/}
		{/*						<div className="cs-stack-icons stack-row-wrap z-index-ontop">*/}
		{/*							<i className="fa-brands fa-x-twitter"></i>*/}
		{/*							<i className="fa-brands fa-instagram active"></i>*/}
		{/*							<i className="fa-brands fa-tiktok"></i>*/}
		{/*							<i className="fa-brands fa-linkedin-in"></i>*/}
		{/*							<i className="fa-brands fa-twitch"></i>*/}
		{/*							<i className="fa-regular fa-globe"></i>*/}
		{/*						</div>*/}
		{/*					</div>*/}
		{/*				</div>*/}
		{/*			</aside>*/}
		{/*		</div>*/}
		{/*	</section>*/}
		{/*</main>*/}
		</>
	);
};

export default memo(ProfileEdit);
